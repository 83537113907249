// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Badge,
} from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import getFilterOperators from 'utils/getFilterOperators';
import IndividualIcon from 'assets/imgs/Individual_icon.png';
import BusinessIcon from 'assets/imgs/Business_icon.png';

import {
  PAGES,
  FILTERS_TYPES,
  BENE_TYPES,
  COUNTRIES,
} from 'appconstants';
import {
  SpButton,
  FilterWrapper,
  SpText,
  SpStatusTag,
} from 'components/DesignKit';

import { fundingSourceActions, transfersActions, filterParamsActions } from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import moment from 'moment-timezone';

import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';

import getFundingSourceBackground from 'utils/getFundingSourceBackground';
import getFundingSourceStatus from 'utils/getFundingSourceStatus';

import AddFundingSource from './components/AddFundingSource';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const FUNDINGSOURCE_FILTERS_TYPES = FILTERS_TYPES.FUNDINGSOURCE;

type Props = {
  fetchFundingSourceList: Function,
  getContactList: Function,
  createFundingSource: Function,
  fundingSource: Array<Object>,
  contactList: Array<Objec>,
  loading: boolean,
  test: boolean,
  submitting: boolean,
  validator: boolean,
  totalElements: number,
  history: {
    push: Function,
  },
  selectedAccount: Object,
  setFilterData: Function,
  preserveFilters: Object,
};

const FundingSourceList = (props: Props) => {
  const {
    loading,
    fetchFundingSourceList,
    getContactList,
    createFundingSource,
    contactList,
    fundingSource,
    totalElements,
    test,
    history,
    submitting,
    validator,
    selectedAccount,
    setFilterData,
    preserveFilters,
  } = props;

  const location = useLocation();
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({});
  const [filterTypes, setFilterTypes] = useState(FUNDINGSOURCE_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterFundingSource, setFilterFundingSource] = useState((preserveFilters && preserveFilters.filterFundingSource)
    ? (preserveFilters && preserveFilters.filterFundingSource)
    : [{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const [addFundingSourceModal, setAddFundingSourceModal] = useState(false);
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterFundingSource: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        filterValueFrom: '',
        filterValueTo: '',
        checked: true,
      }],
    });

  const exportData = fundingSource ? fundingSource.map((values) => ({
    name: values.contact && values.contact.name,
    bname: values.issuerBank && values.issuerBank.bankName,
    status: getFundingSourceStatus(values.status),
    issuerBank: (values.issuerBank)
      ? (values.issuerBank.bankName)
        ? (`${values.issuerBank.bankName} ****${values.issuerBank.lastFour}`)
        : `****${values.issuerBank.lastFour}` : '',
    createdAt: moment
      .utc(values.createdAt)
      .tz(selectedAccount && selectedAccount.timezone)
      .format('MMM DD, YYYY, hh:mm a'),
  })) : '';

  const exportHeaders = [
    { label: 'CONTACT', key: 'name' },
    { label: 'BANK NAME', key: 'bname' },
    { label: 'STATUS', key: 'status' },
    { label: 'FUNDING SOURCE', key: 'issuerBank' },
    { label: 'DATE', key: 'createdAt' },
  ];

  const debouncedFetchFundingSourceList = debounce(fetchFundingSourceList, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchFundingSourceList({ currentPage, sortParams, filterParams });
    getContactList();
  };

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (test) {
      getData(location.search);
    } else if (selectedAccount && selectedAccount.issuer) {
      getData(location.search);
    }
  }, [test, selectedAccount, location]);

  useEffect(() => {
    if (!validator) {
      getData();
      setAddFundingSourceModal(false);
    }
  }, [validator]);

  const createNewFundingSource = (transferInfo: Object) => {
    const {
      contactGid,
      contactAddGid,
      accountNumber,
      routingNumber,
      accountType,
      bankName,
      upiId,
      transferType,
      businessType,
      accountHolderName,
      nickName,
      countryCode,
    } = transferInfo;

    let issuerBank;
    if (transferType === BENE_TYPES.UPI) {
      issuerBank = {
        beneType: BENE_TYPES.VPA,
        vpa: upiId,
        countryCode,
      };
    } else {
      issuerBank = {
        accountNumber,
        routingNumber,
        accountType,
        businessType,
        bankName,
        beneType: BENE_TYPES.BANK,
        countryCode,
        accountHolderName,
      };
    }
    createFundingSource({
      contactGid: contactAddGid || contactGid,
      issuerBank,
      nickName,
    });
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [fundingSource]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.FUNDINGSOURCE,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.FUNDINGSOURCE,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const onCheck = (e, index) => {
    const list = [...filterFundingSource];
    if (filterFundingSource.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = FUNDINGSOURCE_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterFundingSource(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterFundingSource];
    if (e[1] === 'createdAt') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'amount') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'contactType') {
      list[index].filterType = 'Dropdown';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = FUNDINGSOURCE_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterFundingSource(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterFundingSource];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setFilterFundingSource(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setFilterFundingSource(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterFundingSource];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setFilterFundingSource(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setFilterFundingSource(list);
    }
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterFundingSource[index].filterName === 'createdAt') {
      if (filterFundingSource[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).endOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterFundingSource[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterFundingSource[index].filterOperator === 'GT') {
        const utcDate = moment(e).endOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterFundingSource];
    list[index].filterValue = selectedValue;
    setFilterFundingSource(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterFundingSource];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterFundingSource[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterFundingSource(list);
  };

  const handleAddClick = () => {
    const list = [...filterFundingSource];
    setFilterFundingSource([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  };

  const getIsDefault = (value) => {
    if (value === true) return 'Yes';
    return 'No';
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterFundingSource([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(FUNDINGSOURCE_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.FUNDINGSOURCE);
  };

  const applyFilters = () => {
    const fundingSourceFilters = filterFundingSource.filter((item) => item.checked);
    for (let i = 0; i < fundingSourceFilters.length; i += 1) {
      if (!fundingSourceFilters[i].filterName || !fundingSourceFilters[i].filterOperator || !fundingSourceFilters[i].filterValue) {
        return;
      }
    }
    if (fundingSourceFilters[0].filterValue || (fundingSourceFilters[0].filterValueFrom && fundingSourceFilters[0].filterValueTo)) {
      setCount(fundingSourceFilters && fundingSourceFilters.length);
      for (let i = 0; i < fundingSourceFilters.length; i += 1) {
        if (fundingSourceFilters[i].checked) {
          if (fundingSourceFilters[i].filterName === 'createdAt') {
            fundingSourceFilters[i] = fundingSourceFilters[i].filterValue;
          } else if (fundingSourceFilters[i].filterName === 'amount' && fundingSourceFilters[i].filterOperator === 'BT') {
            const name = fundingSourceFilters[i].filterName;
            const from = fundingSourceFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = fundingSourceFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            fundingSourceFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (fundingSourceFilters[i].filterName === 'amount' && fundingSourceFilters[i].filterOperator !== 'BT') {
            const value = fundingSourceFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            fundingSourceFilters[i] = `${fundingSourceFilters[i].filterName}.${fundingSourceFilters[i].filterOperator}=${value}`;
          } else {
            // eslint-disable-next-line max-len
            fundingSourceFilters[i] = `${fundingSourceFilters[i].filterName}.${fundingSourceFilters[i].filterOperator}=${fundingSourceFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(fundingSourceFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterFundingSource,
        });
        history.push({
          pathname: PAGES.FUNDINGSOURCE,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterFundingSource,
        });
        history.push({
          pathname: PAGES.FUNDINGSOURCE,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const indColumns = [
    {
      title: 'CONTACT',
      dataIndex: 'contact.name',
      width: '15%',
      align: 'left',
      render: (cname) => (
        <span style={{ cursor: 'pointer' }}>
          {cname || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'BANK NAME',
      dataIndex: 'issuerBank.bankName',
      width: '15%',
      align: 'left',
      render: (bname) => (
        <span style={{ cursor: 'pointer' }}>
          {bname || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'FUNDING SOURCE',
      dataIndex: 'issuerBank',
      width: '20%',
      align: 'left',
      render: (issuerBank, record) => {
        const bankName = ((record.issuerBank) && (record.issuerBank.bankName));
        const lastFour = ((record.issuerBank) && (record.issuerBank.lastFour));
        const icon = record?.issuerBank?.businessType === 'INDIVIDUAL'
          ? <img src={IndividualIcon} alt="individual" style={{ width: '15px', height: '15px' }} />
          : <img src={BusinessIcon} alt="business" style={{ width: '15px', height: '15px' }} />;
        if ((bankName) && (lastFour)) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {bankName} ****{lastFour} {icon}
            </span>
          );
        } if (lastFour) {
          return (
            <span style={{ cursor: 'pointer' }}>
              ****{lastFour} {icon}
            </span>
          );
        } return (
          <span style={{ cursor: 'pointer' }}>
            <>&#8211;</>
          </span>
        );
      },
    },
    {
      title: 'IS DEFAULT',
      dataIndex: 'isDefault',
      width: '15%',
      align: 'left',
      render: (isDefault) => (
        <span>
          {getIsDefault(isDefault) || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  const columns = [
    {
      title: 'CONTACT',
      dataIndex: 'contact.name',
      width: '15%',
      align: 'left',
      render: (cname) => (
        <span style={{ cursor: 'pointer' }}>
          {cname || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'BANK NAME',
      dataIndex: 'issuerBank.bankName',
      width: '15%',
      align: 'left',
      render: (bname) => (
        <span style={{ cursor: 'pointer' }}>
          {bname || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'issuerBank.status',
      width: '15%',
      align: 'left',
      render: (status) => (
        <span>
          <SpStatusTag style={{ backgroundColor: getFundingSourceBackground(status) }}>
            {getFundingSourceStatus(status)}
          </SpStatusTag>
        </span>
      ),
    },
    {
      title: 'FUNDING SOURCE',
      dataIndex: 'issuerBank',
      width: '20%',
      align: 'left',
      render: (issuerBank, record) => {
        const bankName = ((record.issuerBank) && (record.issuerBank.bankName));
        const lastFour = ((record.issuerBank) && (record.issuerBank.lastFour));
        const icon = record?.issuerBank?.businessType === 'INDIVIDUAL'
          ? <img src={IndividualIcon} alt="individual" style={{ width: '15px', height: '15px' }} />
          : <img src={BusinessIcon} alt="business" style={{ width: '15px', height: '15px' }} />;
        if ((bankName) && (lastFour)) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {bankName} ****{lastFour} {icon}
            </span>
          );
        } if (lastFour) {
          return (
            <span style={{ cursor: 'pointer' }}>
              ****{lastFour} {icon}
            </span>
          );
        } return (
          <span style={{ cursor: 'pointer' }}>
            <>&#8211;</>
          </span>
        );
      },
    },
    {
      title: 'IS DEFAULT',
      dataIndex: 'isDefault',
      width: '15%',
      align: 'left',
      render: (isDefault) => (
        <span>
          {getIsDefault(isDefault) || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  const selectedRecord = (record) => {
    const fundingSourceGid = record?.gid;
    history.push(`${PAGES.FUNDINGSOURCE_DETAILS}/${fundingSourceGid}`);
  };

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <Col>
            <SpButton
              type="secondary"
              shape="round"
              ghost
              className="mr-3"
              onClick={() => setAddFundingSourceModal(true)}
            >
              {'\u002B'}&nbsp;Funding Source
            </SpButton>
            <span>
              {
                exportData !== null
                  ? (
                    <Tooltip placement="left" title="Click here to export Funding Sources">
                      <CSVLink
                        target="_blank"
                        className="mr-3"
                        filename="Funding Sources Report.csv"
                        data={exportData}
                        headers={exportHeaders}
                        onMouseEnter={() => setFillColor(true)}
                        onMouseLeave={() => setFillColor(false)}
                      >
                        <ExportIcon
                          fill={fillColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </CSVLink>
                    </Tooltip>
                  ) : null
              }
            </span>
            <span ref={myRef}>
              <span
                onClick={() => setOpen(!open)}
                style={{ cursor: 'pointer' }}
                onMouseEnter={() => setFilterColor(true)}
                onMouseLeave={() => setFilterColor(false)}
              >
                {open && (
                  <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                    <FilterIcon
                      fill={filterColor
                        ? '#279dfe'
                        : '#C0C0C0'}
                    />
                  </Badge>
                )}
                {!open && (
                  <Tooltip placement="left" title="Click here to filter Funding Sources">
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  </Tooltip>
                )}
              </span>
              {open && (
                <FilterWrapper
                  style={{ marginLeft: '-95px' }}
                >
                  <Filters
                    filtersGrid={filterFundingSource}
                    filterTypes={filterTypes}
                    handleChange={handleChange}
                    onCheck={onCheck}
                    fiterOperators={fiterOperators}
                    handleOperator={handleOperator}
                    handleValue={handleValue}
                    startDate={startDate}
                    endDate={endDate}
                    currentDate={currentDate}
                    handleFromValue={handleFromValue}
                    handleToValue={handleToValue}
                  />
                  <div className="px-4 mt-2">
                    {
                      filterFundingSource.length < FUNDINGSOURCE_FILTERS_TYPES.length && (
                        <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                          <SpText
                            fontWeight="600"
                            color="#279dfe"
                            onClick={handleAddClick}
                          >
                            +&nbsp; Add another filter
                          </SpText>
                        </div>
                      )
                    }
                  </div>
                  <div className="d-flex mb-2 justify-content-end mr-3">
                    <SpButton
                      type="secondary"
                      shape="round"
                      className="mr-2"
                      ghost
                      onClick={resetFilters}
                    >
                      Clear
                    </SpButton>
                    <SpButton
                      type="primary"
                      shape="round"
                      onClick={applyFilters}
                    >
                      Save
                    </SpButton>
                  </div>
                </FilterWrapper>
              )}
            </span>
          </Col>
        </div>
      </Row>
      {/* ADD FUNDING SOURCE MODAL */}
      {addFundingSourceModal && (
        <AddFundingSource
          visible={addFundingSourceModal}
          submitting={submitting}
          selectedAccount={selectedAccount}
          contactList={contactList}
          country={selectedAccount.country.name}
          cntryId={selectedAccount.country.id}
          isIndia={isIndia}
          close={() => setAddFundingSourceModal(false)}
          submit={createNewFundingSource}
        />
      )}
      <Table
        rowClassName={() => 'ant-table-clickable-row'}
        columns={isIndia ? indColumns : columns}
        rowKey={(record) => record.gid}
        dataSource={fundingSource}
        pagination={pagination}
        onRow={(record) => ({
          onClick: () => {
            selectedRecord(record);
          },
        })}
        onChange={handleTableChange}
      />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  fundingSource: state.fundingSource.content,
  contactList: state.transfers.contactList,
  loading: state.loading.loading,
  test: state.account.test,
  totalElements: state.fundingSource.totalElements,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  preserveFilters: state.filterParams.preserveFilters,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFundingSourceList: param => dispatch(fundingSourceActions.fetchFundingSourceList({
    payload: param,
  })),
  createFundingSource: param => dispatch(fundingSourceActions.createFundingSource({
    payload: param,
  })),
  getContactList: param => dispatch(transfersActions.getContactList({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(FundingSourceList);
