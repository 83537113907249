import { EXCHANGE_RATES } from '../actionTypes';

export const getExchangeRatesList = (action) => ({
  type: EXCHANGE_RATES.FETCH,
  payload: action.payload,
});
export const getExchangeRateForAmount = (action) => ({
  type: EXCHANGE_RATES.FETCH_AMOUNT,
  payload: action.payload,
});

export const addExchangeRate = (action) => ({
  type: EXCHANGE_RATES.ADD,
  payload: action.payload,
});

export const updateExchangeRate = (action) => ({
  type: EXCHANGE_RATES.UPDATE,
  payload: action.payload,
});

export const deleteExchangeRate = (action) => ({
  type: EXCHANGE_RATES.DELETE,
  payload: action.payload,
});
