/* eslint-disable */
import React from 'react';

const enabledBadge = () => (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.1299 14L3.84314 11.8725L1.42402 11.3235L1.64706 8.85294L0 7L1.64706 5.14706L1.42402 2.67647L3.84314 2.12745L5.1299 0L7.41176 0.977941L9.69363 0L10.9804 2.12745L13.3995 2.67647L13.1765 5.14706L14.8235 7L13.1765 8.85294L13.3995 11.3235L10.9804 11.8725L9.69363 14L7.41176 13.0221L5.1299 14Z" fill="#14A482" />
        <path d="M7.44149 11.2001C7.33038 11.2001 7.23408 11.1593 7.1526 11.0778C7.07112 10.9964 7.03038 10.8927 7.03038 10.7667V10.3112C6.71927 10.2297 6.44149 10.0964 6.19705 9.91118C5.9526 9.72599 5.76742 9.50007 5.64149 9.2334C5.58964 9.12969 5.58593 9.02229 5.63038 8.91118C5.67482 8.80007 5.7563 8.72229 5.87482 8.67784C5.98593 8.64081 6.09705 8.63895 6.20816 8.67229C6.31927 8.70562 6.40445 8.77414 6.46371 8.87784C6.58964 9.08525 6.74334 9.24636 6.92482 9.36118C7.1063 9.47599 7.29704 9.5334 7.49704 9.5334C7.77853 9.5334 8.01556 9.45562 8.20816 9.30007C8.40075 9.14451 8.49704 8.95562 8.49704 8.7334C8.49704 8.51118 8.41742 8.3297 8.25816 8.18895C8.0989 8.04821 7.81186 7.90377 7.39704 7.75562C6.76742 7.5334 6.32667 7.30007 6.07482 7.05562C5.82297 6.81118 5.69705 6.48895 5.69705 6.08895C5.69705 5.72599 5.82112 5.40562 6.06927 5.12784C6.31742 4.85007 6.63779 4.67784 7.03038 4.61118V4.16673C7.03038 4.04081 7.06927 3.9371 7.14705 3.85562C7.22482 3.77414 7.31927 3.7334 7.43038 3.7334C7.54149 3.7334 7.63593 3.77414 7.71371 3.85562C7.79149 3.9371 7.83038 4.04081 7.83038 4.16673V4.61118C8.07482 4.64821 8.29704 4.72784 8.49704 4.85007C8.69704 4.97229 8.86001 5.12969 8.98593 5.32229C9.0526 5.42599 9.06927 5.5334 9.03593 5.64451C9.0026 5.75562 8.93038 5.8334 8.81927 5.87784C8.71556 5.91488 8.6063 5.91488 8.49149 5.87784C8.37667 5.84081 8.27112 5.77044 8.17482 5.66673C8.09334 5.57044 7.99149 5.49821 7.86927 5.45007C7.74705 5.40192 7.61186 5.37784 7.46371 5.37784C7.20445 5.37784 6.98964 5.44451 6.81927 5.57784C6.6489 5.71118 6.56371 5.87414 6.56371 6.06673C6.56371 6.25932 6.66556 6.42969 6.86927 6.57784C7.07297 6.72599 7.41927 6.88155 7.90816 7.04451C8.41927 7.21488 8.78964 7.4334 9.01927 7.70007C9.2489 7.96673 9.36371 8.31118 9.36371 8.7334C9.36371 9.1334 9.21927 9.4834 8.93038 9.7834C8.64149 10.0834 8.27482 10.2704 7.83038 10.3445V10.7667C7.83038 10.8927 7.79149 10.9964 7.71371 11.0778C7.63593 11.1593 7.54519 11.2001 7.44149 11.2001Z" fill="white" />
    </svg>
);

export default enabledBadge;