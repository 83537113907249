import Login from 'routes/Login';
import AuthChallenge from 'routes/AuthChallenge';
import ForgotPassword from 'routes/ForgotPassword';
import ResetPassword from 'routes/ResetPassword';
import Register from 'routes/Register';
import Account from 'routes/Account';
import AccountSetup from 'routes/AccountSetup';
import LoginError from 'routes/LoginError';
import UnlockAccount from 'routes/UnlockAccount';

import { ROLES } from 'appconstants';

import AdminOverview from 'routes/AdminOverview';

import NoMatch from 'routes/NoMatch';
import Docusign from 'routes/Docusign';
import DocusignLater from 'routes/DocusingLater';
import FeedbackRating from 'routes/FeedbackRating';

import Dashboard from 'routes/Dashboard';
import Customers from 'routes/Customers/components/CustomersList';
import CustomerDetails from 'routes/Customers/components/CustomersDetails';
import Payments from 'routes/Payments';
import AllPayments from 'routes/Payments/PaymentList/components/AllPayments';
import Payouts from 'routes/Payouts';
import Disputes from 'routes/Balance/Disputes';
import Transactions from 'routes/Balance/Transactions';
import Integrations from 'routes/Integrations';
import Team from 'routes/Team';
import Settings from 'routes/Settings';
import AccountSettings from 'routes/Settings/components/AccountSettings';
import BusinessSettings from 'routes/Settings/components/BusinessSettings';
import SupportSettings from 'routes/Settings/components/SupportSettings';
import ProductsSettings from 'routes/Settings/components/Products';
import Reminders from 'routes/Settings/components/Reminders';
import AchPayments from 'routes/Settings/components/AchPayments';
import BankTransfer from 'routes/Settings/components/BankTransfer';
import TransferSettings from 'routes/Settings/components/TransferSettings';
import RewardSettings from 'routes/Settings/components/RewardSettings';
import USOnboarding from 'routes/Settings/components/USOnboarding';
import InternalCloverWebhook from 'routes/Settings/components/CloverWebhook';
import CloverSync from 'routes/Settings/components/CloverSync';
import Developer from 'routes/Developer';
import Report from 'routes/Report';
import AdminReport from 'routes/AdminReport';
import ScheduleReport from 'routes/ScheduleReport';
import AdminScheduleReport from 'routes/AdminScheduleReport';
import Support from 'routes/Support';
import Terminals from 'routes/Terminals/component/AllTerminals';
import TerminalDetails from 'routes/Terminals/component/AllTerminals/components/TerminalDetails';
import Locations from 'routes/Terminals/component/Locations/component/LocationList';
import LocationDetails from 'routes/Terminals/component/Locations/component/LocationDetails';
import PayoutDetails from 'routes/Payouts/component/PayoutDetails';
import PaymentDetails from 'routes/Payments/PaymentList/components/PaymentDetails';
import PaymentPagesEntries from 'routes/Payments/PaymentList/components/PaymentDetails/component/PaymentPagesEntries';
import UserProfile from 'routes/UserProfile';
import Receipt from 'routes/Receipt';
import OrderReceipt from 'routes/OrderReceipt';
import DisputeEvidence from 'routes/Payments/PaymentList/components/DisputeEvidence';
import PaymentsLink from 'routes/PaymentsLink';
import PaymentsLinkDetails from 'routes/PaymentsLink/components/PaymentsLinkDetails';
import NewAccount from 'routes/NewAccount';
import Subscriptions from 'routes/SubscriptionsV2';
import SubscriptionDetails from 'routes/SubscriptionsV2/components/SubscriptionDetails';
import PlanDetails from 'routes/SubscriptionsV2/components/PlanDetails';
import Invoices from 'routes/Invoices';
import InvoicesDetails from 'routes/Invoices/components/InvoicesDetails';
import NewInvoice from 'routes/Invoices/components/NewInvoice';
import Products from 'routes/Products';
import PaymentPages from 'routes/PaymentPages';
import PaymentPagesDetails from 'routes/PaymentPages/components/PaymentPagesDetails';
import AddPaymentPage from 'routes/AddPaymentPage';
import UpdatePaymentPage from 'routes/UpdatePaymentPage';
import PaymentsButton from 'routes/PayamentsButton/PaymentsButtonList';
import PaymentsButtonDetails from 'routes/PayamentsButton/PaymentsButtonDetails';
import SubscriptionButton from 'routes/SubscriptionButtonV2/SubscriptionButtonList';
import SubscriptionButtonDetails from 'routes/SubscriptionButtonV2/SubscriptionButtonDetails';
import Webhooks from 'routes/Settings/components/Webhooks';
import QuickBooks from 'routes/Integrations/QuickBooks';
import Referrals from 'routes/Settings/components/Referrals';
import CustomDocuments from 'routes/Settings/components/CustomDocuments';
import Admin from 'routes/Admin';
import Rates from 'routes/Rates/AllRates';
import RatesDetails from 'routes/Rates/AllRates/components/RatesDetails';
import IssuerRates from 'routes/Rates/AllRates/components/IssuerRatesList';
import ExchangeRates from 'routes/Rates/AllRates/components/ExchangeRatesList';
import IssuerRatesDetails from 'routes/Rates/AllRates/components/IssuerRatesDetails';
import AdminAcquirer from 'routes/AdminAcquirer';
import Acquirer from 'routes/AdminAcquirer/components/Acquirer';
import Issuer from 'routes/AdminAcquirer/components/Issuer';
import Settlements from 'routes/Settlements';
import TaxRate from 'routes/TaxRates';
import Coupons from 'routes/Coupons';
import CouponDetails from 'routes/Coupons/components/CouponsDetails';
import Offers from 'routes/Offers';
import OfferDetails from 'routes/Offers/components/OffersDetails';
import Partner from 'routes/Partner';
import PartnerTransactions from 'routes/Partner/TransactionsList';
import PartnerEarnings from 'routes/Partner/Earnings';
import PartnerSettlement from 'routes/Partner/Settlement';
import SalesForce from 'routes/Integrations/SalesForce';
import SalesForceDetails from 'routes/Integrations/SalesForceDetails';
import Configure from 'routes/Integrations/SalesForceDetails/components/Configure';
import UpdateCredentials from 'routes/Integrations/SalesForceDetails/components/UpdateCredentials';
import SalesForceInvoice from 'routes/Integrations/SalesForceDetails/components/Configure/Invoices';
import SalesForceUpdateInvoice from 'routes/Integrations/SalesForceDetails/components/UpdateInvoice';
import AdminTerminal from 'routes/AdminTerminal/AdminTerminalList';
import AdminTerminalDetails from 'routes/AdminTerminal/AdminTerminalDetails';
import Release from 'routes/ReleaseVersion';
import SpField from 'routes/SpField';
import PosKeys from 'routes/PosKeys';
import GlobalRestrictedKey from 'routes/GlobalRestrictedKey';
import PaymentWidgets from 'routes/PaymentWidgets/PaymentWidgetsList';
import PaymentWidgetsDetails from 'routes/PaymentWidgets/PaymentWidgetsDetails';
import AddPaymentWidget from 'routes/AddPaymentWidgets';
import SpObject from 'routes/SpObject';
import NewRegister from 'routes/NewRegister';
import Posuser from 'routes/PosUser';
import CashList from 'routes/Balance/Transactions/components/CashList';
import ChequeList from 'routes/Balance/Transactions/components/ChequeList';
import CashDetails from 'routes/Balance/Transactions/components/CashDetails';
import ChequeDetails from 'routes/Balance/Transactions/components/ChequeDetails';
import CashReceipt from 'routes/Balance/Transactions/components/CashDetails/CashReceipt';
import ChequeReceipt from 'routes/Balance/Transactions/components/ChequeDetails/ChequeReceipt';
import InternalUsers from 'routes/InternalUsers';
import Services from 'routes/Terminals/component/Services';
import ServiceDetails from 'routes/Terminals/component/Services/components/ServiceDetails';
import Documents from 'routes/Documents';
import Transfers from 'routes/Transfers/TransfersList';
import AllTransfers from 'routes/Transfers/TransfersList/components/AllTransfers';
import AdminBin from 'routes/AdminBin';
import PaymentPageRecipt from 'routes/PaymentPages/components/PaymentPagesRecipts';
import Vendors from 'routes/Vendors/VendorsList';
import VendorsDetails from 'routes/Vendors/VendorsDetails';
import FundingSourceDetails from 'routes/Vendors/FundingSourceDetails';
import TransfersDetails from 'routes/Transfers/TransfersList/components/TransferDetails';
import FundingSources from 'routes/FundingSources';
import TransferLinks from 'routes/TransferLinks';
import BulkTransfer from 'routes/BulkTransfer/BulkTransferList';
import BulkTransferDetails from 'routes/BulkTransfer/BulkTransferDetails';
import TransferReceipt from 'routes/Transfers/TransfersList/components/TransferDetails/TransferReceipt';
import PayamentCheckout from 'routes/PayamentCheckout/PaymentCheckoutList';
import PaymentCheckoutDetails from 'routes/PayamentCheckout/PaymentCheckoutDetails';
import CustomDocumentsDetails from 'routes/Settings/components/CustomDocumentsDetails';
import AdminPayments from 'routes/AdminPayments/PaymentList';
import AdminPaymentDetails from 'routes/AdminPayments/PaymentDetails';
import PaymentSettlement from 'routes/PaymentSettlement';
import OCRRequest from 'routes/OCRRequest';
import BankStatement from 'routes/BankStatement';
import BankstatementDetails from 'routes/BankStatement/components/BankStatementDetails';
import AccountSlug from 'routes/AccountSlug/AccountSlugList';
import AccountSlugDetails from 'routes/AccountSlug/AccountSlugDetails';
import Wallet from 'routes/Wallet';
import WalletDetails from 'routes/Wallet/components/WalletDetails';
import TransactionsList from 'routes/Wallet/components/TransactionsList';
import TransactionsDetails from 'routes/Wallet/components/TransactionsDetails';
import Setup from 'routes/Wallet/components/Setup';
import StaticQr from 'routes/StaticQr';
import StaticQrDetails from 'routes/StaticQr/component/StaticQrDetails';
import CustomReportTemplate from 'routes/CustomReportTemplate/CustomReportTemplateList';
import CustomReportTemplateDetails from 'routes/CustomReportTemplate/CustomReportTemplateDetails';
import CustomReport from 'routes/CustomReport/CustomReportList';
import CustomReportDetails from 'routes/CustomReport/CustomReportDetails';
import VendorInvoice from 'routes/VendorInvoices';
import NewVendorInvoice from 'routes/VendorInvoices/components/NewInvoice';
import VendorInvoicesDetails from 'routes/VendorInvoices/components/InvoicesDetails';
import Beneficiary from 'routes/Beneficiary/BeneficiaryList';
import BeneficiaryDetails from 'routes/Beneficiary/BeneficiaryDetails';
import WalletVendorInvoice from 'routes/WalletVendorInvoices';
import NewWalletVendorInvoice from 'routes/WalletVendorInvoices/components/NewInvoice';
import WalletVendorInvoicesDetails from 'routes/WalletVendorInvoices/components/InvoicesDetails';
import PaymentOrderDetails from 'routes/PaymentPages/components/PaymentPagesDetails/PaymentOrderDetails';
import WooCommerce from 'routes/Integrations/WooCommerce';
import WooCommerceOrderDetails from 'routes/Integrations/WooCommerce/WooCommerceOrderDetails';
import AdminTransfers from 'routes/AdminTransfers/TransferList';
import CloverReceipt from 'routes/CloverReceipt';
import AdminCatalog from 'routes/AdminCatalog';
import AdminOrders from 'routes/AdminCatalog/components/order';
import InventoryOrders from 'routes/InventoryOrders/components/OrdersList';
import InventoryItems from 'routes/InventoryOrders/components/ItemsList';
import InventoryCategories from 'routes/InventoryOrders/components/CategoriesList';
import InventoryModifiers from 'routes/InventoryOrders/components/ModifiersList';
import InventoryMenu from 'routes/InventoryOrders/components/MenuList';
import OrderDetails from 'routes/InventoryOrders/components/OrderDetails';
import ItemDetails from 'routes/InventoryOrders/components/ItemDetails';
import CategorieDetails from 'routes/InventoryOrders/components/CategorieDetails';
import ModifierDetails from 'routes/InventoryOrders/components/ModifierDetails';
import Events from 'routes/AdminEvents/components/EventsList';
import EventsDetails from 'routes/AdminEvents/components/EventsDetails';
import NonSeatedEvents from 'routes/NonSeatedEvents/components/EventsList';
import NonEventsDetails from 'routes/NonSeatedEvents/components/EventsDetails';
import AddSeatedPage from 'routes/AddSeatedPage';
import InventoryOrderTypes from 'routes/InventoryOrders/components/OrderTypes';
import EventPageRecipt from 'routes/AdminEvents/components/EventsRecipts';
import PrinterLabelList from 'routes/InventoryOrders/components/PrinterLabelList';
import PrinterLabelDetails from 'routes/InventoryOrders/components/PrinterLabelDetails';
import ShopsList from 'routes/InventoryOrders/components/ShopsList';
import orderFeedback from 'routes/OrderFeedback';
import ShopsDetails from 'routes/InventoryOrders/components/ShopsDetails';
import CloverWebhook from 'routes/Integrations/CloverWebhook';
import CustomDashboard from 'routes/CustomDashboard';
import OrdersView from 'routes/OrdersView';
import Dining from 'routes/Dining';
import SettingCustomDashboard from 'routes/Settings/components/CustomDashboard';
import Slider from 'routes/Settings/components/Slider';
import DiningList from 'routes/DiningDetails/DiningDetailsList';
import DiningDetails from 'routes/DiningDetails/DiningDetailsView';
import ReservationsList from 'routes/InventoryOrders/components/ReservationsList';
import ReservationsDetails from 'routes/InventoryOrders/components/ReservationsDetails';
import CustomerSupport from 'routes/customerSupport';
import ServiceRates from 'routes/ServiceRates';
import OperatingHours from 'routes/Settings/components/OperatingHours';
import AdvanceFee from 'routes/Settings/components/AdvanceFee';
import PreorderList from 'routes/InventoryOrders/components/PreorderList';
import Transfercenter from 'routes/Transfercenter';
import Inventory from 'routes/Inventory';
import CustomerSolutions from 'routes/CustomerSolutions';
import PayLinksAndPages from 'routes/PayLinksAndPages';
import EventsLinks from 'routes/EventsLinks';
import Sendmoney from 'routes/Sendmoney';
import AccountTransfers from 'routes/AccountTransfers';
import ManageAccounts from 'routes/ManageAccounts';
import ManageAccountDetails from 'routes/ManageAccountDetails';
import AccountSession from 'routes/AccountSession';
import DonationPages from 'routes/DonationPages';
import AddDonationPage from 'routes/AddDonationPage';
import DonationPageDetails from 'routes/DonationPages/components/DonationPageDetails';
import UpdateDonationPage from 'routes/UpdateDonationPage';
import AddEvents from 'routes/AddEvents';
import ViewVendorInvoice from 'routes/AccountSession/ViewVendorInvoice';
import Maintainance from 'routes/Maintainance';

const routes = [
  {
    key: 'login',
    path: '/',
    protected: false,
    exact: true,
    component: Login,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'accountsessions',
    path: '/accountsessions/:id',
    protected: false,
    exact: true,
    component: AccountSession,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'accountsessions-vendorinvoice-details',
    path: '/accountsessions/view/vendorinvoice/:id',
    protected: false,
    exact: true,
    component: ViewVendorInvoice,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'docusign',
    path: '/docusign',
    protected: false,
    exact: true,
    component: Docusign,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'docusignlater',
    path: '/docusignlater',
    protected: false,
    exact: true,
    component: DocusignLater,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'feedbackrating',
    path: '/feedbackrating',
    protected: false,
    exact: true,
    component: FeedbackRating,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'vieworders',
    path: '/vieworders',
    protected: false,
    exact: true,
    component: OrdersView,
    allowedRoles: [
      ROLES.ASSOCIATE,
      ROLES.SUPERUSER,
      ROLES.OWNER,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
      ROLES.ANALYST,
    ],
  },
  {
    key: 'setpassword',
    path: '/setpassword',
    protected: false,
    exact: true,
    component: AuthChallenge,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'forgotpassword',
    path: '/forgotpassword',
    protected: false,
    exact: true,
    component: ForgotPassword,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'resetpassword',
    path: '/resetpassword',
    protected: false,
    exact: true,
    component: ResetPassword,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'register',
    path: '/register',
    protected: false,
    exact: true,
    component: Register,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'maintainance',
    path: '/maintainance',
    protected: false,
    exact: true,
    component: Maintainance,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'newregister',
    path: '/register/new',
    protected: false,
    exact: true,
    component: NewRegister,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'account',
    path: '/account/new',
    protected: false,
    exact: true,
    component: Account,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'account-setup',
    path: '/account/setup',
    protected: false,
    exact: true,
    component: AccountSetup,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'account-error',
    path: '/account/error',
    protected: false,
    exact: true,
    component: LoginError,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'locked',
    path: '/locked',
    protected: false,
    exact: true,
    component: UnlockAccount,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'dashboard',
    path: '/dashboard',
    protected: true,
    exact: true,
    component: Dashboard,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.ANALYST,
      ROLES.SUPPORT,
      // ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'customdashboard',
    path: '/customdashboard',
    protected: true,
    exact: true,
    component: CustomDashboard,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'dining',
    path: '/dining/:gid',
    protected: true,
    exact: true,
    component: Dining,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: 'transfercenter',
    path: '/transfercenter',
    protected: true,
    exact: true,
    component: Transfercenter,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'sendmoney',
    path: '/sendmoney',
    protected: true,
    exact: true,
    component: Sendmoney,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'accounttransfers',
    path: '/accounttransfers',
    protected: true,
    exact: true,
    component: AccountTransfers,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'manageaccounts',
    path: '/manageaccounts',
    protected: true,
    exact: true,
    component: ManageAccounts,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'manageaccountDetails',
    path: '/manageaccounts/:gid',
    protected: true,
    exact: true,
    component: ManageAccountDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'customers',
    path: '/customers',
    protected: true,
    exact: true,
    component: Customers,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'customers-solutions',
    path: '/customer-solutions',
    protected: true,
    exact: true,
    component: CustomerSolutions,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'customers-details',
    path: '/customers/:gid',
    protected: true,
    exact: true,
    component: CustomerDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'events',
    path: '/events',
    protected: true,
    exact: true,
    component: Events,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'events-details',
    path: '/events/:gid',
    protected: true,
    exact: true,
    component: EventsDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'nonevent',
    path: '/seated-link',
    protected: true,
    exact: true,
    component: NonSeatedEvents,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'nonevent-details',
    path: '/seated-link/:gid',
    protected: true,
    exact: true,
    component: NonEventsDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'payouts',
    path: '/payouts',
    protected: true,
    exact: true,
    component: Payouts,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'payouts-details',
    path: '/payouts/:gid',
    protected: true,
    exact: true,
    component: PayoutDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'payments',
    path: '/payments',
    protected: true,
    component: Payments,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'accountsettlement',
    path: '/accountsettlement',
    protected: true,
    component: PaymentSettlement,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'quickbooks',
    path: '/integrations/quickbooks',
    protected: true,
    component: QuickBooks,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'adminoverview',
    path: '/overview',
    protected: true,
    exact: true,
    component: AdminOverview,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'integrations',
    path: '/integrations',
    protected: true,
    exact: true,
    component: Integrations,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'salesforce',
    path: '/integrations/salesforce',
    protected: true,
    component: SalesForce,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'salesforce',
    path: '/integrations/salesforcedetails',
    protected: true,
    component: SalesForceDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'salesforce',
    path: '/integrations/configure',
    protected: true,
    component: Configure,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'salesforce',
    path: '/integrations/updatecredentials',
    protected: false,
    exact: true,
    component: UpdateCredentials,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'salesforce',
    path: '/integrations/invoice',
    protected: true,
    component: SalesForceInvoice,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'salesforce',
    path: '/integrations/updateinvoice',
    protected: true,
    component: SalesForceUpdateInvoice,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'payments-details',
    path: '/payments/:gid',
    protected: true,
    exact: true,
    component: PaymentDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'payments-Entries',
    path: '/payment-page/:gid',
    protected: true,
    exact: true,
    component: PaymentPagesEntries,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'payments-details-dispute',
    path: '/payments/:gid/dispute',
    protected: true,
    exact: true,
    component: DisputeEvidence,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'payments-all',
    path: '/payments/allpayments',
    protected: true,
    exact: true,
    component: AllPayments,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'event-receipts',
    path: '/event-receipts/:eventid',
    protected: false,
    exact: true,
    component: EventPageRecipt,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'page-receipts',
    path: '/page-receipts/:pageid',
    protected: false,
    exact: true,
    component: PaymentPageRecipt,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'disputes',
    path: '/disputes',
    protected: true,
    component: Disputes,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'transactions',
    path: '/transaction',
    protected: true,
    component: Transactions,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'transactions',
    path: '/transaction/cash',
    protected: true,
    exact: true,
    component: CashList,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'cash-receipt',
    path: '/cash-receipt/:receiptid',
    protected: false,
    exact: true,
    component: CashReceipt,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'transactions',
    path: '/transaction/cheque',
    protected: true,
    exact: true,
    component: ChequeList,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'transactions-details',
    path: '/transaction/cash/:gid',
    protected: true,
    exact: true,
    component: CashDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'transactions-details',
    path: '/transaction/cheque/:gid',
    protected: true,
    exact: true,
    component: ChequeDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'check-receipt',
    path: '/check-receipt/:receiptid',
    protected: false,
    exact: true,
    component: ChequeReceipt,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'team',
    path: '/team',
    protected: true,
    exact: true,
    component: Team,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'ocrrequest',
    path: '/ocrrequest',
    protected: true,
    exact: true,
    component: OCRRequest,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'bankstatement',
    path: '/bankstatement',
    protected: true,
    exact: true,
    component: BankStatement,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'bankstatement-details',
    path: '/bankstatement/:gid',
    protected: true,
    exact: true,
    component: BankstatementDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'settings',
    path: '/settings',
    protected: true,
    component: Settings,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'settings-account',
    path: '/settings/account',
    protected: true,
    exact: true,
    component: AccountSettings,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'settings-business',
    path: '/settings/business',
    protected: true,
    exact: true,
    component: BusinessSettings,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'settings-support',
    path: '/settings/support',
    protected: true,
    exact: true,
    component: SupportSettings,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'productsterminals',
    path: '/productsterminals',
    protected: true,
    exact: true,
    component: ProductsSettings,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'settings-reminder',
    path: '/reminders',
    protected: true,
    exact: true,
    component: Reminders,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'settings-ach-payments',
    path: '/settings/ach-payments',
    protected: true,
    exact: true,
    component: AchPayments,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'settings-bank-transfer',
    path: '/settings/bank-transfer',
    protected: true,
    exact: true,
    component: BankTransfer,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'settings-transfer',
    path: '/settings/transfer',
    protected: true,
    exact: true,
    component: TransferSettings,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'settings-reward',
    path: '/reward',
    protected: true,
    exact: true,
    component: RewardSettings,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'settings-webhooks',
    path: '/webhooks',
    protected: true,
    exact: true,
    component: Webhooks,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'settings-referrals',
    path: '/settings/referrals',
    protected: true,
    exact: true,
    component: Referrals,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'custom-dashboard',
    path: '/custom-dashboard',
    protected: true,
    exact: true,
    component: SettingCustomDashboard,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'slider',
    path: '/deals-slider',
    protected: true,
    exact: true,
    component: Slider,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'settings-operatinghours',
    path: '/operatinghours',
    protected: true,
    exact: true,
    component: OperatingHours,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'advancefee',
    path: '/advancefee',
    protected: true,
    exact: true,
    component: AdvanceFee,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'settings-customdocuments',
    path: '/settings/customdocuments',
    protected: true,
    exact: true,
    component: CustomDocuments,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'settings-customdocuments',
    path: '/customdocuments/:gid',
    protected: true,
    exact: true,
    component: CustomDocumentsDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'settings-onboarding',
    path: '/settings/usonboard',
    protected: true,
    exact: true,
    component: USOnboarding,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'settings-cloverwebhook',
    path: '/settings/cloverwebhook',
    protected: true,
    exact: true,
    component: InternalCloverWebhook,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'settings-cloversync',
    path: '/settings/cloversync',
    protected: true,
    exact: true,
    component: CloverSync,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'developer',
    path: '/developer',
    protected: true,
    exact: true,
    component: Developer,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'reports',
    path: '/reports',
    protected: true,
    exact: true,
    component: Report,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'schedule-reports',
    path: '/scheduled-report-attempt',
    protected: true,
    exact: true,
    component: ScheduleReport,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'documents',
    path: '/documents',
    protected: true,
    exact: true,
    component: Documents,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'terminal',
    path: '/terminals',
    protected: true,
    exact: true,
    component: Terminals,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'terminal-details',
    path: '/terminals/:gid',
    protected: true,
    exact: true,
    component: TerminalDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'services',
    path: '/services',
    protected: true,
    exact: true,
    component: Services,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'service-details',
    path: '/services/:gid',
    protected: true,
    exact: true,
    component: ServiceDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'loactions',
    path: '/locations',
    protected: true,
    exact: true,
    component: Locations,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'loactions-details',
    path: '/locations/:gid',
    protected: true,
    exact: true,
    component: LocationDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'support',
    path: '/services-support',
    protected: true,
    exact: true,
    component: Support,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'user-profile',
    path: '/user',
    protected: true,
    exact: true,
    component: UserProfile,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'addevents',
    path: '/addeventspage',
    protected: false,
    exact: true,
    component: AddEvents,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'addevents',
    path: '/addeventspage/:gid',
    protected: false,
    exact: true,
    component: AddEvents,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'addseatedpage',
    path: '/addseatedpage',
    protected: false,
    exact: true,
    component: AddSeatedPage,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'addseatedpage',
    path: '/addseatedpage/:gid',
    protected: false,
    exact: true,
    component: AddSeatedPage,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'addpaymentpage',
    path: '/addpaymentpage',
    protected: false,
    exact: true,
    component: AddPaymentPage,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'addpaymentpage',
    path: '/addpaymentpage/:gid',
    protected: false,
    exact: false,
    component: AddPaymentPage,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'updatepaymentpage',
    path: '/updatepaymentpage/:gid',
    protected: false,
    exact: true,
    component: UpdatePaymentPage,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'receipts',
    path: '/receipts/:receiptid',
    protected: false,
    exact: true,
    component: Receipt,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'order-receipts',
    path: '/order-receipt',
    protected: false,
    exact: true,
    component: OrderReceipt,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'payment-links',
    path: '/payment-links',
    protected: true,
    exact: true,
    component: PaymentsLink,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'payment link-details',
    path: '/payment-links/:gid',
    protected: true,
    exact: true,
    component: PaymentsLinkDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'payment-button',
    path: '/payment-buttons',
    protected: true,
    exact: true,
    component: PaymentsButton,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.POS,
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'payment-button-details',
    path: '/payment-buttons/:gid',
    protected: true,
    exact: true,
    component: PaymentsButtonDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.POS,
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'subscription-button',
    path: '/subscription-button',
    protected: true,
    exact: true,
    component: SubscriptionButton,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.POS,
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'subscription-button-details',
    path: '/subscription-button/:gid',
    protected: true,
    exact: true,
    component: SubscriptionButtonDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.POS,
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'payment-widget',
    path: '/payment-widgets',
    protected: true,
    exact: true,
    component: PaymentWidgets,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'addpaymentwidget',
    path: '/addpaymentwidget',
    protected: false,
    exact: true,
    component: AddPaymentWidget,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'payment-widget-details',
    path: '/payment-widgets/:gid',
    protected: true,
    exact: true,
    component: PaymentWidgetsDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'newaccount',
    path: '/newaccount',
    protected: false,
    exact: true,
    component: NewAccount,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'subscriptions',
    path: '/subscriptions',
    protected: true,
    component: Subscriptions,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'funding-sources',
    path: '/funding-sources',
    protected: true,
    component: FundingSources,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'funding-sourcelink',
    path: '/funding-sources/link',
    protected: true,
    component: FundingSources,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'invoices',
    path: '/invoices',
    protected: true,
    exact: true,
    component: Invoices,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'vendorinvoice',
    path: '/vendorinvoice',
    protected: true,
    exact: true,
    component: VendorInvoice,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'walletvendorinvoice',
    path: '/vendorinvoice-wallet',
    protected: true,
    exact: true,
    component: WalletVendorInvoice,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'plans',
    path: '/subscriptions/plans',
    protected: true,
    component: Subscriptions,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'invoices-details',
    path: '/invoices/:gid',
    protected: true,
    exact: true,
    component: InvoicesDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'vendorinvoice-details',
    path: '/vendorinvoice/:gid',
    protected: true,
    exact: true,
    component: VendorInvoicesDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'wallet-vendorinvoice-details',
    path: '/vendorinvoice-wallet/:gid',
    protected: true,
    exact: true,
    component: WalletVendorInvoicesDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'subscriptions-details',
    path: '/subscriptions/:gid',
    protected: true,
    exact: true,
    component: SubscriptionDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'new-invoice',
    path: '/invoices/add/new',
    protected: true,
    exact: true,
    component: NewInvoice,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'new-vendorinvoice',
    path: '/vendorinvoice/add/new',
    protected: true,
    exact: true,
    component: NewVendorInvoice,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'new-wallet-vendorinvoice',
    path: '/vendorinvoice-wallet/add/new',
    protected: true,
    exact: true,
    component: NewWalletVendorInvoice,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'plan-details',
    path: '/subscriptions/plans/:gid',
    protected: true,
    exact: true,
    component: PlanDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'products',
    path: '/products',
    protected: false,
    exact: true,
    component: Products,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'payment-links-pages',
    path: '/pay-links',
    protected: true,
    exact: true,
    component: PayLinksAndPages,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'events-links-pages',
    path: '/event-links',
    protected: true,
    exact: true,
    component: EventsLinks,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'payment-pages',
    path: '/payment-pages',
    protected: true,
    exact: true,
    component: PaymentPages,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'payment-pages-details',
    path: '/payment-pages/:gid',
    protected: true,
    exact: true,
    component: PaymentPagesDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'donation-pages',
    path: '/donation-pages',
    protected: true,
    exact: true,
    component: DonationPages,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'donation-pages-details',
    path: '/donation-pages/:gid',
    protected: true,
    exact: true,
    component: DonationPageDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'donation-page-update',
    path: '/updatedonationpage/:gid',
    protected: true,
    exact: true,
    component: UpdateDonationPage,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'addDonationPage',
    path: '/adddonationpage',
    protected: false,
    exact: true,
    component: AddDonationPage,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'addDonationPage',
    path: '/adddonationpage/:gid',
    protected: false,
    exact: false,
    component: AddDonationPage,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'payment-order-details',
    path: '/payment-orders/:gid',
    protected: true,
    exact: true,
    component: PaymentOrderDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'admin',
    path: '/admin',
    protected: true,
    exact: true,
    component: Admin,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'admin-payments',
    path: '/payment-admin',
    protected: true,
    exact: true,
    component: AdminPayments,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'admin-paymentdetails',
    path: '/payment-admin/:gid',
    protected: true,
    exact: true,
    component: AdminPaymentDetails,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'admin-staticqr',
    path: '/staticqr-admin',
    protected: true,
    exact: true,
    component: StaticQr,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'admin-staticqrdetails',
    path: '/staticqr-admin/:gid',
    protected: true,
    exact: true,
    component: StaticQrDetails,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'reporttemplates',
    path: '/reporttemplates',
    protected: true,
    exact: true,
    component: CustomReportTemplate,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'reporttemplates-details',
    path: '/reporttemplates/:gid',
    protected: true,
    exact: true,
    component: CustomReportTemplateDetails,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'customreport',
    path: '/customreport',
    protected: true,
    exact: true,
    component: CustomReport,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'customreport-details',
    path: '/customreport/:gid',
    protected: true,
    exact: true,
    component: CustomReportDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'rate',
    path: '/rate',
    protected: true,
    exact: true,
    component: Rates,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'rates-details',
    path: '/rate/:gid/acquirer',
    protected: true,
    exact: true,
    component: RatesDetails,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'issuerrate',
    path: '/rate/issuerrate',
    protected: true,
    exact: true,
    component: IssuerRates,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'exchangerate',
    path: '/rate/exchangerate',
    protected: true,
    exact: true,
    component: ExchangeRates,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'issuerrate-details',
    path: '/rate/:gid/issuer',
    protected: true,
    exact: true,
    component: IssuerRatesDetails,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'acquirer',
    path: '/acquirer',
    protected: true,
    component: AdminAcquirer,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'acquirer',
    path: '/acquirer',
    exact: true,
    protected: true,
    component: Acquirer,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'admincatalog',
    path: '/catalog',
    protected: true,
    component: AdminCatalog,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'adminorder',
    path: '/catalog/adminorder',
    exact: true,
    protected: true,
    component: AdminOrders,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'acquirer-issuer',
    path: '/acquirer/issuer',
    protected: true,
    component: Issuer,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'admin-bins',
    path: '/bin-admin',
    protected: true,
    exact: true,
    component: AdminBin,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'admin-terminals',
    path: '/terminal-admin',
    protected: true,
    exact: true,
    component: AdminTerminal,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'admin-terminals',
    path: '/terminal-admin/:gid',
    protected: true,
    exact: true,
    component: AdminTerminalDetails,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'settlements',
    path: '/settlements',
    protected: true,
    exact: true,
    component: Settlements,
    allowedRoles: [
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'pos-keys',
    path: '/pos-keys',
    protected: true,
    exact: true,
    component: PosKeys,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'global-restricted-key',
    path: '/global-restricted-key',
    protected: true,
    exact: true,
    component: GlobalRestrictedKey,
    allowedRoles: [
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'admin-report',
    path: '/report-admin',
    protected: true,
    exact: true,
    component: AdminReport,
    allowedRoles: [
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'schedule-admin-reports',
    path: '/schedule-admin-reports',
    protected: true,
    exact: true,
    component: AdminScheduleReport,
    allowedRoles: [
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'internaluser',
    path: '/internaluser',
    protected: true,
    exact: true,
    component: InternalUsers,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'taxrates',
    path: '/tax-rates',
    protected: true,
    exact: true,
    component: TaxRate,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'coupons',
    path: '/coupons',
    protected: true,
    exact: true,
    component: Coupons,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'coupons',
    path: '/coupons/:gid',
    protected: true,
    exact: true,
    component: CouponDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'offers',
    path: '/offers',
    protected: true,
    exact: true,
    component: Offers,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'offers',
    path: '/offers/:gid',
    protected: true,
    exact: true,
    component: OfferDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'partner',
    path: '/partner',
    protected: true,
    exact: true,
    component: Partner,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.POS,
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'partner-transactions',
    path: '/transactions/partner',
    protected: true,
    exact: true,
    component: PartnerTransactions,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.POS,
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'partner-earnings',
    path: '/earnings/partner',
    protected: true,
    exact: true,
    component: PartnerEarnings,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.POS,
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'partner-settlement',
    path: '/settlement/partner',
    protected: true,
    exact: true,
    component: PartnerSettlement,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.POS,
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'release-version',
    path: '/release-status',
    protected: false,
    exact: true,
    component: Release,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'pos-user',
    path: '/pos-user',
    protected: false,
    exact: true,
    component: Posuser,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'sp-field',
    path: '/sp-field',
    protected: true,
    exact: true,
    component: SpField,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'sp-object',
    path: '/sp-object',
    protected: true,
    exact: true,
    component: SpObject,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'transfers',
    path: '/transfers',
    protected: true,
    exact: true,
    component: Transfers,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'vendors',
    // path: '/contacts',
    path: '/funding-sources/contacts',
    protected: true,
    exact: true,
    component: Vendors,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'transferlinks',
    path: '/transfer-links',
    protected: true,
    exact: true,
    component: TransferLinks,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'vendor-details',
    path: '/contacts/:gid',
    protected: true,
    exact: true,
    component: VendorsDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'fundingsource-details',
    path: '/fundingsource-details/:gid',
    protected: true,
    exact: true,
    component: FundingSourceDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'transfers-all',
    path: '/transfers/alltransfers',
    protected: true,
    exact: true,
    component: AllTransfers,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'transfers-details',
    path: '/transfers/:gid',
    protected: true,
    exact: true,
    component: TransfersDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'bulk-transfer',
    path: '/bulk-transfer',
    protected: true,
    exact: true,
    component: BulkTransfer,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'bulk-transfer-details',
    path: '/bulk-transfer/:gid',
    protected: true,
    exact: true,
    component: BulkTransferDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'transfer-receipt',
    path: '/transfer-receipts/:receiptid',
    protected: false,
    exact: true,
    component: TransferReceipt,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'checkout-page',
    path: '/checkout-page',
    protected: true,
    exact: true,
    component: PayamentCheckout,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.POS,
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'checkout-page-details',
    path: '/checkout-page/:gid',
    protected: true,
    exact: true,
    component: PaymentCheckoutDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.POS,
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'accountslug',
    path: '/accountslug',
    protected: true,
    exact: true,
    component: AccountSlug,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'accountslug-details',
    path: '/accountslug/:gid',
    protected: true,
    exact: true,
    component: AccountSlugDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
    ],
  },
  {
    key: 'wallet',
    path: '/wallet',
    protected: true,
    exact: true,
    component: Wallet,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'wallet-manage',
    path: '/manage',
    protected: true,
    exact: true,
    component: WalletDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'wallet-transactionslist',
    path: '/transactionsList',
    protected: true,
    exact: true,
    component: TransactionsList,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'wallet-transactionsdetails',
    path: '/transactionsList/:gid',
    protected: true,
    exact: true,
    component: TransactionsDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'setup',
    path: '/setup',
    protected: true,
    exact: true,
    component: Setup,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'wallet-beneficiary',
    path: '/beneficiary',
    protected: true,
    exact: true,
    component: Beneficiary,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'wallet-beneficiarydetails',
    path: '/beneficiary/:gid',
    protected: true,
    exact: true,
    component: BeneficiaryDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'woocommerce',
    path: '/integrations/woocommerce',
    protected: true,
    component: WooCommerce,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'woocommerce-order-details',
    path: '/woocommerce-orders/:gid',
    protected: true,
    exact: true,
    component: WooCommerceOrderDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'cloverwebhook',
    path: '/integrations/cloverwebhook',
    protected: true,
    component: CloverWebhook,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'admin-transfers',
    path: '/transfer-admin',
    protected: true,
    exact: true,
    component: AdminTransfers,
    allowedRoles: [
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.INTERNALSUPPORT,
    ],
  },
  {
    key: 'clover-receipt',
    path: '/us-pos-receipt',
    protected: false,
    exact: false,
    component: CloverReceipt,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'orders',
    path: '/orders',
    protected: true,
    exact: true,
    component: InventoryOrders,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: 'orders-details',
    path: '/orders/:gid',
    protected: true,
    exact: true,
    component: OrderDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: 'items',
    path: '/items',
    protected: true,
    exact: true,
    component: InventoryItems,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: 'items-details',
    path: '/items/:gid',
    protected: true,
    exact: true,
    component: ItemDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: 'categories',
    path: '/categories',
    protected: true,
    exact: true,
    component: InventoryCategories,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: 'categories-details',
    path: '/categories/:gid',
    protected: true,
    exact: true,
    component: CategorieDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: 'modifiers',
    path: '/modifiers',
    protected: true,
    exact: true,
    component: InventoryModifiers,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: 'modifiers-details',
    path: '/modifiers/:gid',
    protected: true,
    exact: true,
    component: ModifierDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: 'menus',
    path: '/menus',
    protected: true,
    exact: true,
    component: InventoryMenu,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: 'ordertypes',
    path: '/ordertypes',
    protected: true,
    exact: true,
    component: InventoryOrderTypes,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: 'printerlabel',
    path: '/printerlabel',
    protected: true,
    exact: true,
    component: PrinterLabelList,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: 'printerlabel-details',
    path: '/printerlabel/:gid',
    protected: true,
    exact: true,
    component: PrinterLabelDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: 'shops',
    path: '/shops',
    protected: true,
    exact: true,
    component: ShopsList,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: 'inventory',
    path: '/inventory',
    protected: true,
    exact: true,
    component: Inventory,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: 'shops-details',
    path: '/shops/:gid',
    protected: true,
    exact: true,
    component: ShopsDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: 'feeedback',
    path: '/feedback',
    protected: true,
    exact: true,
    component: orderFeedback,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: 'reservations',
    path: '/reservations',
    protected: true,
    exact: true,
    component: ReservationsList,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: 'reservations-details',
    path: '/reservations/:gid',
    protected: true,
    exact: true,
    component: ReservationsDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: 'preorder',
    path: '/preorder',
    protected: true,
    exact: true,
    component: PreorderList,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: 'customer-support',
    path: '/supportcustomer',
    protected: true,
    exact: true,
    component: CustomerSupport,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
    ],
  },
  {
    key: 'dininglist',
    path: '/dininglist/:gid',
    protected: true,
    exact: true,
    component: DiningList,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'diningdetails',
    path: '/diningdetails/:gid',
    protected: true,
    exact: true,
    component: DiningDetails,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.KDSUSER,
      ROLES.KDSADMIN,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: 'servicerates',
    path: '/service-rates',
    protected: true,
    exact: true,
    component: ServiceRates,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      // ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
  {
    key: '404',
    path: '',
    protected: true,
    exact: false,
    component: NoMatch,
    allowedRoles: [
      ROLES.ADMINISTRATOR,
      ROLES.OWNER,
      ROLES.DEVELOPER,
      ROLES.OPERATIONS,
      ROLES.ANALYST,
      ROLES.SUPPORT,
      ROLES.INTERNALSUPPORT,
      ROLES.POS,
      ROLES.DEVICE,
      ROLES.SUPERUSER,
      ROLES.ONBOARDING,
    ],
  },
];

export default routes;
