import React from 'react';
import {
  Card,
  Icon,
} from 'antd';
import swireLogo from 'imgs/swirepay.png';
import qpayments from 'imgs/Qpayments.jpeg';
import Maintain from 'imgs/maintain.svg';

const Maintainance = () => (
  <Card
    className="mx-auto"
    style={{ width: '100%', height: 'calc(100vh)' }}
    bodyStyle={{ height: '100%' }}
  >
    <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
      <img src={window.location.host.includes('qpaymentz') ? qpayments : swireLogo} alt="" style={{ width: '200px' }} />
      <h1 style={{ textAlign: 'center', marginTop: '30px', fontWeight: '700' }}>
        We’ll be back soon!
      </h1>
      <h5
        style={{
          textAlign: 'center',
          width: '30%',
          marginTop: '10px',
          marginBottom: '50px',
        }}
      >
        Sorry for the inconvenience caused. Our application is down due to planned maintenence. We will be back shortly, thanks for your cooperation.
      </h5>
      <img src={Maintain} alt="" style={{ width: '25%' }} />
    </div>
  </Card>
);

export default Maintainance;
