import { BANK_TRANSFERS } from '../actionTypes';

export const addIcicCustomer = action => ({
  type: BANK_TRANSFERS.REGISTER,
  payload: action.payload,
});

export const fetchAccountByID = action => ({
  type: BANK_TRANSFERS.ACCOUNT_BY_ID,
  payload: action.payload,
});

export const createIciciFundingSource = action => ({
  type: BANK_TRANSFERS.CREATE_FUNDING_SOURCE,
  payload: action.payload,
});

export const createPrimaryContact = action => ({
  type: BANK_TRANSFERS.CREATE_PRIMARY_CONTACT,
  payload: action.payload,
});

export const deleteContact = action => ({
  type: BANK_TRANSFERS.DELETE_CONTACT,
  payload: action.payload,
});

export const checkBalanceById = action => ({
  type: BANK_TRANSFERS.BALANCE_BY_ID,
  payload: action.payload,
});
