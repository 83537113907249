import Items from 'imgs/items.svg';
import Categories from 'imgs/Categories.svg';
import Menu from 'imgs/Menu.svg';
import Modifer from 'imgs/modifierGroup.svg';
import OrderTypes from 'imgs/orderTypes.svg';
import TaxRates from 'imgs/taxRates.svg';
import Coupons from 'imgs/coupons.svg';
import offers from 'imgs/offers.svg';
import Printerlabels from 'imgs/printerLabels.svg';
import AdvanceFee from 'imgs/advanceFee.svg';
import CustomerSolution from 'imgs/customersSolution.svg';
import Invoice from 'imgs/invoice.svg';
import Subscription from 'imgs/subscription.svg';
import Rewards from 'imgs/rewards.svg';
import Products from 'imgs/products.svg';
import PaymentLinks from 'imgs/paymentLink.svg';
import PaymentButton from 'imgs/paymentButton.svg';
import SubscriptionButton from 'imgs/subscriptionButton.svg';
import PaymentWidgets from 'imgs/paymentWidgets.svg';
import PaymentPages from 'imgs/paymentPages.svg';
import PaymentCheckout from 'imgs/paymentCheckout.svg';
import SeatedPage from 'imgs/seatedEvents.svg';
import AccountSlug from 'imgs/accountSlug.svg';
import Reminders from 'imgs/reminders.svg';
import Contacts from 'imgs/contacts.svg';
import Manage from 'imgs/manageActivity.svg';
import Account from 'imgs/accountTransfer.svg';
import Bulk from 'imgs/bulk.svg';
import Activity from 'imgs/activity.svg';
import Vendor from 'imgs/vendorInvoice.svg';
import Donation from 'imgs/donation.svg';


export const navigationCardTransferCenter = [
  { name: 'View Activity', svg: Activity, path: '/transfers' },
  { name: 'Transfer Funds', svg: Account, path: '/sendmoney' },
  { name: 'Contacts', svg: Contacts, path: '/funding-sources/contacts' },
  { name: 'Pay Invoices', svg: Vendor, path: '/vendorinvoice' },
  { name: 'Bulk Transfer', svg: Bulk, path: '/bulk-transfer' },
  { name: 'Manage Account', svg: Manage, path: '/manageaccounts' },
];

export const navigationCardPayLinks = [
  { name: 'Payment Links', svg: PaymentLinks, path: '/payment-links' },
  { name: 'Payment Buttons', svg: PaymentButton, path: '/payment-buttons' },
  { name: 'Subscription Buttons', svg: SubscriptionButton, path: '/subscription-button' },
  { name: 'Payment Widgets', svg: PaymentWidgets, path: '/payment-widgets' },
  { name: 'Payment Pages', svg: PaymentPages, path: '/payment-pages' },
  { name: 'Payment Checkout', svg: PaymentCheckout, path: '/checkout-page' },
  { name: 'Account Slug', svg: AccountSlug, path: '/accountslug' },
  { name: 'Donation Pages', svg: Donation, path: '/donation-pages' },
  { name: 'Reminders', svg: Reminders, path: '/reminders' },
  { name: 'Seated Pages', svg: SeatedPage, path: '/events' },
];

export const navigationCardEventLinks = [
  { name: 'Non Seated Pages', svg: PaymentPages, path: '/seated-link' },
];


export const navigationCardInventory = [
  { name: ' Categories', svg: Categories, path: '/categories' },
  { name: 'Items', svg: Items, path: '/items' },
  { name: 'Menu/Catalog', svg: Menu, path: '/menus' },
  { name: 'Modifier Groups', svg: Modifer, path: '/modifiers' },
  { name: 'Order Types', svg: OrderTypes, path: '/ordertypes' },
  { name: 'Tax Rates', svg: TaxRates, path: '/tax-rates' },
  { name: 'Coupons', svg: Coupons, path: '/coupons' },
  { name: 'Offers', svg: offers, path: '/offers' },
  { name: 'Printer Labels', svg: Printerlabels, path: '/printerlabel' },
  { name: 'Advance Fee', svg: AdvanceFee, path: '/advancefee' },
];


export const navigationCardCustomerSoluitons = [
  { name: 'Customers', svg: CustomerSolution, path: '/customers' },
  { name: 'Invoice', svg: Invoice, path: '/invoices' },
  { name: 'Subscription', svg: Subscription, path: '/subscriptions' },
  { name: 'Rewards', svg: Rewards, path: '/reward' },
  { name: 'Products', svg: Products, path: '/products' },
];
