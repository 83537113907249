import { call, put } from 'redux-saga/effects';

import generateDetailsQuery from 'utils/generateDetailsQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  NONEVENTSDETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchNonEventDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_EVENT}/${gid}`);

const fetchCardDetailsNon = async (payload) => {
  const { gid } = payload;
  const { currentPage } = payload;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  const query = generateDetailsQuery(currentPage);
  return axiosAuthInstance.get(`${API_END_POINTS.GET_PAYMENTS}?${query}&seatPageMetaGid.EQ=${gid}${sorting}`);
};

const patchEvent = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_EVENT}/${gid}`, params);

const eventDeactivate = async gid => axiosAuthInstance.patch(`${API_END_POINTS.GET_EVENT}/${gid}/deactivate`);

const eventSoldOut = async gid => axiosAuthInstance.patch(`${API_END_POINTS.GET_EVENT}/${gid}/sold-out`);

const salesResume = async gid => axiosAuthInstance.patch(`${API_END_POINTS.GET_EVENT}/${gid}/resume-sale`);

const offlinePayment = async ({ gid, params }) => axiosAuthInstance.post(`${API_END_POINTS.OFFLINE_SEATED_VENUE}/${gid}/offline-payment`, params);

const fetchNonEventsSlug = async gid => axiosAuthInstance.get(`${API_END_POINTS.EVENT_SLUG_API}/${gid}`);

const notifyNonSeatedPageDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_NOTIFY}/${gid}`);

const notifyNonSeatedPage = async payload => axiosAuthInstance.post(`${API_END_POINTS.GET_NOTIFY}/notify`, payload);

const fetchOfflineNonEventsPayments = async (payload) => {
  const defaultPage = 1;
  const defaultSize = 10;
  let query;
  if (payload.currentPage) {
    query = `&page=${payload.currentPage}&size=${defaultSize}`;
  } else {
    query = `&page=${defaultPage}&size=${defaultSize}`;
  }
  const queryParam = `?seatPage.seatPageMetaGid.EQ=${payload.gid}`;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  const offline = '&seatPage.offlinePaymentType.NULL=false';
  return axiosAuthInstance.get(`${API_END_POINTS.EVENT_RECEIPTS}${queryParam}${offline}${sorting}${query}`);
};


export function* getNonEventDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchNonEventDetails, action.payload);
    yield put({ type: NONEVENTSDETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: NONEVENTSDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getCardDetailNon(action) {
  try {
    const { data: { entity } } = yield call(fetchCardDetailsNon, action.payload);
    yield put({ type: NONEVENTSDETAILS.CARDS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: NONEVENTSDETAILS.FAILED, payload: e.response.data });
  }
}

export function* updateNonEvent(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchEvent, action.payload);
    const { data: { entity } } = yield call(fetchNonEventDetails, action.payload.gid);
    yield put({ type: NONEVENTSDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.CUSTOMERS.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deactivateNonEvent(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(eventDeactivate, action.payload);
    const { data: { entity } } = yield call(fetchNonEventDetails, action.payload);
    yield put({ type: NONEVENTSDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.EVENTS.DEACTIVATED,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* soldOutNonEvent(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(eventSoldOut, action.payload);
    const { data: { entity } } = yield call(fetchNonEventDetails, action.payload);
    yield put({ type: NONEVENTSDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.EVENTS.SOLDOUT,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* resumeSalesNon(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(salesResume, action.payload);
    const { data: { entity } } = yield call(fetchNonEventDetails, action.payload);
    yield put({ type: NONEVENTSDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.EVENTS.SOLDOUT,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* offlinePaymentsNon(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(offlinePayment, action.payload);
    const { data: { entity } } = yield call(fetchNonEventDetails, action.payload.params.seatPageMetaGid);
    yield put({ type: NONEVENTSDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.EVENTS.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* getNonEventsSlug(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchNonEventsSlug, action.payload);
    yield put({ type: NONEVENTSDETAILS.EVENTS_SLUG_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: NONEVENTSDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getOfflineNonEventsPaymentsList(action) {
  try {
    const { data: { entity } } = yield call(fetchOfflineNonEventsPayments, action.payload);
    yield put({ type: NONEVENTSDETAILS.SUCCESS_OFFLINE_PAYMENTS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: NONEVENTSDETAILS.FAILED, payload: e.response.data });
  }
}

export function* getNotifyNonSeatedPageDetails(action) {
  try {
    if (action.payload) {
      const { data: { entity } } = yield call(notifyNonSeatedPageDetails, action.payload);
      yield put({ type: NONEVENTSDETAILS.SUCCESS, payload: entity });
      yield put({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.NOTIFY.SUCCESS,
      });
    } else {
      const { data: { entity } } = yield call(notifyNonSeatedPageDetails, action);
      yield put({ type: NONEVENTSDETAILS.SUCCESS, payload: entity });
    }
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* getNotifyNonSeatedPage(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(notifyNonSeatedPage, action.payload);
    yield put({ type: LOADING.VALIDATED });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.NOTIFY.SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
