// @flow
import React, {
  useState,
  useRef,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Button,
  Icon,
  Select,
} from 'antd';
import styled from 'styled-components';
import SimpleReactValidator from 'simple-react-validator';
import type { ContextRouter } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment-timezone';

import {
  SpFormText,
} from 'components/DesignKit';

import {
  axiosLoginInstance,
  API_END_POINTS,
} from 'api';

import {
  PAGES,
  MESSAGES,
  TOKENS,
  TIME_ZONES,
  ALIAS_TIMEZONES,
  COUNTRY_PHONE_CODE,
  PRIVACY_STATEMENT,
  CONTACT_US_EMAIL,
  NEED_HELP_EMAIL,
} from 'appconstants';

import { validators, validationRegex } from 'utils/validationMessages';
import { isValidPhoneNumber, formatNumber } from 'libphonenumber-js';


import { notificationActions, recaptchaDetailsActions } from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import swireLogo from 'imgs/swirepay.png';
import qpayments from 'imgs/Qpayments.jpeg';
import ReCAPTCHA from 'react-google-recaptcha';

const { Option } = Select;

// $FlowFixMe
const Wrapper = styled.div`
  background-image: linear-gradient(120deg, #05d5ff 3%, #007EE5);
  width: 100%;
  display: grid;
  place-items: center center;
  height:135vh;
  overflow: hidden scroll;
  @media only screen and (min-width: 992px) {
    height: 100vh;
    padding:4rem 0px;
  }
`;

// $FlowFixMe
const FormWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingTop: '24px',
  paddingBottom: '24px',
  alignItems: 'center',
  width: '511px',
  borderRadius: '10px',
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: '#f7f7f7',
  overflow: 'auto',
});


type Props = ContextRouter & {
  setNotification: Function,
  history: {
    push: Function,
  },
  location: {
    pathname: string,
    search: string,
  },
  selectedAccount: object,
  fetchRecaptchaDetails: Function,
  fetchRecaptcha: Object,
  isMobileView: Boolean,
};

const Register = (props: Props) => {
  const {
    setNotification,
    location,
    selectedAccount,
    fetchRecaptchaDetails,
    fetchRecaptcha,
    isMobileView,
  } = props;

  const [, forceUpdate] = useState();
  const [emailId, setEmailId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [phoneFormate, setPhoneFormate] = useState('');
  const [recaptchaToken, setRecaptchToken] = useState('');
  const parsedDynamicQr = queryString.parse(location.search);
  const dynamicQr = parsedDynamicQr['dynamic-static-qr'];
  const recaptchaRef = useRef(null);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      emailId: {
        message: validators.register.validEmail,
        rule: val => validationRegex.email.test(val),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
    },
  }));

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const referralCode = parsed ? parsed.referral_code : '';
    const affiliateCode = parsed ? parsed.affiliate_code : '';
    if (referralCode) {
      localStorage.setItem(TOKENS.REFERRAL_CODE, referralCode);
    } else if (affiliateCode) {
      localStorage.setItem(TOKENS.AFFILIATE_CODE, affiliateCode);
    }
    if (dynamicQr) {
      localStorage.setItem('dynamicStaticQr', dynamicQr);
    }
    if (selectedAccount && selectedAccount.gid && referralCode) {
      props.history.replace(`${PAGES.NEW_ACCOUNT}?referral_code=${referralCode}`);
    }
    /* SPFR-2381 removed as per Qa team */
  }, []);

  useEffect(() => {
    const defaultTz = moment.tz.guess();
    const aliasTz = ALIAS_TIMEZONES[defaultTz];
    let tz = aliasTz || defaultTz;
    if (!(TIME_ZONES && TIME_ZONES.includes(tz))) {
      tz = undefined;
    }
    if (tz === 'Asia/Kolkata') {
      setPhoneCode('+91');
    } else {
      setPhoneCode('+1');
    }
  }, []);

  useEffect(() => {
    if (success) {
      props.history.replace({
        pathname: PAGES.NEW_REGISTER,
        search: `?email=${emailId.trim()}`,
      });
    }
  }, [success]);

  useEffect(() => {
    fetchRecaptchaDetails();
  }, []);

  const onPhonecodeChange = (value) => {
    setPhoneCode(value);
    const data = formatNumber(`${value}${phone}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  };

  const handleReload = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
      setRecaptchToken('');
    }
  };

  const onPhoneUpdate = (e) => {
    const phoneNumber = e.currentTarget.value;
    setPhone(phoneNumber.replace(/[^0-9+]/g, ''));
    setPhoneFormate(phoneNumber.replace(/[^0-9+]/g, ''));
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    setLoading(true);

    const params = {
      email: emailId.trim(),
      familyName: lastName.trim(),
      givenName: firstName.trim(),
      phoneNumber: `${phoneCode}${phone}`,
      recaptchaToken: recaptchaToken.trim(),
      platformType: 'WEB',
    };

    // storing the params for creating Account.
    localStorage.setItem('accountInfo', JSON.stringify(params));

    try {
      await axiosLoginInstance.post(API_END_POINTS.USER_REGISTER, params);
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.REGISTER.SUCCESS,
      });
      setSuccess(true);
    } catch (e) {
      localStorage.removeItem(TOKENS.ACCESS_TOKEN);
      localStorage.removeItem(TOKENS.REFRESH_TOKEN);
      localStorage.removeItem(TOKENS.SESSION_TOKEN);
      localStorage.removeItem('email');
      localStorage.removeItem('fcmToken');
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
      });
      handleReload();
    }
    setLoading(false);
  };

  return (
    <Wrapper>
      <div>
        <FormWrapper
          onSubmit={handleRegister}
          style={{
            width: isMobileView ? '900px' : '',
            height: isMobileView ? '900px' : '',
            paddingTop: isMobileView ? '0px' : '',
            paddingBottom: isMobileView ? '0px' : '',
          }}
        >
          <div style={{ maxWidth: isMobileView ? '800px' : '350px' }}>
            <img src={window.location.host.includes('qpaymentz') ? qpayments : swireLogo} alt="" className="align-self-center" style={{ width: isMobileView ? '420px' : '270px' }} />
            <SpFormText fontSize={isMobileView ? '26px' : '18px'}>Sign up</SpFormText>
            <div className="LOGIN">
              <div className="my-3">
                <div>
                  <label htmlFor="" className="font-weight-bold" style={{ fontSize: isMobileView ? '22px' : '' }}>First Name</label>
                </div>
                <Input
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.currentTarget.value)}
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  style={{ width: isMobileView ? '450px' : '', height: isMobileView ? '50px' : '' }}
                />
                <div className="text-danger">
                  {simpleValidator.current.message('first name', firstName, 'required|alpha_space',
                    { messages: { required: validators.register.firstName, alpha_space: validators.register.validFirstName } })}
                </div>
              </div>
              <div className="my-3">
                <div>
                  <label htmlFor="" className="font-weight-bold" style={{ fontSize: isMobileView ? '22px' : '' }}>Last Name</label>
                </div>
                <Input
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.currentTarget.value)}
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  style={{ width: isMobileView ? '450px' : '', height: isMobileView ? '50px' : '' }}
                />
                <div className="text-danger">
                  {simpleValidator.current.message('family name', lastName, 'required|alpha_space',
                    { messages: { required: validators.register.lastName, alpha_space: validators.register.validLastName } })}
                </div>
              </div>
              <div className="mb-3">
                <div>
                  <label htmlFor="" className="font-weight-bold" style={{ fontSize: isMobileView ? '22px' : '' }}>Email</label>
                </div>
                <Input
                  placeholder="Your Email"
                  prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  value={emailId}
                  onBlur={() => setEmailId(emailId.trim())}
                  onChange={(e) => setEmailId(e.currentTarget.value)}
                  style={{ width: isMobileView ? '450px' : '', height: isMobileView ? '50px' : '' }}
                />
                <div className="text-danger">
                  {simpleValidator.current.message('Email', emailId, 'required|emailId|checkLowerCase',
                    { messages: { required: validators.register.email } })}
                </div>
              </div>

              <div className="pb-3">
                <div>
                  <label htmlFor="" className="font-weight-bold" style={{ fontSize: isMobileView ? '22px' : '' }}>Phone</label>
                </div>
                <div className="d-flex">
                  <div style={{ width: '30%', height: '40px' }}>
                    <Select
                      className="w-100"
                      value={phoneCode}
                      onChange={onPhonecodeChange}
                      showSearch
                      style={{ width: isMobileView ? '50px' : '', height: isMobileView ? '' : '40px' }}
                    >
                      {COUNTRY_PHONE_CODE.map((item) => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <div className="text-danger">
                      {simpleValidator.current.message('country code', phoneCode, 'required')}
                    </div>
                  </div>
                  <div style={{ width: '70%' }}>
                    <Input
                      style={{ width: isMobileView ? '312px' : '' }}
                      className="InputnumFormate"
                      placeholder="Phone Number"
                      value={phoneFormate}
                      onChange={onPhoneUpdate}
                      onBlur={e => {
                        if (e.currentTarget.value) {
                          setPhone(e.currentTarget.value);
                          const value = formatNumber(`${phoneCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                          const formater = value.substr(value.indexOf(' ') + 1);
                          setPhoneFormate(formater);
                        }
                      }}
                    />
                    <div className="text-danger">
                      {simpleValidator.current.message('phone number', phone, `required|phoneNumValidation:${phoneCode}${phone}`)}
                    </div>
                  </div>
                </div><br />
                {fetchRecaptcha.signup === true && fetchRecaptcha.recaptchaDetailsStatus === 'SUCCESS'
                  ? (
                    <>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        className="g-recaptcha"
                        sitekey={fetchRecaptcha.siteKey}
                        value={recaptchaToken}
                        onChange={(value) => setRecaptchToken(value)}
                      />
                      <div className="text-danger">
                        {simpleValidator.current.message('captcha', recaptchaToken, 'required',
                          { messages: { required: validators.login.recaptchaToken } })}
                      </div>
                      <br />
                    </>
                  )
                  : ''}
              </div>
              <div className="d-flex justify-content-center">
                <Button
                  type="primary"
                  shape="round"
                  className="w-50"
                  htmlType="submit"
                  loading={loading}
                  onClick={handleRegister}
                  style={{ fontSize: isMobileView ? '18px' : '' }}
                >
                  Register
                </Button>
              </div>
              <div className="d-flex justify-content-center mt-2">
                <Button
                  type="link"
                  block
                  onClick={() => props.history.replace(PAGES.LOGIN)}
                  style={{ fontSize: isMobileView ? '18px' : '' }}
                >
                  Go to Homepage
                </Button>
              </div>
            </div>
          </div>
        </FormWrapper>
        <div className="d-flex justify-content-between align-items-center" style={{ width: isMobileView ? '900px' : '511px' }}>
          <Button type="link" className="pl-1" style={{ fontSize: isMobileView ? '16px' : '' }}>
            <a className="link-style" href={PRIVACY_STATEMENT}>
              Privacy & Terms
            </a>
          </Button>
          <div className="d-flex align-items-center">
            <a href={`mailto:${NEED_HELP_EMAIL}`} className="link-style" style={{ fontSize: isMobileView ? '16px' : '' }}>
              Need Help?
            </a>
            <Button type="link" className="pr-0">
              <a href={`mailto:${CONTACT_US_EMAIL}`} className="link-style" style={{ fontSize: isMobileView ? '16px' : '' }}>
                Contact Us
              </a>
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  selectedAccount: state.account.selectedAccount,
  fetchRecaptcha: state.recaptchaDetails,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchRecaptchaDetails: (payload) => dispatch(recaptchaDetailsActions.fetchRecaptchaDetails({
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(Register);
