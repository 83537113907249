import { EXCHANGE_RATES } from '../actionTypes';
import CONSTANTS from '../constants';

const initialState = {
  content: [],
  transferType: [],
  ratesStatus: undefined,
  ratesError: undefined,
  amount: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EXCHANGE_RATES.FETCH:
      return {
        ...state,
        ratesStatus: CONSTANTS.API_STATUS.STARTED,
      };
    case EXCHANGE_RATES.SUCCESS:
      return {
        ...state,
        ...action.payload,
        ratesStatus: CONSTANTS.API_STATUS.SUCCEEDED,
      };
    case EXCHANGE_RATES.FETCH_AMOUNT_SUCCESS:
      return {
        ...state,
        amount: action.payload,
      };
    case EXCHANGE_RATES.FAILED:
      return {
        ...state,
        ratesError: action.payload,
        ratesStatus: CONSTANTS.API_STATUS.FAILED,
      };
    default:
      return state;
  }
};
