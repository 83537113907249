// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import getSubscriptionBackground from 'utils/getSubscriptionBackground';
import getSubscriptionStatus from 'utils/getSubscriptionStatus';
import getPaymentBackground from 'utils/getPaymentBackground';
import getFrequency from 'utils/getFrequency';
import getPaymentStatus from 'utils/getPaymentStatus';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import getDiscountOff from 'utils/getDiscountOff';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
} from 'antd';

import {
  SpForm,
  SpText,
  SpH5,
  SpButton,
  SpStatusTag,
} from 'components/DesignKit';

import {
  axiosAuthInstance2,
  API_END_POINTS,
} from 'api';

import Loading from 'components/Loading';
import {
  subscriptionV2DetailsActions,
  notificationActions,
  subscriptionAction,
  invoiceDetailsAction,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import {
  MESSAGES,
  PAGES,
  ROLES,
  SUBSCRIPTION_STATUSES,
  ACQUIRER_TYPES,
  RATE_TYPES,
} from 'appconstants';
import RBAC from 'components/rbac';
import Clipboard from 'components/NavIcons/copyclipboard';
import CONSTANTS from 'store/constants';

import DeleteSelectedSubscription from './components/deleteSelectedSubscription';
import NewSubscription from '../SubscriptionList/components/AddSubscription';
import SelectCoupon from '../SubscriptionList/components/SelectCoupon';
import AddCoupon from '../SubscriptionList/components/AddNewCoupon';
import SelectTaxrate from '../SubscriptionList/components/SelectTaxrate';
import AddTaxrate from '../SubscriptionList/components/AddNewTax';

type Props = {
  fetchSubscriptionV2Details: Function,
  fetchSubscriptionV2Payments: Function,
  setNotification: Function,
  addSubscription: Function,
  products: Array<Object>,
  plans: Array<Object>,
  customers: Array<Object>,
  profile: Array<Object>,
  subscriptionDetails: Object,
  loading: boolean,
  validator: boolean,
  selectedAccount: Object,
  submitting: boolean,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  payments: Array<Object>,
  totalElements: number,
  subscriptionDetailsStatus: string,
  customersList: Array<Object>,
  getCustomersList: Function,
  addNewCoupon: Function,
  newCoupon: Object,
  newTaxrate: Object,
  addNewTaxRate: Function,
  taxList: Array<Object>,
  getTaxes: Function,
}
const SubscriptionDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchSubscriptionV2Details,
    fetchSubscriptionV2Payments,
    setNotification,
    addSubscription,
    subscriptionDetails,
    loading,
    submitting,
    validator,
    history,
    payments,
    profile,
    totalElements,
    selectedAccount,
    products,
    plans,
    customers,
    subscriptionDetailsStatus,
    customersList,
    getCustomersList,
    addNewCoupon,
    newCoupon,
    newTaxrate,
    addNewTaxRate,
    taxList,
    getTaxes,
  } = props;
  const { gid } = props.match.params;
  const [pagination, setPagination] = useState({});
  const [deleteSubscriptionModal, setDeleteSubscriptionModal] = useState(false);
  const [duplicateSubscriptionModal, setDuplicateSubscriptionModal] = useState(false);
  const [phoneFormate, setPhoneFormate] = useState('');
  const gidPath = gid.split('-')[1];
  const { id } = selectedAccount && selectedAccount.currency;
  const { prefix } = selectedAccount && selectedAccount.currency;
  const { role } = profile;
  const [phoneCode, setPhoneCode] = useState('');
  const [redirect, setRedirect] = useState(false);
  const currencyType = selectedAccount && selectedAccount.currency;
  const [selectCouponModal, setSelectCouponModal] = useState(false);
  const [updatedCoupon, setUpdatedCoupon] = useState({});
  const [addCouponModal, setAddCouponModal] = useState(false);
  const typeOfCurrency = selectedAccount && selectedAccount.currency.name;
  const [couponRedirect, setCouponRedirect] = useState(false);
  const [selectTaxrateModal, setSelectTaxrateModal] = useState(false);
  const [allSelectedTaxrates, setAllSelectedTaxrates] = useState([]);
  const [addTaxModal, setAddTaxModal] = useState(false);
  const [taxRedirect, setTaxRedirect] = useState(false);
  const [allTaxes, setAllTaxes] = useState(taxList);
  const supportedCurrency = (selectedAccount && selectedAccount.supportedCurrencies);
  const allTaxrates = subscriptionDetails.taxAmount ? subscriptionDetails.taxAmount : [];
  const couponData = subscriptionDetails.coupon ? subscriptionDetails.coupon : {};
  const acquirerName = (selectedAccount && selectedAccount.acquirer && selectedAccount.acquirer.name);
  const discountAmount = (subscriptionDetails && subscriptionDetails.discount) ? (subscriptionDetails.discount / 100) : 0;

  useEffect(() => {
    if (subscriptionDetails && subscriptionDetails.coupon) {
      setUpdatedCoupon(subscriptionDetails.coupon);
    }
    if (subscriptionDetails && subscriptionDetails.taxAmount) {
      setAllSelectedTaxrates(subscriptionDetails.taxAmount);
    }
  }, [subscriptionDetails]);

  const getData = (query) => {
    fetchSubscriptionV2Details(gid);
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    /* sortParams is for payment grid */
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchSubscriptionV2Payments({ currentPage, sortParams, gid });
  };

  const copyCode = (record) => {
    const el = record;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.SUBSCRIPTION.SUCCESS,
    });
  };

  const selectedRecord = (record) => {
    const searchParams = record.gid;
    history.push({
      pathname: `${PAGES.PAYMENTS}/${searchParams}`,
      state: location.search,
    });
  };

  const fetchTaxDetails = (searchParams) => {
    getTaxes(searchParams);
  };

  useEffect(() => {
    if (subscriptionDetails.gid && subscriptionDetails.customer.phoneNumber) {
      const phone = subscriptionDetails.customer.phoneNumber;
      const cntryCode = subscriptionDetails.customer.phoneNumber.substring(0, 2);
      if (cntryCode === '+1') {
        setPhoneFormate('+# (###) ###-####');
      } else if (phone.length !== 14 && cntryCode === '+9') {
        setPhoneFormate('+##-##### #####');
      } else if (phone.length === 12 && cntryCode === '+4') {
        setPhoneFormate('+##-#########');
      } else if (phone.length === 12 && cntryCode !== '+1') {
        setPhoneFormate('+#-##########');
      } else if (phone.length === 13 && cntryCode !== '+9') {
        setPhoneFormate('+##-##########');
      } else if (phone.length === 14) {
        setPhoneFormate('+###-##########');
      }
    }
  }, [subscriptionDetails]);

  /**
   * Get data on page load
   */
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.SUBSCRIPTION);
    }
  }, []);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData(location.search);
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
  }, [location, selectedAccount]);

  useEffect(() => {
    if (!validator && !couponRedirect && !taxRedirect) {
      setDuplicateSubscriptionModal(false);
      setUpdatedCoupon({});
      setAllSelectedTaxrates([]);
    }
    if (!validator && couponRedirect) {
      setAddCouponModal(false);
    }
    if (!validator && taxRedirect) {
      setAddTaxModal(false);
    }
    if (redirect) {
      history.push(PAGES.SUBSCRIPTION);
    }
  }, [validator]);

  useEffect(() => {
    if (newCoupon.gid && couponRedirect) {
      setUpdatedCoupon(newCoupon);
    }
  }, [newCoupon]);

  useEffect(() => {
    if (newTaxrate.gid && taxRedirect) {
      const listOfTaxes = [...allSelectedTaxrates];
      const taxRates = {};
      Object.assign(taxRates, newTaxrate);
      const taxRate = {
        taxRates,
      };
      listOfTaxes.push(taxRate);
      setAllSelectedTaxrates(listOfTaxes);
    }
  }, [newTaxrate]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [payments]);

  useEffect(() => {
    setAllTaxes(taxList);
  }, [taxList]);

  const getAmountOff = () => {
    let amountOff;
    // const productAmount = totalAmount - (discountAmount);
    // if (value.percentageOff) {
    //   const amount = (productAmount * value.percentageOff) / 100;
    //   amountOff = formatNumber((amount).toFixed(2), currencyType);
    // } else if (value.amountOff) {
    //   amountOff = formatNumber((value.amountOff / 100).toFixed(2), currencyType);
    // }
    if (discountAmount) {
      const discount = Math.abs(discountAmount);
      amountOff = formatNumber((discount).toFixed(2), currencyType);
    }
    return amountOff;
  };

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: `${PAGES.SUBSCRIPTION}/${gid}`,
      search: `?pageNum=${tempPage}${tempParams}`,
    });
  };

  const showDeleteSubscriptionModal = () => {
    setDeleteSubscriptionModal(true);
  };

  const deleteSelectedSubscription = async (sgid) => {
    try {
      await axiosAuthInstance2.patch(`${API_END_POINTS.SUBSCRIPTION}/${sgid}/cancel`, { status: SUBSCRIPTION_STATUSES.ACTIVECANCEL });
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.SUBSCRIPTION.DELETE_SUCCESS,
      });
      history.push(PAGES.SUBSCRIPTION);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response.data && e.response.data.message,
      });
    } finally {
      setDeleteSubscriptionModal(false);
    }
  };
  const notifySubscription = async (s2linkgid) => {
    try {
      await axiosAuthInstance2.patch(`${API_END_POINTS.SUBSCRIPTION_LINK}/${s2linkgid}/notify`, { type: 'ALL' });
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.NOTIFY.SUCCESS,
      });
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response.data && e.response.data.message,
      });
    }
  };

  const addNewSubscription = (subscriptions: Object) => {
    const {
      customersGid,
      plansGid,
      descriptions,
      planAamount,
      qtys,
      productsName,
      pdescription,
      pqty,
      notes,
      productsAmount,
      counts,
      pfrequency,
      period,
      planAddGid,
      customerAddGid,
      apiPlanDate,
      notify,
      applicationFee,
      currencyCode,
    } = subscriptions;
    const couponId = updatedCoupon.gid ? updatedCoupon.gid : null;
    const taxesList = [];
    const listOfTaxes = [...allSelectedTaxrates];
    for (let i = 0; i < listOfTaxes.length; i += 1) {
      taxesList.push(listOfTaxes[i].taxRates.gid);
    }
    const plansDate = apiPlanDate;
    const plansAmount = planAamount.replace(/[. ,:-]+/g, '');
    const productAmount = productsAmount.replace(/[. ,:-]+/g, '');
    const billAmount = plansAmount * qtys;
    const upAmount = productAmount ? productAmount * pqty : 0;
    const payloadApplicationFee = applicationFee ? applicationFee.replace(/[^\d]/g, '') : '';
    const subscriptionLineItems = {
      amount: Number(productAmount),
      currencyCode,
      description: pdescription,
      name: productsName,
      note: notes,
      quantity: pqty,
    };
    addSubscription({
      customerGid: customersGid || customerAddGid,
      planGid: plansGid || planAddGid,
      couponGid: couponId,
      planQuantity: qtys,
      taxRates: taxesList,
      planStartDate: plansDate,
      description: descriptions,
      currencyCode,
      status: 'ACTIVE',
      planBillingFrequency: pfrequency,
      planBillingPeriod: period,
      planTotalPayments: counts,
      planAmount: Number(plansAmount),
      billingAmount: Number(billAmount),
      applicationFeePercentage: payloadApplicationFee ? Number(payloadApplicationFee) : null,
      upfrontAmount: Number(upAmount),
      subscriptionLineItems: [subscriptionLineItems],
      notificationType: notify,
    });
    setRedirect(true);
  };

  const applyCoupon = () => {
    setSelectCouponModal(true);
  };

  const updateCoupon = (Coupon: Object) => {
    setUpdatedCoupon(Coupon);
    setSelectCouponModal(false);
  };

  const newCouponAdd = () => {
    setSelectCouponModal(false);
    setAddCouponModal(true);
  };

  const fetchCustomer = (searchText) => {
    getCustomersList(searchText);
  };

  const addCoupon = (coupon: Object) => {
    const {
      name,
      percentageOff,
      amountOff,
      validity,
      expiresAt,
      maximumRedemption,
      code,
      minimumAmount,
      promotionalRedeemed,
      promoExpiresAt,
      promotional,
      customerGid,
      validiyInMonths,
    } = coupon;
    const params = {
      active: true,
      name,
      percentageOff: (percentageOff ? parseFloat(percentageOff) : null),
      amountOff: (amountOff ? parseInt((amountOff).replace(/[_\W]+/g, ''), 10) : null),
      validity,
      expiresAt,
      maximumRedemption: (maximumRedemption ? parseInt(maximumRedemption, 10) : null),
      validiyInMonths: (validiyInMonths ? parseInt(validiyInMonths, 10) : null),
      currencyCode: typeOfCurrency,
    };
    const payload = {
      active: true,
      code,
      enabledForCheckout: true,
      expiresAt: promoExpiresAt,
      maximumRedemption: promotionalRedeemed ? parseInt(promotionalRedeemed, 10) : 0,
      minimumAmount: minimumAmount ? parseInt((minimumAmount).replace(/[_\W]+/g, ''), 10) : 0,
      minimumAmountCurrency: typeOfCurrency,
      restrictedToCustomers: [
        customerGid,
      ],
    };
    if (promotional) {
      addNewCoupon({
        params,
        payload,
      });
    } else if (!promotional) {
      addNewCoupon({
        params,
      });
    }
    setCouponRedirect(true);
  };

  const applyTaxRate = () => {
    setSelectTaxrateModal(true);
  };

  const updateTaxrate = (Taxrate: Object) => {
    const listOfTaxes = [...allSelectedTaxrates];
    listOfTaxes.push(Taxrate);
    setAllSelectedTaxrates(listOfTaxes);
    setSelectTaxrateModal(false);
  };

  const newTaxAdd = () => {
    setSelectTaxrateModal(false);
    setAddTaxModal(true);
  };

  const addTaxrate = (taxRate: Object) => {
    const {
      displayName,
      jurisdiction,
      percentage,
      inclusive,
      taxDescription,
    } = taxRate;
    const params = {
      displayName,
      jurisdiction,
      percentage,
      inclusive,
      description: taxDescription,
    };
    addNewTaxRate({
      params,
    });
    setTaxRedirect(true);
  };

  const removeCoupon = () => {
    setUpdatedCoupon({});
  };

  const removeTax = (i) => {
    const listOfTaxes = [...allSelectedTaxrates];
    listOfTaxes.splice(i, 1);
    setAllSelectedTaxrates(listOfTaxes);
  };

  const columns = [
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '25%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount, record) => {
        const total = (amount / 100).toFixed(record.currency.toFixed);
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {`${record.currency.prefix}${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '25%',
      align: 'left',
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            <SpStatusTag style={{ backgroundColor: getPaymentBackground(status) }}>
              {getPaymentStatus(status)}
            </SpStatusTag>
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'METHOD',
      dataIndex: 'paymentMethod',
      width: '25%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        if (record.paymentMethod && record.paymentMethod.card) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.card) && (record.paymentMethod.card.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.card.bankName} ${(record.paymentMethod.card && record.paymentMethod.card.lastFour) ? `**** ${record.paymentMethod.card.lastFour}` : ''}`
                  : (record.paymentMethod.card) && (record.paymentMethod.card.lastFour)
                    ? `**** ${record.paymentMethod.card.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.upi) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.upi)
                  ? record.paymentMethod.upi.vpa
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.bank) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.bank) && (record.paymentMethod.bank.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.bank.bankName} ${(record.paymentMethod.bank && record.paymentMethod.bank.lastFour) ? `**** ${record.paymentMethod.bank.lastFour}` : ''}`
                  : ((record.paymentMethod.bank) && (record.paymentMethod.bank.lastFour))
                    ? `**** ${record.paymentMethod.bank.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.netbanking) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {record.paymentMethod.netbanking.bankName ? record.paymentMethod.netbanking.bankName : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '25%',
      align: 'left',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  const terminalColumns = [
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount, record) => {
        const total = (amount / 100).toFixed(record.currency.toFixed);
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {`${record.currency.prefix} ${formatNumber(total, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '20%',
      align: 'left',
      render: (status) => (
        <Tooltip placement="top" title="Click here to view details">
          <span>
            <SpStatusTag style={{ backgroundColor: getPaymentBackground(status) }}>
              {getPaymentStatus(status)}
            </SpStatusTag>
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'METHOD',
      dataIndex: 'paymentMethod',
      width: '20%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        if (record.paymentMethod && record.paymentMethod.card) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.card) && (record.paymentMethod.card.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.card.bankName} ${(record.paymentMethod.card && record.paymentMethod.card.lastFour) ? `**** ${record.paymentMethod.card.lastFour}` : ''}`
                  : (record.paymentMethod.card) && (record.paymentMethod.card.lastFour)
                    ? `**** ${record.paymentMethod.card.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.upi) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.upi)
                  ? record.paymentMethod.upi.vpa
                  : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.bank) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {(record.paymentMethod.bank) && (record.paymentMethod.bank.bankName)
                  // eslint-disable-next-line max-len
                  ? `${record.paymentMethod.bank.bankName} ${(record.paymentMethod.bank && record.paymentMethod.bank.lastFour) ? `**** ${record.paymentMethod.bank.lastFour}` : ''}`
                  : ((record.paymentMethod.bank) && (record.paymentMethod.bank.lastFour))
                    ? `**** ${record.paymentMethod.bank.lastFour}` : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } if (record.paymentMethod && record.paymentMethod.netbanking) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
                {record.paymentMethod.netbanking.bankName ? record.paymentMethod.netbanking.bankName : <>&#8211;</>}
              </span>
            </Tooltip>
          );
        } return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }} onClick={() => selectedRecord(record)}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'TERMINAL NAME',
      dataIndex: 'terminal',
      width: '20%',
      align: 'left',
      render: (terminal) => {
        if (terminal && terminal.deviceName) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }}>
                {terminal && terminal.deviceName}
              </span>
            </Tooltip>
          );
        } return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '18%',
      align: 'left',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        subscriptionDetails.gid && subscriptionDetailsStatus === CONSTANTS.API_STATUS.SUCCEEDED && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col className="d-flex align-items-center">
                  {subscriptionDetails.customer.name
                    ? <SpText className="text-uppercase mr-4" fontSize="20px">{subscriptionDetails.customer.name}</SpText>
                    : ''}
                  {subscriptionDetails.status
                    ? (
                      <SpStatusTag style={{ backgroundColor: getSubscriptionBackground(subscriptionDetails.status) }}>
                        {getSubscriptionStatus(subscriptionDetails.status)}
                      </SpStatusTag>
                    )
                    : <>&#8211;</>}
                </Col>
                <Col className="d-flex justify-content-end">
                  {
                    (subscriptionDetails.status !== SUBSCRIPTION_STATUSES.ACTIVECANCEL)
                    && (subscriptionDetails.status !== SUBSCRIPTION_STATUSES.ACTIVEPAID) && (
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR,
                        ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER, ROLES.INTERNALSUPPORT, ROLES.ONBOARDING]}
                      >
                        <SpButton
                          className="ml-2"
                          type="danger"
                          shape="round"
                          onClick={showDeleteSubscriptionModal}
                        >
                          Cancel Subscription
                        </SpButton>
                        {subscriptionDetails?.subscription2Links?.length > 0 && (
                        <SpButton
                          type="primary"
                          shape="round"
                          className="ml-2"
                          onClick={() => notifySubscription(subscriptionDetails?.subscription2Links[0]?.gid)}
                        >
                          Resend
                        </SpButton>
                           )}
                      </RBAC>
                    )
                  }
                </Col>
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Email</span>
                  </Col>
                  <Col span={6}>
                    {subscriptionDetails.customer.email
                      ? <SpH5>{subscriptionDetails.customer.email}</SpH5>
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Phone</span>
                  </Col>
                  <Col span={6}>
                    {subscriptionDetails.customer.phoneNumber
                      ? (
                        <NumberFormat
                          className="numFormate"
                          value={subscriptionDetails.customer.phoneNumber}
                          format={phoneFormate}
                          mask="_"
                          disabled
                        />
                      )
                      : <>&#8211;</>}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Plan Type</span>
                  </Col>
                  <Col span={6}>
                    {subscriptionDetails.plan2
                      ? <SpH5>{subscriptionDetails.plan2.name}</SpH5>
                      : <>&#8211;</>}
                    {subscriptionDetails.plan2BillingFrequency
                      ? (
                        <SpH5> &#8211;&nbsp;
                          {`Every ${subscriptionDetails.plan2BillingPeriod} `}
                          {`${getFrequency(subscriptionDetails.plan2BillingFrequency)}`}
                        </SpH5>
                      )
                      : ''}
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Next Payment Due On</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {subscriptionDetails.nextPaymentDate
                        ? (
                          moment
                            .utc(subscriptionDetails.nextPaymentDate)
                            .tz(selectedAccount.timezone)
                            .format('MMM DD, YYYY')
                        )
                        : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              {/* <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Upfront Amount</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {subscriptionDetails.upfrontAmount
                        ? `${subscriptionDetails.currency.prefix}${formatNumber((subscriptionDetails.upfrontAmount / 100).toFixed(2), currencyType)}`
                        : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm> */}
              <SpForm>
                {
                (role && role.name === ROLES.PARTNER) ? '' : (
                  <Row type="flex" justify="start">
                    <Col span={24}>
                      <Col span={4}>
                        <span>Link</span>
                      </Col>
                      <Col span={10}>
                        {/* eslint-disable-next-line max-len */}
                        {subscriptionDetails.subscription2Links && subscriptionDetails.subscription2Links[0].link && subscriptionDetails.status !== SUBSCRIPTION_STATUSES.ACTIVECANCEL
                          ? (
                            <SpH5>{subscriptionDetails.subscription2Links[0].link || ''}
                              <SpButton
                                type="secondary"
                                className="ml-2"
                                borderRadius="50px"
                                onClick={() => { copyCode(subscriptionDetails.subscription2Links[0].link || ''); }}
                              >
                                <Clipboard />
                              </SpButton>
                            </SpH5>
                          )
                          : <>&#8211;</>}
                      </Col>
                    </Col>
                  </Row>
                )
              }
                {couponData.gid && (
                  <>
                    <Row type="flex" justify="space-between" className="my-2 mb-3">
                      <Col>
                        <SpText className="mr-3" fontSize="20px" fontWeight="600">
                          Coupon
                        </SpText>
                      </Col>
                    </Row>
                    <SpForm>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={4}>
                          <span>
                            {couponData.name}
                          </span><br />
                          <span fontSize="12px">
                            {getDiscountOff(couponData)}
                          </span>
                        </Col>
                        <Col span={6}>
                          <SpH5>({prefix} {getAmountOff(couponData)})</SpH5>
                        </Col>
                      </Row>
                    </SpForm>
                  </>
                )}
                {
                  (subscriptionDetails) && (subscriptionDetails.taxAmount) && (
                    <>
                      <Row type="flex" justify="space-between" className="my-2 mb-3">
                        <Col>
                          <SpText className="mr-3" fontSize="20px" fontWeight="600">
                            Rates
                          </SpText>
                        </Col>
                      </Row>
                      <SpForm>
                        <>
                          {
                            allTaxrates.map((item) => (
                              <Row type="flex" justify="start" align="middle">
                                <Col span={4}>
                                  <span>
                                    {item.taxRates.displayName}
                                  </span><br />
                                  <span fontSize="12px">
                                    {item.taxRates.inclusive ? RATE_TYPES.INCLUSIVE : RATE_TYPES.EXCLUSIVE}
                                  </span>
                                </Col>
                                <Col span={6}>
                                  <SpH5>
                                    {item.taxRates.percentage} %
                                  </SpH5>
                                </Col>
                              </Row>
                            ))
                          }
                        </>
                      </SpForm>
                    </>
                  )
                }
                <Row className="mb-4">
                  <Col
                    span={24}
                    className="d-flex justify-content-end"
                  >
                    <SpH5 color="#7f7f7f">{subscriptionDetails.gid}</SpH5>
                  </Col>
                </Row>
              </SpForm>

              {/* SELECT COUPON MODAL */}
              {selectCouponModal && (
              <SelectCoupon
                visible={selectCouponModal}
                submitting={submitting}
                close={() => setSelectCouponModal(false)}
                submit={updateCoupon}
                newCouponAdd={newCouponAdd}
              />
              )}

              {/* ADD COUPON MODAL */}
              {addCouponModal && (
              <AddCoupon
                visible={addCouponModal}
                submitting={submitting}
                close={() => setAddCouponModal(false)}
                submit={addCoupon}
                selectedAccount={selectedAccount}
                allCustomers={customersList}
                fetchCustomer={fetchCustomer}
              />
              )}

              {/* SELECT TAXRATE MODAL */}
              {selectTaxrateModal && (
              <SelectTaxrate
                visible={selectTaxrateModal}
                submitting={submitting}
                close={() => setSelectTaxrateModal(false)}
                submit={updateTaxrate}
                newTaxAdd={newTaxAdd}
              />
              )}

              {/* ADD TAX MODAL */}
              {addTaxModal && (
              <AddTaxrate
                visible={addTaxModal}
                submitting={submitting}
                close={() => setAddTaxModal(false)}
                submit={addTaxrate}
                selectedAccount={selectedAccount}
                allTaxes={allTaxes}
                fetchTaxDetails={fetchTaxDetails}
              />
              )}

              {/* DUPLICATE SUBSCRIPTION MODAL */}
              {duplicateSubscriptionModal && (
                <NewSubscription
                  visible={duplicateSubscriptionModal}
                  submitting={submitting}
                  phoneCode={phoneCode}
                  id={id}
                  selectedAccount={selectedAccount}
                  subscriptionDetails={subscriptionDetails}
                  products={products}
                  plans={plans}
                  customers={customers}
                  close={() => setDuplicateSubscriptionModal(false)}
                  submit={addNewSubscription}
                  supportedCurrency={supportedCurrency}
                  applyCoupon={applyCoupon}
                  updatedCoupon={updatedCoupon}
                  applyTaxRate={applyTaxRate}
                  allSelectedTaxrates={allSelectedTaxrates}
                  role={profile && profile.role}
                  removeCoupon={removeCoupon}
                  removeTax={removeTax}
                />
              )}
              {/* DELETE LOCATION MODAL */}
              {deleteSubscriptionModal && (
                <DeleteSelectedSubscription
                  visible={deleteSubscriptionModal}
                  subscriptionDetails={subscriptionDetails}
                  submitting={submitting}
                  close={() => setDeleteSubscriptionModal(false)}
                  submit={deleteSelectedSubscription}
                />
              )}
            </Card>
            <Card className="pl-2">
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">PAYMENTS FOR THIS SUBSCRIPTION</SpText>
                </Col>
              </Row>
              <Table
                onRow={(record) => ({
                  onClick: () => {
                    selectedRecord(record);
                  },
                })}
                rowClassName={() => 'ant-table-clickable-row'}
                columns={
                  (acquirerName && acquirerName === ACQUIRER_TYPES.IMSPOS) ? terminalColumns : columns
                }
                rowKey={(record) => record.gid}
                dataSource={payments}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  subscriptionDetails: state.subscriptionV2Details.subscriptionData,
  subscriptionDetailsStatus: state.subscriptionV2Details.subscriptionDetailsStatus,
  products: state.subscription.products.content,
  profile: state.subscription.profile,
  plans: state.plans.content,
  customers: state.customers.content,
  payments: state.subscriptionV2Details.payments.content,
  totalElements: state.subscriptionV2Details.payments ? state.subscriptionV2Details.payments.totalElements : '',
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  customersList: state.invoiceDetails.customersList,
  newCoupon: state.invoiceDetails.newCoupon,
  newTaxrate: state.invoiceDetails.newTaxrate,
  taxList: state.invoiceDetails.taxList,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSubscriptionV2Details: gid => dispatch(subscriptionV2DetailsActions.fetchSubscriptionV2Details({
    payload: gid,
  })),
  fetchSubscriptionV2Payments: param => dispatch(subscriptionV2DetailsActions.fetchSubscriptionV2Payments({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  addSubscription: param => dispatch(subscriptionAction.addSubscription({
    payload: param,
  })),
  getCustomersList: param => dispatch(invoiceDetailsAction.getCustomersList({
    payload: param,
  })),
  addNewCoupon: param => dispatch(invoiceDetailsAction.addNewCoupon({
    payload: param,
  })),
  addNewTaxRate: param => dispatch(invoiceDetailsAction.addNewTaxRate({
    payload: param,
  })),
  getTaxes: param => dispatch(invoiceDetailsAction.getTaxes({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionDetails);
