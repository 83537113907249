import React from 'react';
import { connect } from 'react-redux';
import {
  SpText, SpCaption, AuthWrapper, AuthPanel,
  SpButton,
} from 'components/DesignKit';
import {
  PAGES,
  ROLES,
} from 'appconstants';

import swireLogo from 'imgs/swirepay.png';
import qpayments from 'imgs/Qpayments.jpeg';

type Props = {
  history: {
    push: Function,
  },
  user: Object,
};

const NoMatch = (props: Props) => {
  const { history, user } = props;

  const redierctToHome = () => {
    if (user.internalRole && user.internalRole.name) {
      if (user.internalRole && user.internalRole.name === ROLES.SUPERUSER) {
        history.push(PAGES.DASHBOARD);
      } else {
        history.push(PAGES.PAYMENTS);
      }
    } else {
      history.push(PAGES.DASHBOARD);
    }
  };

  return (
    <AuthWrapper>
      <AuthPanel>
        <img src={window.location.host.includes('qpaymentz') ? qpayments : swireLogo} alt="" style={{ width: '270px', margin: '32px 0px' }} />
        <div
          className="mb-4"
        >
          <SpText
            fontSize="18px"
            color="#000"
          >
            Oops! Are you sure you are not lost?
          </SpText>
        </div>
        <div className="mb-5 text-center">
          <SpCaption>
            Looks like you have reached a page that doesn&lsquo;t exist. Please click the button below to go back to home page.
          </SpCaption>
        </div>
        <div>
          <SpButton
            onClick={() => redierctToHome()}
          >
            Take me home
          </SpButton>
        </div>
      </AuthPanel>
    </AuthWrapper>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(
  mapStateToProps,
  null,
)(NoMatch);
