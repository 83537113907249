import { ACCOUNT_SESSION } from '../actionTypes';

const initialState = {
  content: [],
  accountSessionInvoiceList: [],
  invoice: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_SESSION.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case ACCOUNT_SESSION.SUCCESS_INVOICES:
      return {
        ...state,
        accountSessionInvoiceList: action.payload,
      };
    case ACCOUNT_SESSION.SUCCESS_VIEW_INVOICES:
      return {
        ...state,
        invoice: action.payload,
        invoiceDetailsStatus: 'SUCCESS',
      };
    default:
      return state;
  }
};
