import React, { useState, useRef, useEffect } from 'react';
import {
  Col,
  Row,
  Card,
  Input,
  Switch,
  Checkbox,

} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpText, SpButton, SpError,
} from 'components/DesignKit';
import {
  PAGES,
} from 'appconstants';
import Loading from 'components/Loading';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { cloverWebhookActions } from 'store/actions';

type Props = {
  loader: boolean,
  validator: boolean,
  cloverWebhook: Object,
  fetchCloverWebhook: Function,
  updateCloverWebhook: Function,
  addBulkImportWebHook: Function,
};

const InternalCloverWebHook = (props: Props) => {
  const {
    loader,
    cloverWebhook,
    fetchCloverWebhook,
    updateCloverWebhook,
    validator,
    addBulkImportWebHook,
  } = props;
  const history = useHistory();
  const [, forceUpdate] = useState();
  const [merchantId, setMerchantId] = useState((cloverWebhook && cloverWebhook.merchantId) || '');
  const [token, setToken] = useState((cloverWebhook && cloverWebhook.apiToken) || '');
  const [ecomApiToken, setEcomApiToken] = useState((cloverWebhook && cloverWebhook.ecomApiToken) || '');
  const [apiKey, setApiKey] = useState((cloverWebhook && cloverWebhook.apiKey) || '');
  const [manageInventory, setManageInventory] = useState((cloverWebhook && cloverWebhook.manageCloverInventory) || false);
  const [redirect, setRedirect] = useState(false);
  const [enable, setEnable] = useState((cloverWebhook && cloverWebhook.enable) || false);
  const [allowInventory, setAllowInventory] = useState((cloverWebhook && cloverWebhook.allowInventory) || false);
  const [allowOrder, setAllowOrder] = useState((cloverWebhook && cloverWebhook.allowOrder) || false);
  const [isEdit, setIsEdit] = useState(false);

  const simpleValidator = useRef(new SimpleReactValidator());

  const addNewWebhook = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const params = {
      merchantId,
      apiToken: token,
      apiKey,
      ecomApiToken,
      manageCloverInventory: manageInventory,
      allowInventory,
      allowOrder,
      enable,
    };
    updateCloverWebhook(params);
    setRedirect(true);
  };

  useEffect(() => {
    if (!(cloverWebhook && cloverWebhook.gid)) {
      fetchCloverWebhook();
    }
  }, []);

  useEffect(() => {
    if (cloverWebhook && cloverWebhook.gid) {
      setMerchantId(cloverWebhook && cloverWebhook.merchantId);
      setToken(cloverWebhook && cloverWebhook.apiToken);
      setApiKey(cloverWebhook && cloverWebhook.apiKey);
      setEcomApiToken(cloverWebhook && cloverWebhook.ecomApiToken);
      setManageInventory((cloverWebhook && cloverWebhook.manageCloverInventory) || false);
      setEnable((cloverWebhook && cloverWebhook?.enable) || false);
    }
    if (!validator && redirect) {
      history && history.push(PAGES.INTEGRATIONS);
    }
  }, [validator, cloverWebhook]);

  if (loader) {
    return <Loading />;
  }

  const handleChange = () => {
    setEnable(!enable);
  };

  const handleInventory = () => {
    setAllowInventory(prevState => !prevState);
  };

  const handleOrder = () => {
    setAllowOrder(prevState => !prevState);
  };


  return (
    <>
      <Card>
        <>
          <Row className="mt-3" type="flex" justify="end" gutter={[16, 16]}>
            <Col>
              <SpButton
                type="secondary"
                shape="round"
                className="mr-3"
                onClick={isEdit ? () => setIsEdit(false) : () => history && history.push(PAGES.INTEGRATIONS)}
              >
                { isEdit ? 'Cancel' : 'Close' }
              </SpButton>
              <SpButton
                type="primary"
                shape="round"
                onClick={isEdit ? () => addNewWebhook() : () => setIsEdit(true)}
              >
                {isEdit ? (cloverWebhook && cloverWebhook.gid ? 'Update' : 'Save') : 'Edit'}
              </SpButton>
            </Col>
          </Row>
          <Row type="flex" justify="start" align="middle" className="mb-1">
            <Col span={8}>
              <SpText fontWeight="600">Merchant Clover ID</SpText>
            </Col>
          </Row>
          <Row type="flex" justify="start" align="middle" className="mb-3">
            <Col span={8}>
              <Input
                placeholder="Enter Merchant Clover ID"
                value={merchantId}
                disabled={!isEdit}
                onChange={(e) => setMerchantId(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('merchant Id', merchantId, 'required')}
              </SpError>
            </Col>
          </Row>
          <Row type="flex" justify="start" align="middle" className="mb-1">
            <Col span={8}>
              <SpText fontWeight="600">API Token</SpText>
            </Col>
          </Row>
          <Row type="flex" justify="start" align="middle" className="mb-3">
            <Col span={8}>
              <Input
                placeholder="Enter API Token"
                value={token}
                disabled={!isEdit}
                onChange={(e) => setToken(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('token', token, 'required')}
              </SpError>
            </Col>
          </Row>
          <Row type="flex" justify="start" align="middle" className="mb-1">
            <Col span={8}>
              <SpText fontWeight="600">API Key (Optional)</SpText>
            </Col>
          </Row>
          <Row type="flex" justify="start" align="middle" className="mb-3">
            <Col span={8}>
              <Input
                placeholder="Enter API Key"
                value={apiKey}
                disabled={!isEdit}
                onChange={(e) => setApiKey(e.currentTarget.value)}
              />
            </Col>
          </Row>
          <Row type="flex" justify="start" align="middle" className="mb-1">
            <Col span={8}>
              <SpText fontWeight="600">Ecommerce API Token (Optional)</SpText>
            </Col>
          </Row>
          <Row type="flex" justify="start" align="middle" className="mb-3">
            <Col span={8}>
              <Input
                placeholder="Ecommerce API Token"
                value={ecomApiToken}
                disabled={!isEdit}
                onChange={(e) => setEcomApiToken(e.currentTarget.value)}
              />
            </Col>
          </Row>
        </>
      </Card>
      <Card>
        <>
          <Row type="flex" justify="start" align="middle" className="mb-1">
            <Col span={8}>
              <SpText fontWeight="600">Merchant Webhooks</SpText>
            </Col>
          </Row>
          <br />
          <Row type="flex" justify="start" align="middle" className="mb-3">
            <Col span={9}>
              <Switch
                className="mb-1"
                disabled={!isEdit}
                checked={enable}
                onChange={handleChange}
              />&nbsp;
              <SpText fontWeight="600">Enable Webhooks</SpText>
            </Col>
          </Row>
          <div
            className="mb-3"
            style={{
              border: '1px solid #d9d9d9', borderRadius: '5px', padding: '14px', maxWidth: '350px',
            }}
            type="flex"
            justify="start"
            align="left"
          >
            <div>
              <Checkbox
                checked={allowInventory}
                className="mr-2"
                onChange={handleInventory}
                disabled={!enable || !isEdit}
              />
             &nbsp;
              <SpText fontWeight="600">Inventory</SpText>
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <SpText fontWeight="400" fontSize="11px">When inventory items are created, updated, or deleted </SpText>
            </div>
          </div>
          <div
            style={{
              border: '1px solid #d9d9d9', borderRadius: '5px', padding: '14px', maxWidth: '350px',
            }}
            type="flex"
            justify="start"
            align="left"
          >
            <div>
              <Checkbox
                checked={allowOrder}
                className="mr-2"
                onChange={handleOrder}
                disabled={!enable || !isEdit}
              />
             &nbsp;
              <SpText fontWeight="600">Orders</SpText><br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <SpText fontWeight="400" fontSize="11px">When orders are created, updated, deleted </SpText>
            </div>
          </div>
        </>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  cloverWebhook: state.cloverWebhook,
  salesforceError: state.salesforce.salesforceError,
  loader: state.loading.loading,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  updateCloverWebhook: param => dispatch(cloverWebhookActions.updateCloverWebhook({
    payload: param,
  })),
  fetchCloverWebhook: param => dispatch(cloverWebhookActions.fetchCloverWebhook({
    payload: param,
  })),
  addBulkImportWebHook: param => dispatch(cloverWebhookActions.addBulkImportWebHook({
    payload: param,
  })),
});


export default connect(mapStateToProps, mapDispatchToProps)(InternalCloverWebHook);
