// @flow
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import Lock from 'assets/imgs/lock.svg';
import {
  Input,
  Select,
  Icon,
  Modal,
  Row,
  Col,
  AutoComplete,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  MESSAGES,
  BENE_TYPES,
} from 'appconstants';
import {
  validationRegex,
  validators,
} from 'utils/validationMessages';
import {
  SpH5, SpError, SpButton, SpText,
} from 'components/DesignKit';
import {
  manageAccountActions, notificationActions, transfersActions, vendorInvoiceDetailsActions,
} from 'store/actions';
import formatAmount from 'utils/formatAmount';
import { API_END_POINTS, axiosAuthInstance } from 'api';
import IndividualIcon from 'assets/imgs/Individual_icon.png';
import BusinessIcon from 'assets/imgs/Business_icon.png';
import AccountVerifiedIcon from 'assets/imgs/account-verified-badge.png';
import EnabledBadge from 'components/NavIcons/enabledBadge';
import OTPInput from 'react-otp-input';
import { NOTIFICATION } from 'store/actionTypes';

const { Option } = Select;

type Props = {
  isIndia: boolean,
  visible: boolean,
  transferDetailsData: Object,
  selectedAccount: Object,
  close: Function,
  invoiceDetails: Object,
  invoiceFundingSourceName: String,
  fetchVendorInvoiceDetails: Function,
  searchFundingSource: Function,
  fundingSearchList: Array<Object>,
  manageAccountsList: Array<Object>,
  fetchSelfAccounts: Function,
  setNotification: Function,
  submit: Function,
};

const ConfirmationPaymentModal = (props: Props) => {
  const {
    searchFundingSource,
    invoiceFundingSourceName,
    manageAccountsList,
    fetchSelfAccounts,
    fundingSearchList,
    invoiceDetails,
    isIndia,
    transferDetailsData,
    selectedAccount,
    visible,
    close,
    setNotification,
    fetchVendorInvoiceDetails,
    submit,

  } = props;

  const [, forceUpdate] = useState();
  const [manageAccountInfo, setManageAccountInfo] = useState(manageAccountsList);
  const [contactName, setContactName] = useState('');
  const [payerFundingSourceGid, setPayerFundingSourceGid] = useState('');
  const [fundingSourceName, setFundingSourceName] = useState(invoiceFundingSourceName);
  const [fundingSourceGid, setFundingSourceGid] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [routingNumber, setRoutingNumber] = useState();
  const [accountType, setAccountType] = useState(isIndia ? 'CURRENT' : 'CHECKING');
  const [businessType, setBusinessType] = useState('INDIVIDUAL');
  const [bankName, setBankName] = useState();
  const [transferSettings, setTransfersetting] = useState({});
  const [upiId, setUpiId] = useState();
  const [accountHolderName, setAccountHolderName] = useState();
  const [fundingResult, setFundingResult] = useState([]);
  const [disableFunding, setDisableFunding] = useState();
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const [otp, setOtp] = useState();
  const [UniqueIdOtp, setUniqueIdOtp] = useState('');
  const [otpVerifyLoading, setOtpVerifyLoading] = useState(false);
  const [fundingSourceNewSuccess, setAddFundingSourceNewSuccess] = useState(false);
  const [error, setError] = useState(false);
  const accountRegex = /[0-9]{6,17}/;
  const routingRegex = /[0-9]{9}/;
  const upiRegex = /^\w.+@\w+$/;
  const transferType = (isIndia ? 'IMPS' : 'ACH');
  const countryCode = selectedAccount.country.alpha2;
  const { prefix } = selectedAccount && selectedAccount.currency;
  const currencyType = selectedAccount && selectedAccount.currency;
  const totalAmount = formatAmount((invoiceDetails && (invoiceDetails.amount / 100)), currencyType);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      accountNumber: {
        message: MESSAGES.BANK_DETAILS.VALID,
        rule: val => accountRegex.test(val),
      },
      bankRoutingNumber: {
        message: MESSAGES.BANK_DETAILS.ROUTING,
        rule: val => routingRegex.test(val),
      },
      bankName: {
        message: validators.bussiness.validBankName,
        rule: val => validationRegex.nameRegex.test(val),
      },
      upiNumber: {
        message: MESSAGES.BANK_DETAILS.UPI,
        rule: val => upiRegex.test(val),
      },
    },
  }));

  simpleValidator.current.purgeFields();

  const getTransferSetting = async () => {
    try {
      const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.TRANSFER_SETTING}`);
      setTransfersetting(entity);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response.data && e.response.data.message,
      });
    }
  };
  useEffect(() => {
    const { fundingSource, contact } = invoiceDetails || {};
    if (fundingSource?.gid) {
      setFundingSourceGid(fundingSource.gid);
    }
    if (contact?.gid) {
      searchFundingSource(`page=1&size=100&contact.gid.EQ=${contact.gid}`);
    }
    if (contact) {
      fetchSelfAccounts('page=1&size=10&sortBy=createdAt&direction=DESC&contact.contactType.EQ=SELF');
      getTransferSetting();
    }
  }, [invoiceDetails]);


  useEffect(() => {
    if (manageAccountsList && manageAccountsList.length > 0) {
      setManageAccountInfo(manageAccountsList);
      const primaryContact = manageAccountsList.filter((item) => (item?.contact?.primary));
      if (primaryContact.length > 0 && primaryContact?.[0]?.gid) {
        setContactName(
          primaryContact[0]?.issuerBank?.bankName && primaryContact[0]?.issuerBank?.lastFour
            ? `${primaryContact[0]?.issuerBank?.bankName} **** ${primaryContact[0]?.issuerBank?.lastFour}`
            : '',
        );
        setPayerFundingSourceGid(primaryContact?.[0]?.gid);
      }
    }
  }, [manageAccountsList]);

  useEffect(() => {
    if (fundingSearchList && fundingSearchList.length > 0) setFundingResult(fundingSearchList);
  }, [fundingSearchList]);

  const updateFunding = (value) => {
    if (value === 'New Funding Source') {
      setDisableFunding(false);
      setFundingSourceName('New Funding Source');
      setAccountNumber('');
      setRoutingNumber('');
      setAccountType(countryCode === 'IN' ? 'CURRENT' : 'CHECKING');
      setBusinessType('INDIVIDUAL');
      setBankName('');
      setAccountHolderName('');
      setUpiId('');
    } else if (value) {
      setFundingSourceName(value);
      const results = fundingResult.filter((item) => (item.gid === value));
      if (results && results[0].gid) {
        setFundingSourceGid(results && results[0].gid);
        if (results && results[0].issuerBank) {
          let fundName;
          if (results && results[0].issuerBank) {
            if ((results[0].contact.name) && (results[0].issuerBank.bankName) && (results[0].issuerBank.lastFour)) {
              fundName = `${results[0].contact.name} | ${results[0].issuerBank.bankName} ****${results[0].issuerBank.lastFour}`;
            } else if ((results[0].issuerBank.bankName) && (results[0].issuerBank.lastFour)) {
              fundName = `${results[0].issuerBank.bankName} ****${results[0].issuerBank.lastFour}`;
            } else if ((results[0].issuerBank.vpa)) {
              fundName = results[0].issuerBank.vpa;
            } else if ((results[0].issuerBank.bankName)) {
              fundName = results[0].issuerBank.bankName;
            } else if ((results[0].issuerBank.lastFour)) {
              fundName = `****${results[0].issuerBank.lastFour}`;
            }
          }
          setFundingSourceName(fundName);
          setUpiId(results[0].issuerBank && results[0].issuerBank.vpa);
          setAccountNumber(results[0].issuerBank && results[0].issuerBank.lastFour);
          setAccountHolderName(results[0].issuerBank && results[0].issuerBank.accountHolderName);
          setRoutingNumber(results[0].issuerBank && results[0].issuerBank.routingNumber);
          setAccountType(results[0].issuerBank && results[0].issuerBank.accountType);
          setBusinessType((results[0].issuerBank && results[0].issuerBank.businessType) || 'INDIVIDUAL');
          setBankName(results[0].issuerBank && results[0].issuerBank.bankName);
        }
        setDisableFunding(true);
      }
    }
  };

  const getOtpUniqueId = async () => {
    try {
      setOtpVerifyLoading(true);
      const { data: { entity: { uniqueId } } } = await axiosAuthInstance.post(API_END_POINTS.TRANSFER_OTP);
      setUniqueIdOtp(uniqueId);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
      });
    } finally {
      setOtpVerifyLoading(false);
    }
  };

  const handleOtp = async () => {
    if (!UniqueIdOtp) {
      getOtpUniqueId();
      setShowOtpScreen(true);
      return;
    }
    if (UniqueIdOtp && !otp) {
      setError(true);
      return;
    }
    // Api calls are done here to avoid loading and closing of modal and enterotp validation
    if (UniqueIdOtp && otp) {
      setError(false);
      const params = {
        payerFundingSourceGid, fundingSourceGid, swirepayOtpGid: UniqueIdOtp, otpCode: otp,
      };
      try {
        setOtpVerifyLoading(true);
        await axiosAuthInstance.patch(`${API_END_POINTS.VENDORINVOICE_API}/${invoiceDetails?.gid}/pay`, params);
        await fetchVendorInvoiceDetails(invoiceDetails?.gid);
      } catch (e) {
        setNotification({
          type: NOTIFICATION.ERROR,
          payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
        });
      } finally {
        setOtpVerifyLoading(false);
      }
    }
  };

  const handlePay = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const approveLimt = (transferSettings && transferSettings.minApprovalLimit);
    const requiresOtp = (Number(invoiceDetails?.amount) > approveLimt) && !selectedAccount.issuer.requiresOtp;
    if (requiresOtp && fundingSourceName !== 'New Funding Source') {
      handleOtp();
    }
    if (fundingSourceName === 'New Funding Source') {
      const contactGid = invoiceDetails?.contact?.gid;
      let fundingSourceAddGid;
      let issuerBank;
      if (transferType === BENE_TYPES.UPI) {
        issuerBank = {
          beneType: BENE_TYPES.VPA,
          vpa: upiId,
          countryCode: selectedAccount.country.alpha2,
        };
      } else {
        issuerBank = {
          accountNumber,
          routingNumber,
          accountType,
          businessType,
          bankName,
          fundingSourceName,
          beneType: BENE_TYPES.BANK,
          countryCode: selectedAccount.country.alpha2,
          accountHolderName,
        };
      }
      const fundingObject = {
        contactGid,
        issuerBank,
      };
      if (!fundingSourceNewSuccess) {
        try {
          const { data: { entity: { gid } } } = await axiosAuthInstance.post(API_END_POINTS.GET_FUNDINGSOURCE, fundingObject);
          fundingSourceAddGid = gid;
          setFundingSourceGid(gid);
          setAddFundingSourceNewSuccess(true);
          setNotification({
            type: NOTIFICATION.SUCCESS,
            payload: MESSAGES.FUNDINGSOURCE.ADD_SUCCESS,
          });
        } catch (e) {
          setNotification({
            type: NOTIFICATION.ERROR,
            payload: e.response.data && e.response.data.message,
          });
        }
      }
      if (requiresOtp) {
        handleOtp();
      }
      if (!requiresOtp) {
        submit({ payerFundingSourceGid, fundingSourceGid: fundingSourceAddGid });
      }
    } else if (!requiresOtp) submit({ payerFundingSourceGid, fundingSourceGid });
  };
  const searchContact = (val) => {
    if (val) {
      const testArray = manageAccountsList.filter((item) => (item.issuerBank.bankName.toLowerCase()).includes(val.toLowerCase()));
      setManageAccountInfo(testArray);
    } else {
      setManageAccountInfo(manageAccountsList);
    }
  };

  const updateContact = (value) => {
    const results = manageAccountsList.filter((item) => (item.gid === value));
    if (results && results[0].gid) {
      setContactName(`${results[0].issuerBank.bankName} **** ${results[0].issuerBank.lastFour}`);
      setPayerFundingSourceGid(results[0].gid);
    }
  };
  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      width="500px"
      footer={[
        <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton key="2" onClick={handlePay} loading={otpVerifyLoading} disabled={otpVerifyLoading}>Pay</SpButton>,
      ]}
      className="p-3"
      title="Payment Confirmation"
    >
      <div className="px-5 mt-2">
        <div className="mb-3">
          <label htmlFor=""><SpH5>From</SpH5></label>
          <AutoComplete
            placeholder="Select Account"
            showSearch
            className="w-100"
            onSearch={searchContact}
            dataSource={manageAccountInfo.map((item, key) => {
              const bankInfo = `${item.issuerBank.bankName} **** ${item.issuerBank.lastFour}`;
              const displayDetails = item?.contact?.primary ? `${bankInfo} (Primary)` : bankInfo;
              return (
                <Option key={key} value={item.gid} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  {displayDetails}
                </Option>
              );
            })}
            style={{ height: '40px' }}
            value={contactName}
            onSelect={e => updateContact(e)}
          />
          <SpError>
            {simpleValidator.current.message('account', contactName, 'required')}
          </SpError>

        </div>
        <div className="mt-2 mb-3 ">
          <label htmlFor=""><SpH5>To Account</SpH5></label>

          <Select
            placeholder="Select Funding Source"
            className="w-100"
            style={{ height: '40px' }}
            value={fundingSourceName}
            onSelect={e => updateFunding(e)}
          >
            <Option key="1" value="New Funding Source">
              <Icon type="plus-circle" />&nbsp;New Funding Source
            </Option>
            {fundingResult && fundingResult.map((item) => {
              const { issuerBank } = item;
              const icon = issuerBank?.businessType === 'INDIVIDUAL'
                ? <img src={IndividualIcon} alt="individual" style={{ width: '15px', height: '15px' }} />
                : <img src={BusinessIcon} alt="business" style={{ width: '15px', height: '15px' }} />;
              const verifiedIcon = issuerBank?.status === 'VERIFIED'
                ? <img src={AccountVerifiedIcon} alt="individual" style={{ width: '15px', height: '15px' }} />
                : null;
              const rtpSupportedIcon = (issuerBank?.rtpsupported !== null && issuerBank?.rtpsupported)
                ? <EnabledBadge />
                : null;
              return (
                <Option key={item.gid} value={item.gid}>
                  {
                    item.issuerBank && item.issuerBank.vpa
                      ? item.issuerBank.vpa
                      : (item.issuerBank && item.issuerBank.bankName && item.issuerBank.lastFour)
                        ? (
                          <>
                            {item?.contact?.name} | {item.issuerBank.bankName} ****{item.issuerBank.lastFour} {icon} {verifiedIcon} {rtpSupportedIcon}
                          </>
                        )
                        : item.issuerBank && item.issuerBank.bankName
                          ? (
                            <>
                              {item.issuerBank.bankName} {icon} {verifiedIcon} {rtpSupportedIcon}
                            </>
                          )
                          : (
                            <>
                              {item?.contact?.name} | ****{item.issuerBank.lastFour} {icon} {verifiedIcon} {rtpSupportedIcon}
                            </>
                          )
                  }
                </Option>
              );
            })}
          </Select>
          <SpError>
            {simpleValidator.current.message('funding Source Name', fundingSourceName, 'required')}
          </SpError>
        </div>
        {fundingSourceName === 'New Funding Source' && (
          <>
            <div className="mb-3">
              <label htmlFor=""><SpH5>Account Holder Name</SpH5></label>
              <Input
                placeholder="Name"
                value={accountHolderName}
                disabled={disableFunding}
                onChange={(e) => setAccountHolderName(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('Account Holder Name', accountHolderName, 'required')}
              </SpError>
            </div>
            {
              (transferDetailsData && (transferDetailsData.transferType === BENE_TYPES.UPI)) ? (
                <>
                  <div>
                    <label htmlFor=""><SpH5>UPI Id</SpH5></label>
                    <Input
                      placeholder="Enter UPI Id"
                      value={upiId}
                      disabled={disableFunding}
                      onChange={(e) => setUpiId(e.currentTarget.value)}
                    />
                    <SpError>
                      {simpleValidator.current.message('upi id', upiId, 'required|upiNumber')}
                    </SpError>
                  </div>
                </>
              ) : (
                <>
                  <div className="mb-3">
                    <label htmlFor=""><SpH5>Account Number</SpH5></label>
                    <Input
                      placeholder="Account Number"
                      value={disableFunding ? `****** ${accountNumber}` : accountNumber}
                      disabled={disableFunding}
                      onBlur={() => setAccountNumber(accountNumber.trim())}
                      onChange={(e) => setAccountNumber(e.currentTarget.value)}
                    />
                    {
                      disableFunding ? (
                        <SpError>
                          {simpleValidator.current.message('account number', accountNumber, 'required')}
                        </SpError>
                      ) : (
                        <SpError>
                          {simpleValidator.current.message('account number', accountNumber, 'required|accountNumber')}
                        </SpError>
                      )
                    }
                  </div>
                  {
                    !isIndia && (
                      <div className="mb-3">
                        <label htmlFor=""><SpH5>Routing Number</SpH5></label>
                        <Input
                          placeholder="Routing Number"
                          disabled={disableFunding}
                          value={routingNumber}
                          onBlur={() => setRoutingNumber(routingNumber.trim())}
                          onChange={(e) => setRoutingNumber(e.currentTarget.value)}
                        />
                        <SpError>
                          {simpleValidator.current.message('routing number', routingNumber, 'required|bankRoutingNumber')}
                        </SpError>
                      </div>
                    )
                  }
                  {
                    isIndia && (
                      <div className="mb-3">
                        <label htmlFor=""><SpH5>IFSC Number</SpH5></label>
                        <Input
                          placeholder="IFSC Number"
                          disabled={disableFunding}
                          value={routingNumber}
                          onBlur={() => setRoutingNumber(routingNumber.trim())}
                          onChange={(e) => setRoutingNumber((e.currentTarget.value).toUpperCase())}
                        />
                        <SpError>
                          {simpleValidator.current.message('ifsc number', routingNumber, 'required')}
                        </SpError>
                      </div>
                    )
                  }
                  <div className="mb-3">
                    <label htmlFor=""><SpH5>Bank Name</SpH5></label>
                    <Input
                      placeholder="Bank Name"
                      disabled={disableFunding}
                      value={bankName}
                      onChange={(e) => setBankName(e.currentTarget.value)}
                    />
                    {
                      !disableFunding && (
                        <SpError>
                          {simpleValidator.current.message('bank name', bankName, 'required|bankName')}
                        </SpError>
                      )
                    }
                  </div>
                  <div>
                    <label htmlFor=""><SpH5>Account Type</SpH5></label>
                    {
                      isIndia && (
                        <Select
                          className="w-100"
                          showSearch
                          disabled={disableFunding}
                          value={accountType}
                          onChange={(value) => {
                            setAccountType(value);
                          }}
                        >
                          <Option key={1} value="CURRENT">CURRENT ACCOUNT</Option>
                          <Option key={2} value="SAVINGS">SAVINGS</Option>
                        </Select>
                      )
                    }
                    {
                      !isIndia && (
                        <Select
                          className="w-100"
                          showSearch
                          disabled={disableFunding}
                          value={accountType}
                          onChange={(value) => {
                            setAccountType(value);
                          }}
                        >
                          <Option key={1} value="CHECKING">CHECKING</Option>
                          <Option key={2} value="SAVINGS">SAVINGS</Option>
                        </Select>
                      )
                    }
                    <SpError>
                      {simpleValidator.current.message('account type', accountType, 'required')}
                    </SpError>
                  </div>
                  <div className="mb-3 mt-3">
                    <label htmlFor=""><SpH5>Business Type</SpH5></label>
                    <Select
                      className="w-100"
                      showSearch
                      value={businessType}
                      onChange={(value) => {
                        setBusinessType(value);
                      }}
                    >
                      <Option key={1} value="INDIVIDUAL">INDIVIDUAL</Option>
                      <Option key={2} value="BUSINESS">BUSINESS</Option>
                    </Select>
                    <SpError>
                      {simpleValidator.current.message('business type', businessType, 'required')}
                    </SpError>
                  </div>
                </>
              )
            }
          </>
        )}
        <div style={{ border: '1px solid #C7C7C7', padding: '10px', borderRadius: '6px' }} className="mt-2">
          <div className="mb-3">
            <Row justify="start">
              <Col>
                <SpH5>Invoice Amount</SpH5>
              </Col>
              <Col>
                <SpText>{(totalAmount) ? `${prefix}${totalAmount}` : <>&#8211;</>}</SpText>
              </Col>
            </Row>
          </div>
          <div className="mb-3">
            <Row justify="start">
              <Col>
                <SpH5>Invoice No.</SpH5>
              </Col>
              <Col>
                <SpText>{(invoiceDetails && invoiceDetails.invoiceNumber) || <>&#8211;</>}</SpText>
              </Col>
            </Row>
          </div>
          <div>
            <Row justify="start">
              <Col>
                <SpH5>Contact</SpH5>
              </Col>
              <Col>
                <SpText>
                  {fundingSourceName ?? <>&#8211;</>}
                </SpText>
              </Col>
            </Row>
          </div>
        </div>
        {
          showOtpScreen && transferSettings && (
            <div style={{
              display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',
            }}
            >
              {
                error && (
                  <div>
                    <SpError>
                      Please provide the OTP
                    </SpError>
                  </div>
                )
              }
              <img src={Lock} alt="Lock" style={{ marginTop: '3px', width: '25px' }} />
              <SpH5 style={{ marginTop: '3px' }}>Enter Code</SpH5>
              <SpText>Enter the code sent to {transferSettings && transferSettings.approvalEmail}</SpText>
              <div className="mt-2">
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span style={{ width: '8px' }} />}
                  inputType="password"
                  inputStyle={{
                    border: '1px solid',
                    borderRadius: '8px',
                    width: '54px',
                    height: '54px',
                    fontSize: '12px',
                    color: '#000',
                    fontWeight: '400',
                    caretColor: 'blue',
                  }}
                  renderInput={(props) => <input {...props} />}
                />
              </div>
            </div>
          )
        }
      </div>
    </Modal>
  );
};

// $FlowFixMe
const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  fundingSearchList: state.transfers.searchFundingList,
  manageAccountsList: state.manageAccounts.content,


});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  searchFundingSource: param => dispatch(transfersActions.searchFundingSource({
    payload: param,
  })),
  fetchSelfAccounts: param => dispatch(manageAccountActions.fetchSelfAccounts({
    payload: param,
  })),
  fetchVendorInvoiceDetails: gid => dispatch(vendorInvoiceDetailsActions.fetchVendorInvoiceDetails({
    payload: gid,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationPaymentModal);
