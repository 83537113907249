// @flow
import React, { useEffect, useState } from 'react';
import { GoogleApiWrapper } from 'google-maps-react';
import {
  Input,
} from 'antd';
import {
  SpH5,
} from 'components/DesignKit';
import moment from 'moment-timezone';
import PAY from '../../../assets/imgs/payIcons.svg';

type Props = {
  pageData: Object,
  customerDetailsArray: Array,
  productArrays: Array,
  inventoryItems: Array,
  sortCategoryList: Array,
};

const ReviewSeatedPage = (props: Props) => {
  const {
    pageData,
    customerDetailsArray,
    productArrays,
    inventoryItems,
    sortCategoryList,
  } = props;

  console.log(sortCategoryList, 'productDetailsArrayproductDetailsArrayproductDetailsArrayproductDetailsArrayproductDetailsArray');

  const [mobileView, setMobileView] = useState(false);

  const getItemName = (itemGid) => {
    const selectedItem = inventoryItems.filter((item) => item.gid === itemGid);
    return selectedItem[0]?.name;
  };

  const getItemPrice = (itemGid) => {
    const selectedItem = inventoryItems.filter((item) => item.gid === itemGid);
    return selectedItem[0]?.price ? (selectedItem[0]?.price / 100) : '0';
  };

  const getCatName = (catGid) => {
    const selectedItem = sortCategoryList.filter((item) => item.gid === catGid);
    return selectedItem[0]?.name;
  };

  const getCatPrice = (catGid) => {
    const selectedItem = sortCategoryList.filter((item) => item.gid === catGid);
    return selectedItem[0]?.price ? (selectedItem[0]?.price / 100) : '0';
  };

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 480) {
      setMobileView(true);
    }
  }, []);

  return (
    <div className="ml-4 mr-4">
      <div className="d-flex" style={{ backgroundColor: '#0F0F0F', paddingTop: '15px' }}>
        <div style={{ width: '60%' }}>
          <img src={pageData && pageData.logo} style={{ width: '100px' }} alt="logo" className="ml-2" />
          <span style={{ fontSize: '16px', fontWeight: '600', color: '#FFFFFF' }} className="ml-2">{pageData && pageData.title}</span>
        </div>
        <div style={{ width: '40%' }}>
          <p style={{ fontSize: '16px', fontWeight: '400', color: '#FFFFFF' }}>Contact: {pageData?.phoneNumberCode} - {pageData?.phoneFormate}</p>
          <p style={{ fontSize: '16px', fontWeight: '400', color: '#FFFFFF' }}>Email: {pageData && pageData.contactUsEmail}</p>
        </div>
      </div>
      <div
        className="mt-4 mb-4"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={pageData && pageData.banner} style={{ width: '300px', objectFit: 'cover' }} alt="banner" />
      </div>
      <div
        className="mt-4 mb-4"
        style={{
          justifyContent: 'start',
        }}
      >
        <p style={{ fontSize: '20px', fontWeight: '600' }}>Ticket Details</p>
      </div>
      <div
        className="mb-4"
        style={{
          justifyContent: 'start',
        }}
      >
        {
          productArrays.map((item) => (
            <div>
              {
                item.inventoryCategoryGids.map((cat) => (
                  <>
                    <div className="d-flex" style={{ width: '100%' }}>
                      <div style={{ width: '50%' }}>
                        <p style={{ fontSize: '14px', fontWeight: '600' }}>{getCatName(cat)}</p>
                        <p style={{ fontSize: '18px', fontWeight: '600' }}>$ {getCatPrice(cat)}</p>
                      </div>
                      <div style={{ width: '15%' }}>
                        <div style={{ border: '1px solid #D9D9D9', borderRadius: '10px', padding: '10px' }}>
                          &#8211; &nbsp;&nbsp;&nbsp; 0 &nbsp;&nbsp;&nbsp; +
                        </div>
                      </div>
                    </div>
                  </>
                ))
              }
              {
                item.itemGids.map((items) => (
                  <>
                    <div className="d-flex" style={{ width: '100%' }}>
                      <div style={{ width: '50%' }}>
                        <p style={{ fontSize: '14px', fontWeight: '600' }}>{getItemName(items)}</p>
                        <p style={{ fontSize: '18px', fontWeight: '600' }}>$ {getItemPrice(items)}</p>
                      </div>
                      <div style={{ width: '15%' }}>
                        <div style={{ border: '1px solid #D9D9D9', borderRadius: '10px', padding: '10px' }}>
                          &#8211; &nbsp;&nbsp;&nbsp; 0 &nbsp;&nbsp;&nbsp; +
                        </div>
                      </div>
                    </div>
                  </>
                ))
              }
            </div>
          ))
        }
      </div>

      <div
        className="mt-4 mb-4"
        style={{
          justifyContent: 'start',
        }}
      >
        <p style={{ fontSize: '20px', fontWeight: '600' }}>Your Information</p>
      </div>
      <div
        className="mb-4"
        style={{
          justifyContent: 'start',
        }}
      >
        {
          customerDetailsArray.map((item) => (
            <div className="mb-4">
              <label htmlFor=""><SpH5>{item.label}</SpH5></label>
              <Input
                placeholder={item.label}
              />
            </div>
          ))
        }
      </div>
      <div
        className="mb-4"
        style={{
          justifyContent: 'center',
          fontFamily: 'sans-serif',
          display: 'flex',
        }}
      >
        <img src={PAY} alt="pay" />
      </div>
    </div>
  );
};

// $FlowFixMe
export default GoogleApiWrapper({
  apiKey: 'AIzaSyCLR2kMaQ9AHIa_tqsG48hxA9CZ7ullXds',
})(ReviewSeatedPage);
