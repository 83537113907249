// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';
import queryString from 'query-string';
import RBAC from 'components/rbac';
import { useLocation } from 'react-router-dom';
import Minus from 'imgs/Minus.png';
import SeperateLine from 'imgs/SeperateLine.png';

import getOrdersPaymentBackground from 'utils/getOrdersPaymentBackground';
import getOrdersPaymentStatus from 'utils/getOrdersPaymentStatus';
import {
  Card,
  Table,
  Row,
  Col,
  Menu,
  Icon,
  Tooltip,
  Modal,
} from 'antd';

import {
  SpForm,
  SpText,
  SpH5,
  SpTab,
  SpButton,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  inventoryOrderDetailsActions,
  notificationActions,
} from 'store/actions';
import {
  PAGES,
  ROLES,
} from 'appconstants';
import DeleteLineItem from './components/DeleteLineItem';
import DeleteOrder from './components/DeleteOrder';
import AddItem from './components/AddItem';
import MultiStepModal from './components/refund';

type Props = {
  fetchInventoryOrderDetails: Function,
  refundOrderDetails: Function,
  deleteOrderLineItem: Function,
  refundOrderLineItem: Function,
  deleteOrderDetails: Function,
  inventoryOrderDetails: Object,
  loading: boolean,
  selectedAccount: Object,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  lineItems: Array<Object>,
  payments: Array<Object>,
  totalElements: Number,
  totalPromoElements: Number,
  validator: Boolean,
  submitting: Boolean,
  fetchPayments: Function,
  addItemOrder: Function,
}

const StatusTag = styled('div')({
  borderRadius: '10px',
  display: 'inline-block',
  paddingLeft: '5px',
  paddingRight: '5px',
  marginLeft: 'auto',
  marginRight: 'auto',
  minWidth: '75px',
  textAlign: 'center',
});

const OrderDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchInventoryOrderDetails,
    refundOrderDetails,
    deleteOrderLineItem,
    refundOrderLineItem,
    deleteOrderDetails,
    inventoryOrderDetails,
    loading,
    history,
    lineItems,
    payments,
    selectedAccount,
    totalElements,
    totalPromoElements,
    validator,
    submitting,
    fetchPayments,
    addItemOrder,
  } = props;
  const { gid } = props.match.params;
  const gidPath = gid.split('-')[1];
  const path = props.match.url;
  const currencyType = selectedAccount && selectedAccount.currency;

  const [pagination, setPagination] = useState({});
  const [current, setCurrent] = useState('lineItems');
  const [firstTime, setFirstTime] = useState(true);
  const [deleteItemModal, setDeleteItemModal] = useState(false);
  const [deleteOrderModal, setDeleteOrderModal] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [selectedLineItem, setSelectedLineItem] = useState({});
  const [disableButton, setDisableButton] = useState(false);
  const [addItem, setAddItem] = useState(false);
  const [restockItem, setRestockItem] = useState(false);
  const [refundModal, setRefundModal] = useState(false);
  const [refundItemLevel, setRefundItemLevel] = useState(false);
  const [itemStock, setItemStock] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);


  // const getData = () => {
  //   fetchInventoryOrderDetails(gid);
  // };

  const getData = (query) => {
    if (firstTime) {
      fetchInventoryOrderDetails(gid);
      setFirstTime(false);
    }
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchPayments({ currentPage, sortParams, gid });
  };

  /**
   * Get data on page load
   */
  // useEffect(() => {
  //   getData();
  // }, []);

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.INVENTORY_ORDERS);
    }
  }, []);

  useEffect(() => {
    if (redirect && !submitting) {
      setDeleteOrderModal(false);
      setDisableButton(false);
      history.push(PAGES.INVENTORY_ORDERS);
    }
    if (!submitting) {
      setRestockItem(false);
    }
  }, [submitting]);

  useEffect(() => {
    if (redirect && !validator) {
      setDisableButton(false);
      setDeleteItemModal(false);
      setAddItem(false);
      getData(location.search);
    }
    if (!validator) {
      setRestockItem(false);
      setRefundModal(false);
    }
  }, [validator]);

  const getTotalElements = () => {
    if (current === 'lineItems') {
      return totalElements;
    } if (current === 'payments') {
      return totalPromoElements;
    }
    return null;
  };

  const lineItemSubmit = (data1, data2, data3) => {
    console.log('1', JSON.stringify(data1));
    console.log('2', JSON.stringify(data2));
    console.log('3', JSON.stringify(data3));


    setRefundItemLevel(false);
    /* const quantityLookup = data3.reduce((acc, item) => {
      acc[item.gid] = item.quantity;
      return acc;
    }, {}); */

    const params = data1.map(item1 => {
      const item2 = data2.find(i => i.gid === item1.gid);


      return {
        gid: item1 ? item1.gid : item2.gid,
        quantity: item1 ? item1.quantity : 0,
        refundNote: 'refund Test',
        restockItem: item2 ? item2.stockEnabled : false,
        itemRestockQuantity: item2 ? item2.quantity : 0, // Assuming itemRestockQuantity is taken from data3's quantity
      };
    });

    console.log(params);
    refundOrderLineItem({ gid, params });
  };

  const decreaseQuantity = (item) => {
    setItemStock(prevItemStock => prevItemStock.map(stockItem => (
      stockItem.itemGid === item.itemGid && stockItem.quantity > 1
        ? { ...stockItem, quantity: stockItem.quantity - 1 }
        : stockItem)));
  };

  const increaseQuantity = (item) => {
    setItemStock(prevItemStock => prevItemStock.map(stockItem => (
      stockItem.itemGid === item.itemGid && stockItem.quantity < stockItem.maxQuantity
        ? { ...stockItem, quantity: stockItem.quantity + 1 }
        : stockItem)));
  };

  const refundPayments = () => {
    if (inventoryOrderDetails
      && inventoryOrderDetails.inventoryOrderLineItems
      && inventoryOrderDetails.inventoryOrderLineItems.length !== 0
      && inventoryOrderDetails.inventoryOrderLineItems.some(
        item => item.stockEnabled && !item.stockRefunded,
      )) {
      setRestockItem(true);
    } else {
      setRefundModal(true);
    }
  };


  const refundPaymentsItem = () => {
    setRefundItemLevel(true);
  };
  const refundPaymentsItemClose = () => {
    setRefundItemLevel(false);
  };

  const handleTableChange = (page, sorter, filters) => {
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: `${PAGES.INVENTORY_ORDERS}/${gid}`,
      search: `?pageNum=${tempPage}${tempParams}`,
    });
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: getTotalElements(),
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [current, payments]);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData(location.search);
  }, [location, selectedAccount]);

  const showDeleteItemModal = (value: Object) => {
    setSelectedLineItem(value);
    setDeleteItemModal(true);
  };

  useEffect(() => {
    if (inventoryOrderDetails && inventoryOrderDetails.inventoryOrderLineItems) {
      const accumulatedItems = {};

      inventoryOrderDetails.inventoryOrderLineItems.forEach(item => {
        if (item && item.stockEnabled && !item.stockRefunded) {
          const {
            itemGid, itemName, quantity,
          } = item;

          if (!accumulatedItems[itemGid]) {
            accumulatedItems[itemGid] = {
              itemGid, itemName, quantity, maxQuantity: quantity,
            };
          } else {
            accumulatedItems[itemGid].quantity += quantity;
            accumulatedItems[itemGid].maxQuantity += quantity;
          }
        }
      });

      setItemStock(Object.values(accumulatedItems));
    }
  }, [inventoryOrderDetails]);


  const selectedRecord = (record) => {
    const orderGid = record.paymentSession && record.paymentSession.gid;
    const cashGid = record.cashTransaction && record.cashTransaction.gid;
    if (orderGid) {
      history.push({
        pathname: `${PAGES.PAYMENTS}/${orderGid}`,
      });
    }
    if (cashGid) {
      history.push({
        pathname: `${PAGES.CASH_TRANSACTIONS}/${cashGid}`,
      });
    }
  };

  const deleteSelectedItem = (info: Object) => {
    const {
      quantity,
      note,
      lineItemGid,
    } = info;
    const params = [{
      quantity,
      deleteNote: note,
      gid: lineItemGid,
    }];
    deleteOrderLineItem({
      gid,
      params,
    });
    setRedirect(true);
  };

  const addSelectedItem = (info: Object) => {
    const {
      modiferData,
    } = info;
    addItemOrder({
      gid: inventoryOrderDetails.gid,
      params: modiferData,
    });
    setRedirect(true);
  };

  const deleteSelectedOrder = (customerGid: Object) => {
    deleteOrderDetails({
      gid: customerGid,
    });
    setRedirect(true);
  };

  const cloverColumns = [
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount, record) => {
        const total = record.cashTransaction ? (record.cashTransaction.amount / 100).toFixed(2) : record.paymentSession
          ? (record.paymentSession.amount / 100).toFixed(2) : '0.00';
        return (
          <span>
            {`${selectedAccount.currency && selectedAccount.currency.prefix} ${formatNumber(total, currencyType)}`}
          </span>
        );
      },
    },
    {
      title: 'METHOD',
      dataIndex: 'paymentMethod',
      width: '20%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        const paymentMethods = record.paymentSession && record.paymentSession.paymentMethod;
        if (paymentMethods && paymentMethods.card) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {(paymentMethods.card) && (paymentMethods.card.bankName)
                // eslint-disable-next-line max-len
                ? `${paymentMethods.card.bankName} ${(paymentMethods.card && paymentMethods.card.lastFour) ? `**** ${paymentMethods.card.lastFour}` : ''}`
                : (paymentMethods.card) && (paymentMethods.card.lastFour)
                  ? `**** ${paymentMethods.card.lastFour}` : <>&#8211;</>}
            </span>
          );
        } if (paymentMethods && paymentMethods.upi) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {(paymentMethods.upi)
                ? (paymentMethods.upi.vpa || 'UPI')
                : <>&#8211;</>}
            </span>
          );
        } if (paymentMethods && paymentMethods.bank) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {(paymentMethods.bank) && (paymentMethods.bank.bankName)
                // eslint-disable-next-line max-len
                ? `${paymentMethods.bank.bankName} ${(paymentMethods.bank && paymentMethods.bank.lastFour) ? `**** ${paymentMethods.bank.lastFour}` : ''}`
                : ((paymentMethods.bank) && (paymentMethods.bank.lastFour))
                  ? `**** ${paymentMethods.bank.lastFour}` : <>&#8211;</>}
            </span>
          );
        } if (paymentMethods && paymentMethods.netbanking) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {paymentMethods.netbanking.bankName ? paymentMethods.netbanking.bankName : <>&#8211;</>}
            </span>
          );
        } if (paymentMethods && paymentMethods.paymentType) {
          const QrName = (paymentMethods.paymentType && paymentMethods.paymentType.name);
          return (
            <span style={{ cursor: 'pointer' }}>
              {(QrName === 'QR') ? QrName : <>&#8211;</>}
            </span>
          );
        } if (record && record.cashTransaction) {
          return (
            <span style={{ cursor: 'pointer' }}>
              CASH
            </span>
          );
        } return (
          <span style={{ cursor: 'pointer' }}>
            <>&#8211;</>
          </span>
        );
      },
    },
    {
      title: 'RECEIPT NUMBER',
      dataIndex: 'receiptNumber',
      width: '20%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        const paymentMethods = record.paymentSession;
        const cashMethod = record.cashTransaction;
        if (paymentMethods && paymentMethods.receiptNumber) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {paymentMethods.receiptNumber}
            </span>
          );
        } if (cashMethod && cashMethod.receiptNumber) {
          return (
            <span style={{ cursor: 'pointer' }}>
              {cashMethod.receiptNumber}
            </span>
          );
        } return (
          <span style={{ cursor: 'pointer' }}>
            <>&#8211;</>
          </span>
        );
      },
    },
    {
      title: 'ORDER ID',
      dataIndex: 'mrn',
      width: '16%',
      align: 'left',
      render: (mrn) => (
        <span style={{ cursor: 'pointer' }}>
          {mrn || <>&#8211;</>}
        </span>
      ),
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '18%',
      align: 'left',
      render: (text, record) => {
        if (record.createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(record.createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];


  const columns = [
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '12%',
      align: 'left',
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '15%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        if (record.itemName) {
          return (
            <span>
              {record.itemName}
            </span>
          );
        }
        if (record.itemData) {
          return (
            <span>
              {(record.itemData) && (record.itemData.name)
                ? (record.itemData) && (record.itemData.name)
                : <>&#8211;</>}
            </span>
          );
        } return <>&#8211;</>;
      },
    },
    {
      title: 'QTY',
      dataIndex: 'quantity',
      width: '10%',
      align: 'left',
      render: (quantity) => (
        <span>
          {quantity || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'PRICE',
      dataIndex: 'amount',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount) => {
        const total = amount ? (amount / 100).toFixed(2) : '';
        return (
          <span>
            {total
              ? `${selectedAccount.currency && selectedAccount.currency.prefix} ${formatNumber(total, currencyType)}`
              : <>&#8211;</>}
          </span>
        );
      },
    },
    {
      title: 'MODIFICATIONS',
      dataIndex: 'modifierData',
      width: '20%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        if (record.lineItemModifiers && record.lineItemModifiers.length !== 0) {
          return (
            <>
              {record.lineItemModifiers.map((item, id) => (
                <>
                  <Row>
                    <Col>
                      {item.modifierName || <>&#8211;</>}
                    </Col>
                    <Col className="mt-2">
                      {item.price
                        // eslint-disable-next-line max-len
                        ? `${selectedAccount.currency && selectedAccount.currency.prefix} ${formatNumber((item.price / 100).toFixed(2), currencyType)}`
                        : `${selectedAccount.currency && selectedAccount.currency.prefix} 0.00`}
                    </Col>
                  </Row>
                  {record.lineItemModifiers.length !== (id + 1) ? <hr style={{ marginTop: '8px', marginBottom: '8px' }} /> : null}
                </>
              ))}
            </>
          );
        }
        if (record.modifierData && record.modifierData.length !== 0) {
          return (
            <>
              {record.modifierData.map((item, id) => (
                <>
                  <Row>
                    <Col>
                      {item.name || <>&#8211;</>}
                    </Col>
                    <Col className="mt-2">
                      {item.price
                        // eslint-disable-next-line max-len
                        ? `${selectedAccount.currency && selectedAccount.currency.prefix} ${formatNumber((item.price / 100).toFixed(2), currencyType)}`
                        : `${selectedAccount.currency && selectedAccount.currency.prefix} 0.00`}
                    </Col>
                  </Row>
                  {record.modifierData.length !== (id + 1) ? <hr style={{ marginTop: '8px', marginBottom: '8px' }} /> : null}
                </>
              ))}
            </>
          );
        } if (record.modifications && record.modifications.length !== 0) {
          return (
            <>
              {record.modifications.map((item, id) => (
                <>
                  <Row>
                    <Col>
                      {item.name || <>&#8211;</>}
                    </Col>
                    <Col className="mt-2">
                      {item.price
                        // eslint-disable-next-line max-len
                        ? `${selectedAccount.currency && selectedAccount.currency.prefix} ${formatNumber((item.price / 100).toFixed(2), currencyType)}`
                        : `${selectedAccount.currency && selectedAccount.currency.prefix} 0.00`}
                    </Col>
                  </Row>
                  {record.modifications.length !== (id + 1) ? <hr style={{ marginTop: '8px', marginBottom: '8px' }} /> : null}
                </>
              ))}
            </>
          );
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'DISCOUNT',
      dataIndex: 'discountData',
      width: '20%',
      align: 'left',
      sorter: false,
      render: (text, record) => {
        if (record.lineItemDiscounts && record.lineItemDiscounts.length !== 0) {
          return (
            <>
              {record.lineItemDiscounts.map((item, id) => (
                <>
                  <Row>
                    <Col>
                      {item.name || <>&#8211;</>}
                    </Col>
                    <Col className="mt-1">
                      {item.amount
                        // eslint-disable-next-line max-len
                        ? `${selectedAccount.currency && selectedAccount.currency.prefix} ${formatNumber((item.amount / 100).toFixed(2), currencyType)}`
                        : `${selectedAccount.currency && selectedAccount.currency.prefix} 0.00`}
                    </Col>
                  </Row>
                  {record.lineItemDiscounts.length !== (id + 1) ? <hr style={{ marginTop: '8px', marginBottom: '8px' }} /> : null}
                </>
              ))}
            </>
          );
        }
        if (record.lineItemDiscount && record.lineItemDiscount.length !== 0) {
          return (
            <>
              {record.lineItemDiscount.map((item, id) => (
                <>
                  <Row>
                    <Col>
                      {item.name || <>&#8211;</>}
                    </Col>
                    <Col className="mt-1">
                      {item.amount
                        // eslint-disable-next-line max-len
                        ? `${selectedAccount.currency && selectedAccount.currency.prefix} ${formatNumber((item.amount / 100).toFixed(2), currencyType)}`
                        : `${selectedAccount.currency && selectedAccount.currency.prefix} 0.00`}
                    </Col>
                  </Row>
                  {record.lineItemDiscount.length !== (id + 1) ? <hr style={{ marginTop: '8px', marginBottom: '8px' }} /> : null}
                </>
              ))}
            </>
          );
        }
        if (record.discountData && record.discountData.length !== 0) {
          return (
            <>
              {record.discountData.map((item, id) => (
                <>
                  <Row>
                    <Col>
                      {item.name || <>&#8211;</>}
                    </Col>
                    <Col className="mt-1">
                      {item.amountOffVal
                        // eslint-disable-next-line max-len
                        ? `${selectedAccount.currency && selectedAccount.currency.prefix} ${formatNumber((item.amountOffVal / 100).toFixed(2), currencyType)}`
                        : `${selectedAccount.currency && selectedAccount.currency.prefix} 0.00`}
                    </Col>
                  </Row>
                  {record.discountData.length !== (id + 1) ? <hr style={{ marginTop: '8px', marginBottom: '8px' }} /> : null}
                </>
              ))}
            </>
          );
        } return (
          <>&#8211;</>
        );
      },
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '16%',
    align: 'left',
    render: (text, record) => (
      <span>
        <Tooltip placement="top" title="Delete">
          <Icon
            type="delete"
            className="ml-3"
            style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); showDeleteItemModal(record); }}
          />
        </Tooltip>
      </span>
    ),
  };

  if (loading) {
    return <Loading />;
  }

  const onRefundClose = () => {
    setRestockItem(false);
    setDisableButton(false);
  };

  const handleCheckboxChange = (item) => {
    setSelectedItems(prevSelectedItems => (
      prevSelectedItems.includes(item.itemGid)
        ? prevSelectedItems.filter(itemGid => itemGid !== item.itemGid)
        : [...prevSelectedItems, item.itemGid]));
  };

  const handleRefundProcess = () => {
    setDisableButton(true);
    const selectedItemsData = itemStock
      .filter(item => selectedItems.includes(item.itemGid))
      .map(item => ({
        gid: item.itemGid,
        quantity: item.quantity,
      }));
    refundOrderDetails({ gid, params: selectedItemsData });
  };
  const handleRefundOrder = () => {
    setDisableButton(true);
    const params = {
      gid,
    };
    refundOrderDetails(params);
  };
  return (
    <>
      {
        inventoryOrderDetails.gid && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                <Col className="d-flex align-items-center">
                  <SpText className="mr-3" fontWeight="600" fontSize="20px">
                    {(inventoryOrderDetails && inventoryOrderDetails.cloverPosOrderId)
                      || (inventoryOrderDetails && inventoryOrderDetails.orderNumber) || <>&#8211;</>}
                  </SpText>
                  <StatusTag style={{ backgroundColor: getOrdersPaymentBackground(inventoryOrderDetails.paymentStatus) }}>
                    {getOrdersPaymentStatus(inventoryOrderDetails.paymentStatus)}
                  </StatusTag>
                </Col>
                <Col>
                  {
                    (inventoryOrderDetails && inventoryOrderDetails.orderType !== 'POS') ? (
                      inventoryOrderDetails && inventoryOrderDetails.paymentStatus !== 'PAID' ? (
                        inventoryOrderDetails && inventoryOrderDetails.paymentStatus !== 'PARTIALLY_REFUNDED'
                        && inventoryOrderDetails && inventoryOrderDetails.paymentStatus !== 'REFUNDED' && (
                          <RBAC
                            permit={
                              [ROLES.SUPERUSER, ROLES.KDSADMIN1, ROLES.OWNER]
                            }
                          >
                            <SpButton
                              type="secondary"
                              shape="round"
                              className="mr-3"
                              onClick={() => setAddItem(true)}
                              disabled={disableButton}
                            >
                              {'\u002B'}&nbsp;Add Item
                            </SpButton>
                          </RBAC>
                        )) : null) : null
                  }

                  {
                    refundItemLevel && (<MultiStepModal data={lineItems} close={refundPaymentsItemClose} selectedAccount={selectedAccount} lineItemSubmit={lineItemSubmit} />)
                  }

                  {addItem && (
                    <AddItem
                      visible={addItem}
                      submitting={submitting}
                      inventoryItemDetails={selectedLineItem}
                      selectedAccount={selectedAccount}
                      shopGid={inventoryOrderDetails && inventoryOrderDetails.shop && inventoryOrderDetails.shop.gid}
                      close={() => setAddItem(false)}
                      submit={addSelectedItem}
                    />
                  )}
                  {
                    inventoryOrderDetails && inventoryOrderDetails.paymentStatus === 'UN_PAID' ? (
                      <RBAC
                        permit={
                          [ROLES.SUPERUSER, ROLES.KDSADMIN1, ROLES.OWNER]
                        }
                      >
                        <SpButton
                          type="danger"
                          shape="round"
                          className="mr-3"
                          onClick={() => setDeleteOrderModal(true)}
                          disabled={disableButton}
                        >
                          Delete
                        </SpButton>
                      </RBAC>
                    ) : null
                  }
                  {
                    inventoryOrderDetails && inventoryOrderDetails.paymentStatus === 'PAID' ? (
                      <RBAC
                        permit={
                          [ROLES.SUPERUSER, ROLES.KDSADMIN1, ROLES.OWNER]
                        }
                      >
                        <SpButton
                          type="primary"
                          shape="round"
                          onClick={refundPayments}
                          disabled={disableButton}
                        >
                          {itemStock && itemStock.length !== 0 ? 'Restock & Refund' : 'Refund'}
                        </SpButton>
                      </RBAC>
                    ) : null
                  }

                </Col>
              </Row>

              {restockItem && (
                <Modal
                  centered
                  closable={false}
                  visible
                  footer={null}
                  onCancel={onRefundClose}
                  title={(
                    <div style={{
                      fontFamily: 'Open Sans', fontStyle: 'normal', fontWeight: '700', fontSize: '16px', lineHeight: '22px',
                    }}
                    >
                      Restock and Refund Items or Refund Only?
                    </div>
                  )}
                >
                  <div style={{
                    padding: '15px 30px',
                    fontFamily: 'Open Sans',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '169.7%',
                  }}
                  >
                    <div
                      className="w100"
                      style={{
                        padding: '0px 0px 10px',
                        fontFamily: 'Open Sans',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        fontSize: '14px',
                        lineHeight: '169.7%',
                      }}
                    > Choose items and adjust quantities to restock and refund, or proceed with only a refund by not selecting any items.
                    </div>
                    {itemStock.map((item, index) => (
                      <div
                        key={index}
                        className="w100"
                        style={{
                          display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '5px',
                        }}
                      >
                        <div style={{
                          display: 'flex', alignItems: 'center', fontWeight: '500', fontSize: '14px',
                        }}
                        >
                          <input
                            type="checkbox"
                            checked={selectedItems.includes(item.itemGid)}
                            onChange={() => handleCheckboxChange(item)}
                          />
                          <div style={{ marginLeft: '10px' }}>{item.itemName}</div>
                        </div>
                        <div
                          className="mt-3 mb-3"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid #1A5244',
                            padding: '10px',
                            background: '#FFFFFF',
                            borderRadius: '8px',
                            fontSize: '14px',
                            userSelect: 'none',
                            width: '130px',
                            height: '34px',
                            gap: '16px',
                          }}
                        >
                          <div style={{ cursor: 'pointer' }} onClick={() => decreaseQuantity(item)}>
                            <img src={Minus} alt="cancel" />
                          </div>
                          <div><img src={SeperateLine} alt="cancel" /></div>
                          <div><SpH5>{item.quantity}</SpH5></div>
                          <div><img src={SeperateLine} alt="cancel" /></div>
                          <div style={{ cursor: 'pointer', fontSize: '2em' }} onClick={() => increaseQuantity(item)}>+</div>

                        </div>
                      </div>
                    ))}
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    padding: '18px 28px',
                    gap: '13px',
                    background: '#FFFFFF',
                    borderRadius: '0px 0px 8px 8px',
                    position: 'relative',
                  }}
                  >
                    <SpButton onClick={onRefundClose} disabled={disableButton} type="secondary" style={{ marginRight: '10px' }}>Cancel</SpButton>
                    <SpButton onClick={handleRefundProcess} disabled={disableButton}>Confirm</SpButton>
                  </div>
                </Modal>

              )}
              {refundModal
                && (
                  <Modal
                    centered
                    closable={false}
                    visible
                    footer={[
                      <SpButton onClick={() => setRefundModal(false)} disabled={disableButton} type="secondary">Cancel</SpButton>,
                      <SpButton onClick={handleRefundOrder} disabled={disableButton} type="primary">Confirm</SpButton>,
                    ]}
                    title="Confirm Refund"
                  >
                    <div className="px-4">
                      <div className="mt-1">Are you sure you want to refund this amount?</div>
                      <SpH5 className="pb-3">
                        Refund Amount:
                        {inventoryOrderDetails.total < 0
                          ? `( ${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber(
                            (inventoryOrderDetails.total / 100).toFixed(2), currencyType,
                          )})`
                          : inventoryOrderDetails.total
                            ? ` ${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber((inventoryOrderDetails.total / 100).toFixed(2), currencyType)}`
                            : <>&#8211;</>}
                      </SpH5>
                    </div>
                  </Modal>
                )}
              <SpForm>
                {/* DELETE ORDER MODAL */}
                {deleteOrderModal && (
                  <DeleteOrder
                    visible={deleteOrderModal}
                    submitting={submitting}
                    inventoryOrderDetails={inventoryOrderDetails}
                    selectedAccount={selectedAccount}
                    close={() => setDeleteOrderModal(false)}
                    submit={deleteSelectedOrder}
                  />
                )}
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Order Number</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {inventoryOrderDetails.orderNumber != null ? inventoryOrderDetails.orderNumber : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Amount</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {inventoryOrderDetails.total < 0
                        ? `(${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber(
                          (inventoryOrderDetails.total / 100).toFixed(2), currencyType,
                        )})`
                        : inventoryOrderDetails.total
                          ? `${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber((inventoryOrderDetails.total / 100).toFixed(2), currencyType)}`
                          : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Tip</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {inventoryOrderDetails.totalTip < 0
                        ? `(${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber(
                          (inventoryOrderDetails.totalTip / 100).toFixed(2), currencyType,
                        )})`
                        : inventoryOrderDetails.totalTip
                          ? `${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber((inventoryOrderDetails.totalTip / 100).toFixed(2), currencyType)}`
                          : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Tax</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {inventoryOrderDetails.totalTax < 0
                        ? `(${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber(
                          (inventoryOrderDetails.totalTax / 100).toFixed(2), currencyType,
                        )})`
                        : inventoryOrderDetails.totalTax
                          ? `${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber((inventoryOrderDetails.totalTax / 100).toFixed(2), currencyType)}`
                          : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Service Fee</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {inventoryOrderDetails.totalServiceFee < 0
                        ? `(${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber(
                          (inventoryOrderDetails.totalServiceFee / 100).toFixed(2), currencyType,
                        )})`
                        : inventoryOrderDetails.totalServiceFee
                          ? `${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber((inventoryOrderDetails.totalServiceFee / 100).toFixed(2), currencyType)}`
                          : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Delivery Fee</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {inventoryOrderDetails.deliveryFee < 0
                        ? `(${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber(
                          (inventoryOrderDetails.deliveryFee / 100).toFixed(2), currencyType,
                        )})`
                        : inventoryOrderDetails.deliveryFee
                          ? `${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber((inventoryOrderDetails.deliveryFee / 100).toFixed(2), currencyType)}`
                          : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Refunded Amount</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {inventoryOrderDetails.refundedAmount < 0
                        ? `(${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber(
                          (inventoryOrderDetails.refundedAmount / 100).toFixed(2), currencyType,
                        )})`
                        : inventoryOrderDetails.refundedAmount
                          ? `${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber((inventoryOrderDetails.refundedAmount / 100).toFixed(2), currencyType)}`
                          : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Discount</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {inventoryOrderDetails.orderDiscountAmount < 0
                        ? `(${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber(
                          (inventoryOrderDetails.orderDiscountAmount / 100).toFixed(2), currencyType,
                        )})`
                        : inventoryOrderDetails.orderDiscountAmount
                          ? `${selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix} 
                      ${formatNumber((inventoryOrderDetails.orderDiscountAmount / 100).toFixed(2), currencyType)}`
                          : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              {
                inventoryOrderDetails && inventoryOrderDetails.customer && (
                  <>
                    <SpForm>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={3}>
                          <span>Customer Name</span>
                        </Col>
                        <Col span={6}>
                          <SpH5>
                            {inventoryOrderDetails.customer && inventoryOrderDetails.customer.name}
                          </SpH5>
                        </Col>
                      </Row>
                    </SpForm>
                    <SpForm>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={3}>
                          <span>Customer Email</span>
                        </Col>
                        <Col span={6}>
                          <SpH5>
                            {inventoryOrderDetails.customer && inventoryOrderDetails.customer.email}
                          </SpH5>
                        </Col>
                      </Row>
                    </SpForm>
                    <SpForm>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={3}>
                          <span>Customer Phone</span>
                        </Col>
                        <Col span={6}>
                          <SpH5>
                            {inventoryOrderDetails.customer && inventoryOrderDetails.customer.phoneNumber}
                          </SpH5>
                        </Col>
                      </Row>
                    </SpForm>
                  </>
                )
              }
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={24}>
                    <Col span={3}>
                      <span>Date</span>
                    </Col>
                    <Col span={6}>
                      <SpH5>
                        {
                          inventoryOrderDetails.orderedTime && selectedAccount && selectedAccount.timezone
                            ? (
                              moment
                                .utc(inventoryOrderDetails.orderedTime)
                                .tz(selectedAccount.timezone)
                                .format('MMM DD, YYYY, hh:mm a')
                            )
                            : inventoryOrderDetails.createdAt && selectedAccount && selectedAccount.timezone
                              ? (
                                moment
                                  .utc(inventoryOrderDetails.createdAt)
                                  .tz(selectedAccount.timezone)
                                  .format('MMM DD, YYYY, hh:mm a')
                              ) : <>&#8211;</>
                        }
                      </SpH5>
                    </Col>
                  </Col>

                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={3}>
                    <span>Shop</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {inventoryOrderDetails.shop && inventoryOrderDetails.shop != null ? inventoryOrderDetails.shop.name : <>&#8211;</>}
                    </SpH5>
                  </Col>
                  <Col span={24} className="d-flex justify-content-end">
                    <SpH5 color="#7f7f7f">{inventoryOrderDetails.gid}</SpH5>
                  </Col>
                </Row>
              </SpForm>
            </Card>
            <Card className="pl-2">
              <Menu
                onClick={e => { setCurrent(e.key); }}
                selectedKeys={[current]}
                mode="horizontal"
                style={{ fontSize: '20px' }}
              >
                <Menu.Item key="lineItems">
                  <SpTab
                    to={`${path}`}
                    selected={current === 'lineItems'}
                  >
                    Order Line Items
                  </SpTab>
                </Menu.Item>
                <Menu.Item key="payments">
                  <SpTab
                    to={`${path}`}
                    selected={current === 'payments'}
                  >
                    Payments
                  </SpTab>
                </Menu.Item>
                <Menu.Item key="Refund">
                  <SpTab
                    to={`${path}`}
                    selected={current === 'Refund'}
                  >
                    Refund Line Items
                  </SpTab>
                </Menu.Item>

                {
                  inventoryOrderDetails && inventoryOrderDetails.paymentStatus === 'PAID' ? (
                    <RBAC
                      permit={
                        [ROLES.SUPERUSER, ROLES.KDSADMIN1, ROLES.OWNER]
                      }
                    >
                      <SpButton
                        style={{ float: 'right' }}
                        type="primary"
                        shape="round"
                        onClick={refundPaymentsItem}
                        disabled={disableButton}
                      >
                        Restock & Refund Item Level
                      </SpButton>
                    </RBAC>
                  ) : null
                }
                {(inventoryOrderDetails && inventoryOrderDetails.paymentStatus === 'PARTIALLY_REFUNDED')
                  ? (
                    <RBAC
                      permit={
                        [ROLES.SUPERUSER, ROLES.KDSADMIN1, ROLES.OWNER]
                      }
                    >
                      <SpButton
                        style={{ float: 'right' }}
                        type="primary"
                        shape="round"
                        onClick={refundPaymentsItem}
                        disabled={disableButton}
                      >
                        Restock & Refund Item Level
                      </SpButton>
                    </RBAC>
                  )
                  : null}
              </Menu>

              {/* DELETE LINE ITEM MODAL */}
              {deleteItemModal && (
                <DeleteLineItem
                  visible={deleteItemModal}
                  submitting={submitting}
                  inventoryItemDetails={selectedLineItem}
                  selectedAccount={selectedAccount}
                  close={() => setDeleteItemModal(false)}
                  submit={deleteSelectedItem}
                />
              )}
              {
                current === 'lineItems' ? (
                  <Table
                    rowClassName={() => 'ant-table-clickable-row'}
                    columns={inventoryOrderDetails.paymentStatus === 'UN_PAID' ? [...columns, actionColumn] : columns}
                    rowKey={(record) => record.gid}
                    className="custom-table"
                    dataSource={lineItems}
                    pagination={pagination}
                    onChange={handleTableChange}
                  />
                ) : current === 'Refund' ? (
                  <Table
                    rowClassName={() => 'ant-table-clickable-row'}
                    columns={inventoryOrderDetails.refundedInventoryOrderLineItems === 'UN_PAID' ? [...columns, actionColumn] : columns}
                    rowKey={(record) => record.gid}
                    className="custom-table"
                    dataSource={inventoryOrderDetails.refundedInventoryOrderLineItems}
                    pagination={pagination}
                    onChange={handleTableChange}
                  />
                ) : (
                  <Table
                    rowClassName={() => 'ant-table-clickable-row'}
                    columns={cloverColumns}
                    onRow={(record) => ({
                      onClick: () => {
                        selectedRecord(record);
                      },
                    })}
                    rowKey={(record) => record.gid}
                    className="custom-table"
                    dataSource={payments}
                    pagination={pagination}
                    onChange={handleTableChange}
                  />
                )
              }
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  inventoryOrderDetails: state.inventoryOrderDetails,
  lineItems: state.inventoryOrderDetails.inventoryOrderLineItems,
  cloverPayments: state.inventoryOrderDetails.cloverPayments,
  totalElements: state.inventoryOrderDetails.inventoryOrderLineItems ? state.inventoryOrderDetails.inventoryOrderLineItems.length : '',
  // totalPromoElements: state.inventoryOrderDetails.cloverPayments ? state.inventoryOrderDetails.cloverPayments.length : '',
  payments: state.inventoryOrderDetails.payments.content,
  totalPromoElements: state.inventoryOrderDetails.payments ? state.inventoryOrderDetails.payments.totalElements : '',
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  profile: state.subscription.profile,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInventoryOrderDetails: gid => dispatch(inventoryOrderDetailsActions.fetchInventoryOrderDetails({
    payload: gid,
  })),
  fetchPayments: gid => dispatch(inventoryOrderDetailsActions.fetchPayments({
    payload: gid,
  })),
  refundOrderDetails: gid => dispatch(inventoryOrderDetailsActions.refundOrderDetails({
    payload: gid,
  })),
  deleteOrderLineItem: param => dispatch(inventoryOrderDetailsActions.deleteOrderLineItem({
    payload: param,
  })),
  refundOrderLineItem: param => dispatch(inventoryOrderDetailsActions.refundOrderLineItem({
    payload: param,
  })),
  deleteOrderDetails: param => dispatch(inventoryOrderDetailsActions.deleteOrderDetails({
    payload: param,
  })),
  addItemOrder: param => dispatch(inventoryOrderDetailsActions.addItemOrder({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
