import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  EXCHANGE_RATES,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchExchangeRates = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.exchangeRateParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.EXCHANGE_RATE_LIST}?${payload.exchangeRateParams}`);
  }
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.EXCHANGE_RATE_LIST}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.EXCHANGE_RATE_LIST}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.EXCHANGE_RATE_LIST}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.EXCHANGE_RATE_LIST}${query}${sorting}`);
};

const postExchangeRate = async payload => axiosAuthInstance.post(`${API_END_POINTS.EXCHANGE_RATE}`, payload);

const fetchExchangeRateForAmount = async ({ gid, params }) => axiosAuthInstance.get(`${API_END_POINTS.EXCHANGE_RATE_SPLITUP}/${gid}?${params}`);

const DeleteExchangeRate = async gid => axiosAuthInstance.delete(`${API_END_POINTS.EXCHANGE_RATE}/${gid}`);

const patchExchangeRate = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.EXCHANGE_RATE}/${gid}`, params);

export function* getExchangeRatesList(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchExchangeRates, action.payload);
    yield put({ type: EXCHANGE_RATES.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: EXCHANGE_RATES.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}


export function* getExchangeRateForAmount(action) {
  try {
    const { data: { entity } } = yield call(fetchExchangeRateForAmount, action.payload);
    yield put({ type: EXCHANGE_RATES.FETCH_AMOUNT_SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: EXCHANGE_RATES.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}
export function* addExchangeRate(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postExchangeRate, action.payload);
    const { data: { entity } } = yield call(fetchExchangeRates, action.payload);
    yield put({ type: EXCHANGE_RATES.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.EXCHANGE_RATE.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteExchangeRate(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(DeleteExchangeRate, action.payload);
    const { data: { entity } } = yield call(fetchExchangeRates, action.payload);
    yield put({ type: EXCHANGE_RATES.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.EXCHANGE_RATE.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
export function* updateExchangeRate(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchExchangeRate, action.payload);
    const { data: { entity } } = yield call(fetchExchangeRates, action.payload);
    yield put({ type: EXCHANGE_RATES.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.EXCHANGE_RATE.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}