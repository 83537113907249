import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
  axiosNoAuthInstance,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  NONEVENTS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchNonEvents = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_EVENT}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_EVENT}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_EVENT}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_EVENT}${query}${sorting}`);
};

const postEvent = async params => axiosAuthInstance.post(API_END_POINTS.GET_EVENT, params);

const patchEvent = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.UPDATE_CUSTOMERS}/${gid}`, params);

const deleteEvent = async ({ gid }) => axiosAuthInstance.delete(`${API_END_POINTS.GET_EVENTS}/${gid}`);

const fetchNonEventReceipts = async gid => axiosNoAuthInstance.get(`${API_END_POINTS.EVENT_RECEIPTS}/${gid}`);

export function* getNonEvents(action) {
  try {
    const { data: { entity } } = yield call(fetchNonEvents, action.payload);
    yield put({ type: NONEVENTS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: NONEVENTS.FAILED, payload: e.response.data });
  }
}

export function* addNewNonEvents(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postEvent, action.payload);
    // yield put({
    //   type: NOTIFICATION.SUCCESS,
    //   payload: MESSAGES.EVENTS.ADD_SUCCESS,
    // });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateNonEvents(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchEvent, action.payload);
    const { data: { entity } } = yield call(fetchNonEvents, action.payload);
    yield put({ type: NONEVENTS.SUCCESS, payload: entity });
    // yield put({
    //   type: NOTIFICATION.SUCCESS,
    //   payload: MESSAGES.EVENTS.UPDATE_SUCCESS,
    // });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteNonEvents(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(deleteEvent, action.payload);
    const { data: { entity } } = yield call(fetchNonEvents, action.payload);
    yield put({ type: NONEVENTS.SUCCESS, payload: entity });
    // yield put({
    //   type: NOTIFICATION.SUCCESS,
    //   payload: MESSAGES.EVENTS.DELETE_SUCCESS,
    // });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getNonEventReceipts(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchNonEventReceipts, action.payload);
    yield put({ type: NONEVENTS.RECEIPT_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}
