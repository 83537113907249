import { NONEVENTS } from '../actionTypes';

export const fetchNonEvents = (action) => ({
  type: NONEVENTS.FETCH,
  payload: action.payload,
});

export const addNewNonEvents = (action) => ({
  type: NONEVENTS.ADD_EVENT,
  payload: action.payload,
});

export const updateNonEvents = (action) => ({
  type: NONEVENTS.UPDATE,
  payload: action.payload,
});

export const deleteNonEvents = (action) => ({
  type: NONEVENTS.DELETE,
  payload: action.payload,
});

export const fetchNonEventReceipts = (action) => ({
  type: NONEVENTS.FETCH_RECEIPT,
  payload: action.payload,
});
