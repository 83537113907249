// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpError, SpButton } from 'components/DesignKit';

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  selectedTransfer: Object,
  submitting: boolean,
};

const UpdateCrossBorder = (props: Props) => {
  const {
    visible,
    close,
    submit,
    selectedTransfer,
    submitting,
  } = props;

  const [, forceUpdate] = useState();
  const gid = selectedTransfer && selectedTransfer.gid;
  const [convertedAmount, setConvertedAmount] = useState(selectedTransfer?.convertedAmount ? (selectedTransfer?.convertedAmount / 100).toFixed(2) : '');
  const [convertedCurrencyCode, setConvertedCurrencyCode] = useState(selectedTransfer?.convertedCurrency?.name ? selectedTransfer?.convertedCurrency?.name : '');
  const [exchangeRate, setExchangeRate] = useState(selectedTransfer?.exchangeRate ? selectedTransfer?.exchangeRate : '');
  const [disableButton, setDisableButton] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      gid,
      convertedAmount: Number(convertedAmount * 100),
      convertedCurrencyCode,
      exchangeRate,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Update</SpButton>,
      ]}
      title="Update Cross Border Details"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Converted Amount</SpH5></label>
          <Input
            placeholder="Enter Converted Amount"
            value={convertedAmount}
            onChange={(e) => setConvertedAmount(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('converted amount', convertedAmount, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Converted Currency</SpH5></label>
          <Input
            placeholder="Enter Converted Currency"
            value={convertedCurrencyCode}
            onChange={(e) => setConvertedCurrencyCode(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('converted currency', convertedCurrencyCode, 'required')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Exchange Rate</SpH5></label>
          <Input
            placeholder="Enter Exchange Rate"
            value={exchangeRate}
            onChange={(e) => setExchangeRate(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('exchange rate', exchangeRate, 'required')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(UpdateCrossBorder);
