import React from 'react';
import {
  SpText, SpCaption, AuthWrapper, AuthPanel,
} from 'components/DesignKit';

import swireLogo from 'imgs/swirepay.png';
import qpayments from 'imgs/Qpayments.jpeg';


const Docusign = () => (
  <AuthWrapper>
    <AuthPanel>
      <img src={window.location.host.includes('qpaymentz') ? qpayments : swireLogo} alt="" style={{ width: '270px', margin: '32px 0px' }} />
      <div
        className="mb-4"
      >
        <SpText
          fontSize="18px"
          color="#000"
        >
          Thank You for Completing DocuSign!
        </SpText>
      </div>
      <div className="mb-5 text-center">
        <SpCaption>Once activated you will automatically receive an email confirming the account is active to the primary contact email address you provided. Please click on either OK or Cancel to return to your Swirepay dashboard
        </SpCaption>
      </div>
    </AuthPanel>
  </AuthWrapper>
);

export default Docusign;

