/* eslint-disable */
import React from 'react';

const exchangeIcon = () => (
<svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_3546_127374)">
<circle cx="30" cy="30.5" r="20" fill="white"/>
</g>
<path d="M22.7998 28.1H37.1998L32.3998 23.3M37.5194 32.1H23.1194L27.9194 36.9" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<filter id="filter0_d_3546_127374" x="0" y="0.5" width="60" height="60" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3546_127374"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3546_127374" result="shape"/>
</filter>
</defs>
</svg>

    
);

export default exchangeIcon;