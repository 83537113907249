// @flow
import React from 'react';
import { Modal } from 'antd';
import { SpH5, SpButton } from 'components/DesignKit';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
};

const DeleteBankTransfer = (props: Props) => {
  const {
    visible,
    close,
    submit,
  } = props;

  const deleteContact = () => {
    submit();
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={deleteContact} type="danger">Delete</SpButton>,
      ]}
      title="Delete Bank Transfer"
    >
      <div className="px-4">
        <div className="mt-1">Are you sure you want to delete this Bank Transfer?</div>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default DeleteBankTransfer;
