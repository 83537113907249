import { BANK_TRANSFERS } from '../actionTypes';

const initialState = {
  benficiers: [],
  account: {},
  balance: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BANK_TRANSFERS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case BANK_TRANSFERS.ACCOUNT_BY_ID_SUCCESS:
      return {
        ...state,
        account: action.payload,
      };
    case BANK_TRANSFERS.BALANCE_BY_ID_SUCCESS:
      return {
        ...state,
        balance: action.payload,
      };
    default:
      return state;
  }
};
