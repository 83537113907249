// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
  Icon,
  Upload,
} from 'antd';
import moment from 'moment-timezone';
import {
  accountSessionActions,
  notificationActions,
} from 'store/actions';
import {
  Line,
  SpFormWrapper, SpText, SpWrapper,
} from 'components/DesignKit';
import formatNumber from 'utils/formatNumber';
import getDiscountOff from 'utils/getDiscountOff';
import { API_STATUS, MESSAGES, RATE_TYPES } from 'appconstants';
import getTaxAmount from 'utils/getTaxAmount';
import ViewPdfIcon from 'components/NavIcons/viewPdfIcon';
import swirepaylogo from 'components/NavIcons/swirepay';
import swirePayIcon from 'imgs/swirePayIcon.svg';
import Loading from 'components/Loading';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Download from 'components/NavIcons/downloadIcon';
import { API_END_POINTS, axiosNoAuthInstance } from 'api';
import { NOTIFICATION } from 'store/actionTypes';

type Props = {
  invoiceDetails: Object,
  history: {
    push: Function,
  },
  fetchAccountSessionInvoiceDetails: Function,
  invoiceDetailsStatus: String,
  loading: boolean,
  setNotification: Function,
  isMobileView: Boolean,


};

const ViewVendorInvoice = (props: Props) => {
  const {
    invoiceDetails,
    fetchAccountSessionInvoiceDetails,
    invoiceDetailsStatus,
    loading,
    setNotification,
    isMobileView,
  } = props;

  const { id } = props.match.params;

  const invoiceLineItems = invoiceDetails.invoiceLineItems ? invoiceDetails.invoiceLineItems : [];
  const totalAmount = invoiceDetails && (invoiceDetails.amount / 100);
  const currencyType = invoiceDetails && invoiceDetails?.currency;
  const allSelectedTaxrates = invoiceDetails.taxAmount ? invoiceDetails.taxAmount : [];
  const currencyPrefix = invoiceDetails && invoiceDetails?.currency && invoiceDetails?.currency?.prefix;
  const currencyObj = invoiceDetails && invoiceDetails?.currency;
  const updatedCoupon = invoiceDetails.coupon ? invoiceDetails.coupon : {};
  const [loader, setLoader] = useState(false);

  //   const [addFundingSourceModal, setAddFundingSourceModal] = useState(false);
  //   const issuerName = (selectedAccount.issuer && selectedAccount.issuer.name);
  //   const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;

  const getData = () => {
    fetchAccountSessionInvoiceDetails(id);
  };

  useEffect(() => {
    getData();
  }, []);


  const getAmountOff = (value) => {
    let amountOff;
    if (value.percentageOff) {
      const amount = (totalAmount * value.percentageOff) / 100;
      amountOff = formatNumber((amount).toFixed(2), currencyObj);
    } else if (value.amountOff) {
      amountOff = formatNumber((value.amountOff / 100).toFixed(2), currencyObj);
    }
    return amountOff;
  };

  const getTotalAmount = (value) => {
    let finalAmount;
    if (value) {
      const finalValue = parseFloat(value.replace(/,/g, ''));
      finalAmount = formatNumber((totalAmount - finalValue).toFixed(2), currencyObj);
    } else {
      finalAmount = formatNumber((totalAmount).toFixed(2), currencyObj);
    }
    return finalAmount;
  };

  const getFinalAmount = (amount) => {
    let finalAmount;
    const listOfTaxes = [...allSelectedTaxrates];
    const taxLists = listOfTaxes.filter((item) => item.taxRates.inclusive === false);
    if (taxLists.length > 0) {
      const percentageAmount = taxLists.reduce(
        (total, item) => total + ((parseFloat(amount.replace(/,/g, '')) * item.taxRates.percentage) / 100), 0,
      );
      finalAmount = formatNumber((parseFloat(amount.replace(/,/g, '')) + percentageAmount).toFixed(2), currencyObj);
    } else {
      finalAmount = amount;
    }
    return finalAmount;
  };

  const handleViewPdf = () => {
    window.open(invoiceDetails.invoiceDocument, '_blank');
  };

  const downloadPdf = async (pdfKey) => {
    // /const pdfKey = invoiceDetails.invoicePdfKey;
    setLoader(true);
    try {
      const { data: { entity: { fileUrl } } } = await axiosNoAuthInstance
        .get(`${API_END_POINTS.VENDORINVOICE_API}/${pdfKey}/download`);
      window.open(fileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.INVOICE.DOWNLOAD_ERROR,
      });
    } finally {
      setLoader(false);
    }
  };

  if (loading || loader) {
    return <Loading />;
  }
  return (
    <Card>
      <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
        <Col className="d-flex align-items-center">
          <ArrowLeftOutlined
            onClick={() => window.history.back()}
            style={{ fontSize: '24px', cursor: 'pointer' }}
          />
        </Col>
        { invoiceDetails.gid && invoiceDetailsStatus === API_STATUS.SUCCESS && invoiceDetails?.invoicePdfKey
       && (
       <div
         style={{
           display: 'inline-flex',
           justifyContent: 'center',
           alignItems: 'center',
           backgroundColor: '#3E8BFF',
           borderRadius: '50%',
           width: '60px',
           height: '60px',
           overflow: 'hidden',
           cursor: 'pointer',
           padding: '5px',
           position: 'fixed',
           bottom: isMobileView ? '39%' : '20%',
           left: '69%',
           zIndex: 1,
         }}
         onClick={() => downloadPdf(invoiceDetails?.invoicePdfKey)}
       >
         <Download fillColor="white" />
       </div>
       )}
      </Row>
      {invoiceDetails.gid && invoiceDetailsStatus === API_STATUS.SUCCESS && (
      <SpWrapper>
        <SpFormWrapper>
          <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
            <Col className="d-flex align-items-center">
              {
                invoiceDetails && invoiceDetails.accountLogo ? (
                  <img src={invoiceDetails.accountLogo} alt={invoiceDetails.accountName} style={{ width: '108px', height: '74px' }} />
                ) : (
                  <SpText className="mr-5" fontSize="20px" fontWeight="600">{invoiceDetails.accountName}</SpText>
                )
              }
            </Col>
            <Col className="d-flex">
              <div className="mr-2 mt-3">
                <SpText color="rgba(0, 0, 0, 0.7)">Powered by</SpText>
              </div>
              <Icon
                style={{
                  fontSize: '24px',
                }}
                component={swirepaylogo}
              />
            </Col>
          </Row>
          <Row type="flex" className="my-2 mb-3 mt-2">
            <Col className="d-flex align-items-center" span={12}>
              <SpText className="mr-2" fontSize="18px">INVOICE No.</SpText>
              <SpText fontSize="18px" fontWeight="600">{invoiceDetails.invoiceNumber}</SpText>
            </Col>
          </Row>
          <Row type="flex" className="my-2 mb-3 mt-2">
            <Col span={12}>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Service Date</SpText></div>
              <div className="pt-1 pb-1">
                <SpText color="#434343">
                  {
                    invoiceDetails.issueDate
                      ? (
                        moment
                          .utc(invoiceDetails.issueDate)
                          .format('MMM DD, YYYY, hh:mm a')
                      )
                      : <>&#8211;</>
                  }
                </SpText>
              </div>
            </Col>
            <Col span={12}>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Contact</SpText></div>
              <div className="pt-1 pb-1">
                <SpText color="#434343">
                  {invoiceDetails?.contact?.name}{' '}
                  {invoiceDetails?.fundingSource?.issuerBank?.bankName && invoiceDetails?.fundingSource?.issuerBank?.lastFour
                    ? `| ${invoiceDetails?.fundingSource?.issuerBank?.bankName} **** ${invoiceDetails?.fundingSource?.issuerBank?.lastFour}`
                    : null}
                </SpText>
              </div>
            </Col>
          </Row>
          <Row type="flex" className="my-2 mb-3 mt-2">
            <Col span={12}>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Transfer Date</SpText></div>
              <div className="pt-1 pb-1">
                <SpText color="#434343">
                  {
                    invoiceDetails.transferDueDate
                      ? (
                        moment(invoiceDetails.transferDueDate).format('MMM DD, YYYY')
                      )
                      : <>&#8211;</>
                  }
                </SpText>
              </div>
            </Col>
            <Col span={12}>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Transfer Type</SpText></div>
              <div className="pt-1 pb-1"><SpText color="#434343">{invoiceDetails?.transferType?.value ?? '-'}</SpText></div>
            </Col>
          </Row>
          <Row type="flex" className="my-2 mb-3 mt-2">
            <Col span={24}>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Description</SpText></div>
              <div className="pt-1 pb-1 pr-5"><SpText color="#434343">{invoiceDetails.description}</SpText></div>
            </Col>
          </Row>
          <Row type="flex" className="my-2 mb-3 mt-2">
            <Col span={12}>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Billing Address</SpText></div>
              <div className="pt-1 pb-1 pr-5">
                {
                  invoiceDetails.billingAddress && invoiceDetails.billingAddress.street ? (
                    <SpText color="#434343">
                      {`${invoiceDetails.billingAddress.street}, ${invoiceDetails.billingAddress.city},
                      ${invoiceDetails.billingAddress.state} ${invoiceDetails.billingAddress.postalCode}`}
                    </SpText>
                  ) : (
                    <SpText color="#434343">
                      N/A
                    </SpText>
                  )
                }
              </div>
            </Col>
            <Col span={12}>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Shipping Address</SpText></div>
              <div className="pt-1 pb-1 pr-5">
                {
                  invoiceDetails.shippingAddress && invoiceDetails.shippingAddress.street ? (
                    <SpText color="#434343">
                      {`${invoiceDetails.shippingAddress.street}, ${invoiceDetails.shippingAddress.city},
                      ${invoiceDetails.shippingAddress.state} ${invoiceDetails.shippingAddress.postalCode}`}
                    </SpText>
                  ) : (
                    <SpText color="#434343">
                      N/A
                    </SpText>
                  )
                }
              </div>
            </Col>
          </Row>
          <Row type="flex" className="my-2 mb-3 mt-2" style={{ background: '#eeeeff', padding: '5px' }}>
            <Col span={12}>
              <div className="pt-1 pb-1 pl-2"><SpText fontWeight="600">Description</SpText></div>
            </Col>
            <Col span={12}>
              <Row type="flex" justify="end">
                <Col span={6} className="pt-1 mr-3" align="center">
                  <SpText fontWeight="600" fontSize="14px">Rate</SpText>
                </Col>
                <Col span={6} className="pt-1 mr-3" align="center">
                  <SpText fontWeight="600" fontSize="14px">Quantity</SpText>
                </Col>
                <Col span={6} className="pt-1 mr-3" align="right">
                  <SpText fontWeight="600" fontSize="14px">Total</SpText>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row type="flex" className="my-2 mb-3 mt-2" style={{ padding: '0 5px' }}>
            {invoiceLineItems.map((item) => (
              <>
                <Col span={12}>
                  <div className="pt-1 pb-1 pl-2"><SpText>{item.name}</SpText></div>
                </Col>
                <Col span={12}>
                  <Row type="flex" justify="end">
                    <Col span={6} className="mr-3" align="center">
                      <SpText fontWeight="600">{invoiceDetails.currency.prefix} {(item.amount / 100).toFixed(2)}</SpText>
                    </Col>
                    <Col span={6} className="mr-3" align="center">
                      <SpText fontWeight="600">{item.quantity}</SpText>
                    </Col>
                    <Col span={6} className="mr-3" align="right">
                      <SpText fontWeight="600">{invoiceDetails.currency.prefix}
                        {formatNumber(((item.amount / 100) * (item.quantity)).toFixed(2), currencyType)}
                      </SpText>
                    </Col>
                  </Row>
                </Col>
              </>
            ))}
          </Row>
          <Row type="flex" justify="end" className="ml-5 mb-2">
            {updatedCoupon.gid && (
            <Col span={14}>
              <Row type="flex" justify="end">
                <Col span={12} className="pt-1 ml-3" align="left">
                  <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                    <SpText>
                      {updatedCoupon.name}
                    </SpText><br />
                    <SpText fontSize="12px">
                      {getDiscountOff(updatedCoupon)}
                    </SpText>
                  </div>
                </Col>
                <Col span={6} className="pt-2 ml-5 mr-3" align="right">
                  <SpText fontWeight="600">({currencyPrefix} {getAmountOff(updatedCoupon)})</SpText>
                </Col>
                <Col span={2} className="pt-1" align="right" />
              </Row>
            </Col>
            )}
          </Row>
          <Row type="flex" justify="end" className="ml-5">
            {
              allSelectedTaxrates.map((item) => (
                <Col span={14}>
                  <Row type="flex" justify="end">
                    <Col span={9} className="pt-1 ml-3" align="left">
                      <div className="pt-1 pb-1" style={{ cursor: 'pointer' }}>
                        <SpText>
                          {item.taxRates.displayName}
                        </SpText><br />
                        <SpText fontSize="12px">
                          {item.taxRates.inclusive ? RATE_TYPES.INCLUSIVE : RATE_TYPES.EXCLUSIVE}
                        </SpText>
                      </div>
                    </Col>
                    <Col span={3} className="pt-1" align="right">{item.taxRates.percentage} %</Col>
                    <Col span={6} className="pt-2 ml-5 mr-3" align="right">
                      <SpText fontWeight="600">
                        {currencyPrefix} {getTaxAmount(item.taxRates, totalAmount, getAmountOff(updatedCoupon), invoiceDetails)}
                      </SpText>
                    </Col>
                    <Col span={2} className="pt-1" align="right" />
                  </Row>
                </Col>
              ))
            }
          </Row>
          <Line opacity="0.3" className="mt-1 mb-1" />
          <Row type="flex" justify="end" className="mr-3">
            <Col className="mt-1 pb-1">
              <SpText className="mr-5" fontWeight="500" fontSize="20px">
                Total Amount
              </SpText>
              <SpText fontWeight="600" fontSize="20px">
                {
                  (getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon)))) < 0 ? (
                    `${currencyPrefix} 0.00`
                  ) : (
                    `${currencyPrefix} ${getFinalAmount(getTotalAmount(getAmountOff(updatedCoupon)))}`
                  )
                }
              </SpText>
            </Col>
          </Row>
          <Line />
          {
            invoiceDetails?.invoiceDocument ? (
              <div style={{ border: '2px solid #EBEBEB' }} className="mt-2">

                <Row className="my-2 mb-3 mt-4">
                  <Col>
                    <Upload
                      name="avatar"
                      className="avatar-uploader ml-3"
                      showUploadList={false}
                      disabled
                    >
                      <ViewPdfIcon />
                    </Upload>
                    <span
                      className="ml-4 cursor-pointer"
                      onClick={handleViewPdf}
                    >
                      <SpText color="#279dfe" fontWeight="600">View</SpText>
                    </span>
                  </Col>
                </Row>
              </div>
          ) : (
            null
          )
        }

          <Row className="my-2 mb-3 mt-2">
            <Col>
              <div className="pt-1 pb-1"><SpText fontWeight="600">Notes</SpText></div>
              <div className="pt-1 pb-1">
                <SpText color="#434343">
                  {invoiceDetails.vendorNote}
                </SpText>
              </div>
            </Col>
          </Row>
          <Row className="my-2 mb-3 mt-2" style={{ background: '#eeeeff', padding: '5px' }}>
            <Col>
              <SpText fontWeight="600">{invoiceDetails?.accountName}</SpText>
            </Col>
          </Row>
          <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">

            <Col span={6}>
              <img src={swirePayIcon} alt="" style={{ width: '70px', height: '4rem' }} />
            </Col>
            <Col span={18} style={{ textAlign: 'end' }}>
              <SpText color="#434343">
                1900 Lafayette Street,
                Suite 245, Santa Clara, CA 95050
              </SpText>
              <br />
              <SpText color="#434343">
                7 Kattabomman Street, Muthamizh Nagar, Pammal, Chennai - 600075
              </SpText>
              <br />
              <SpText fontWeight="600">
                To transform the way your business transacts,
                <SpText color="#434343">
                  click here:
                </SpText>
                <a href="https://www.swirepay.com" target="_blank" rel="noopener noreferrer">
                  www.swirepay.com
                </a>
              </SpText>
            </Col>
          </Row>
        </SpFormWrapper>
      </SpWrapper>
      )}
    </Card>
  );
};

const mapStateToProps = (state) => ({
  invoiceDetails: state.accountSession.invoice,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  loading: state.loading.loading,
  invoiceDetailsStatus: state.accountSession.invoiceDetailsStatus,
  isMobileView: state.mobileView.isMobileView,
});
const mapDispatchToProps = (dispatch) => ({
  fetchAccountSessionInvoiceDetails: gid => dispatch(accountSessionActions.fetchAccountSessionInvoiceDetails({
    payload: gid,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(ViewVendorInvoice);
