import { FUNDINGSOURCE } from '../actionTypes';

export const fetchFundingSourceList = (payload) => ({
  type: FUNDINGSOURCE.FETCH,
  payload,
});
export const fetchFundingSourceListByContactGid = (payload) => ({
  type: FUNDINGSOURCE.FETCH_BY_CONTACTGID,
  payload,
});

export const fetchFundingSourceDetails = (action) => ({
  type: FUNDINGSOURCE.FETCH_FUNDINGSOURCEDETAILS,
  payload: action.payload,
});

export const createFundingSource = (action) => ({
  type: FUNDINGSOURCE.CREATE,
  payload: action.payload,
});
export const DeleteFundingSource = (action) => ({
  type: FUNDINGSOURCE.DELETE,
  payload: action.payload,
});
