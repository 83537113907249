// @flow
import React, {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import {
  Input,
  Row,
  Col,
  Icon,
  Upload,
  message,
  Select,
  DatePicker,
  Switch,
} from 'antd';

// import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import {
  axiosAuthInstance,
  axiosEmptyInstance,
  API_END_POINTS,
} from 'api';
import {
  MESSAGES,
  COUNTRY_PHONE_CODE,
  COUNTRY_CODES,
} from 'appconstants';
import { NOTIFICATION } from 'store/actionTypes';
import {
  notificationActions,
} from 'store/actions';
import {
  validators,
  validationRegex,
  countryCodeValidation,
} from 'utils/validationMessages';
import getStateList from 'utils/stateList';
import { isValidPhoneNumber, formatNumber } from 'libphonenumber-js';

import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpH5,
  SpError,
  SpText,
} from 'components/DesignKit';

import Previewicon from 'components/NavIcons/previewIcon';
import Uploadicon from 'components/NavIcons/uploadIcon';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

const { Option } = Select;

const { TextArea } = Input;

const MOMENT_FORMAT = 'YYYY-MM-DD';

type Props = {
  pageData: Object,
  selectedAccount: Object,
  setNotification: Function,
  isIndia: boolean,
  updateLayout: Function,
  isGid: String,
  venueName1: String,
};

const PageDetails = forwardRef((props: Props, ref) => {
  const {
    pageData,
    selectedAccount,
    setNotification,
    isIndia,
    updateLayout,
    isGid,
    venueName1,
  } = props;
  const [, forceUpdate] = useState();
  const currencyType = selectedAccount && selectedAccount.currency;
  const [title, setTitle] = useState(pageData && pageData.title);
  const [content, setContent] = useState(pageData && pageData.content);
  const [logo, setLogo] = useState(pageData && pageData.logo);
  const [preview, setPreview] = useState(pageData && pageData.preview);
  const [fileName, setFileName] = useState(pageData && pageData.fileName);
  const [loader, setLoading] = useState(false);
  const [banner, setBanner] = useState(pageData && pageData.banner);
  const [preview1, setPreview1] = useState(pageData && pageData.preview1);
  const [fileName1, setFileName1] = useState(pageData && pageData.fileName1);
  const [street, setStreet] = useState(pageData && pageData.street);
  const [state, setState] = useState(pageData && pageData.state);
  const [city, setCity] = useState(pageData && pageData.city);
  const [zipcode, setZipcode] = useState(pageData && pageData.zipcode);
  const [country, setCountry] = useState(selectedAccount?.country?.id);
  const [eventDate, setEventDate] = useState(pageData && pageData.eventDate);
  const [contactName, setContactName] = useState(pageData && pageData.contactName);
  const [contactUsEmail, setEmail] = useState(pageData && pageData.contactUsEmail);
  const [contactPhone, setContactPhone] = useState(pageData && pageData.contactPhone);
  const [phoneNumberCode, setPhoneCode] = useState(pageData && pageData.phoneNumberCode
    ? pageData && pageData.phoneNumberCode
    : getCountryPhoneCode(selectedAccount?.country?.id));
  const [phoneFormate, setPhoneFormate] = useState(pageData && pageData.phoneFormate ? pageData && pageData.phoneFormate : '');
  const [receiptNote, setReceiptNote] = useState(pageData && pageData.receiptNote);
  const prefix = selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix;
  const [statesInCountry, setStatesInCountry] = useState(getStateList(selectedAccount?.country?.id));
  const countryName = selectedAccount?.country?.name;
  const postalCodeRegex = countryName === 'India' ? /^[1-9][0-9]{5}$/ : /^[0-9][0-9]{4}$/;
  const [qrGeneration, setqrGeneration] = useState();
  const [designation, setDesignation] = useState(pageData.designation);
  const [supportOfflinePayment, setSupportOfflinePayment] = useState(pageData.supportOfflinePayment);

  useEffect(() => {
    if (pageData && pageData.title) {
      setTitle(pageData.title);
      setContent(pageData.content);
      setLogo(pageData.logo);
      setPreview(pageData.preview);
      setFileName(pageData.fileName);
      setEmail(pageData.contactUsEmail);
      setContactPhone(pageData.contactPhone);
      setPhoneCode(pageData.phoneNumberCode
        ? pageData.phoneNumberCode
        : getCountryPhoneCode((selectedAccount && selectedAccount.country && selectedAccount.country.id)));
      setPhoneFormate(pageData.phoneFormate);
      setBanner(pageData.banner);
      setFileName1(pageData.fileName1);
      setPreview1(pageData.preview1);
      setContactName(pageData.contactName);
      setReceiptNote(pageData.description);
      setStreet(pageData?.street);
      setCity(pageData.city);
      setState(pageData?.state);
      setZipcode(pageData.zipcode);
      setqrGeneration(pageData.qrGeneration);
      setReceiptNote(pageData.receiptNote);
      setEventDate(moment(pageData.eventDate).format(MOMENT_FORMAT));
      setDesignation(pageData.designation);
      setSupportOfflinePayment(pageData.supportOfflinePayment);
    }
  }, [pageData]);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      emailId: {
        message: validators.register.validEmail,
        rule: val => validationRegex.new_email.test(val),
      },
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      feeAmount: {
        message: 'fee should be greater than zero',
        rule: val => Number(val && val.replace(/,/g, '')) >= 0,
      },
      rateAmount: {
        message: 'rate should be greater than zero',
        rule: val => Number(val && val.replace(/,/g, '')) >= 0,
      },
      taxAmount: {
        message: 'tax should be greater than zero',
        rule: val => Number(val && val.replace(/,/g, '')) > 0,
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
      postalcode: {
        message: validators.address.pincode,
        rule: val => (postalCodeRegex.test(val)),
      },
      noEmojiValidation: {
        message: 'Emojis are not allowed in the page description.',
        rule: val => {
          const emojiRegex = /[\uD800-\uDFFF]/;
          return !emojiRegex.test(val);
        },
      },
    },
  }));

  simpleValidator.current.purgeFields();

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setContactPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  const onCountryCodeChange = (value) => {
    setPhoneCode(value);
    const data = formatNumber(`${value}${contactPhone}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      title,
      content,
      logo,
      preview,
      fileName,
      banner,
      preview1,
      fileName1,
      street,
      state,
      city,
      zipcode,
      country,
      contactName,
      contactUsEmail,
      contactPhone,
      phoneFormate,
      phoneNumberCode,
      eventDate,
      receiptNote,
      qrGeneration,
      designation,
      supportOfflinePayment,
    }),
  }));

  const uploadButton = (
    <div>
      {loader ? <Icon type="loading" /> : <Uploadicon />}
      <div className="ant-upload-text mt-2">Click or drag the file here to upload</div>
      <div className="mt-2"><SpText color="rgba(0, 0, 0, 0.45)">Support extension：.pdf .jpg .png</SpText></div>
    </div>
  );

  const beforeUpload = (file) => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    const PDF = 'application/pdf';
    const IMAGE = 'image/*';
    const pattern = file.type.match(IMAGE) || file.type.match(PDF);
    if (!pattern) {
      message.error('File is not image or pdf');
      return false;
    }
    if (pattern && !isLt5M) {
      message.error('File must smaller than 5MB!');
      return false;
    }
    return true;
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const fileReader = async file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });

  const fileUpload = async (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    try {
      setLoading(true);
      const file = info.file.originFileObj;
      if (file) {
        const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.UPLOAD_EVENTS}`);
        await axiosEmptyInstance.put(entity, await fileReader(file), { headers: { 'Content-Type': file.type } });
        const { data: { entity: { fileUrl } } } = await axiosAuthInstance.post(`${API_END_POINTS.UPLOAD_EVENTS}`, { fileUrl: entity, keyType: 'EVENT_PAGE_LOGO' });
        setLogo(fileUrl);
        const pattern = /image*/;
        if (file.type.match(pattern)) {
          getBase64(file, imageUrl => {
            setPreview(<img src={imageUrl} alt="..." style={{ width: '115px' }} />);
          });
        } else {
          setPreview(<Previewicon />);
        }
        setFileName(info.file.name);
      }
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.FILE_UPLOAD.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  const fileUpload1 = async (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    try {
      setLoading(true);
      const file = info.file.originFileObj;
      if (file) {
        const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.UPLOAD_EVENTS}`);
        await axiosEmptyInstance.put(entity, await fileReader(file), { headers: { 'Content-Type': file.type } });
        const { data: { entity: { fileUrl } } } = await axiosAuthInstance.post(`${API_END_POINTS.UPLOAD_EVENTS}`, { fileUrl: entity, keyType: 'EVENT_PAGE_BANNER' });
        setBanner(fileUrl);
        const pattern = /image*/;
        if (file.type.match(pattern)) {
          getBase64(file, imageUrl => {
            setPreview1(<img src={imageUrl} alt="..." style={{ width: '115px' }} />);
          });
        } else {
          setPreview1(<Previewicon />);
        }
        setFileName1(info.file.name);
      }
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.FILE_UPLOAD.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDate = (date) => {
    // eslint-disable-next-line
    const Date = moment(date._d).format(MOMENT_FORMAT);
    setEventDate(Date);
  };

  return (
    <>
      <div>
        <Row>
          <Col span={16} push={4} className="mb-3">
            <SpText fontWeight="600" fontSize="18px">Event Details</SpText>
          </Col>
        </Row>
        <Row>
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Event Name/Page Title</SpH5></label>
            <Input
              placeholder="Enter the page title"
              value={title}
              maxlength="100"
              onChange={(e) => setTitle(e.currentTarget.value)}
            />
            <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 100 Characters</SpText>
            <SpError>
              {simpleValidator.current.message('page title', title, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Event Description</SpH5></label>
            <ReactQuill
              theme="snow"
              rows={10}
              placeholder="Add the page description you want your customer to see"
              value={content}
              maxlength="1000"
              onChange={setContent}
              className="richTextFormatting"
            />
            <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 1000 Characters</SpText>
            <SpError>
              {simpleValidator.current.message('page description', content, 'required|noEmojiValidation')}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Address</SpH5></label>
            <Input
              placeholder="Address"
              value={street}
              maxlength="100"
              onChange={(e) => setStreet(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('address', street, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={8} push={4}>
            <label htmlFor=""><SpH5>State</SpH5></label>
            <Select
              className="w-100"
              placeholder="State"
              showSearch
              optionFilterProp="children"
              value={state}
              onChange={(e) => setState(e)}
            >
              {
                  statesInCountry.map(s => (
                    <Option
                      key={s.id}
                      value={s.id}
                      className="text-capitalize"
                    >
                      {s.name}
                    </Option>
                  ))
                }
            </Select>
            <SpError>
              {simpleValidator.current.message('state', state, 'required')}
            </SpError>
          </Col>
          <Col span={8} push={4} className="ml-3">
            <label htmlFor=""><SpH5>City</SpH5></label>
            <Input
              placeholder="City"
              value={city}
              maxlength="100"
              onChange={(e) => setCity(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('city', city, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={8} push={4}>
            <label htmlFor=""><SpH5>Country</SpH5></label>
            <Select
              defaultValue={countryName}
              style={{ width: '100%' }}
              disabled
            >
              {COUNTRY_CODES.map((item) => (
                <Option key={item.ID} value={item.ALPHA2}>
                  {item.NAME}
                </Option>
              ))}
            </Select>
            <SpError>
              {simpleValidator.current.message('country', country, 'required')}
            </SpError>
          </Col>
          <Col span={8} push={4} className="ml-3">
            <label htmlFor=""><SpH5>Zipcode</SpH5></label>
            <Input
              placeholder="Enter the Zipcode"
              value={zipcode}
              maxlength="100"
              onChange={(e) => setZipcode(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('zipcode', zipcode, 'required|postalcode')}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Date</SpH5></label>
            <div className="d-flex">
              <DatePicker
                value={eventDate ? moment(eventDate, MOMENT_FORMAT) : ''}
                format={MOMENT_FORMAT}
                // style={{ width: '111px' }}
                allowClear={false}
                onChange={handleDate}
                disabledDate={current => current && current < moment().subtract(1, 'days')}
              />
            </div>
            <SpError>
              {simpleValidator.current.message('Date', eventDate, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Logo</SpH5></label>
            <Upload
              name="avatar"
              listType="picture-card upload-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={fileUpload}
            >
              {fileName ? preview : uploadButton}
            </Upload>
            <SpError>
              {simpleValidator.current.message('logo', logo, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Banner</SpH5></label>
            <Upload
              name="avatar"
              listType="picture-card upload-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={fileUpload1}
            >
              {fileName1 ? preview1 : uploadButton}
            </Upload>
            <SpError>
              {simpleValidator.current.message('banner', banner, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row>
          <Col span={15} push={4} className="mb-3">
            <label htmlFor=""><SpH5>QR Generation</SpH5></label>
            <Select
              className="w-100"
              placeholder="QR Generation"
              showSearch
              optionFilterProp="children"
              value={qrGeneration}
              onChange={(e) => setqrGeneration(e)}
            >
              <Option value="ORDER_BASED" className="text-capitalize">
                ORDER_BASED
              </Option>
              <Option value="LINE_ITEM_BASED" className="text-capitalize">
                LINE_ITEM_BASED
              </Option>
              <Option value="ITEM_BASED" className="text-capitalize">
                ITEM_BASED
              </Option>
            </Select>
            <SpError>
              {simpleValidator.current.message('state', state, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row>
          <Col span={16} push={4} className="mb-3 mt-3">
            <SpText fontWeight="600" fontSize="18px">Support Info</SpText>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Name</SpH5></label>
            <Input
              placeholder="Enter the name"
              value={contactName}
              maxlength="100"
              onChange={(e) => setContactName(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('name', contactName, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Email</SpH5></label>
            <Input
              placeholder="Enter the email"
              value={contactUsEmail}
              maxlength="100"
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('email', contactUsEmail, 'required|email')}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Phone Number</SpH5></label>
            <div className="d-flex">
              <div
                style={{ width: '20%' }}
                onKeyPress={e => countryCodeValidation(e)}
              >
                <Select
                  className="w-100"
                  showSearch
                  value={phoneNumberCode}
                  onChange={onCountryCodeChange}
                >
                  {COUNTRY_PHONE_CODE.map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </div>
              <div style={{ width: '80%' }}>
                <Input
                  className="InputnumFormate"
                  placeholder="Phone Number"
                  value={phoneFormate}
                  onChange={onPhoneNumberChange}
                  onBlur={e => {
                    if (e.currentTarget.value) {
                      setContactPhone(e.currentTarget.value);
                      const value = formatNumber(`${phoneNumberCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                      const formater = value.substr(value.indexOf(' ') + 1);
                      setPhoneFormate(formater);
                    }
                  }}
                />
                <SpError>
                  {simpleValidator.current.message('phone number', contactPhone, `required|phoneNumValidation:${phoneNumberCode}${contactPhone}`)}
                </SpError>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Designation</SpH5></label>
            <Input
              placeholder="Enter the designation"
              value={designation}
              maxlength="100"
              onChange={(e) => setDesignation(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('designation', designation, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <Switch
              defaultChecked={supportOfflinePayment}
              onChange={val => setSupportOfflinePayment(val)}
            /> <SpH5> Support Offline Payment</SpH5>
          </Col>
        </Row>
        <Row>
          <Col span={16} push={4} className="mb-3 mt-4">
            <SpText fontWeight="600" fontSize="18px">Receipt Format</SpText>
          </Col>
        </Row>
        <Row>
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Event Description</SpH5></label>
            <TextArea
              placeholder="Enter event description"
              className="resize"
              rows={5}
              value={receiptNote}
              onChange={(e) => setReceiptNote(e.currentTarget.value)}
            />
            {/* <SpError>
              {simpleValidator.current.message('description', receiptNote, 'required')}
            </SpError> */}
          </Col>
        </Row>
      </div>
    </>
  );
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(
  null,
  mapDispatchToProps,
  null,
  { forwardRef: true },
)(PageDetails);
