// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  Layout,
  Menu,
  Icon,
  Divider,
  Input,
} from 'antd';
import { withRouter } from 'react-router-dom';
import type { ContextRouter } from 'react-router-dom';
import { slide as Menus } from 'react-burger-menu';
import styled from 'styled-components';

import {
  downArrow,
  upArrow,
  toggleOn,
  toggleOff,
  kdsdashboard,
} from 'components/NavIcons';
import getSideNavOptions from 'config/sidenav';
import { API_END_POINTS, axiosAuthInstance } from 'api';
import {
  PAGES,
  TOKENS,
  MESSAGES,
  ROLES,
} from 'appconstants';
import {
  accountActions,
  notificationActions,
  terminalActions,
} from 'store/actions';
import {
  ACCOUNT,
  NOTIFICATION,
  TERMINAL,
} from 'store/actionTypes';

import {
  SpText,
  SpStyledMenu,
} from 'components/DesignKit';
import qpayments from 'imgs/Qpayments.jpeg';
import QCollapse from 'imgs/Q.jpg';
import Search from '../NavIcons/Search';

// import Logo from '../NavIcons/Logo';
import LogoNew from '../NavIcons/LogoNew';
import LogoCollapse from '../NavIcons/LogoCollapse';
import CollapseArr from '../NavIcons/CollapseArr';
import ExpandArr from '../NavIcons/ExpandArr';

const StyledMenu = styled(Menu)`
  background: #12284A;
  border: none;
`;

const StyledMenuItem = styled(Menu.Item)`
  height: 40px !important;
  padding: 6px 13px 6px 22px !important;
`;

const { Sider } = Layout;

const COLLAPSIBLE = true;

type Props = ContextRouter & {
  test: boolean,
  selectedAccount: Object,
  internalAccount: Object,
  role: {
    name: string,
  },
  setTestData: Function,
  history: {
    push: Function,
  },
  location: {
    pathname: string,
  },
  setNotification: Function,
  setLocationData: Function,
  customDashboardSucess: Function,
  user: Object,
  isMobileView: Boolean,
};

const SideNav = (props: Props) => {
  const {
    test,
    selectedAccount,
    internalAccount,
    role,
    setTestData,
    history,
    location,
    setNotification,
    setLocationData,
    customDashboardSucess,
    user,
    isMobileView,
  } = props;
  const [current, setCurrent] = useState('dashboard');
  const [collapsed, setCollapsed] = useState(false);
  const [sideNavOptions, setSideNavOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [noResults, setNoResults] = useState(false);


  useEffect(() => {
    const layoutsDiv = document.getElementsByClassName('ant-layout');
    if (!isMobileView) {
      layoutsDiv[1].style.minHeight = '1250px';
      layoutsDiv[1].style.marginLeft = '240px';
    } else if (isMobileView) {
      layoutsDiv[1].style.height = 'auto';
      layoutsDiv[1].style.marginLeft = '0px';
    }
  }, [isMobileView]);

  const handleCustomReport = async (roles, recon, country) => {
    const navOptns = getSideNavOptions(roles, recon, country);
    if (roles !== 'associate' && roles !== 'KDS-USER' && roles !== 'KDS-ADMIN') {
      const updatedNavOptions = [...navOptns];
      const productsLegacy = {
        key: '/products',
        display: 'Products',
        id: 'products',
        allowedRoles: [
          ROLES.ADMINISTRATOR,
          ROLES.OWNER,
          ROLES.DEVELOPER,
          ROLES.OPERATIONS,
          // ROLES.ANALYST,
          ROLES.SUPPORT,
          ROLES.INTERNALSUPPORT,
          ROLES.POS,
          ROLES.SUPERUSER,
          ROLES.ONBOARDING,
        ],
      };
      const index = updatedNavOptions.findIndex((iten) => iten.key === '/customer-solutions');
      const dropdoumIndex = updatedNavOptions[index].dropdown.findIndex((item) => item.key === '/products');
      if (selectedAccount && !selectedAccount.legacySubscription) {
        if (dropdoumIndex >= 1) {
          delete updatedNavOptions[index].dropdown[dropdoumIndex];
        }
      } else if (selectedAccount && selectedAccount.legacySubscription) {
        if (dropdoumIndex < 1) {
          updatedNavOptions[index].dropdown.push(productsLegacy);
        }
      }
      try {
        const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.SUPERSET_DASHBOARD}/list`);
        const { data } = await axiosAuthInstance.get(API_END_POINTS.GET_INVENTORY_SHOPS);
        if (roles === 'analyst' && entity && entity.content && entity.content.length !== 0) {
          customDashboardSucess(entity.content[0]);
        } else if (roles !== 'analyst' && entity && entity.content && entity.content.length !== 0) {
          const customDashboardDropdown = [{
            key: '/customdashboard',
            display: 'Custom Dashboard',
            allowedRoles: [
              ROLES.ADMINISTRATOR,
              ROLES.OWNER,
              ROLES.DEVELOPER,
              ROLES.OPERATIONS,
              ROLES.ANALYST,
              ROLES.SUPPORT,
              ROLES.INTERNALSUPPORT,
              ROLES.SUPERUSER,
              ROLES.ONBOARDING,
            ],
          }];
          updatedNavOptions[0].dropdown = customDashboardDropdown;
          customDashboardSucess(entity.content[0]);
        } else {
          delete navOptns[0].dropdown;
          delete updatedNavOptions[0].dropdown;
          customDashboardSucess({});
        }
        if (data && data.entity && data.entity.content && data.entity.content.length !== 0) {
          const dropdown1 = {
            key: '/vieworders',
            icon: kdsdashboard,
            display: 'KDS Dashboard',
            allowedRoles: [
              ROLES.OWNER,
              ROLES.ASSOCIATE,
              ROLES.INTERNALSUPPORT,
            ],
          };
          updatedNavOptions.splice(1, 0, dropdown1);
        }
        if (user.internalRole && user.internalRole.name) {
          if (user.internalRole && user.internalRole.name === ROLES.SUPERUSER) {
            setSideNavOptions(updatedNavOptions);
            setFilteredOptions(updatedNavOptions);
          } else {
            setSideNavOptions(updatedNavOptions.filter((item) => item.display !== 'Dashboard'));
            setFilteredOptions(updatedNavOptions.filter((item) => item.display !== 'Dashboard'));
          }
        } else {
          setSideNavOptions(updatedNavOptions);
          setFilteredOptions(updatedNavOptions);
        }
        setCurrent(location.pathname.substr(1));
      } catch (e) {
        setNotification({
          type: NOTIFICATION.ERROR,
          payload: e.response ? e.response.data.message : '',
        });
      }
    } else {
      setSideNavOptions(navOptns);
      setFilteredOptions(navOptns);
    }
  };

  useEffect(() => {
    if (internalAccount) {
      handleCustomReport(role.name, internalAccount && internalAccount.reconciliationEnabled,
        selectedAccount && selectedAccount.settlementCurrency && selectedAccount.settlementCurrency.countryAlpha2 === 'IN');
    } else {
      handleCustomReport(role.name, selectedAccount && selectedAccount.reconciliationEnabled,
        selectedAccount && selectedAccount.settlementCurrency && selectedAccount.settlementCurrency.countryAlpha2 === 'IN');
    }
  }, [role, selectedAccount, test, internalAccount]);

  const onChange = async (value) => {
    let fetchToken;
    if (((selectedAccount && role && role.name) === 'superuser')
      || ((role && role.name) === 'onboarding') || ((role && role.name) === 'internalsupport')) {
      fetchToken = `/internal${API_END_POINTS.JWE}/${selectedAccount.gid}/switch`;
    } else if (selectedAccount) {
      fetchToken = `${API_END_POINTS.JWE}/${selectedAccount.gid}/switch`;
    }
    if (value) {
      fetchToken = `${fetchToken}?isTest=true`;
    }
    try {
      const { data: { entity: { token: { accessToken } } } } = await axiosAuthInstance.get(fetchToken);
      localStorage.setItem(TOKENS.ACCESS_TOKEN, accessToken);
      const { pathname } = location;
      const path = pathname.split('/')[1];
      if (path === 'payments') {
        history.push(PAGES.PAYMENTS);
      } else if (path === 'payouts') {
        history.push(PAGES.PAYOUTS);
      } else if (path === 'customers') {
        history.push(PAGES.CUSTOMERS);
      } else if (path === 'subscriptions') {
        history.push(PAGES.SUBSCRIPTION);
      } else if (path === 'plans') {
        history.push(PAGES.PLAN);
      } else if (path === 'payment-links') {
        history.push(PAGES.PAYMENTLINK);
      } else if (path === 'locations') {
        history.push(PAGES.LOCATIONS);
      } else if (path === 'invoices') {
        history.push(PAGES.INVOICES);
      } else if (path === 'payment-buttons') {
        history.push(PAGES.PAYMENTBUTTON);
      } else if (path === 'subscription-button') {
        history.push(PAGES.SUBSCRIPTION_BUTTON);
      } else if (path === 'payment-pages') {
        history.push(PAGES.PAYMENTPAGES);
      } else if (path === 'transfers') {
        history.push(PAGES.TRANSFERS);
      } else if (path === 'terminals') {
        history.push(PAGES.TERMINALS);
      } else {
        history.push(pathname);
      }
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    setTestData(value);
  };

  const handleClick = (e) => {
    setCurrent(e.key);
    history.push(e.key);
    setLocationData({
      search: '',
    });
  };

  const searchNav = (value) => {
    const filtered = sideNavOptions.map(option => {
      // Check if the main option matches
      const matchesMainOption = option.display.toLowerCase().includes(value.toLowerCase());

      // Filter the dropdown items
      const filteredDropdown = option.dropdown ? option.dropdown.filter(k => k.display.toLowerCase().includes(value.toLowerCase())) : [];

      // If there are matching dropdown items, set the selected option
      if (value === '') {
        setSelectedOption(null);
      } else if (filteredDropdown.length > 0) {
        setSelectedOption(option.key);
      }
      // Return a new object for the filtered option
      return {
        ...option,
        dropdown: filteredDropdown.length > 0 ? filteredDropdown : undefined, // Exclude dropdown if empty
        matchesMainOption, // Include the matchesMainOption for the final filter
      };
    });

    // Filter options based on matches
    const finalFilteredOptions = filtered.filter(option => option.matchesMainOption || (option.dropdown && option.dropdown.length > 0));

    // Set the noResults state based on whether finalFilteredOptions is empty
    setNoResults(finalFilteredOptions.length === 0);

    // Update the filtered options state
    setFilteredOptions(finalFilteredOptions);
  };
  const debouncedSearchSideNav = debounce(searchNav, 2000, { leading: true });

  const handleSearch = (value) => {
    setSearchTerm(value);
    debouncedSearchSideNav(value);
  };
  const handleCollapse = () => {
    // clear searchBar
    debouncedSearchSideNav('');
    setSearchTerm('');
    if (COLLAPSIBLE) {
      setCollapsed(!collapsed);
      const layoutsDiv = document.getElementsByClassName('ant-layout');
      if (!collapsed) {
        layoutsDiv[1].style.marginLeft = '100px';
      } else if (collapsed) {
        layoutsDiv[1].style.marginLeft = '240px';
      }
    }
  };

  return (
    <>
      {
      isMobileView ? (
        <Menus isOpen={isOpen}>
          <div className="d-flex flex-column">
            <div
              className="d-flex justify-content-center mt-3 mb-3"
              style={{
                position: 'relative',
                paddingRight: '8px',
              }}
            >
              {
            collapsed
              ? window.location.host.includes('qpaymentz') ? <img src={QCollapse} alt="" style={{ width: '55px', margin: '3px 0px' }} /> : <LogoCollapse />
              : window.location.host.includes('qpaymentz') ? <img src={qpayments} alt="" style={{ width: '180px', margin: '5px 0px' }} /> : <LogoNew />
          }

            </div>
            <Divider
              type="horizontal"
              className="mt-2"
              style={{
                backgroundColor: '#25385F',
                margin: '16px 0px',
              }}
            />
            { !collapsed && (
            <div style={{ margin: '10px' }}>
              <Input
                value={searchTerm}
                type="text"
                placeholder="Search"
                onFocus={(e) => { e.target.autocomplete = 'new-password'; }}
                onChange={(e) => handleSearch(e.target.value)}
                prefix={<Icon type="search" style={{ color: 'rgb(0,0,0)' }} />}
                suffix={searchTerm && (
                <Icon type="close" onClick={() => handleSearch('')} style={{ color: 'rgb(0,0,0)', cursor: 'pointer'}} />
                )}
                enterButton
                autoComplete="off"
                name="search_field"
              />
            </div>
            )}
            {noResults && <div className="mt-5 ml-5 text-white">No results found.</div>}
            <SpStyledMenu>
              <StyledMenu
                mode="inline"
                onClick={(e) => handleClick(e)}
                selectedKeys={[current]}
              >
                {
              filteredOptions.map((option) => {
                if (option.dropdown) {
                  return (
                    <StyledMenu key={option.key} inlineIndent={0}>
                      <StyledMenuItem
                        key={option.key}
                        onClick={() => {
                          setSelectedOption(selectedOption === option.key ? null : option.key);
                        }}
                        style={{
                          marginBottom: '0px',
                          marginTop: '0px',
                          color: location.pathname.startsWith(option.key)
                            ? '#279dfe'
                            : '#B7C9E5',
                          backgroundColor: location.pathname.startsWith(option.key)
                            ? '#3372D1'
                            : '#12284A',
                        }}
                      >
                        <Icon
                          component={option.icon}
                          height={option.height}
                          style={{
                            color: location.pathname.startsWith(option.key)
                              ? '#FFFFFF'
                              : '#BFC7D9',
                          }}
                        />
                        <SpText
                          color={location.pathname.startsWith(option.key)
                            ? '#FFFFFF'
                            : '#B7C9E5'}
                          fontSize="13px"
                          fontWeight="400"
                          style={{
                            letterSpacing: '0.03em',
                          }}
                        >
                          {option.display}
                        </SpText>
                        {
                          !location.pathname.startsWith(option.key)
                            ? (
                              <Icon
                                style={{
                                  float: 'right',
                                  verticalAlign: 'middle',
                                  lineHeight: '28px',
                                  marginRight: '3px',
                                }}
                                component={downArrow}
                                height={option.height}
                              />
                            ) : selectedOption ? (
                              <Icon
                                style={{
                                  float: 'right',
                                  verticalAlign: 'middle',
                                  lineHeight: '28px',
                                  marginRight: '3px',
                                }}
                                component={upArrow}
                                height={option.height}
                              />
                            ) : (
                              <Icon
                                style={{
                                  float: 'right',
                                  verticalAlign: 'middle',
                                  lineHeight: '28px',
                                  marginRight: '3px',
                                }}
                                component={downArrow}
                                height={option.height}
                              />
                            )
                        }
                      </StyledMenuItem>
                      {
                        !collapsed
                        && option.dropdown
                        && (
                          option.dropdown.map((k) => (
                            <StyledMenuItem
                              key={k.key}
                              id={option.key}
                              style={{
                                marginBottom: '0px',
                                marginTop: '0px',
                                display: selectedOption === option.key ? 'block' : 'none',
                                color: location.pathname.startsWith(k.key)
                                  ? '#279dfe'
                                  : '#B7C9E5',
                                backgroundColor: location.pathname.startsWith(k.key)
                                  ? '#3372D1'
                                  : '#0B182B',
                              }}
                            >
                              <SpText
                                className="ml-2"
                                color={location.pathname.startsWith(k.key)
                                  ? '#FFFFFF'
                                  : '#B7C9E5'}
                                fontSize="13px"
                                fontWeight="400"
                                style={{
                                  paddingLeft: '16px',
                                  letterSpacing: '0.03em',
                                }}
                              >
                                {k.display}
                              </SpText>
                            </StyledMenuItem>
                          ))
                        )
                      }
                    </StyledMenu>
                  );
                }
                return (
                  <StyledMenuItem
                    style={{
                      marginBottom: '0px',
                      marginTop: '0px',
                      color: location.pathname.startsWith(option.key)
                        ? '#279dfe'
                        : '#B7C9E5',
                      backgroundColor: location.pathname.startsWith(option.key)
                        ? '#3372D1'
                        : '#12284A',
                    }}
                    key={option.key}
                    onClick={() => {
                      setSelectedOption(option.key);
                    }}
                  >
                    <Icon
                      component={option.icon}
                      height={option.height}
                      style={{
                        color: location.pathname.startsWith(option.key)
                          ? '#FFFFFF'
                          : '#BFC7D9',
                      }}
                    />
                    <SpText
                      color={location.pathname.startsWith(option.key)
                        ? '#FFFFFF'
                        : '#B7C9E5'}
                      fontSize="13px"
                      fontWeight="400"
                      style={{
                        letterSpacing: '0.03em',
                      }}
                    >
                      {option.display}
                    </SpText>
                  </StyledMenuItem>
                );
              })
            }
              </StyledMenu>
            </SpStyledMenu>

            <div
              style={{
                overflow: 'hidden',
              }}
            >
              {
            (role && role.name !== 'associate') ? (
              <>
                <Divider
                  type="horizontal"
                  style={{
                    backgroundColor: '#25385F',
                    margin: '0px 0px 16px 0px',
                  }}
                />

                <div
                  className="d-flex"
                  style={{
                    flexDirection: collapsed ? 'column' : 'row',
                  }}
                >
                  <SpText
                    color="#C2D5F2"
                    fontSize="13px"
                    fontWeight="400"
                    style={{
                      marginTop: collapsed ? '16px' : '0',
                      paddingLeft: '22px',
                    }}
                  >
                    {
                      collapsed ? '' : 'Switch to test data'
                    }
                  </SpText>
                  <Icon
                    onClick={() => onChange(!test)}
                    component={test ? toggleOn : toggleOff}
                    title={collapsed ? 'Switch to test data' : ''}
                    style={{
                      marginLeft: collapsed ? '-15px' : '55px',
                      marginTop: '3px',
                    }}
                  />
                </div>
              </>
            ) : null
          }
              <div className="d-flex justify-content-center align-items-center">
                <SpText
                  color="#99A8BF"
                  fontSize="11px"
                  fontWeight="400"
                  style={{
                    position: 'fixed',
                    bottom: '10px',
                    marginRight: '5px',
                  }}
                >
                  {collapsed
                    ? <p>&copy; {window.location.host.includes('qpaymentz') ? 'QP' : 'SP'} {new Date().getFullYear()}</p>
                    : <>&copy; {window.location.host.includes('qpaymentz') ? 'Q PaymentZ' : 'Swirepay'} {new Date().getFullYear()}</>}
                </SpText>
              </div>
            </div>
          </div>
        </Menus>
      ) : (
        <Sider
          width={240}
          collapsedWidth={98}
          collapsible
          trigger={null}
          collapsed={collapsed}
          className="SIDENAV"
        >
          <div className="d-flex flex-column">
            <div
              className="d-flex justify-content-center mt-3 mb-3"
              style={{
                position: 'relative',
                paddingRight: '8px',
              }}
            >
              {
            collapsed
              ? window.location.host.includes('qpaymentz') ? <img src={QCollapse} alt="" style={{ width: '55px', margin: '3px 0px' }} /> : <LogoCollapse />
              : window.location.host.includes('qpaymentz') ? <img src={qpayments} alt="" style={{ width: '180px', margin: '5px 0px' }} /> : <LogoNew />
          }

            </div>
            <div
              style={{
                position: 'absolute',
                marginLeft: collapsed ? '90px' : '231px',
                top: '12px',
                cursor: 'pointer',
              }}
              onClick={() => handleCollapse()}
            >
              {
            collapsed ? <CollapseArr /> : <ExpandArr />
          }
            </div>
            <Divider
              type="horizontal"
              className="mt-2"
              style={{
                backgroundColor: '#25385F',
                margin: '16px 0px',
              }}
            />
            { !collapsed ? (
              <div style={{ margin: '10px' }}>
                <Input
                  value={searchTerm}
                  onFocus={(e) => { e.target.autocomplete = 'new-password'; }}
                  type="text"
                  placeholder="Search"
                  onChange={(e) => handleSearch(e.target.value)}
                  enterButton
                  autoComplete="off"
                  prefix={<Icon type="search" style={{ color: 'rgb(0,0,0)' }} />}
                  suffix={searchTerm && (
                  <Icon type="close" onClick={() => handleSearch('')} style={{ color: 'rgb(0,0,0)', cursor: 'pointer'}} />
                  )}
                  name="search_field"
                />
              </div>
            ) : (
              <div style={{ margin: '0 2rem', cursor: 'pointer' }} onClick={() => handleCollapse()}>
                <Search />
              </div>
            )}
            {noResults && <div className="mt-5 ml-5 text-white">No results found.</div>}
            <SpStyledMenu>
              <StyledMenu
                mode="inline"
                onClick={(e) => handleClick(e)}
                selectedKeys={[current]}
              >
                {
              filteredOptions.map((option) => {
                if (option.dropdown) {
                  return (
                    <StyledMenu key={option.key} inlineIndent={0}>
                      <StyledMenuItem
                        key={option.key}
                        onClick={() => {
                          setSelectedOption(selectedOption === option.key ? null : option.key);
                        }}
                        style={{
                          marginBottom: '0px',
                          marginTop: '0px',
                          color: location.pathname.startsWith(option.key)
                            ? '#279dfe'
                            : '#B7C9E5',
                          backgroundColor: location.pathname.startsWith(option.key)
                            ? '#3372D1'
                            : '#12284A',
                        }}
                      >
                        <Icon
                          component={option.icon}
                          height={option.height}
                          style={{
                            color: location.pathname.startsWith(option.key)
                              ? '#FFFFFF'
                              : '#BFC7D9',
                          }}
                        />
                        <SpText
                          color={location.pathname.startsWith(option.key)
                            ? '#FFFFFF'
                            : '#B7C9E5'}
                          fontSize="13px"
                          fontWeight="400"
                          style={{
                            letterSpacing: '0.03em',
                          }}
                        >
                          {option.display}
                        </SpText>
                        {
                          !location.pathname.startsWith(option.key)
                            ? (
                              <Icon
                                style={{
                                  float: 'right',
                                  verticalAlign: 'middle',
                                  lineHeight: '28px',
                                  marginRight: '3px',
                                }}
                                component={downArrow}
                                height={option.height}
                              />
                            ) : selectedOption ? (
                              <Icon
                                style={{
                                  float: 'right',
                                  verticalAlign: 'middle',
                                  lineHeight: '28px',
                                  marginRight: '3px',
                                }}
                                component={upArrow}
                                height={option.height}
                              />
                            ) : (
                              <Icon
                                style={{
                                  float: 'right',
                                  verticalAlign: 'middle',
                                  lineHeight: '28px',
                                  marginRight: '3px',
                                }}
                                component={downArrow}
                                height={option.height}
                              />
                            )
                        }
                      </StyledMenuItem>
                      {
                        !collapsed
                        && option.dropdown
                        && (
                          option.dropdown.map((k) => (
                            <StyledMenuItem
                              key={k.key}
                              id={option.key}
                              style={{
                                marginBottom: '0px',
                                marginTop: '0px',
                                display: selectedOption === option.key ? 'block' : 'none',
                                color: location.pathname.startsWith(k.key)
                                  ? '#279dfe'
                                  : '#B7C9E5',
                                backgroundColor: location.pathname.startsWith(k.key)
                                  ? '#3372D1'
                                  : '#0B182B',
                              }}
                            >
                              <SpText
                                className="ml-2"
                                color={location.pathname.startsWith(k.key)
                                  ? '#FFFFFF'
                                  : '#B7C9E5'}
                                fontSize="13px"
                                fontWeight="400"
                                style={{
                                  paddingLeft: '16px',
                                  letterSpacing: '0.03em',
                                }}
                              >
                                {k.display}
                              </SpText>
                            </StyledMenuItem>
                          ))
                        )
                      }
                    </StyledMenu>
                  );
                }
                return (
                  <StyledMenuItem
                    style={{
                      marginBottom: '0px',
                      marginTop: '0px',
                      color: location.pathname.startsWith(option.key)
                        ? '#279dfe'
                        : '#B7C9E5',
                      backgroundColor: location.pathname.startsWith(option.key)
                        ? '#3372D1'
                        : '#12284A',
                    }}
                    key={option.key}
                    onClick={() => {
                      setSelectedOption(option.key);
                    }}
                  >
                    <Icon
                      component={option.icon}
                      height={option.height}
                      style={{
                        color: location.pathname.startsWith(option.key)
                          ? '#FFFFFF'
                          : '#BFC7D9',
                      }}
                    />
                    <SpText
                      color={location.pathname.startsWith(option.key)
                        ? '#FFFFFF'
                        : '#B7C9E5'}
                      fontSize="13px"
                      fontWeight="400"
                      style={{
                        letterSpacing: '0.03em',
                      }}
                    >
                      {option.display}
                    </SpText>
                  </StyledMenuItem>
                );
              })
            }
              </StyledMenu>
            </SpStyledMenu>
            <div
              style={{
                overflow: 'hidden',
              }}
            >
              {
            (role && role.name !== 'associate') ? (
              <>
                <Divider
                  type="horizontal"
                  style={{
                    backgroundColor: '#25385F',
                    margin: '0px 0px 16px 0px',
                  }}
                />

                <div
                  className="d-flex"
                  style={{
                    flexDirection: collapsed ? 'column' : 'row',
                  }}
                >
                  <SpText
                    color="#C2D5F2"
                    fontSize="13px"
                    fontWeight="400"
                    style={{
                      marginTop: collapsed ? '16px' : '0',
                      paddingLeft: '22px',
                    }}
                  >
                    {
                      collapsed ? '' : 'Switch to test data'
                    }
                  </SpText>
                  <Icon
                    onClick={() => onChange(!test)}
                    component={test ? toggleOn : toggleOff}
                    title={collapsed ? 'Switch to test data' : ''}
                    style={{
                      marginLeft: collapsed ? '-15px' : '55px',
                      marginTop: '3px',
                    }}
                  />
                </div>
              </>
            ) : null
          }
              <div className="d-flex justify-content-center align-items-center">
                <SpText
                  color="#99A8BF"
                  fontSize="11px"
                  fontWeight="400"
                  style={{
                    position: 'fixed',
                    bottom: '10px',
                    marginRight: '5px',
                  }}
                >
                  {collapsed
                    ? <p>&copy; {window.location.host.includes('qpaymentz') ? 'QP' : 'SP'}  {new Date().getFullYear()}</p>
                    : <>&copy; {window.location.host.includes('qpaymentz') ? 'Q PaymentZ' : 'Swirepay'}  {new Date().getFullYear()}</>}
                </SpText>
              </div>
            </div>
          </div>
        </Sider>
      )
    }
    </>

  );
};

const mapStateToProps = (state) => ({
  role: (state.user.internalRole ? state.user.internalRole : state.user.role),
  test: state.account.test,
  customReport: state.account.customReport,
  selectedAccount: state.account.selectedAccount,
  internalAccount: state.account.internalAccount,
  user: state.user,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  setTestData: (value) => dispatch(accountActions.setTestData({
    type: ACCOUNT.SET_TEST_DATA,
    payload: value,
  })),
  customDashboardSucess: (value) => dispatch(accountActions.customDashboardSucess({
    type: ACCOUNT.CUSTOM_DASHBOARD_SUCCESS,
    payload: value,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setLocationData: (value) => dispatch(terminalActions.setLocationData({
    type: TERMINAL.SEARCH_LOCATION,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SideNav));
