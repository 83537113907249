// @flow
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Button,
  Icon,
} from 'antd';
import styled from 'styled-components';
import SimpleReactValidator from 'simple-react-validator';
import type { ContextRouter } from 'react-router-dom';

import {
  SpFormText,
  SpText,
} from 'components/DesignKit';

import {
  axiosLoginInstance,
  API_END_POINTS,
} from 'api';

import {
  PAGES,
  MESSAGES,
  TOKENS,
  AUTH_CHALLENGES,
} from 'appconstants';

import { validators } from 'utils/validationMessages';

import { notificationActions } from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import swireLogo from 'imgs/swirepay.png';
import qpayments from 'imgs/Qpayments.jpeg';

// $FlowFixMe
const Wrapper = styled.div`
  background-image: linear-gradient(120deg, #05d5ff 3%, #007EE5);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

// $FlowFixMe
const FormWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingTop: '24px',
  paddingBottom: '24px',
  alignItems: 'center',
  width: '511px',
  minHeight: '520px',
  borderRadius: '10px',
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: '#f7f7f7',
});

type Props = ContextRouter & {
  setNotification: Function,
  history: {
    push: Function,
  },
  location: {
    pathname: string,
    search: string,
  },
  isMobileView: Boolean,
};

const NewRegister = (props: Props) => {
  const {
    setNotification,
    location,
    isMobileView,
  } = props;

  const [, forceUpdate] = useState();
  const [email, setEmailId] = useState('');
  const [password, setOtp] = useState('');
  const [loading, setLoading] = useState(false);

  const simpleValidator = useRef(new SimpleReactValidator());

  useEffect(() => {
    const emailId = location.search && location.search.split('=')[1];
    if (emailId) {
      setEmailId(emailId);
    } else {
      const registerEmail = JSON.parse(localStorage.getItem('accountInfo'));
      setEmailId(registerEmail && registerEmail.email);
    }
  }, []);

  const handleResend = async () => {
    setOtp('');
    setLoading(true);
    try {
      await axiosLoginInstance.post(API_END_POINTS.RESEND_PASSWORD, {
        email,
      });
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.REGISTER.SUCCESS,
      });
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    setLoading(false);
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    setLoading(true);

    try {
      const { data: { entity } } = await axiosLoginInstance.post(API_END_POINTS.AUTHENTICATE, {
        email,
        password,
        platformType: 'WEB',
      });
      localStorage.setItem(TOKENS.SESSION_TOKEN, entity.session);
      if (entity.challengeName === AUTH_CHALLENGES.NEW_PASSWORD_REQUIRED) {
        setLoading(false);
        props.history.push({
          pathname: PAGES.SET_PASSWORD,
          search: `?email=${email}`,
        });
      }
    } catch (e) {
      if (e.response.status === 401) {
        setNotification({
          type: NOTIFICATION.ERROR,
          payload: MESSAGES.OTP_ERROR,
        });
      } else if (e.response.status !== 401) {
        setNotification({
          type: NOTIFICATION.ERROR,
          payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
        });
      }
    }
    setLoading(false);
  };

  return (
    <Wrapper>
      <div>
        <FormWrapper
          onSubmit={handleRegister}
          style={{
            width: isMobileView ? '900px' : '',
            height: isMobileView ? '900px' : '',
            paddingTop: isMobileView ? '0px' : '',
            paddingBottom: isMobileView ? '0px' : '',
          }}
        >
          <div style={{ maxWidth: isMobileView ? '800px' : '350px', textAlign: 'center' }}>
            <img src={window.location.host.includes('qpaymentz') ? qpayments : swireLogo} alt="" className="align-self-center" style={{ width: isMobileView ? '420px' : '270px', margin: '32px 0px' }} />
            <SpFormText fontSize={isMobileView ? '26px' : '18px'}>Provide Onetime Password</SpFormText>
            <div className="LOGIN">
              <div
                className="my-3"
                style={{
                  textAlign: 'left',
                }}
              >
                <div>
                  <label htmlFor="" className="font-weight-bold" style={{ fontSize: isMobileView ? '22px' : '' }}>Onetime Password (OTP)</label>
                </div>
                <Input
                  style={{ width: isMobileView ? '600px' : '', height: isMobileView ? '50px' : '' }}
                  placeholder="Enter OTP"
                  value={password}
                  onChange={(e) => setOtp(e.currentTarget.value)}
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                />
                <div className="text-danger">
                  {simpleValidator.current.message('OTP', password, 'required',
                    { messages: { required: validators.newRegister.otpRequired } })}
                </div>
              </div>

              <div className="mt-2">
                <SpText fontSize={isMobileView ? '26px' : '18px'}>Please provide OTP sent to {email}</SpText>
                <div className="mt-1">
                  <SpText className="mt-1" fontSize={isMobileView ? '26px' : '18px'}>Did not recieve one?</SpText>
                  <Button
                    type="link"
                    onClick={handleResend}
                    style={{ fontSize: isMobileView ? '18px' : '' }}
                  >
                    Resend
                  </Button>
                </div>
              </div>

              <div className="d-flex justify-content-center mt-2">
                <Button
                  type="primary"
                  shape="round"
                  className="w-50"
                  htmlType="submit"
                  loading={loading}
                  onClick={handleRegister}
                  style={{ fontSize: isMobileView ? '18px' : '' }}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </FormWrapper>
        <div className="d-flex justify-content-between align-items-center" style={{ width: isMobileView ? '900px' : '511px' }}>
          <Button type="link" className="pl-1">
            <span style={{ color: '#F7F7F7', fontSize: isMobileView ? '18px' : '' }}>
              Privacy & Terms
            </span>
          </Button>
          <div className="d-flex align-items-center">
            <span style={{ color: '#F7F7F7', fontSize: isMobileView ? '18px' : '' }}>
              Need Help?
            </span>
            <Button type="link" className="pr-0">
              <span style={{ color: '#F7F7F7', fontSize: isMobileView ? '18px' : '' }}>
                Contact Us
              </span>
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  selectedAccount: state.account.selectedAccount,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(NewRegister);
