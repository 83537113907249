// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Menu,
  Icon,
} from 'antd';
import {
  SpButton,
  SpH5,
  SpTab,
} from 'components/DesignKit';

import Loading from 'components/Loading';

import {
  ratesActions,
  issuerrateActions,
  exchangeRateActions,
  issuerActions,
  filterParamsActions,
  notificationActions,
} from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  ROLES,
} from 'appconstants';

import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import RBAC from 'components/rbac';
import AddExchangeRate from './components/AddExchangeRate';
import DeleteExchangeRateModal from './components/DeleteExchangeModal';
import EditExchangeRate from './components/EditExchangeRate';

type Props = {
  getExchangeRatesList: Function,
  deleteExchangeRate: Function,
  addExchangeRate: Function,
  updateExchangeRate: Function,
  selectedAccount: Object,
  totalElements: number,
  test: boolean,
  loading: boolean,
  submitting: boolean,
  validator: boolean,
  setFilterData: Function,
  preserveFilters: Object,
  exchangeRate: Object,
  history: {
    push: Function,
  },
};

const ExchangeRatesList = (props: Props) => {
  const location = useLocation();
  const {
    selectedAccount,
    loading,
    addExchangeRate,
    getExchangeRatesList,
    updateExchangeRate,
    deleteExchangeRate,
    totalElements,
    submitting,
    validator,
    test,
    history,
    setFilterData,
    preserveFilters,
    exchangeRate,
  } = props;

  const [pagination, setPagination] = useState({});
  const [addRateModal, setAddRateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editExchangeModal, setEditExchangeModal] = useState(false);
  const [selectedExchangeRateGid, setSelectedExchangeRateGid] = useState('');
  const [selectedExchangeRate, setSelectedExchangeRate] = useState('');
  const { pathname } = location;

  const [current, setCurrent] = useState('exchangerate');
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterRates: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        checked: true,
      }],
    });


  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    getExchangeRatesList({ currentPage, sortParams, filterParams });
  };
  useEffect(() => {
    getData(location.search);
  }, [location, test, selectedAccount]);

  useEffect(() => {
    if (!validator) {
      setAddRateModal(false);
      setDeleteModal(false);
      setEditExchangeModal(false);
    }
  }, [validator]);


  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} Rates`,
    }));
  }, [exchangeRate]);


  const addNewExchangeRate = (exchangeRateDetails: Object) => {
    const {
      fromCurrencyCode,
      toCurrencyCode,
      rate,
      lowVolumeLimit,
      lowVolumeFeeType,
      lowVolumeFee,
      apiStartDate,
      apiEndDate,
    } = exchangeRateDetails;

    const params = {
      fromCurrencyCode,
      toCurrencyCode,
      rate,
      lowVolumeLimit: (lowVolumeLimit * 100),
      lowVolumeFeeType,
      lowVolumeFee: (lowVolumeFee * 100),
      effectiveFrom: apiStartDate,
      effectiveTo: apiEndDate,

    };
    addExchangeRate(
      params,
    );
  };
  const editExchangeRate = (exchangeRateValues: Object) => {
    const {
      fromCurrencyCode,
      toCurrencyCode,
      rate,
      lowVolumeLimit,
      lowVolumeFeeType,
      lowVolumeFee,
      effectiveFrom,
      effectiveTo,
      gid,
    } = exchangeRateValues;

    const params = {
      fromCurrencyCode,
      toCurrencyCode,
      rate,
      lowVolumeLimit,
      lowVolumeFeeType,
      lowVolumeFee,
      effectiveFrom,
      effectiveTo,

    };
    updateExchangeRate({
      params,
      gid,
    });
  };
  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = '&sortBy=createdAt&direction=ASC';
    } else if (filters.order === 'descend') {
      tempParams = '&sortBy=createdAt&direction=DESC';
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.EXCHANGE_RATE,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.EXCHANGE_RATE,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };


  const handleDeleteExchange = (record) => {
    setSelectedExchangeRateGid(record?.gid);
    setDeleteModal(true);
  };
  const handleEditExchange = (record) => {
    setSelectedExchangeRate(record);
    setEditExchangeModal(true);
  };

  const deleteSelectedExchangeRate = (fundingSourceGid: Object) => {
    deleteExchangeRate(fundingSourceGid);
    // history.push(`${PAGES.EXCHANGE_RATE}`);
  };

  const columns = [
    {
      title: 'FROM CURRENCY',
      dataIndex: 'fromCurrency',
      align: 'left',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (fromCurrency) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {fromCurrency?.name || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'TO CURRENCY',
      dataIndex: 'toCurrency',
      align: 'left',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (toCurrency) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {toCurrency?.name || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'EXCHANGE RATE',
      dataIndex: 'record',
      align: 'left',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {record?.rate && typeof record?.rate === 'number' && record?.fromCurrency && record?.toCurrency
              ? `1 ${record?.fromCurrency?.name} = ${record?.rate} ${record?.toCurrency?.name}`
              : <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'LOW VOLUME FEE',
      dataIndex: 'record',
      align: 'left',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {record?.lowVolumeFee
              ? `${(record?.lowVolumeFee / 100).toFixed(2)}${record?.lowVolumeFeeType === 'PERCENTAGE' ? '%' : ''}`
              : <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: (
        <span>
          START DATE
          <span style={{ fontSize: '12px', color: 'grey', marginLeft: '5px' }}>UTC</span>
        </span>
      ),
      dataIndex: 'effectiveFrom',
      align: 'left',
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (effectiveFrom) => {
        if (effectiveFrom && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(effectiveFrom)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: (
        <span>
          END DATE
          <span style={{ fontSize: '12px', color: 'grey', marginLeft: '5px' }}>UTC</span>
        </span>
      ),
      dataIndex: 'effectiveTo',
      align: 'left',
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (effectiveTo) => {
        if (effectiveTo && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(effectiveTo)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'ACTION',
      dataIndex: 'status',
      align: 'center',
      render: (status, record) => (
        <span className="d-flex" style={{ fontSize: '20px', justifyContent: 'center' }}>
          <Tooltip placement="top" title="Delete">
            <Icon
              type="delete"
              style={{ cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); handleDeleteExchange(record); }}
            />
          </Tooltip>
          <Tooltip placement="top" title="Edit">
            <Icon
              type="edit"
              className="ml-3"
              style={{ cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); handleEditExchange(record); }}

            />
          </Tooltip>
        </span>
      )
      ,
    },
  ];

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <>
      <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal" style={{ fontSize: '20px' }}>
        <Menu.Item key="acquirer">
          <SpTab
            to="/rate"
            selected={current === 'acquirer'}
          >
            Acquirer Rates
          </SpTab>
        </Menu.Item>
        <Menu.Item key="issuerrate">
          <SpTab
            to="/rate/issuerrate"
            selected={current === 'issuerrate'}
          >
            Issuer Rates
          </SpTab>
        </Menu.Item>
        <Menu.Item key="exchangerate">
          <SpTab
            to="/rate/exchangerate"
            selected={current === 'exchangerate'}
          >
            Exchange Rates
          </SpTab>
        </Menu.Item>
      </Menu>
      <Card>
        {exchangeRate && exchangeRate.length > 0 && (
        <Row className="my-2">
          <div className="d-flex justify-content-end">
            <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
              <Col>
                <SpButton
                  type="secondary"
                  shape="round"
                  ghost
                  className="mr-3"
                  onClick={() => setAddRateModal(true)}
                >
                  {'\u002B'}&nbsp;Add Exchange Rate
                </SpButton>
              </Col>
            </RBAC>
          </div>
        </Row>
        )}

        {/* ADD RATE MODAL */}
        {addRateModal && (
          <AddExchangeRate
            visible={addRateModal}
            submitting={submitting}
            selectedAccount={selectedAccount}
            close={() => setAddRateModal(false)}
            submit={addNewExchangeRate}
          />
        )}

        {/* EDIT RATE MODAL */}
        {editExchangeModal && (
          <EditExchangeRate
            visible={editExchangeModal}
            submitting={submitting}
            exchangeRate={selectedExchangeRate}
            close={() => setEditExchangeModal(false)}
            submit={editExchangeRate}
          />
        )}

        {/* Delete RATE MODAL */}
        {deleteModal && (
          <DeleteExchangeRateModal
            visible={deleteModal}
            exchangeRateGid={selectedExchangeRateGid}
            close={() => setDeleteModal(false)}
            submit={deleteSelectedExchangeRate}
          />
        )}
        {exchangeRate && exchangeRate.length > 0
          ? (
            <RBAC
              fallback={(
                <Table
                  columns={columns}
                  rowKey={(record) => record.gid}
                  dataSource={exchangeRate}
                  pagination={pagination}
                  onChange={handleTableChange}
                />
          )}
              permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}
            >
              <Table
                rowClassName={() => 'ant-table-clickable-row'}
                columns={[...columns]}
                rowKey={(record) => record.gid}
                dataSource={exchangeRate}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </RBAC>
          )
          : (
            <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
              <div
                style={{
                  border: '1px solid #CBCBCB',
                  minHeight: '253px',
                  width: '1014 px',
                  marginTop: '10px',
                  marginBottom: '25px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div>
                  <div className="ml-4">
                    <SpH5 color="#333333">
                      No data
                    </SpH5>
                  </div>
                  <div
                    style={{
                      marginTop: '15px',
                    }}
                  >
                    <SpButton
                      type="secondary"
                      onClick={() => setAddRateModal(true)}
                    >
                      {'\u002B'}&nbsp;Add Rate
                    </SpButton>
                  </div>
                </div>
              </div>
            </RBAC>
          )}

      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  rates: state.issuerrate.content,
  issuer: state.issuer.content,
  category: state.rates.category,
  transferType: state.issuerrate.transferType.content,
  totalElements: state.exchangeRate.totalElements,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
  preserveFilters: state.filterParams.preserveFilters,
  exchangeRate: state.exchangeRate.content,
});

const mapDispatchToProps = (dispatch) => ({
  getExchangeRatesList: param => dispatch(exchangeRateActions.getExchangeRatesList({
    payload: param,
  })),
  deleteExchangeRate: param => dispatch(exchangeRateActions.deleteExchangeRate({
    payload: param,
  })),
  addExchangeRate: param => dispatch(exchangeRateActions.addExchangeRate({
    payload: param,
  })),
  updateExchangeRate: param => dispatch(exchangeRateActions.updateExchangeRate({
    payload: param,
  })),
  fetchIssuer: param => dispatch(issuerActions.fetchIssuer({
    payload: param,
  })),
  fetchRateCategory: param => dispatch(ratesActions.fetchRateCategory({
    payload: param,
  })),
  fetchTransferType: param => dispatch(issuerrateActions.fetchTransferType({
    payload: param,
  })),
  addIssuerRate: param => dispatch(issuerrateActions.addIssuerRate({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(ExchangeRatesList);
