import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Button,
  Icon,
  Alert,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';

import {
  SpFormText,
  LoginFormWrapper,
  LoginWrapper,
} from 'components/DesignKit';

import {
  notificationActions,
} from 'store/actions';

import {
  NOTIFICATION,
} from 'store/actionTypes';

import {
  PAGES,
  MESSAGES,
} from 'appconstants';
import {
  validators,
  validationRegex,
} from 'utils/validationMessages';
import {
  axiosLoginInstance,
  API_END_POINTS,
} from 'api';

import swireLogo from 'imgs/swirepay.png';
import qpayments from 'imgs/Qpayments.jpeg';

type Props = {
  setNotification: Function,
  history: {
    replace: Function,
  },
};

const ResetPassword = (props: Props) => {
  const { setNotification } = props;
  const [, forceUpdate] = useState();
  const email = sessionStorage.getItem('email');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [loading, setLoading] = useState(false);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      confirmPassword: {
        message: validators.resetPassword.validConfirmPassword,
        rule: (val, params) => val === params[0],
      },
      passwordValidate: {
        message: validators.resetPassword.validPassword,
        rule: val => validationRegex.password.test(val),
      },
    },
  }));

  useEffect(() => {
    if (!email) {
      props.history.replace(PAGES.LOGIN);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    setLoading(true);

    try {
      await axiosLoginInstance.post(API_END_POINTS.RESET_PASSWORD, {
        email,
        password,
        confirmationCode,
      });
      props.history.replace(PAGES.LOGIN);
      sessionStorage.removeItem('email');
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.RESET_PASSWORD.SUCCESS,
      });
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex justify-content-center flex-column align-items-center">
      <LoginWrapper>
        <LoginFormWrapper onSubmit={handleSubmit}>
          <div style={{ maxWidth: '350px' }}>
            <div className="d-flex justify-content-center align-items-center">
              <img src={window.location.host.includes('qpaymentz') ? qpayments : swireLogo} alt="" style={{ width: '270px', margin: '32px 0px' }} />
            </div>
            <SpFormText>Set your password</SpFormText>
            <div className="LOGIN">
              <div className="my-3">
                <label htmlFor="" className="font-weight-bold">New Password</label>
                <Input.Password
                  placeholder="Your Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                />
                <div className="text-danger">
                  {simpleValidator.current.message('password', password, 'required|passwordValidate',
                    { messages: { required: validators.resetPassword.newPassword } })}
                </div>
              </div>

              <div className="my-3">
                <label htmlFor="" className="font-weight-bold">Confirm New Password</label>
                <Input.Password
                  placeholder="Your Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                />
                <div className="text-danger">
                  {simpleValidator.current.message('confirm new password', confirmPassword, `required|confirmPassword:${password}`,
                    { messages: { required: validators.resetPassword.confirmPassword } })}
                </div>
              </div>

              <div className="my-3">
                <label htmlFor="" className="font-weight-bold">Verification Code</label>
                <Input.Password
                  placeholder="Verification Code"
                  value={confirmationCode}
                  onChange={(e) => setConfirmationCode(e.target.value)}
                  prefix={<Icon type="key" style={{ color: 'rgba(0,0,0,.25)' }} />}
                />
                <div className="text-danger">
                  {simpleValidator.current.message('confirmation code', confirmationCode, 'required',
                    { messages: { required: validators.resetPassword.verificationCode } })}
                </div>
              </div>
              {
                email && (
                  <Alert
                    message={validators.resetPassword.validMsg}
                    type="success"
                    className="mb-2"
                  />
                )
              }
              <div className="d-flex justify-content-center">
                <Button
                  type="primary"
                  shape="round"
                  className="w-50"
                  htmlType="submit"
                  loading={loading}
                  onClick={handleSubmit}
                >
                  Reset Password
                </Button>
              </div>

              <div className="d-flex justify-content-center mt-3">
                <Button
                  type="link"
                  block
                  onClick={() => props.history.replace(PAGES.LOGIN)}
                >
                  Go to Homepage
                </Button>
              </div>
            </div>
          </div>
        </LoginFormWrapper>
      </LoginWrapper>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

export default connect(null, mapDispatchToProps)(ResetPassword);
