import { NONEVENTSDETAILS } from '../actionTypes';

export const fetchNonEventDetails = (action) => ({
  type: NONEVENTSDETAILS.FETCH,
  payload: action.payload,
});

export const fetchCardDetailsNon = (action) => ({
  type: NONEVENTSDETAILS.CARDS_FETCH,
  payload: action.payload,
});

export const updateNonEvent = (action) => ({
  type: NONEVENTSDETAILS.UPDATE,
  payload: action.payload,
});

export const deactivateNonEvent = (action) => ({
  type: NONEVENTSDETAILS.DEACTIVE,
  payload: action.payload,
});

export const soldOutNonEvent = (action) => ({
  type: NONEVENTSDETAILS.SOLDOUT,
  payload: action.payload,
});

export const resumeSalesNon = (action) => ({
  type: NONEVENTSDETAILS.RESUME_SALES,
  payload: action.payload,
});

export const offlinePaymentsNon = (action) => ({
  type: NONEVENTSDETAILS.OFFLINE_PAYMENTS,
  payload: action.payload,
});

export const fetchNonEventsSlug = (action) => ({
  type: NONEVENTSDETAILS.EVENTS_SLUG_FETCH,
  payload: action.payload,
});

export const fetchOfflineNonEventsPayments = (action) => ({
  type: NONEVENTSDETAILS.FETCH_OFFLINE_PAYMENTS,
  payload: action.payload,
});

export const clearNonEventDetails = (action) => ({
  type: NONEVENTSDETAILS.CLEAR_EVENT_DETAILS,
  payload: action.payload,
});

export const notifyNonSeatedPageDetails = (action) => ({
  type: NONEVENTSDETAILS.PAGE_NOTIFY,
  payload: action.payload,
});

export const notifyNonSeatedPage = (action) => ({
  type: NONEVENTSDETAILS.SEATED_NOTIFY,
  payload: action.payload,
});
