import React, {
  useEffect,
  useState,
  useRef,
  createRef,
} from 'react';
import { connect } from 'react-redux';
import type { ContextRouter } from 'react-router-dom';

import ReactDragListView from 'react-drag-listview';

import {
  Row,
  Col,
  Steps,
  Layout,
  Checkbox,
  Input,
  Select,
  Card,
  Switch,
  Radio,
} from 'antd';
import {
  SpButton,
  SpH5,
  SpText,
  SpError,
  PaymentWrapper,
  PaymentFormWrapper,
} from 'components/DesignKit';
import imageOnError from 'utils/imageOnError';
import { formatNumber, findPhoneNumbersInText } from 'libphonenumber-js';

import {
  nonEventDetailsActions,
  notificationActions,
  nonEventActions,
  accountSlugActions,
  inventoryItemsActions,
  inventoryCategoriesActions,
} from 'store/actions';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { NOTIFICATION } from 'store/actionTypes';

import {
  PAGES,
  COUNTRIES,
  MAX_AMOUNT,
  MESSAGES,
  API_STATUS,
} from 'appconstants';
import formatAmount from 'utils/formatAmount';
import SimpleReactValidator from 'simple-react-validator';
import PaymentClose from 'components/NavIcons/paymentClose';

import getPlaceholder from 'utils/getPaymentPagePlaceholder';
import Loading from 'components/Loading';

import reorderIcon from '../../assets/imgs/reorder-icon.png';
import arrowUp from '../../assets/imgs/arrow-upward.png';
import arrowDown from '../../assets/imgs/arrow-down.png';
import arrowUpDisabled from '../../assets/imgs/arrow-upward-disabled.png';
import arrowDownDisabled from '../../assets/imgs/arrow-down-disabled.png';
import removeNonLayout from '../../assets/imgs/removeNonLayout.png';

import PageDetails from './components/PageDetails';
import AccontSlug from './components/AccontSlug';
import ReviewSeatedPage from './components/ReviewSeatedPage';

const { Step } = Steps;
const { Header } = Layout;
const { Option } = Select;

type Props = ContextRouter & {
  selectedAccount: Object,
  addNewNonEvents: Function,
  fetchNonEventDetails: Function,
  eventDetailsStatus: string,
  eventDetails: Object,
  validator: boolean,
  submitting: boolean,
  loading: boolean,
  history: {
    replace: Function,
  },
  setNotification: Function,
  addSeatedEvent: Boolean,
  accountSlug: Array<Object>,
  fetchAccountSlug: Function,
  fetchInventorySortItems: Function,
  inventoryItems: Array,
  fetchInventorySortCategory: Function,
  sortCategoryList: Array,
};

const AddEvents = (props: Props) => {
  const {
    selectedAccount,
    history,
    addNewNonEvents,
    validator,
    submitting,
    loading,
    eventDetails,
    fetchNonEventDetails,
    eventDetailsStatus,
    setNotification,
    addSeatedEvent,
    accountSlug,
    fetchAccountSlug,
    fetchInventorySortItems,
    inventoryItems,
    fetchInventorySortCategory,
    sortCategoryList,
  } = props;
  const { gid } = props.match.params;
  const GOOGLE_MAPS_API_KEY = 'AIzaSyCLR2kMaQ9AHIa_tqsG48hxA9CZ7ullXds';
  const [, forceUpdate] = useState();
  const pageDetailsRef = createRef();
  const [refArray, setRefArray] = useState([pageDetailsRef]);
  const [current, setCurrent] = useState(0);
  const [title, setTitle] = useState();
  const [content, setContent] = useState();
  const [logo, setLogo] = useState();
  // eslint-disable-next-line no-unused-vars
  const [defaultLocation, setDefaultLocation] = useState(null);
  const [preview, setPreview] = useState();
  const [fileName, setFileName] = useState();
  const [banner, setBanner] = useState();
  const [preview1, setPreview1] = useState();
  const [fileName1, setFileName1] = useState();
  const [contactName, setContactName] = useState();
  const [contactUsEmail, setEmail] = useState();
  const [contactPhone, setContactPhone] = useState();
  const [phoneNumberCode, setPhoneCode] = useState();
  const [street, setStreet] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [zipcode, setZipcode] = useState();
  const [country, setCountry] = useState(selectedAccount?.country?.id);
  // eslint-disable-next-line no-unused-vars
  const [show, setShow] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [receiptNote, setReceiptNote] = useState('');
  const [phoneFormate, setPhoneFormate] = useState();
  const [primaryColor, setColorCode] = useState('#007bff');
  const [serviceFeeEnabled, setServiceFeeEnabled] = useState(false);
  const [serviceFeeRate, setServiceFeeRate] = useState(null);
  const [serviceFee, setServiceFee] = useState(null);
  const [supportOfflinePayment, setSupportOfflinePayment] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [enableTip, setEnableTip] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isTipOptional, setIsTipOptional] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [firstOption, setFirstOption] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [secondOption, setSecondOption] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [thirdOption, setThirdOption] = useState(null);
  const currencyName = (selectedAccount && (selectedAccount.currency) && (selectedAccount.currency.name));
  const [designation, setDesignation] = useState('');

  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const [venueDetails, setVenueDetails] = useState();
  const [eventDate, setEventDate] = useState('');
  const [slugGid, setSlugGid] = useState(null);
  const [slugLink, setSlugLink] = useState('');
  const [slugFlag, setSlugFlag] = useState(false);
  const [qrGeneration, setqrGeneration] = useState('ORDER_BASED');
  const [productArrays, setProductArrays] = useState([
    {
      eventPageProductType: '',
      eventPageProductName: '',
      itemGids: [],
      inventoryCategoryGids: [],
    },
  ]);

  const pageData = {
    title,
    content,
    logo,
    preview,
    fileName,
    banner,
    preview1,
    fileName1,
    street,
    state,
    city,
    zipcode,
    country,
    contactName,
    contactUsEmail,
    contactPhone,
    phoneFormate,
    phoneNumberCode,
    eventDate,
    receiptNote,
    slugGid,
    slugLink,
    qrGeneration,
    designation,
    supportOfflinePayment,
  };

  const newSlug = () => {
    const currentPage = 1;
    const size = 1000;
    const params = '&sortBy=createdAt&direction=DESC';
    fetchAccountSlug({ currentPage, params, size });
    const sortParam = '&sortBy=name&direction=ASC';
    fetchInventorySortCategory({ sortParam });
  };

  useEffect(() => {
    if (gid) {
      fetchNonEventDetails(gid);
    }
    newSlug();
    const currentPage = 1;
    const sortBy = 'sortOrder';
    const direction = 'ASC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParams = '';
    fetchInventorySortItems({ currentPage, sortParams, filterParams });
  }, []);

  const getVenueList = async () => {
    try {
      const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.SEATED_VENUE}`);
      setVenueDetails(entity.content);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: 'Failed to load venue details',
      });
    }
  };

  useEffect(() => {
    getVenueList();
  }, []);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      minLimit: {
        message: 'Stock limit should be greater than zero',
        rule: val => val >= 1,
      },
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= (typeof val === 'string' ? Number(val && val.replace(/,/g, '')) : val) && (typeof val === 'string' ? Number(val && val.replace(/,/g, '')) : val) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= (typeof val === 'string' ? Number(val && val.replace(/,/g, '')) : val) && (typeof val === 'string' ? Number(val && val.replace(/,/g, '')) : val) <= MAX_AMOUNT.LIMIT_USD),
      },
      minAmount: {
        message: 'Min amount should be greater than zero',
        rule: val => Number(val && val.replace(/,/g, '')) >= 1,
      },
      optionalMinAmount: {
        message: 'Min amount should be greater than or equal to zero',
        rule: val => Number(val && val.replace(/,/g, '')) >= 0,
      },
      minValidAmount: {
        message: 'Max amount should be greater than zero',
        rule: val => val && Number(val) >= 1,
      },
      maxAmount: {
        message: 'Max amount should be greater than Min amount',
        rule: (val, params) => val && (Number(val) > Number(params[0])),
      },
      tipAmount: {
        message: 'Tip should be > 0 and <= to 100',
        rule: val => Number(val && val.replace(/,/g, '')) > 0 && Number(val && val.replace(/,/g, '')) <= 100,
      },
      tipValidation1: {
        message: 'Please enter different tip value ',
        rule: (val, params) => val && (Number(params[0] && params[0].replace(/,/g, '')) !== Number(val && val.replace(/,/g, ''))),
      },
      tipValidation: {
        message: 'Please enter different tip value ',
        rule: (val, params) => val && (Number(params[0] && params[0].replace(/,/g, '')) !== Number(val && val.replace(/,/g, ''))),
      },
    },
  }));

  simpleValidator.current.purgeFields();

  const [productDetailsArray, setProductDetailsArray] = useState([]);


  useEffect(() => {
    if (addSeatedEvent) {
      let listDetails = [];
      if (venueDetails && venueDetails[0] && venueDetails[0].seatedVenueCategories) {
        listDetails = venueDetails && venueDetails[0].seatedVenueCategories;
        for (let i = 0; i < listDetails.length; i += 1) {
          const listObject = {};
          listObject.currencyCode = currencyName;
          listObject.price = null;
          listObject.name = '';
          listObject.category = listDetails[i].name;
          listObject.seatedVenueCategoryGid = listDetails[i].gid;
          listObject.type = 'TICKET';
          listObject.sequenceNumber = listDetails[i].seatedVenueId;
          listObject.required = true;
          listDetails[i].mataData = [];
          listDetails[i].mataData.push(listObject);
        }
      }
      setProductDetailsArray(venueDetails && venueDetails[0] && venueDetails[0].seatedVenueCategories);
    }
  }, [venueDetails, eventDetails]);

  const [customerDetailsArray, setCustomerDetailsArray] = useState([{
    type: 'INPUT_EMAIL',
    label: 'Email',
    isRequired: true,
    checked: 'checked',
    disableRequired: true,
  },
  {
    type: 'INPUT_PHONE',
    label: 'Phone',
    isRequired: true,
    checked: 'checked',
    disableRequired: true,
  },
  ]);

  const addCustomerDetails = (event) => {
    event.preventDefault();
    simpleValidator.current.hideMessages();
    forceUpdate();
    setCustomerDetailsArray([...customerDetailsArray, {
      type: '',
      label: '',
      required: true,
      checked: 'checked',
      name: '',
      category: '',
    }]);
  };

  const removeCustomerDetails = (j) => {
    const list = [...customerDetailsArray];
    list.splice(j, 1);
    setCustomerDetailsArray(list);
  };

  useEffect(() => {
    if (!validator && redirect) {
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.EVENTS.ADD_SUCCESS,
      });
      history.push(PAGES.NON_EVENTS);
    }
  }, [validator]);

  useEffect(() => {
    if (gid && (eventDetails.gid)) {
      setTitle(eventDetails.title);
      setContent(eventDetails.description);
      setLogo(eventDetails.logo);
      setFileName(eventDetails.logo);
      setBanner(eventDetails.banner);
      setFileName1(eventDetails.banner);
      setPreview(
        <img
          src={`${eventDetails.logo}?time=${new Date().getTime()}`}
          alt=""
          onError={event => imageOnError(event)}
          style={{ width: '100%' }}
        />,
      );
      setPreview1(
        <img
          src={`${eventDetails.banner}?time=${new Date().getTime()}`}
          alt=""
          onError={event => imageOnError(event)}
          style={{ width: '100%' }}
        />,
      );
      setContactName(eventDetails.eventPageSupports[0]?.name);
      setEmail(eventDetails.eventPageSupports[0]?.email);
      const phoneResult = ((eventDetails?.eventPageSupports[0]?.phoneNumber))
        ? findPhoneNumbersInText(eventDetails && eventDetails.eventPageSupports[0]?.phoneNumber) : null;
      if ((phoneResult && phoneResult.length > 0) && phoneResult[0].number) {
        setPhoneCode(`+${phoneResult[0].number.countryCallingCode}`);
        setContactPhone(phoneResult[0].number.nationalNumber);
        const data = formatNumber(phoneResult[0].number.number, 'INTERNATIONAL');
        const formater = data.substr(data.indexOf(' ') + 1);
        setPhoneFormate(formater);
      }
      setReceiptNote(eventDetails.description);
      setStreet(eventDetails?.address?.street);
      setCity(eventDetails?.address?.city);
      setState(eventDetails?.address?.state);
      setZipcode(eventDetails?.address?.postalCode);
      setqrGeneration(eventDetails?.qrGeneration);
      setEventDate(eventDetails?.eventDate);
      setDesignation(eventDetails?.eventPageSupports[0]?.designation);
      setSupportOfflinePayment(eventDetails?.supportOfflinePayment);
      const tempProducts = eventDetails?.eventPageProducts;
      const tempPrdList = [];
      let tempPrdObj = {};
      for (let i = 0; i < tempProducts.length; i += 1) {
        tempPrdObj.eventPageProductType = tempProducts[i].eventPageProductType;
        tempPrdObj.eventPageProductName = tempProducts[i].eventPageProductName;
        let inventoryCategoryGids = [];
        let itemGids = [];
        for (let j = 0; j < tempProducts[i]?.inventoryCategories?.length; j += 1) {
          inventoryCategoryGids.push(tempProducts[i].inventoryCategories[j].gid);
        }
        for (let k = 0; k < tempProducts[i]?.items?.length; k += 1) {
          itemGids.push(tempProducts[i].items[k].gid);
        }
        tempPrdObj.inventoryCategoryGids = inventoryCategoryGids;
        tempPrdObj.itemGids = itemGids;
        tempPrdList.push(tempPrdObj);
        inventoryCategoryGids = [];
        itemGids = [];
        tempPrdObj = {};
      }
      setProductArrays(tempPrdList);
      const custList = eventDetails?.eventPageCustomInfos;
      const tempCstList = [];
      let tempCstObj = {};
      for (let i = 0; i < custList.length; i += 1) {
        tempCstObj.type = custList[i].inputFieldType;
        tempCstObj.label = custList[i].inputFieldLabel;
        tempCstObj.isRequired = custList[i].isOptional;
        tempCstObj.checked = 'checked';
        tempCstObj.disableRequired = false;
        tempCstList.push(tempCstObj);
        tempCstObj = {};
      }
      setCustomerDetailsArray(tempCstList);
    }
  }, [eventDetailsStatus, venueDetails]);

  useEffect(() => {
    if (current === 3 && !submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  useEffect(() => {
    setRefArray(existingElements => (
      [...existingElements]
    ));
  }, []);

  const steps = [
    {
      title: 'Event Info',
    },
    {
      title: 'Product Info',
    },
    {
      title: 'Custom Info',
    },
    {
      title: 'Slug Creation',
    },
    {
      title: 'Review',
    },
  ];

  const next = (event) => {
    const validationArray = [];
    const valuesArray = [];
    if (current === 0) {
      validationArray.push(refArray[0].current.validate());
      valuesArray.push(refArray[0].current.getValues());
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setCurrent(current + 1);
      setTitle(valuesArray[0].title);
      setContent(valuesArray[0].content);
      setLogo(valuesArray[0].logo ? valuesArray[0].logo : 'logo_test');
      setPreview(valuesArray[0].preview);
      setFileName(valuesArray[0].fileName);
      setBanner(valuesArray[0].banner ? valuesArray[0].banner : 'logo_test');
      setPreview1(valuesArray[0].preview1);
      setFileName1(valuesArray[0].fileName1);
      setStreet(valuesArray[0].street);
      setState(valuesArray[0].state);
      setCity(valuesArray[0].city);
      setZipcode(valuesArray[0].zipcode);
      setCountry(valuesArray[0].country);
      setContactName(valuesArray[0].contactName);
      setEmail(valuesArray[0].contactUsEmail);
      setContactPhone(valuesArray[0].contactPhone);
      setPhoneFormate(valuesArray[0].phoneFormate);
      setPhoneCode(valuesArray[0].phoneNumberCode);
      setReceiptNote(valuesArray[0].receiptNote ? valuesArray[0].receiptNote : null);
      setEventDate(valuesArray[0].eventDate ? valuesArray[0].eventDate : null);
      setColorCode(valuesArray[0].primaryColor);
      setServiceFeeEnabled(valuesArray[0].serviceFeeEnabled);
      setSupportOfflinePayment(valuesArray[0].supportOfflinePayment);
      setqrGeneration(valuesArray[0].qrGeneration);
      setDesignation(valuesArray[0].designation);
      if (valuesArray[0].serviceFeeRate) {
        const feerate = Number((valuesArray[0].serviceFeeRate).replace(/[^\d]/g, ''));
        setServiceFeeRate(feerate);
      }
      if (valuesArray[0].serviceFee) {
        const fee = Number((valuesArray[0].serviceFee).replace(/[^\d]/g, ''));
        setServiceFee(fee);
      }
      if (valuesArray[0].serviceFeeEnabled === false) {
        setServiceFeeRate(null);
        setServiceFee(null);
      }
      simpleValidator.current.hideMessages();
    } else if (current === 1) {
      event.preventDefault();
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setCurrent(current + 1);
      simpleValidator.current.hideMessages();
      forceUpdate();
    } else if (current === 2) {
      event.preventDefault();
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setCurrent(current + 1);
    } else if (current === 3) {
      valuesArray.push(refArray[0].current.getValues());
      setSlugGid(valuesArray[0].slugGid);
      setSlugLink(valuesArray[0].slugLink);
      setCurrent(current + 1);
    } else if (current === 4) {
      setDisableButton(true);
      const contactNumber = `${pageData.phoneNumberCode}${pageData.contactPhone}`;
      const custList = [];
      let custObj = {};
      for (let i = 0; i < customerDetailsArray.length; i += 1) {
        custObj.inputFieldType = customerDetailsArray[i].type;
        custObj.inputFieldLabel = customerDetailsArray[i].label;
        custObj.isOptional = !customerDetailsArray[i].required;
        custList.push(custObj);
        custObj = {};
      }
      const payload = {
        title: pageData.title,
        description: pageData.receiptNote,
        logo: pageData.logo,
        banner: pageData.banner,
        bannerType: 'VERTICAL',
        eventDate: pageData.eventDate,
        qrGeneration: pageData.qrGeneration,
        supportOfflinePayment,
        address: {
          street: pageData.street,
          city: pageData.city,
          state: pageData.state,
          countryCode: selectedAccount?.country?.alpha2,
          postalCode: pageData.zipcode,
        },
        eventPageSupports: [
          {
            name: pageData.contactName,
            email: pageData.contactUsEmail,
            phoneNumber: contactNumber,
            designation,
          },
        ],
        eventPageProducts: productArrays,
        eventPageCustomInfos: custList,
        taxRatesGids: [],
        serviceFeeGids: [],
        eventPageTips: [],
      };
      addNewNonEvents(payload);
      setRedirect(true);
    }
  };

  const withoutSlug = () => {
    setSlugFlag(true);
    next();
  };

  const prev = (event) => {
    event.preventDefault();
    setDisableButton(false);
    setSlugFlag(false);
    setCurrent(current - 1);
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const dragdata = [...customerDetailsArray];
      const item = dragdata.splice(fromIndex, 1)[0];
      dragdata.splice(toIndex, 0, item);
      setCustomerDetailsArray(dragdata);
    },
    nodeSelector: 'div',
    handleSelector: 'p',
    lineClassName: 'global-drag-line',
  };

  const onArrowUp = (fromIndex) => {
    const dragdata = [...customerDetailsArray];
    const item = dragdata.splice(fromIndex, 1)[0];
    const toIndex = fromIndex - 1;
    dragdata.splice(toIndex, 0, item);
    setCustomerDetailsArray(dragdata);
  };

  const onArrowDown = (fromIndex) => {
    const dragdata = [...customerDetailsArray];
    const item = dragdata.splice(fromIndex, 1)[0];
    const toIndex = fromIndex + 1;
    dragdata.splice(toIndex, 0, item);
    setCustomerDetailsArray(dragdata);
  };

  if (loading && redirect) {
    return <Loading />;
  }

  const updateLayout = (val) => {
    if (!gid) {
      if (val === 'noLayout') {
        setCustomerDetailsArray([{
          type: 'INPUT_EMAIL',
          label: '',
          required: true,
          checked: 'checked',
          disableRequired: true,
          name: '',
          category: '',
        },
        {
          type: 'INPUT_PHONE',
          label: '',
          required: true,
          checked: 'checked',
          disableRequired: true,
          name: '',
          category: '',
        }]);
      } else if (val !== 'noLayout') {
        setCustomerDetailsArray([]);
      }
    }
  };

  return (
    <>
      {
        (gid && eventDetailsStatus === API_STATUS.SUCCESS) && (
          <Layout style={{ marginLeft: '0px', minHeight: '0px' }}>
            <Header style={{ color: '#fff', fontSize: '20px', background: '#18294c !important' }}>
              <Row type="flex" justify="space-between">
                <Col className="d-flex align-items-center">
                  Clone a Non Seated event
                </Col>
                <Col className="d-flex align-items-center justify-content-end">
                  <span style={{ cursor: 'pointer' }} onClick={() => history.push(PAGES.NON_EVENTS)}>
                    <PaymentClose />
                  </span>
                </Col>
              </Row>
            </Header>
            <PaymentWrapper style={{ background: current === 3 ? primaryColor : '' }}>
              <Steps current={current} className="mb-4 paymentPage" style={{ width: '100%' }}>
                {steps.map(item => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              <PaymentFormWrapper style={{ width: current === 3 ? '964px' : '800px' }}>
                <div>
                  {current === 0 && (
                    <PageDetails
                      ref={refArray[0]}
                      selectedAccount={selectedAccount}
                      pageData={pageData}
                      isIndia={isIndia}
                      updateLayout={updateLayout}
                    />
                  )}
                  {current === 1 && (
                    <div>
                      <Card className="mb-4 pt-4">
                        <Row>
                          <Col span={16} push={4} className="mb-3">
                            <SpText fontWeight="600" fontSize="18px">Product Info</SpText>
                          </Col>
                        </Row>
                        {
                          productArrays.map((item, i) => (
                            <div>
                              <Row>
                                <Col span={16} push={4} className="mb-3">
                                  <label htmlFor=""><SpH5>Product type</SpH5></label>
                                  <div>
                                    <Select
                                      className="w-100"
                                      placeholder="Select product type"
                                      value={item.eventPageProductType || 'Select product type'}
                                      onChange={(value) => {
                                        const list = [...productArrays];
                                        list[i].eventPageProductType = value;
                                        setProductArrays(list);
                                      }}
                                    >
                                      <Option key={1} value="TICKET">TICKET</Option>
                                      <Option key={2} value="FOOD">FOOD</Option>
                                      <Option key={3} value="CUSTOM">CUSTOM</Option>
                                    </Select>
                                  </div>
                                  <SpError>
                                    {simpleValidator.current.message('Product type', productArrays[i].eventPageProductType, 'required')}
                                  </SpError>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={16} push={4} className="mb-3">
                                  <label htmlFor=""><SpH5>Input field label</SpH5></label>
                                  <Input
                                    placeholder="Enter the field label"
                                    value={item.eventPageProductName}
                                    maxlength="100"
                                    onChange={(e) => {
                                      const list = [...productArrays];
                                      const { value } = e.currentTarget;
                                      list[i].eventPageProductName = value;
                                      setProductArrays(list);
                                    }}
                                  />
                                  <SpError>
                                    {simpleValidator.current.message('Input field label', productArrays[i].eventPageProductName, 'required')}
                                  </SpError>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={16} push={4} className="mb-3">
                                  <label htmlFor=""><SpH5>Product Category</SpH5></label>
                                  <Select
                                    className="w-100"
                                    mode="multiple"
                                    allowClear
                                    placeholder="Select category"
                                    onChange={(value) => {
                                      const list = [...productArrays];
                                      list[i].inventoryCategoryGids = value;
                                      setProductArrays(list);
                                    }}
                                    value={item.inventoryCategoryGids}
                                    showSearch
                                    optionFilterProp="name"
                                    style={{ width: '100%' }}
                                  >
                                    {sortCategoryList.map((items) => (
                                      <Option key={items.gid} name={items.name} value={items.gid}>
                                        {items.name}
                                      </Option>
                                    ))}
                                  </Select>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={16} push={4} className="mb-3">
                                  <label htmlFor=""><SpH5>Product Items</SpH5></label>
                                  <Select
                                    className="w-100"
                                    mode="multiple"
                                    allowClear
                                    placeholder="Select item"
                                    onChange={(value) => {
                                      const list = [...productArrays];
                                      list[i].itemGids = value;
                                      setProductArrays(list);
                                    }}
                                    value={item.itemGids}
                                    showSearch
                                    optionFilterProp="name"
                                    style={{ width: '100%' }}
                                  >
                                    {inventoryItems.map((items) => (
                                      <Option key={items.gid} name={items.name} value={items.gid}>
                                        {items.name}
                                      </Option>
                                    ))}
                                  </Select>
                                </Col>
                              </Row>
                              {
                                i > 0 ? (
                                  <Row className="mt-4 mb-4">
                                    <Col span={16} push={4}>
                                      <SpButton
                                        type="danger"
                                        style={{ width: '50%' }}
                                        onClick={() => {
                                          const list = [...productArrays];
                                          list.splice(i, 1);
                                          setProductArrays(list);
                                        }}
                                      >
                                        {'\u002B'}&nbsp;Remove Input Field
                                      </SpButton>
                                    </Col>
                                  </Row>
                                ) : (
                                  <></>
                                )
                              }
                            </div>
                          ))
                        }
                        <Row className="mt-4 mb-4">
                          <Col span={16} push={4}>
                            <SpButton
                              type="secondary"
                              style={{ width: '50%' }}
                              onClick={() => {
                                const list = [...productArrays];
                                list.push({
                                  productType: '',
                                  label: '',
                                  itemGids: [],
                                  inventoryCategoryGids: [],
                                });
                                setProductArrays(list);
                              }}
                            >
                              {'\u002B'}&nbsp;Add Input Field
                            </SpButton>
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  )}
                  {current === 2 && (
                    <div>
                      <ReactDragListView
                        {...dragProps}
                      >
                        {
                          customerDetailsArray.map((item, j) => (
                            <div
                              style={{
                                backgroundColor: '#fff',
                                padding: '10px',
                                margin: '25px 0',
                                borderRadius: '5px',
                                cursor: 'pointer',
                              }}
                            >
                              <p>
                                <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                                  <Col className="d-flex align-items-center">
                                    <div className="ml-2"><img src={reorderIcon} alt="" /></div>
                                  </Col>
                                  <Col className="d-flex align-items-center">
                                    {
                                      j === 0 ? (
                                        <div className="mr-2">
                                          <img src={arrowUpDisabled} alt="" />
                                        </div>
                                      ) : (
                                        <div
                                          className="mr-2"
                                          onClick={() => onArrowUp(j)}
                                        >
                                          <img src={arrowUp} alt="" />
                                        </div>
                                      )
                                    }
                                    {
                                      j === (customerDetailsArray.length - 1) ? (
                                        <div
                                          className="mr-2"
                                        >
                                          <img src={arrowDownDisabled} alt="" />
                                        </div>
                                      ) : (
                                        <div
                                          className="mr-2"
                                          onClick={() => onArrowDown(j)}
                                        >
                                          <img src={arrowDown} alt="" />
                                        </div>
                                      )
                                    }

                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Input Field Type</SpH5></label>
                                    <div>
                                      <Select
                                        style={{ width: '50%' }}
                                        placeholder="Select field type"
                                        value={item.type || undefined}
                                        disabled={item.disableRequired ? true : ''}
                                        onChange={(e) => {
                                          const list = [...customerDetailsArray];
                                          list[j].type = e;
                                          setCustomerDetailsArray(list);
                                        }}
                                      >
                                        <Option key={1} value="INPUT_EMAIL">Email</Option>
                                        <Option key={2} value="INPUT_PHONE">Phone</Option>
                                        <Option key={3} value="INPUT_NUMBER">Number</Option>
                                        <Option key={4} value="INPUT_ALPHA_NUMERIC">Alpha Numeric</Option>
                                        <Option key={5} value="INPUT_SINGLE_LINE_TEXT">Single Line Text</Option>
                                        <Option key={6} value="PROMOTIONAL_CODES">Promotional Code</Option>
                                      </Select>
                                    </div>
                                    <SpError>
                                      {simpleValidator.current.message(`type${j + 1}`, customerDetailsArray[j].type, 'required',
                                        { messages: { required: 'Please select field type' } })}
                                    </SpError>
                                  </Col>
                                </Row>
                                <Row className="mt-4 mb-4">
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Input Field Label</SpH5></label>
                                    <Input
                                      placeholder={getPlaceholder(item.type || '')}
                                      value={item.label}
                                      maxlength="100"
                                      onChange={(e) => {
                                        const list = [...customerDetailsArray];
                                        list[j].label = e.currentTarget.value;
                                        list[j].name = e.currentTarget.value;
                                        setCustomerDetailsArray(list);
                                      }}
                                    />
                                    <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 100 Characters</SpText>
                                    <SpError>
                                      {simpleValidator.current.message(`Label${j + 1}`, customerDetailsArray[j].label, 'required',
                                        { messages: { required: 'The label field is required' } })}
                                    </SpError>
                                  </Col>
                                </Row>
                                <Row className="mt-4 mb-4">
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Is Optional</SpH5></label>
                                    <Checkbox
                                      className="ml-4"
                                      disabled={item.disableRequired || false}
                                      checked={!(item.required)}
                                      onChange={({ target: { checked } }) => {
                                        const list = [...customerDetailsArray];
                                        list[j].required = !checked;
                                        if (checked) {
                                          list[j].checked = '';
                                        } else if (!checked) {
                                          list[j].checked = 'checked';
                                        }
                                        setCustomerDetailsArray(list);
                                      }}
                                    />
                                  </Col>
                                </Row>
                                {
                                  (!item.disableRequired) ? (
                                    <Row className="mt-4 mb-4">
                                      <Col span={16} push={4}>
                                        <SpButton
                                          type="secondary"
                                          style={{ width: '50%' }}
                                          onClick={() => removeCustomerDetails(j)}
                                        >
                                          {'\u2212'}&nbsp;Remove Input Field
                                        </SpButton>
                                      </Col>
                                    </Row>
                                  ) : ''
                                }
                              </p>
                            </div>
                          ))
                        }
                      </ReactDragListView>
                      <Row className="mt-4">
                        <Col span={16} push={8}>
                          <SpButton
                            type="secondary"
                            style={{ width: '50%' }}
                            onClick={addCustomerDetails}
                          >
                            {'\u002B'}&nbsp;Add Another Input Field
                          </SpButton>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {
                    current === 3 && (
                      <AccontSlug
                        ref={refArray[0]}
                        selectedAccount={selectedAccount}
                        pageData={pageData}
                        isIndia={isIndia}
                        accountSlug={accountSlug}
                        submitting={submitting}
                        newSlug={newSlug}
                        withoutSlug={withoutSlug}
                      />
                    )
                  }
                  {
                    current === 4 && (
                      <ReviewSeatedPage
                        pageData={pageData}
                        selectedAccount={selectedAccount}
                        customerDetailsArray={customerDetailsArray}
                        productArrays={productArrays}
                        inventoryItems={inventoryItems}
                        sortCategoryList={sortCategoryList}
                      />
                    )
                  }
                </div>
                <div className="d-flex justify-content-end mt-auto mr-4">
                  {current > 0 && (
                    <SpButton type="secondary" className="mr-4" onClick={prev}>
                      Back
                    </SpButton>
                  )}
                  {current < steps.length - 1 && (
                    <SpButton type="primary" onClick={next}>
                      Next
                    </SpButton>
                  )}
                  {current === steps.length - 1 && (
                    <SpButton type="primary" onClick={next} disabled={disableButton}>
                      Create
                    </SpButton>
                  )}
                </div>
              </PaymentFormWrapper>
            </PaymentWrapper>
          </Layout>
        )
      }
      {
        (!gid) && (
          <Layout style={{ marginLeft: '0px', minHeight: '0px' }}>
            <Header style={{ color: '#fff', fontSize: '20px', background: '#18294c !important' }}>
              <Row type="flex" justify="space-between">
                <Col className="d-flex align-items-center">
                  Create a Non Seated event
                </Col>
                <Col className="d-flex align-items-center justify-content-end">
                  <span style={{ cursor: 'pointer' }} onClick={() => history.push(PAGES.NON_EVENTS)}>
                    <PaymentClose />
                  </span>
                </Col>
              </Row>
            </Header>
            <PaymentWrapper style={{ background: current === 3 ? primaryColor : '' }}>
              <Steps current={current} className="mb-4 paymentPage" style={{ width: '100%' }}>
                {steps.map(item => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              <PaymentFormWrapper style={{ width: current === 3 ? '964px' : '800px' }}>
                <div>
                  {current === 0 && (
                    <PageDetails
                      ref={refArray[0]}
                      selectedAccount={selectedAccount}
                      pageData={pageData}
                      isIndia={isIndia}
                      updateLayout={updateLayout}
                    />
                  )}
                  {current === 1 && (
                    <div>
                      <Card className="mb-4 pt-4">
                        <Row>
                          <Col span={16} push={4} className="mb-3">
                            <SpText fontWeight="600" fontSize="18px">Product Info</SpText>
                          </Col>
                        </Row>
                        {
                          productArrays.map((item, i) => (
                            <div>
                              <Row>
                                <Col span={16} push={4} className="mb-3">
                                  <label htmlFor=""><SpH5>Product type</SpH5></label>
                                  <div>
                                    <Select
                                      className="w-100"
                                      placeholder="Select product type"
                                      value={item.eventPageProductType || 'Select product type'}
                                      onChange={(value) => {
                                        const list = [...productArrays];
                                        list[i].eventPageProductType = value;
                                        setProductArrays(list);
                                      }}
                                    >
                                      <Option key={1} value="TICKET">TICKET</Option>
                                      <Option key={2} value="FOOD">FOOD</Option>
                                      <Option key={3} value="CUSTOM">CUSTOM</Option>
                                    </Select>
                                  </div>
                                  <SpError>
                                    {simpleValidator.current.message('Product type', productArrays[i].eventPageProductType, 'required')}
                                  </SpError>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={16} push={4} className="mb-3">
                                  <label htmlFor=""><SpH5>Input field label</SpH5></label>
                                  <Input
                                    placeholder="Enter the field label"
                                    value={item.eventPageProductName}
                                    maxlength="100"
                                    onChange={(e) => {
                                      const list = [...productArrays];
                                      const { value } = e.currentTarget;
                                      list[i].eventPageProductName = value;
                                      setProductArrays(list);
                                    }}
                                  />
                                  <SpError>
                                    {simpleValidator.current.message('Input field label', productArrays[i].eventPageProductName, 'required')}
                                  </SpError>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={16} push={4} className="mb-3">
                                  <label htmlFor=""><SpH5>Product Category</SpH5></label>
                                  <Select
                                    className="w-100"
                                    mode="multiple"
                                    allowClear
                                    placeholder="Select category"
                                    onChange={(value) => {
                                      const list = [...productArrays];
                                      list[i].inventoryCategoryGids = value;
                                      setProductArrays(list);
                                    }}
                                    value={item.inventoryCategoryGids}
                                    showSearch
                                    optionFilterProp="name"
                                    style={{ width: '100%' }}
                                  >
                                    {sortCategoryList.map((items) => (
                                      <Option key={items.gid} name={items.name} value={items.gid}>
                                        {items.name}
                                      </Option>
                                    ))}
                                  </Select>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={16} push={4} className="mb-3">
                                  <label htmlFor=""><SpH5>Product Items</SpH5></label>
                                  <Select
                                    className="w-100"
                                    mode="multiple"
                                    allowClear
                                    placeholder="Select item"
                                    onChange={(value) => {
                                      const list = [...productArrays];
                                      list[i].itemGids = value;
                                      setProductArrays(list);
                                    }}
                                    value={item.itemGids}
                                    showSearch
                                    optionFilterProp="name"
                                    style={{ width: '100%' }}
                                  >
                                    {inventoryItems.map((items) => (
                                      <Option key={items.gid} name={items.name} value={items.gid}>
                                        {items.name}
                                      </Option>
                                    ))}
                                  </Select>
                                </Col>
                              </Row>
                              {
                                i > 0 ? (
                                  <Row className="mt-4 mb-4">
                                    <Col span={16} push={4}>
                                      <SpButton
                                        type="danger"
                                        style={{ width: '50%' }}
                                        onClick={() => {
                                          const list = [...productArrays];
                                          list.splice(i, 1);
                                          setProductArrays(list);
                                        }}
                                      >
                                        {'\u002B'}&nbsp;Remove Input Field
                                      </SpButton>
                                    </Col>
                                  </Row>
                                ) : (
                                  <></>
                                )
                              }
                            </div>
                          ))
                        }
                        <Row className="mt-4 mb-4">
                          <Col span={16} push={4}>
                            <SpButton
                              type="secondary"
                              style={{ width: '50%' }}
                              onClick={() => {
                                const list = [...productArrays];
                                list.push({
                                  productType: '',
                                  label: '',
                                  itemGids: [],
                                  inventoryCategoryGids: [],
                                });
                                setProductArrays(list);
                              }}
                            >
                              {'\u002B'}&nbsp;Add Input Field
                            </SpButton>
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  )}
                  {current === 2 && (
                    <div>
                      <ReactDragListView
                        {...dragProps}
                      >
                        {
                          customerDetailsArray.map((item, j) => (
                            <div
                              style={{
                                backgroundColor: '#fff',
                                padding: '10px',
                                margin: '25px 0',
                                borderRadius: '5px',
                                cursor: 'pointer',
                              }}
                            >
                              <p>
                                <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                                  <Col className="d-flex align-items-center">
                                    <div className="ml-2"><img src={reorderIcon} alt="" /></div>
                                  </Col>
                                  <Col className="d-flex align-items-center">
                                    {
                                      j === 0 ? (
                                        <div className="mr-2">
                                          <img src={arrowUpDisabled} alt="" />
                                        </div>
                                      ) : (
                                        <div
                                          className="mr-2"
                                          onClick={() => onArrowUp(j)}
                                        >
                                          <img src={arrowUp} alt="" />
                                        </div>
                                      )
                                    }
                                    {
                                      j === (customerDetailsArray.length - 1) ? (
                                        <div
                                          className="mr-2"
                                        >
                                          <img src={arrowDownDisabled} alt="" />
                                        </div>
                                      ) : (
                                        <div
                                          className="mr-2"
                                          onClick={() => onArrowDown(j)}
                                        >
                                          <img src={arrowDown} alt="" />
                                        </div>
                                      )
                                    }

                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Input Field Type</SpH5></label>
                                    <div>
                                      <Select
                                        style={{ width: '50%' }}
                                        placeholder="Select field type"
                                        value={item.type || undefined}
                                        disabled={item.disableRequired ? true : ''}
                                        onChange={(e) => {
                                          const list = [...customerDetailsArray];
                                          list[j].type = e;
                                          setCustomerDetailsArray(list);
                                        }}
                                      >
                                        <Option key={1} value="INPUT_EMAIL">Email</Option>
                                        <Option key={2} value="INPUT_PHONE">Phone</Option>
                                        <Option key={3} value="INPUT_NUMBER">Number</Option>
                                        <Option key={4} value="INPUT_ALPHA_NUMERIC">Alpha Numeric</Option>
                                        <Option key={5} value="INPUT_SINGLE_LINE_TEXT">Single Line Text</Option>
                                        <Option key={6} value="PROMOTIONAL_CODES">Promotional Code</Option>
                                      </Select>
                                    </div>
                                    <SpError>
                                      {simpleValidator.current.message(`type${j + 1}`, customerDetailsArray[j].type, 'required',
                                        { messages: { required: 'Please select field type' } })}
                                    </SpError>
                                  </Col>
                                </Row>
                                <Row className="mt-4 mb-4">
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Input Field Label</SpH5></label>
                                    <Input
                                      placeholder={getPlaceholder(item.type || '')}
                                      value={item.label}
                                      maxlength="100"
                                      onChange={(e) => {
                                        const list = [...customerDetailsArray];
                                        list[j].label = e.currentTarget.value;
                                        list[j].name = e.currentTarget.value;
                                        list[j].category = e.currentTarget.value;
                                        setCustomerDetailsArray(list);
                                      }}
                                    />
                                    <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 100 Characters</SpText>
                                    <SpError>
                                      {simpleValidator.current.message(`Label${j + 1}`, customerDetailsArray[j].label, 'required',
                                        { messages: { required: 'The label field is required' } })}
                                    </SpError>
                                  </Col>
                                </Row>
                                <Row className="mt-4 mb-4">
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Is Optional</SpH5></label>
                                    <Checkbox
                                      className="ml-4"
                                      disabled={item.disableRequired || false}
                                      checked={!(item.required)}
                                      onChange={({ target: { checked } }) => {
                                        const list = [...customerDetailsArray];
                                        list[j].required = !checked;
                                        if (checked) {
                                          list[j].checked = '';
                                        } else if (!checked) {
                                          list[j].checked = 'checked';
                                        }
                                        setCustomerDetailsArray(list);
                                      }}
                                    />
                                  </Col>
                                </Row>
                                {
                                  (!item.disableRequired) ? (
                                    <Row className="mt-4 mb-4">
                                      <Col span={16} push={4}>
                                        <SpButton
                                          type="secondary"
                                          style={{ width: '50%' }}
                                          onClick={() => removeCustomerDetails(j)}
                                        >
                                          {'\u2212'}&nbsp;Remove Input Field
                                        </SpButton>
                                      </Col>
                                    </Row>
                                  ) : ''
                                }
                              </p>
                            </div>
                          ))
                        }
                      </ReactDragListView>
                      <Row className="mt-4">
                        <Col span={16} push={8}>
                          <SpButton
                            type="secondary"
                            style={{ width: '50%' }}
                            onClick={addCustomerDetails}
                          >
                            {'\u002B'}&nbsp;Add Another Input Field
                          </SpButton>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {
                    current === 3 && (
                      <AccontSlug
                        ref={refArray[0]}
                        selectedAccount={selectedAccount}
                        pageData={pageData}
                        isIndia={isIndia}
                        accountSlug={accountSlug}
                        submitting={submitting}
                        newSlug={newSlug}
                        withoutSlug={withoutSlug}
                      />
                    )
                  }
                  {
                    current === 4 && (
                      <ReviewSeatedPage
                        pageData={pageData}
                        selectedAccount={selectedAccount}
                        customerDetailsArray={customerDetailsArray}
                        productArrays={productArrays}
                        inventoryItems={inventoryItems}
                        sortCategoryList={sortCategoryList}
                      />
                    )
                  }
                </div>
                <div className="d-flex justify-content-end mt-auto mr-4">
                  {current > 0 && (
                    <SpButton type="secondary" className="mr-4" onClick={prev}>
                      Back
                    </SpButton>
                  )}
                  {current < steps.length - 1 && (
                    <SpButton type="primary" onClick={next}>
                      Next
                    </SpButton>
                  )}
                  {current === steps.length - 1 && (
                    <SpButton type="primary" onClick={next} disabled={disableButton}>
                      Create
                    </SpButton>
                  )}
                </div>
              </PaymentFormWrapper>
            </PaymentWrapper>
          </Layout>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  selectedAccount: state.account.selectedAccount,
  eventDetails: state.nonEventDetails,
  addSeatedEvent: state.eventDetails.addSeatedEvent,
  eventDetailsStatus: state.nonEventDetails.eventDetailsStatus,
  accountSlug: state.accountSlug.content,
  inventoryItems: state.inventoryItems.sortItemsList.content ? state.inventoryItems.sortItemsList.content : [],
  sortCategoryList: state.inventoryCategories.sortCategoryList.content ? state.inventoryCategories.sortCategoryList.content : [],
});

const mapDispatchToProps = (dispatch) => ({
  addNewNonEvents: param => dispatch(nonEventActions.addNewNonEvents({
    payload: param,
  })),
  fetchNonEventDetails: gid => dispatch(nonEventDetailsActions.fetchNonEventDetails({
    payload: gid,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchAccountSlug: param => dispatch(accountSlugActions.fetchAccountSlug({
    payload: param,
  })),
  fetchInventorySortItems: param => dispatch(inventoryItemsActions.fetchInventorySortItems({
    payload: param,
  })),
  fetchInventorySortCategory: param => dispatch(inventoryCategoriesActions.fetchInventorySortCategory({
    payload: param,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEvents);
