// @flow
import React from 'react';
import { Modal } from 'antd';
import { SpH5, SpButton } from 'components/DesignKit';
import DeleteAlertIcon from 'components/NavIcons/deleteAlertIcon';

type Props = {
  visible: boolean,
  close: Function,
  submit: Function,
  exchangeRateGid: String,
};

const DeleteExchangeRateModal = (props: Props) => {
  const {
    visible,
    close,
    submit,
    exchangeRateGid,
  } = props;

  const DeleteVendor = () => {
    submit(exchangeRateGid);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={DeleteVendor} type="danger">Delete</SpButton>,
      ]}
      title="Delete Contact"
    >
      <div className="px-4">
        <div className="mt-1 mb-2">Are you sure you want to delete this Exchange Rate?</div>
        <SpH5 className="pb-3">
          <div>
            <DeleteAlertIcon />
            <span className="ml-2">This action cannot be undone.</span>
          </div>
          {/* <div>{fundingSourceDetails.name || <>&#8211;</>}</div> */}
        </SpH5>
      </div>
    </Modal>
  );
};

// $FlowFixMe
export default DeleteExchangeRateModal;
