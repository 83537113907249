import { ACCOUNT_SESSION } from '../actionTypes';

export const fetchAccountSession = (action) => ({
  type: ACCOUNT_SESSION.FETCH,
  payload: action.payload,
});
export const fetchAccountSessionInvoices = (payload) => ({
  type: ACCOUNT_SESSION.FETCH_INVOICES,
  payload,
});
export const fetchAccountSessionInvoiceDetails = (action) => ({
  type: ACCOUNT_SESSION.FETCH_VIEW_INVOICES,
  payload: action.payload,
});
