// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
} from 'antd';
import { useLocation } from 'react-router-dom';
import { createSelector } from 'reselect';
import _get from 'lodash.get';

import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';

import getVendorType from 'utils/getVendorType';
import {
  SpText,
  SpH5,
  SpButton,
  SpForm,
  SpStatusTag,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import TimelineBar from 'components/TimelineBar';
import { transfersDetailsActions, transfersActions } from 'store/actions';
import {
  PAGES,
  ROLES,
  API_STATUS,
} from 'appconstants';
import RBAC from 'components/rbac';

import TransferType from 'components/TransferDetails';
import getTransferBackground from 'utils/getTransferBackground';
import getTransferStatus from 'utils/getTransferStatus';
import ConfirmOtp from './components/ConfirmOtp';
import { cancelTransferInDetails } from 'store/sagas/transfers';

type Props = {
  fetchTransferDetails: Function,
  fetchTransferTimeline: Function,
  notifyTransferDetails: Function,
  confirmTransferOtp: Function,
  cancelTransferInDetails:Function,
  transferTimeline: Array<Object>,
  transfersDetails: Object,
  loading: boolean,
  test: boolean,
  match: Object,
  params: Object,
  selectedAccount: Object,
  history: {
    push: Function,
  };
  transferDetailsStatus: string,
  displayAmount: Object,
  validator: boolean,
  submitting: boolean,
}

const amountFilter = (transfersDetails) => transfersDetails;

const displayAmountSelector = createSelector(
  [amountFilter],
  (transfersDetails) => ({
    prefix: _get(transfersDetails, 'currency.prefix', null),
    amountDisplay:
      formatNumber(transfersDetails.amount
        && (transfersDetails.amount / 100).toFixed(transfersDetails.currency.toFixed), transfersDetails.currency),
    amountVoided: formatNumber(transfersDetails.amountVoided
      && (transfersDetails.amountVoided / 100).toFixed(2), transfersDetails.currency),
  }),
);

const TransferDetails = (props: Props) => {
  const {
    fetchTransferDetails,
    fetchTransferTimeline,
    transferTimeline,
    notifyTransferDetails,
    transfersDetails,
    loading,
    test,
    history,
    selectedAccount,
    transferDetailsStatus,
    confirmTransferOtp,
    cancelTransferInDetails,
    validator,
    submitting,
    displayAmount,
  } = props;

  const location = useLocation();
  const [notify, setNotify] = useState(false);
  const { gid } = props.match.params;
  const gidPath = gid.split('-')[1];
  const [transferOtpModal, setTransferOtpModal] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [phoneCode, setPhoneCode] = useState('');
  const [lastFour, setLastFour] = useState('');
  const currencyType = selectedAccount && selectedAccount.currency;

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.PAYMENTS);
    }
  }, []);

  useEffect(() => {
    fetchTransferDetails(gid);
    fetchTransferTimeline(gid);
  }, [location, test, selectedAccount]);

  useEffect(() => {
    if (transfersDetails.confirmationEmail || transfersDetails.confirmationSms) {
      setNotify(true);
    }
  }, [transfersDetails]);

  useEffect(() => {
    if (!validator && redirect) {
      history.push(PAGES.TRANSFERS);
    }
  }, [validator]);

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
    if (selectedAccount && selectedAccount.contactNumber) {
      setLastFour((selectedAccount.contactNumber).slice(-4));
    }
  }, [selectedAccount]);

  const selectedNotify = () => {
    notifyTransferDetails(gid);
  };

  const selectedReceipts = (record) => {
    const id = record;
    window.open(`${PAGES.TRANSFER_RECEIPT}/${id}`);
  };

  const otpTransfer = (otpInfo: Object) => {
    const {
      otpCode,
    } = otpInfo;
    const params = { otpCode };
    setRedirect(true);
    confirmTransferOtp({
      gid,
      params,
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        transfersDetails.gid && transferDetailsStatus === API_STATUS.SUCCESS && (
          <>
            <Card>
              <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                <Col className="d-flex align-items-center">
                  <SpText className="mr-5" fontSize="20px" fontWeight="600">
                    {`${displayAmount.prefix}${displayAmount.amountDisplay}`}
                  </SpText>
                  <SpStatusTag style={{ backgroundColor: getTransferBackground(transfersDetails.status) }}>
                    {getTransferStatus(transfersDetails.status)}
                  </SpStatusTag>
                </Col>
                <Col>
                  {
                    (transfersDetails.status === 'REQUIRES_CONFIRMATION') && (
                      <RBAC permit={
                        [ROLES.OWNER, ROLES.ADMINISTRATOR,
                          ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.ANALYST, ROLES.SUPPORT, ROLES.POS, ROLES.SUPERUSER]
                      }
                      >
                        <SpButton
                          type="secondary"
                          shape="round"
                          className="mr-4"
                          ghost
                          onClick={() => setTransferOtpModal(true)}
                        >
                          Confirm OTP
                        </SpButton>
                      </RBAC>
                    )
                  }
                  {
                    notify && (
                      <RBAC permit={
                        [ROLES.OWNER, ROLES.ADMINISTRATOR,
                          ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.ANALYST, ROLES.SUPPORT, ROLES.POS, ROLES.SUPERUSER]
                      }
                      >
                        <SpButton
                          type="secondary"
                          shape="round"
                          ghost
                          onClick={selectedNotify}
                        >
                          Notify
                        </SpButton>
                      </RBAC>
                    )
                  }
                  {
                    transfersDetails.status === "PROCESSING" && (
                      <RBAC permit={
                        [ROLES.OWNER, ROLES.ADMINISTRATOR,
                          ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.ANALYST, ROLES.SUPPORT, ROLES.POS, ROLES.SUPERUSER]
                      }
                      >
                        <SpButton
                          type="danger"
                          className="m-2"
                          shape="round"
                          onClick={() => cancelTransferInDetails({
                            gid,
                          })}
                        >
                          Cancel
                        </SpButton>
                      </RBAC>
                    )
                  }
                </Col>

              </Row>
              <SpForm>
                <Row type="flex">
                  <Col span={6}>
                    <div>
                      <span className="mr-3">Date</span>
                    </div>
                    <div>
                      <SpText fontSize="18px" fontWeight="600">
                        {
                          transfersDetails.createdAt && selectedAccount && selectedAccount.timezone
                            ? (
                              moment
                                .utc(transfersDetails.createdAt)
                                .tz(selectedAccount.timezone)
                                .format('MMM DD, YYYY, hh:mm a')
                            )
                            : <>&#8211;</>
                        }
                      </SpText>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div>
                      <span>Funding Source</span>
                    </div>
                    <div>
                      <SpText fontSize="18px" fontWeight="600">
                        {
                          ((transfersDetails.fundingSource) && (transfersDetails.fundingSource.issuerBank)
                            && (transfersDetails.fundingSource.issuerBank.bankName) && (transfersDetails.fundingSource.issuerBank.lastFour))
                            ? `${transfersDetails.fundingSource.issuerBank.bankName} ****${transfersDetails.fundingSource.issuerBank.lastFour}`
                            : ((transfersDetails.fundingSource) && (transfersDetails.fundingSource.issuerBank)
                              && (transfersDetails.fundingSource.issuerBank.lastFour))
                              ? `****${transfersDetails.fundingSource.issuerBank.lastFour}` : <>&#8211;</>
                        }
                      </SpText>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div>
                      <span>Contact</span>
                    </div>
                    <div>
                      <SpText fontSize="18px" fontWeight="600">
                        {(transfersDetails.contact && transfersDetails.contact.name)
                          ? (transfersDetails.contact && transfersDetails.contact.name) : <>&#8211;</>}
                      </SpText>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div>
                      <span>Contact Type</span>
                    </div>
                    <div>
                      <SpText fontSize="18px" fontWeight="600">
                        {(transfersDetails.contact && transfersDetails.contact.contactType)
                          ? (getVendorType(transfersDetails.contact && transfersDetails.contact.contactType)) : <>&#8211;</>}
                      </SpText>
                    </div>
                  </Col>
                  {transfersDetails.contact && transfersDetails.contact.customType && (
                    <Col span={6}>
                      <div>
                        <span>Custom Type</span>
                      </div>
                      <div>
                        <SpText fontSize="18px" fontWeight="600">
                          {transfersDetails.contact && transfersDetails.contact.customType}
                        </SpText>
                      </div>
                    </Col>
                  )}
                </Row>
              </SpForm>
              <hr />
              <SpForm>
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>Amount</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {`${displayAmount.prefix}${displayAmount.amountDisplay}`}
                    </SpH5>
                  </Col>
                  {transfersDetails.convertedAmount
                  && transfersDetails.convertedCurrency && (
                  <>
                    <Col span={3}>
                      <span>Converted Amount</span>
                    </Col>
                    <Col span={8}>
                      <SpH5 color="#7f7f7f">
                        {`${transfersDetails.convertedCurrency.prefix} ${(transfersDetails.convertedAmount / 100).toFixed(2)}` || <>&#8211;</>}
                      </SpH5>
                    </Col>
                  </>
                  )}
                </Row>
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>Mode</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {(transfersDetails && transfersDetails.transferTypeCode)
                        ? (transfersDetails.transferTypeCode === 'RTP' ? 'INSTANT PAYMENT' : transfersDetails.transferTypeCode) : <>&#8211;</>}
                    </SpH5>
                  </Col>
                  {transfersDetails.convertedCurrency
                  && (
                  <>
                    <Col span={3}>
                      <span>Converted Currency</span>
                    </Col>
                    <Col span={8}>
                      <SpH5 color="#7f7f7f">
                        {transfersDetails?.convertedCurrency?.name || <>&#8211;</>}
                      </SpH5>
                    </Col>
                  </>
                  )}
                </Row>
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>Unique Reference Number</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {(transfersDetails && transfersDetails.uniqueReferenceNumber)
                        ? transfersDetails.uniqueReferenceNumber : <>&#8211;</>}
                    </SpH5>
                  </Col>
                  {transfersDetails.exchangeRate
                  && transfersDetails.convertedCurrency && (
                  <>
                    <Col span={3}>
                      <span>Exchange Rate</span>
                    </Col>
                    <Col span={8}>
                      <SpH5 color="#7f7f7f">
                        {`${transfersDetails?.convertedCurrency?.prefix} ${transfersDetails?.exchangeRate}` || <>&#8211;</>}
                      </SpH5>
                    </Col>
                  </>
                  )}
                </Row>
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>Description</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {(transfersDetails && transfersDetails.description)
                        ? transfersDetails.description : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <hr />
              {
                transfersDetails && transfersDetails.neftTransfer
                  ? (
                    <TransferType
                      transferDetails={transfersDetails}
                      transferTypeDetails={transfersDetails.neftTransfer}
                      currencyType={currencyType}
                      selectedAccount={selectedAccount}
                    />
                  ) : transfersDetails && transfersDetails.rtgsTransfer
                    ? (
                      <TransferType
                        transferDetails={transfersDetails}
                        transferTypeDetails={transfersDetails.rtgsTransfer}
                        currencyType={currencyType}
                        selectedAccount={selectedAccount}
                      />
                    ) : transfersDetails && transfersDetails.impsTransfer
                      ? (
                        <TransferType
                          transferDetails={transfersDetails}
                          transferTypeDetails={transfersDetails.impsTransfer}
                          currencyType={currencyType}
                          selectedAccount={selectedAccount}
                        />
                      ) : transfersDetails && transfersDetails.achTransfer
                        ? (
                          <TransferType
                            transferDetails={transfersDetails}
                            transferTypeDetails={transfersDetails.achTransfer}
                            currencyType={currencyType}
                            ach={transfersDetails.achTransfer}
                            selectedAccount={selectedAccount}
                          />
                        ) : ''
              }
              <Row className="d-flex justify-content-end">
                <Col>
                  <SpH5 color="#7f7f7f">{gid}</SpH5>
                </Col>
              </Row>
            </Card>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">TIMELINE</SpText>
                </Col>
              </Row>
              <Row className="pl-3">
                <TimelineBar
                  paymentTimeline={transferTimeline}
                  paymentDetails={transfersDetails}
                  selectedAccount={selectedAccount}
                />
              </Row>
            </Card>
            {
              transfersDetails.receiptNumber
                ? (
                  <Card className="mb-3">
                    <Row type="flex" justify="space-between" className="my-2">
                      <Col>
                        <SpText className="text-uppercase mb-3" fontSize="20px">Receipt</SpText>
                      </Col>
                      <Col>
                        <SpButton
                          type="secondary"
                          shape="round"
                          className="mr-4"
                          onClick={() => selectedReceipts(transfersDetails.receiptNumber)}
                        >
                          View Receipt
                        </SpButton>
                      </Col>
                    </Row>
                    <SpForm>
                      <Row type="flex" justify="start">
                        <Col span={4}>
                          <span>
                            {
                              transfersDetails.transferDate && selectedAccount && selectedAccount.timezone
                                ? (
                                  moment
                                    .utc(transfersDetails.transferDate)
                                    .tz(selectedAccount.timezone)
                                    .format('MMM DD, YYYY, hh:mm a')
                                )
                                : <>&#8211;</>
                            }
                          </span>
                        </Col>
                        <Col span={4}>
                          <span>
                            {`Receipt ${(transfersDetails && transfersDetails.receiptNumber)
                              ? transfersDetails.receiptNumber
                              : <>&#8211;</>}`}
                          </span>
                        </Col>
                      </Row>
                    </SpForm>
                  </Card>
                ) : ''
            }
            {/* ADD OTP MODAL */}
            {transferOtpModal && (
              <ConfirmOtp
                visible={transferOtpModal}
                submitting={submitting}
                lastFour={lastFour}
                phoneCode={phoneCode}
                gid={gid}
                close={() => setTransferOtpModal(false)}
                submit={otpTransfer}
              />
            )}
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  transfersDetails: state.transfersDetails,
  transferDetailsStatus: state.transfersDetails.transferDetailsStatus,
  transferTimeline: state.transfersDetails.transferTimeline,
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  displayAmount: displayAmountSelector(state.transfersDetails),
});

const mapDispatchToProps = (dispatch) => ({
  fetchTransferDetails: gid => dispatch(transfersDetailsActions.fetchTransferDetails({
    payload: gid,
  })),
  notifyTransferDetails: gid => dispatch(transfersDetailsActions.notifyTransferDetails({
    payload: gid,
  })),
  fetchTransferTimeline: gid => dispatch(transfersDetailsActions.fetchTransferTimeline({
    payload: gid,
  })),
  confirmTransferOtp: param => dispatch(transfersActions.confirmTransferOtp({
    payload: param,
  })),
  cancelTransferInDetails: param => dispatch(transfersActions.cancelTransferInDetails({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(TransferDetails);
