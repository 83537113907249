import React, { useState, useRef } from 'react';
import {
  Row,
  Col,
  Select,
  Button,
  AutoComplete,
  Input,
  Switch,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';

import {
  ACQUIRER_TYPES,
  PAYOUT_TIMEZONE,
  MESSAGES,
} from 'appconstants';

import {
  SpButton,
  SpH5,
  SpError,
} from 'components/DesignKit';

const { Option } = Select;

type Props = {
  internalAccount: Object,
  acquirerData: Object,
  submit: Function,
  openModal: Function,
  fetchAcquirerDetails: Function,
};

const Acquirer = (props: Props) => {
  const {
    internalAccount,
    acquirerData,
    fetchAcquirerDetails,
    openModal,
    submit,
  } = props;

  const {
    status,
    payoutTimezone,
    active,
  } = internalAccount;

  const [, forceUpdate] = useState();
  const [acquirername, setAcquirerName] = useState((internalAccount && internalAccount.acquirer)
    ? (internalAccount.acquirer && internalAccount.acquirer.name)
    : '');
  const accountRegex = /[0-9]{6,17}/;
  const [acquirerId, setAcquirerId] = useState((internalAccount && internalAccount.acquirer)
    ? (internalAccount.acquirer && internalAccount.acquirer.id)
    : '');
  const [subMerchantId, setSubMerchantId] = useState(internalAccount && internalAccount.subMerchantId);
  const [commerceSubMerchantId, setCommerceSubMerchantId] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.COMMERCE_CONNECT_SUB_MERCHANT_ID)
    : '');
  const [subMerchantDetails, setSubMerchantDetails] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.IMS_SHARED_SECRET)
    : '');
  const [atomPassword, setAtomPassword] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.ATOM_PASSWORD)
    : '');
  const [atomAccountNumber, setAtomAccountNumber] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.ATOM_ACCOUNT_NO)
    : '');
  const [atomProductId, setAtomProductId] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.ATOM_PRODUCT_ID)
    : '');
  const [publicKey, setPublicKey] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.AFFINIPAY_PUBLIC_KEY)
    : '');
  const [privateKey, setPrivateKey] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.AFFINIPAY_PRIVATE_KEY)
    : '');
  const [classificationId, setClassificationId] = useState((internalAccount && internalAccount.usBusiness)
    ? (internalAccount.usBusiness && internalAccount.usBusiness.dwollaBusinessClassificationId)
    : '');
  const [timeZone, setTimeZone] = useState(payoutTimezone);
  const [acquirerStatus, setStatus] = useState(status);
  const [notify, setNotify] = useState(active);
  const [swirepayUsPoolId, setSwirepayUsPoolId] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.CASHFREE_APP_ID)
    : '');
  const [cashAppID, setCashAppID] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.CASHFREE_APP_ID)
    : '');
  const [secretKey, setSecretKey] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.CASHFREE_SECRET_KEY)
    : '');
  const [encryptionKey, setEncryptionKey] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.COMMERCE_CONNECT_ENCRYPTION_KEY)
    : '');
  const [encryptionIv, setEncryptionIv] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.COMMERCE_CONNECT_ENCRYPTION_IV)
    : '');
  const [pageId, setPageId] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.COMMERCE_CONNECT_PAGE_ID)
    : '');
  const [billDeskId, setBillDeskId] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.BILLDESK_CLIENT_ID)
    : '');
  const [achMerchantId, setAchMerchantId] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.ACH_MERCHANT_ID)
    : '');
  const [gpayMerchantId, setGpayMerchantId] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.GOOGLE_PAY_MERCHANT_ID)
    : '');
  const [ipgApiKey, setIpgApiKey] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.IPG_API_KEY)
    : '');
  const [ipgSecretKey, setIpgSecretKey] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.IPG_API_SECRET)
    : '');
  const [ipgSharedSecretKey, setIpgSharedSecretKey] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.IPG_API_SHARED_SECRET)
    : '');
  const [schemaCode, setSchemaCode] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.SCHEME_CODE)
    : '');
  const [encryptionCode, setEncryptionCode] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.ENCRYPTION_CODE)
    : '');
  const [encryptionIV, setEncryptionIV] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.ENCRYPTION_IV)
    : '');
  const [usPooledSubmerchant, setUSPooledSubmerchant] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.US_POOLED_SUB_MERCHANT)
    : '');
  const [accessCode, setAccessCode] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.ACCESS_CODE)
    : '');
  const [workingKey, setWorkingKey] = useState((internalAccount && internalAccount.subMerchantDetails)
    ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.WORKING_KEY)
    : '');
  const { acquirerList } = acquirerData;
  // const isUsa = !!(internalAccount && internalAccount.country.alpha3 === 'USA');
  const [disabled, setDisabled] = useState(true);
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      accountNumber: {
        message: MESSAGES.BANK_DETAILS.VALID,
        rule: val => accountRegex.test(val),
      },
    },
  }));

  simpleValidator.current.purgeFields();

  const updateInternalAcquirer = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      acquirername,
      acquirerId,
      timeZone,
      publicKey,
      privateKey,
      cashAppID,
      secretKey,
      notify,
      subMerchantId,
      commerceSubMerchantId,
      acquirerStatus,
      classificationId,
      subMerchantDetails,
      encryptionKey,
      encryptionIv,
      pageId,
      atomPassword,
      atomProductId,
      billDeskId,
      gpayMerchantId,
      achMerchantId,
      atomAccountNumber,
      ipgApiKey,
      ipgSecretKey,
      schemaCode,
      encryptionCode,
      encryptionIV,
      usPooledSubmerchant,
      ipgSharedSecretKey,
      workingKey,
      accessCode,
    });
  };

  const reset = () => {
    setAcquirerName((internalAccount && internalAccount.acquirer) ? (internalAccount.acquirer && internalAccount.acquirer.name) : '');
    setAcquirerId((internalAccount && internalAccount.acquirer) ? (internalAccount.acquirer && internalAccount.acquirer.id) : '');
    setSubMerchantId(internalAccount && internalAccount.subMerchantId);
    setCommerceSubMerchantId((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.COMMERCE_CONNECT_SUB_MERCHANT_ID)
      : '');
    setSubMerchantDetails((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.IMS_SHARED_SECRET)
      : '');
    setPublicKey((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.AFFINIPAY_PUBLIC_KEY)
      : '');
    setPrivateKey((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.AFFINIPAY_PRIVATE_KEY)
      : '');
    setClassificationId((internalAccount && internalAccount.usBusiness)
      ? (internalAccount.usBusiness && internalAccount.usBusiness.dwollaBusinessClassificationId)
      : '');
    setTimeZone(payoutTimezone);
    setStatus(status);
    setNotify(active);
    setSwirepayUsPoolId((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.CASHFREE_APP_ID)
      : '');
    setCashAppID((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.CASHFREE_APP_ID)
      : '');
    setSecretKey((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.CASHFREE_SECRET_KEY)
      : '');
    setEncryptionKey((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.COMMERCE_CONNECT_ENCRYPTION_KEY)
      : '');
    setEncryptionIv((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.COMMERCE_CONNECT_ENCRYPTION_IV)
      : '');
    setPageId((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.COMMERCE_CONNECT_PAGE_ID)
      : '');
    setAtomPassword((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.ATOM_PASSWORD)
      : '');
    setAtomAccountNumber((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.ATOM_ACCOUNT_NO)
      : '');
    setAtomProductId((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.ATOM_PRODUCT_ID)
      : '');
    setBillDeskId((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.BILLDESK_CLIENT_ID)
      : '');
    setGpayMerchantId((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.GOOGLE_PAY_MERCHANT_ID)
      : '');
    setIpgApiKey((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.IPG_API_KEY)
      : '');
    setIpgSecretKey((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.IPG_API_SECRET)
      : '');
    setIpgSharedSecretKey((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.IPG_API_SHARED_SECRET)
      : '');
    setSchemaCode((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.SCHEME_CODE)
      : '');
    setEncryptionCode((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.ENCRYPTION_CODE)
      : '');
    setEncryptionIV((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.ENCRYPTION_IV)
      : '');
    setUSPooledSubmerchant((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.US_POOLED_SUB_MERCHANT)
      : '');
    setAccessCode((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.ACCESS_CODE)
      : '');
    setWorkingKey((internalAccount && internalAccount.subMerchantDetails)
      ? (internalAccount.subMerchantDetails && internalAccount.subMerchantDetails.WORKING_KEY)
      : '');
    setDisabled(true);
    openModal(false);
  };

  const searchAcquirer = (value) => {
    setAcquirerName(value);
    fetchAcquirerDetails(value);
  };

  const updateAcquirer = (value) => {
    const results = acquirerList.filter((item) => (item.name === value));
    if (results && results[0].name) {
      simpleValidator.current.hideMessages();
      forceUpdate();
      setAcquirerId(results[0].id);
      setAcquirerName(results[0].name);
      setDisabled(false);
      openModal(true);
    }
  };

  return (
    <>
      <div>
        <Row>
          <Col span={3} className="mb-2">
            <SpH5>Acquirer</SpH5>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <AutoComplete
              placeholder="Enter a Acquirer name"
              showSearch
              className="w-100"
              dataSource={acquirerList.map((item, idx) => (
                <Option key={idx} value={item.name}>
                  {item.name}
                </Option>
              ))}
              onSearch={searchAcquirer}
              value={acquirername}
              onSelect={e => updateAcquirer(e)}
            >
              <Input.Search enterButton />
            </AutoComplete>
            <SpError>
              {simpleValidator.current.message('acquirer Name', acquirername, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row>
          <Col span={6} className="mb-2 mt-4">
            <SpH5>
              {(acquirername === ACQUIRER_TYPES.IPGCOMMERCECONNECT) ? 'IPG Sub Merchant ID' : 'Sub Merchant ID'}
            </SpH5>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Input
              placeholder="Enter a Sub Merchant ID"
              value={subMerchantId}
              onChange={e => {
                setSubMerchantId(e.currentTarget.value);
                setDisabled(false);
                openModal(true);
              }}
            />
            <SpError>
              {simpleValidator.current.message('sub Merchant Id', subMerchantId, 'required')}
            </SpError>
          </Col>
        </Row>
        {
          (acquirername === ACQUIRER_TYPES.ATOM) && (
            <div>
              <Row>
                <Col span={3} className="mb-2 mt-4">
                  <SpH5>ATOM Password</SpH5>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <div>
                    <Input.Password
                      autoComplete="new-password"
                      placeholder="Enter a ATOM Password"
                      value={atomPassword}
                      onChange={e => {
                        setAtomPassword(e.currentTarget.value);
                        setDisabled(false);
                        openModal(true);
                      }}
                    />
                    <SpError>
                      {simpleValidator.current.message('ATOM Password', atomPassword, 'required')}
                    </SpError>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={4} className="mb-2 mt-4">
                  <SpH5>ATOM Account Number</SpH5>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <div>
                    <Input
                      placeholder="Enter Account Number"
                      value={atomAccountNumber}
                      onChange={e => {
                        setAtomAccountNumber(e.currentTarget.value);
                        setDisabled(false);
                        openModal(true);
                      }}
                    />
                    <SpError>
                      {simpleValidator.current.message('Account Number', atomAccountNumber, 'required|accountNumber')}
                    </SpError>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={3} className="mb-2 mt-4">
                  <SpH5>ATOM Product ID</SpH5>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <div>
                    <Input
                      placeholder="Enter Product ID"
                      value={atomProductId}
                      onChange={e => {
                        setAtomProductId(e.currentTarget.value);
                        setDisabled(false);
                        openModal(true);
                      }}
                    />
                    <SpError>
                      {simpleValidator.current.message('Product ID', atomProductId, 'required')}
                    </SpError>
                  </div>
                </Col>
              </Row>
            </div>
          )
        }
        {
          (acquirername === ACQUIRER_TYPES.BILLDESK) && (
            <div>
              <Row>
                <Col span={4} className="mb-2 mt-4">
                  <SpH5>Billdesk ID</SpH5>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <div>
                    <Input
                      placeholder="Enter Billdesk ID"
                      value={billDeskId}
                      onChange={e => {
                        setBillDeskId(e.currentTarget.value);
                        setDisabled(false);
                        openModal(true);
                      }}
                    />
                    <SpError>
                      {simpleValidator.current.message('billdesk Id', billDeskId, 'required')}
                    </SpError>
                  </div>
                </Col>
              </Row>
            </div>
          )
        }
        {
          (acquirername === ACQUIRER_TYPES.CARDPOINTE || acquirername === ACQUIRER_TYPES.CLOVERPOS
            || acquirername === ACQUIRER_TYPES.CLOVER_ECOM_POS) && (
            <div>
              <Row>
                <Col span={5} className="mb-2 mt-4">
                  <SpH5>Google Pay Merchant Id</SpH5>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <div>
                    <Input
                      placeholder="Enter Merchant Id"
                      value={gpayMerchantId}
                      onChange={e => {
                        setGpayMerchantId(e.currentTarget.value);
                        setDisabled(false);
                        openModal(true);
                      }}
                    />
                    <SpError>
                      {simpleValidator.current.message('merchant Id', gpayMerchantId, 'alpha_num')}
                    </SpError>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={5} className="mb-2 mt-4">
                  <SpH5>ACH Merchant Id</SpH5>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <div>
                    <Input
                      placeholder="Enter ACH Merchant Id"
                      value={achMerchantId}
                      onChange={e => {
                        setAchMerchantId(e.currentTarget.value);
                        setDisabled(false);
                        openModal(true);
                      }}
                    />
                    <SpError>
                      {simpleValidator.current.message('merchant Id', achMerchantId, 'alpha_num')}
                    </SpError>
                  </div>
                </Col>
              </Row>
            </div>
          )
        }
        {
          ((acquirername === ACQUIRER_TYPES.IMS) || (acquirername === ACQUIRER_TYPES.IMSSWIREPAY)) && (
            <div>
              <Row>
                <Col span={3} className="mb-2 mt-4">
                  <SpH5>IMS Shared Secret</SpH5>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <div>
                    <Input.Password
                      autoComplete="new-password"
                      placeholder="Enter a IMS Shared Secret"
                      value={subMerchantDetails}
                      onChange={e => {
                        setSubMerchantDetails(e.currentTarget.value);
                        setDisabled(false);
                        openModal(true);
                      }}
                    />
                    <SpError>
                      {simpleValidator.current.message('sub Merchant Details', subMerchantDetails, 'required')}
                    </SpError>
                  </div>
                </Col>
              </Row>
            </div>
          )
        }
        {
          (acquirername === ACQUIRER_TYPES.AFFINIPAY) && (
            <>
              <div>
                <Row>
                  <Col span={3} className="mb-2 mt-4">
                    <SpH5>Public Key</SpH5>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div>
                      <Input.Password
                        autoComplete="new-password"
                        placeholder="Enter a Public Key"
                        value={publicKey}
                        onChange={e => {
                          setPublicKey(e.currentTarget.value);
                          setDisabled(false);
                          openModal(true);
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('public key', publicKey, 'required')}
                      </SpError>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col span={3} className="mb-2 mt-4">
                    <SpH5>Private Key</SpH5>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div>
                      <Input.Password
                        autoComplete="new-password"
                        placeholder="Enter a Private Key"
                        value={privateKey}
                        onChange={e => {
                          setPrivateKey(e.currentTarget.value);
                          setDisabled(false);
                          openModal(true);
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('private key', privateKey, 'required')}
                      </SpError>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )
        }
        {
          (acquirername === ACQUIRER_TYPES.CASHFREEUSPOOL) && (
            <>
              <div>
                <Row>
                  <Col span={3} className="mb-2 mt-4">
                    <SpH5>Pool ID</SpH5>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div>
                      <Input
                        placeholder="Enter a Pool ID"
                        value={swirepayUsPoolId}
                        onChange={e => {
                          setSwirepayUsPoolId(e.currentTarget.value);
                          setDisabled(false);
                          openModal(true);
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('pool id', swirepayUsPoolId, 'required')}
                      </SpError>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col span={3} className="mb-2 mt-4">
                    <SpH5>APP ID</SpH5>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div>
                      <Input
                        placeholder="Enter a APP ID"
                        value={cashAppID}
                        onChange={e => {
                          setCashAppID(e.currentTarget.value);
                          setDisabled(false);
                          openModal(true);
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('app id', cashAppID, 'required')}
                      </SpError>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col span={3} className="mb-2 mt-4">
                    <SpH5>Secret Key</SpH5>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div>
                      <Input.Password
                        autoComplete="new-password"
                        placeholder="Enter a Secret Key"
                        value={secretKey}
                        onChange={e => {
                          setSecretKey(e.currentTarget.value);
                          setDisabled(false);
                          openModal(true);
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('secret key', secretKey, 'required')}
                      </SpError>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )
        }
        {
          (acquirername === ACQUIRER_TYPES.SWIREPAYPOOL) && (
            <>
              <div>
                <Row>
                  <Col span={3} className="mb-2 mt-4">
                    <SpH5>Pool ID</SpH5>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div>
                      <Input
                        placeholder="Enter a Pool ID"
                        value={swirepayUsPoolId}
                        onChange={e => {
                          setSwirepayUsPoolId(e.currentTarget.value);
                          setDisabled(false);
                          openModal(true);
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('pool id', swirepayUsPoolId, 'required')}
                      </SpError>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )
        }
        {
          ((acquirername === ACQUIRER_TYPES.COMMERCECONNECT) || (acquirername === ACQUIRER_TYPES.COMMERCECONNECTV2)) && (
            <>
              <div>
                <Row>
                  <Col span={3} className="mb-2 mt-4">
                    <SpH5>Encryption Key</SpH5>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div>
                      <Input
                        placeholder="Enter a Encryption Key"
                        value={encryptionKey}
                        onChange={e => {
                          setEncryptionKey(e.currentTarget.value);
                          setDisabled(false);
                          openModal(true);
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('encryption key', encryptionKey, 'required')}
                      </SpError>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={3} className="mb-2 mt-4">
                    <SpH5>Encryption IV</SpH5>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div>
                      <Input
                        placeholder="Enter a Encryption IV"
                        value={encryptionIv}
                        onChange={e => {
                          setEncryptionIv(e.currentTarget.value);
                          setDisabled(false);
                          openModal(true);
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('encryption iv', encryptionIv, 'required')}
                      </SpError>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={3} className="mb-2 mt-4">
                    <SpH5>Page ID</SpH5>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div>
                      <Input
                        placeholder="Enter a Page ID"
                        value={pageId}
                        onChange={e => {
                          setPageId(e.currentTarget.value);
                          setDisabled(false);
                          openModal(true);
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('page id', pageId, 'required')}
                      </SpError>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )
        }
        {
          (acquirername === ACQUIRER_TYPES.IPG) && (
            <>
              <div>
                <Row>
                  <Col span={3} className="mb-2 mt-4">
                    <SpH5>Api Key</SpH5>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div>
                      <Input.Password
                        autoComplete="new-password"
                        placeholder="Enter a Api Key"
                        value={ipgApiKey}
                        onChange={e => {
                          setIpgApiKey(e.currentTarget.value);
                          setDisabled(false);
                          openModal(true);
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('api key', ipgApiKey, 'required')}
                      </SpError>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col span={3} className="mb-2 mt-4">
                    <SpH5>Api Secret</SpH5>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div>
                      <Input.Password
                        autoComplete="new-password"
                        placeholder="Enter a Secret Key"
                        value={ipgSecretKey}
                        onChange={e => {
                          setIpgSecretKey(e.currentTarget.value);
                          setDisabled(false);
                          openModal(true);
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('secret key', ipgSecretKey, 'required')}
                      </SpError>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col span={3} className="mb-2 mt-4">
                    <SpH5>Api Shared Secret</SpH5>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div>
                      <Input.Password
                        autoComplete="new-password"
                        placeholder="Enter a Shared Secret"
                        value={ipgSharedSecretKey}
                        onChange={e => {
                          setIpgSharedSecretKey(e.currentTarget.value);
                          setDisabled(false);
                          openModal(true);
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('shared secret', ipgSharedSecretKey, 'required')}
                      </SpError>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )
        }
        {
          (acquirername === ACQUIRER_TYPES.IPGCOMMERCECONNECT) && (
            <>
              <div>
                <Row>
                  <Col span={6} className="mb-2 mt-4">
                    <SpH5>IPG Api Key</SpH5>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div>
                      <Input.Password
                        autoComplete="new-password"
                        placeholder="Enter a Api Key"
                        value={ipgApiKey}
                        onChange={e => {
                          setIpgApiKey(e.currentTarget.value);
                          setDisabled(false);
                          openModal(true);
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('api key', ipgApiKey, 'required')}
                      </SpError>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col span={6} className="mb-2 mt-4">
                    <SpH5>IPG Api Secret</SpH5>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div>
                      <Input.Password
                        autoComplete="new-password"
                        placeholder="Enter a Secret Key"
                        value={ipgSecretKey}
                        onChange={e => {
                          setIpgSecretKey(e.currentTarget.value);
                          setDisabled(false);
                          openModal(true);
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('secret key', ipgSecretKey, 'required')}
                      </SpError>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col span={6} className="mb-2 mt-4">
                    <SpH5>IPG Api Shared Secret</SpH5>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div>
                      <Input.Password
                        autoComplete="new-password"
                        placeholder="Enter a Shared Secret"
                        value={ipgSharedSecretKey}
                        onChange={e => {
                          setIpgSharedSecretKey(e.currentTarget.value);
                          setDisabled(false);
                          openModal(true);
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('shared secret', ipgSharedSecretKey, 'required')}
                      </SpError>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col span={6} className="mb-2 mt-4">
                    <SpH5>Commerce Connect Encryption Key</SpH5>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div>
                      <Input
                        placeholder="Enter a Encryption Key"
                        value={encryptionKey}
                        onChange={e => {
                          setEncryptionKey(e.currentTarget.value);
                          setDisabled(false);
                          openModal(true);
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('encryption key', encryptionKey, 'required')}
                      </SpError>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={6} className="mb-2 mt-4">
                    <SpH5>Commerce Connect Encryption IV</SpH5>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div>
                      <Input
                        placeholder="Enter a Encryption IV"
                        value={encryptionIv}
                        onChange={e => {
                          setEncryptionIv(e.currentTarget.value);
                          setDisabled(false);
                          openModal(true);
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('encryption iv', encryptionIv, 'required')}
                      </SpError>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={6} className="mb-2 mt-4">
                    <SpH5>Commerce Connect Page ID</SpH5>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div>
                      <Input
                        placeholder="Enter a Page ID"
                        value={pageId}
                        onChange={e => {
                          setPageId(e.currentTarget.value);
                          setDisabled(false);
                          openModal(true);
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('page id', pageId, 'required')}
                      </SpError>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={6} className="mb-2 mt-4">
                    <SpH5>Commerce Connect Sub Merchant ID</SpH5>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <Input
                      placeholder="Enter a Sub Merchant ID"
                      value={commerceSubMerchantId}
                      onChange={e => {
                        setCommerceSubMerchantId(e.currentTarget.value);
                        setDisabled(false);
                        openModal(true);
                      }}
                    />
                    <SpError>
                      {simpleValidator.current.message('sub Merchant Id', commerceSubMerchantId, 'required')}
                    </SpError>
                  </Col>
                </Row>
              </div>
            </>
          )
        }
        {
          ((acquirername === ACQUIRER_TYPES.INCWLCSTNWLUWL) || (acquirername === ACQUIRER_TYPES.WORLDLINE)) && (
            <>
              <div>
                <Row>
                  <Col span={3} className="mb-2 mt-4">
                    <SpH5>Schema Code</SpH5>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div>
                      <Input
                        placeholder="Enter a Schema Code"
                        value={schemaCode}
                        onChange={e => {
                          setSchemaCode(e.currentTarget.value);
                          setDisabled(false);
                          openModal(true);
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('schema code', schemaCode, 'required|alpha_num')}
                      </SpError>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col span={3} className="mb-2 mt-4">
                    <SpH5>Encryption Code</SpH5>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div>
                      <Input.Password
                        autoComplete="new-password"
                        placeholder="Enter a Encryption Code"
                        value={encryptionCode}
                        onChange={e => {
                          setEncryptionCode(e.currentTarget.value);
                          setDisabled(false);
                          openModal(true);
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('encryption code', encryptionCode, 'required|alpha_num')}
                      </SpError>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col span={3} className="mb-2 mt-4">
                    <SpH5>Encryption IV</SpH5>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <div>
                      <Input.Password
                        autoComplete="new-password"
                        placeholder="Enter a Encryption IV"
                        value={encryptionIV}
                        onChange={e => {
                          setEncryptionIV(e.currentTarget.value);
                          setDisabled(false);
                          openModal(true);
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('encryption IV', encryptionIV, 'required|alpha_num')}
                      </SpError>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )
        }
        {
          (acquirername === ACQUIRER_TYPES.INCWLCSTNWLUWL) && (
            <div>
              <Row>
                <Col span={4} className="mb-2 mt-4">
                  <SpH5>US Pooled Sub Merchant</SpH5>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <div>
                    <Input
                      placeholder="Enter a Pooled Sub Merchant"
                      value={usPooledSubmerchant}
                      onChange={e => {
                        setUSPooledSubmerchant(e.currentTarget.value);
                        setDisabled(false);
                        openModal(true);
                      }}
                    />
                    <SpError>
                      {simpleValidator.current.message('pooled sub merchant', usPooledSubmerchant, 'required')}
                    </SpError>
                  </div>
                </Col>
              </Row>
            </div>
          )
        }
        {
          (acquirername === ACQUIRER_TYPES.CCAVENUE) && (
            <div>
              <Row>
                <Col span={4} className="mb-2 mt-4">
                  <SpH5>Access Code</SpH5>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <div>
                    <Input
                      placeholder="Enter a Access Code"
                      value={accessCode}
                      onChange={e => {
                        setAccessCode(e.currentTarget.value);
                        setDisabled(false);
                        openModal(true);
                      }}
                    />
                    <SpError>
                      {simpleValidator.current.message('access code', accessCode, 'required')}
                    </SpError>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={4} className="mb-2 mt-4">
                  <SpH5>Working Key</SpH5>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <div>
                    <Input.Password
                      autoComplete="new-password"
                      placeholder="Enter a Working Key"
                      value={workingKey}
                      onChange={e => {
                        setWorkingKey(e.currentTarget.value);
                        setDisabled(false);
                        openModal(true);
                      }}
                    />
                    <SpError>
                      {simpleValidator.current.message('working key', workingKey, 'required')}
                    </SpError>
                  </div>
                </Col>
              </Row>
            </div>
          )
        }
        <div>
          <Row>
            <Col span={3} className="mb-2 mt-4">
              <SpH5>Status</SpH5>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Select
                value={acquirerStatus}
                placeholder="Please select"
                className="w-100"
                onChange={value => {
                  if (value === 'DECLINED') { setNotify(false); }
                  setStatus(value);
                  setDisabled(false);
                  openModal(true);
                }}
                showSearch
              >
                <Option key={1} value="TO_ACTIVATE">To Activate</Option>
                <Option key={2} value="TO_VERIFY">To Verify</Option>
                <Option key={3} value="VERIFIED">Verified</Option>
                <Option key={4} value="TO_REVERIFY">To Reverify</Option>
                <Option key={4} value="DECLINED">Declined</Option>
              </Select>
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col span={3} className="mb-2 mt-4">
              <SpH5>Payout Timezone</SpH5>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Select
                value={timeZone}
                placeholder="Please select"
                className="w-100"
                onChange={value => {
                  setTimeZone(value);
                  setDisabled(false);
                  openModal(true);
                }}
                showSearch
              >
                {PAYOUT_TIMEZONE.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col span={6} className="mb-2 mt-4">
              <Switch
                checked={notify}
                className="mr-2"
                onChange={val => {
                  setNotify(val);
                  setDisabled(false);
                  openModal(true);
                }}
              />
              <SpH5>Acquirer Active</SpH5>
            </Col>
          </Row>
        </div>
        <Row className="mt-4" style={{ marginLeft: '-15px' }}>
          <Col span={2}>
            <Button
              type="link"
              onClick={reset}
              disabled={disabled}
            >
              Reset
            </Button>
          </Col>
          <Col span={2}>
            <SpButton
              type="primary"
              shape="round"
              disabled={disabled}
              onClick={updateInternalAcquirer}
            >
              Update
            </SpButton>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default React.memo(Acquirer);
