/* eslint-disable no-shadow */
import React, {
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import moment from 'moment-timezone';
import {
  Card,
  Table,
  Row,
  Col,
  Menu,
  Skeleton,
  Tooltip,
} from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import Loading from 'components/Loading';
import formatAmount from 'utils/formatAmount';
import getInvoiceBackground from 'utils/getInvoiceBackground';
import getInvoiceStatus from 'utils/getInvoiceStatus';
import {
  accountSessionActions,
} from 'store/actions';
import { PAGES } from 'appconstants';
import { SpStatusTag, SpTab } from 'components/DesignKit';
import swireLogo from 'imgs/swirepay.png';
import qpayments from 'imgs/Qpayments.jpeg';
import getTransferBackground from 'utils/getTransferBackground';
import getTransferStatus from 'utils/getTransferStatus';
import { EyeOutlined } from '@ant-design/icons';
import Verified from 'assets/imgs/Verified.svg';
import VerifiedUser from 'assets/imgs/Verified_User.svg';


type Props = {
  accountSession: Array<Object>,
  accountSessionInvoiceList: Array<Object>,
  fetchAccountSession: Function,
  fetchAccountSessionInvoices: Function,
  loading: boolean,
  history: {
    push: Function,
  },
  totalElements: Number,
  totalElementsInvoiceList: Number,
  skeltonLoading: boolean,
};

const AccountSession = (props: Props) => {
  const {
    accountSession,
    fetchAccountSession,
    loading,
    history,
    totalElements,
    fetchAccountSessionInvoices,
    totalElementsInvoiceList,
    accountSessionInvoiceList,
    skeltonLoading,

  } = props;

  const location = useLocation();
  const { id } = props.match.params;
  const path = props.match.url;
  const [pagination, setPagination] = useState({});
  const [paginationInvoices, setPaginationInvoices] = useState({});
  const [current, setCurrent] = useState('paymentDetails');
  const [pageNoInvoices, setPageNoInvoices] = useState(1);


  const debouncedFetchSessions = debounce(fetchAccountSession, 2000, { leading: true });
  const debouncedFetchSessionsInvoices = debounce(fetchAccountSessionInvoices, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const custGid = id;
    debouncedFetchSessions({
      currentPage, sortParams, filterParam, custGid,
    });
  };
  const getDataInvoices = (query) => {
    const contactGid = id;
    debouncedFetchSessionsInvoices({ contactGid, pageNo: pageNoInvoices });
  };

  useEffect(() => {
    if (!id) return; // Early return if `id` is not available
    if (current === 'paymentDetails') {
      getData(location.search);
    } else if (current === 'invoices') {
      getDataInvoices(location.search);
    }
  }, [location, current, id]);


  useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [accountSession]);

  useEffect(() => {
    const currentPage = pageNoInvoices || 1;
    setPaginationInvoices(existingElements => ({
      ...existingElements,
      total: totalElementsInvoiceList,
      current: parseInt(currentPage, 10),
      pageSize: 10,
      showTotal: total => `Total ${total} invoices`,
    }));
  }, [accountSessionInvoiceList]);


  const handleViewInvoice = (vendorInvoiceGid) => {
    history.push({
      pathname: `${PAGES.ACCOUNT_SESSIONS_VIEW_INVOICE}/${vendorInvoiceGid}`,
      state: location.search,
    });
  };
  const columns = [
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '15%',
      align: 'left',
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (createdAt) => {
        if (createdAt) {
          const tzDate = moment
            .utc(createdAt)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (description) => (
        <span>
          {description || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'FROM ACCOUNT',
      dataIndex: 'payerAccountName',
      width: '20%',
      align: 'left',
      render: (text, record) => {
        const name = record?.payerAccountName || <>&#8211;</>;
        const bankName = record?.payerBank && record?.payerAccountLastFour
          ? `| ${record.payerBank}  ****${record.payerAccountLastFour}`
          : <>&#8211;</>;
        return (
          <span style={{ cursor: 'pointer' }}>
            {name} {bankName}
          </span>
        );
      },
    },
    {
      title: 'TO ACCOUNT',
      dataIndex: 'beneficiaryAccountName',
      width: '20%',
      align: 'left',
      render: (text, record) => {
        const name = record?.beneficiaryAccountName || <>&#8211;</>;
        const bankName = record?.beneficiaryBank && record?.beneficiaryAccountLastFour
          ? `| ${record.beneficiaryBank}  ****${record.beneficiaryAccountLastFour}`
          : <>&#8211;</>;
        return (
          <span style={{ cursor: 'pointer' }}>
            {name} {bankName}
          </span>
        );
      },
    },
    // {
    //   title: 'TRANSFER MODE',
    //   dataIndex: 'transferTypeCode',
    //   width: '12%',
    //   align: 'left',
    //   render: (transferTypeCode) => (
    //     <span style={{ cursor: 'pointer' }}>
    //       {(transferTypeCode === 'RTP' ? 'Instant Payment' : transferTypeCode) || <>&#8211;</>}
    //     </span>
    //   ),
    // },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => (
        <span style={{ cursor: 'pointer' }}>
          {record?.currency?.prefix} {formatAmount((record?.amount / 100), record?.currency)}
        </span>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (status) => (
        <span>
          <SpStatusTag style={{ backgroundColor: getTransferBackground(status), fontSize: '12px' }}>
            {getTransferStatus(status)}
          </SpStatusTag>
        </span>
      ),
    },
  ];
  const columnsInvoices = [
    {
      title: 'INVOICE NUMBER',
      dataIndex: 'invoiceNumber',
      align: 'left',
      render: (text, record) => (
        <span style={{ cursor: 'pointer' }}>
          {record?.invoiceNumber ?? <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'FROM ACCOUNT',
      dataIndex: 'fromAccount',
      align: 'left',
      render: (text, record) => {
        const name = record?.payerFundingSource?.issuerBank?.accountHolderName;
        const bankName = `${record?.payerFundingSource?.issuerBank?.bankName || ''} ****${record?.payerFundingSource?.issuerBank?.lastFour || ''}`;
        return (
          <span style={{ cursor: 'pointer' }}>
            {name} | {bankName}
          </span>
        );
      },
    },
    {
      title: 'TO ACCOUNT',
      dataIndex: 'toAccount',
      align: 'left',
      render: (text, record) => {
        const name = record?.fundingSource?.issuerBank?.accountHolderName;
        const verified = record?.fundingSource?.issuerBank?.isVerified;
        const bankName = `${record?.fundingSource?.issuerBank?.bankName || ''} ****${record?.fundingSource?.issuerBank?.lastFour || ''}`;
        return (
          <span style={{ cursor: 'pointer' }}>
            {name} | {bankName} {verified ? <><img src={Verified} alt="verified" /> <img src={VerifiedUser} alt="verified" /></> : ''}
          </span>
        );
      },
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (description) => (
        <span>
          {description || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => (
        <span style={{ cursor: 'pointer' }}>
          {record?.currency?.prefix} {formatAmount((record?.amount / 100), record?.currency)}
        </span>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (status) => (
        <span>
          <SpStatusTag style={{ backgroundColor: getInvoiceBackground(status), fontSize: '12px' }}>
            {getInvoiceStatus(status)}
          </SpStatusTag>
        </span>
      ),
    },
    {
      title: 'SERVICE DATE',
      dataIndex: 'createdAt',
      align: 'left',
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (issueDate) => {
        if (issueDate) {
          const tzDate = moment
            .utc(issueDate)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'TRANSFER DATE',
      dataIndex: 'transferDueDate',
      align: 'left',
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (transferDueDate) => {
        if (transferDueDate) {
          const tzDate = moment(transferDueDate).format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'ACTION',
      dataIndex: 'action',
      align: 'center',
      render: (text, record) => (
        <span>
          {record?.gid && (
          <Tooltip placement="top" title="View Invoice">
            <EyeOutlined
              style={{ fontSize: '20px', color: '#08c', marginRight: '8px' }}
              onClick={() => handleViewInvoice(record?.gid)}
            />
          </Tooltip>
)}
        </span>
      ),
    },
  ];


  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: `${PAGES.ACCOUNT_SESSIONS}/${id}`,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: `${PAGES.ACCOUNT_SESSIONS}/${id}`,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const handleTableChangeInvoices = (page, sorter, filters) => {
    const tempPage = page.current;
    setPageNoInvoices(page.current);
    const contactGid = id;
    debouncedFetchSessionsInvoices({ contactGid, pageNo: tempPage });
  };

  const balanceUpdate = (val) => {
    setCurrent(val);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card>
      <div style={{ marginBottom: '50px' }}>&nbsp;</div>
      <Row
        className="my-2 d-flex justify-content-end"
        style={{
          background: '#B7C9E5', height: '4px',
        }}
      >
        <Col>
          <img
            src={window.location.host.includes('qpaymentz') ? qpayments : swireLogo}
            alt=""
            style={{
              width: '220px', position: 'absolute', top: '-50px', left: '-225px', background: 'white',
            }}
          />
        </Col>
      </Row>
      <div style={{ marginTop: '25px' }}>&nbsp;</div>
      <Row className="my-2">
        <Col>
          <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal" style={{ fontSize: '15px' }}>
            <Menu.Item key="paymentDetails" className="pl-2">
              <SpTab
                to={`${path}`}
                selected={current === 'paymentDetails'}
                onClick={() => balanceUpdate('paymentDetails')}
              >
                Payment Details
              </SpTab>
            </Menu.Item>
            <Menu.Item key="invoices">
              <SpTab
                to={`${path}`}
                selected={current === 'invoices'}
                onClick={() => balanceUpdate('invoices')}
              >
                Invoices
              </SpTab>
            </Menu.Item>
          </Menu>
        </Col>
      </Row>
      {current === 'paymentDetails'
      && (
      <Table
        rowClassName={() => 'ant-table-clickable-row'}
        columns={columns}
        rowKey={(record) => record.gid}
        dataSource={accountSession}
        pagination={pagination}
        onChange={handleTableChange}
      />
      )}
      {current === 'invoices' && (
        skeltonLoading ? (
          <Skeleton active />
        ) : (
          <Table
            rowClassName={() => 'ant-table-clickable-row'}
            columns={columnsInvoices}
            rowKey={(record) => record.gid}
            dataSource={accountSessionInvoiceList}
            pagination={paginationInvoices}
            onChange={handleTableChangeInvoices}
          />
        ))}
    </Card>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  accountSession: state.accountSession.content ? state.accountSession.content : [],
  accountSessionInvoiceList: state.accountSession.accountSessionInvoiceList.content ? state.accountSession.accountSessionInvoiceList.content : [],
  isMobileView: state.mobileView.isMobileView,
  totalElements: state.accountSession.totalElements,
  totalElementsInvoiceList: state.accountSession.accountSessionInvoiceList.totalElements,
  skeltonLoading: state.loading.skeleton,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAccountSession: gid => dispatch(accountSessionActions.fetchAccountSession({
    payload: gid,
  })),
  fetchAccountSessionInvoices: gid => dispatch(accountSessionActions.fetchAccountSessionInvoices({
    payload: gid,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSession);
