/* eslint-disable */
import React from 'react';

const DeleteAlertIcon = () => (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.915228 13.5833C0.775228 13.5833 0.654395 13.5526 0.552728 13.4913C0.4512 13.4299 0.372242 13.3489 0.315853
  13.2483C0.250853 13.15 0.222034 13.0389 0.229395 12.915C0.236756 12.7911 0.272033 12.6719 0.335228 12.5575L7.42752 0.775837C7.49085
  0.661392 7.5737 0.580421 7.67606 0.532921C7.77842 0.485421 7.88662 0.46167 8.00064 0.46167C8.11453 0.46167 8.22252 0.485421 8.3246 0.532921C8.42655
  0.580421 8.50919 0.661392 8.57252 0.775837L15.6648 12.5575C15.728 12.6719 15.7633 12.7911 15.7706 12.915C15.778 13.0389 15.7492 13.15 15.6842 13.2483C15.6218
  13.3475 15.5413 13.4281 15.4427 13.4902C15.3441 13.5523 15.2248 13.5833 15.0848 13.5833H0.915228ZM1.64585 12.5H14.3542L8.00002 1.91667L1.64585 12.5ZM7.99565
  11.3398C8.16148 11.3398 8.30196 11.2837 8.4171 11.1715C8.53224 11.0592 8.58981 10.9202 8.58981 10.7544C8.58981 10.5885 8.5337 10.4481 8.42148 10.3329C8.30926 10.2178 8.17023 10.1602 8.00439 10.1602C7.83856 10.1602 7.69808
  10.2163 7.58294 10.3285C7.4678 10.4408 7.41023 10.5798 7.41023 10.7456C7.41023 10.9115 7.46634 11.0519 7.57856 11.1671C7.69078 11.2822 7.82981 11.3398 7.99565 11.3398ZM7.99585 9.16021C8.14821
   9.16021 8.27724 9.10834 8.38294 9.00459C8.48877 8.9007 8.54169 8.77202 8.54169 8.61855V5.70188C8.54169 5.54841 8.49016 5.4198 8.3871 5.31605C8.28405 5.21216 8.15641 5.16021 8.00419
   5.16021C7.85183 5.16021 7.7228 5.21216 7.6171 5.31605C7.51127 5.4198 7.45835 5.54841 7.45835 5.70188V8.61855C7.45835 8.77202 7.50988 8.9007 7.61294 9.00459C7.71599
   9.10834 7.84363 9.16021 7.99585 9.16021Z"
      fill="#1C1B1F"
    />
  </svg>
);

export default DeleteAlertIcon;