import { FUNDINGSOURCE } from '../actionTypes';

const initialState = {
  content: [],
  newfundingsource: {},
  fundingSourceListByContactgid: [],
  fundingSourceDetails: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FUNDINGSOURCE.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case FUNDINGSOURCE.ADD_FUNDINGSOURCE:
      return {
        ...state,
        newfundingsource: action.payload,
      };
    case FUNDINGSOURCE.SUCCESS_CONTACTGID:
      return {
        ...state,
        fundingSourceListByContactgid: action.payload,
      };
    case FUNDINGSOURCE.FUNDINGSOURCEDETAILS_SUCCESS:
      return {
        ...state,
        fundingSourceDetails: action.payload,
      };
    default:
      return state;
  }
};
