// @flow
import React, {
  useRef,
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import { SeatsioSeatingChart } from '@seatsio/seatsio-react';
import moment from 'moment-timezone';
import {
  Modal,
  Steps,
  Input,
  Row,
  Col,
  Select,
} from 'antd';
import {
  COUNTRY_PHONE_CODE,
  OfflinePaymentTypeIn,
  OfflinePaymentTypeUs,
  COUNTRIES,
  MAX_AMOUNT,
} from 'appconstants';
import {
  SpH5,
  SpError,
  SpButton,
  SpText,
} from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';
import {
  validationRegex,
  validators,
  countryCodeValidation,
} from 'utils/validationMessages';
import formatAmount from 'utils/formatAmount';
import currencyFormatter from 'utils/currencyformatter';
import { isValidPhoneNumber, formatNumber } from 'libphonenumber-js';
import {
  notificationActions,
} from 'store/actions';

const { Option } = Select;

type Props = {
  close: Function,
  visible: boolean,
  submitting: boolean,
  test: boolean,
  selectedAccount: Object,
  phoneCode: string,
  paymentPagesDetails: Object,
  submit: Function,
  nolayout: Boolean,
};

const { Step } = Steps;

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const { format: formatPrice } = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol',
});

const OfflinePaymentPage = (props: Props) => {
  const {
    visible,
    close,
    submitting,
    phoneCode,
    paymentPagesDetails,
    selectedAccount,
    submit,
    test,
    nolayout,
  } = props;

  const [, forceUpdate] = useState();
  const [current, setCurrent] = useState(0);
  const [disableButton, setDisableButton] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [countryCode, setCountryCode] = useState(phoneCode);
  const [phoneNumber, setPhone] = useState('');
  const [phoneFormate, setPhoneFormate] = useState();
  const [paymentNote, setPaymentNote] = useState('');
  const [category, setCategory] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [eventDetails, setEventDetails] = useState(paymentPagesDetails);
  const [seatInfo, setSeatInfo] = useState();
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [publicKey, setPublicKey] = useState();
  const [eventKey, setEventKey] = useState();
  const [tooltipData, setTooltipData] = useState();
  const [paymentType, setPaymentType] = useState('');
  const [OfflinePaymentType, setOfflinePaymentType] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [checkLabel, setCheckLabel] = useState('');
  const [eventAmount, setAmount] = useState(null);
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const qntAmtRegex = /^[0-9]+$/;
  let seatsData = [];
  let totalAmount = 0;
  const currencyType = selectedAccount && selectedAccount.currency;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
      stringLength: {
        message: 'The name should have minimum 4 characters.',
        rule: val => val.length >= 4,
      },
      onlyInteger: {
        message: 'Decimal format is not accepted',
        rule: val => (qntAmtRegex.test(val)),
      },
      minQuantity: {
        message: 'Please enter valid Stock',
        rule: (val, params) => val && (val <= Number(params[0])),
      },
      emailId: {
        message: validators.register.validEmail,
        rule: val => validationRegex.new_email.test(val),
      },
    },
  }));

  useEffect(() => {
    if (isIndia) {
      setOfflinePaymentType(OfflinePaymentTypeIn);
      setCheckLabel('Cheque');
    } else if (!isIndia) {
      setOfflinePaymentType(OfflinePaymentTypeUs);
      setCheckLabel('Check');
    }
  }, [selectedAccount, test]);

  useEffect(() => {
    if (eventDetails && eventDetails.seatData) {
      const encodeQuery = atob(eventDetails.seatData);
      const parseData = JSON.parse(encodeQuery);
      const publicId = atob(eventDetails.seatPublicKey);
      const eventId = atob(eventDetails.seatEventId);
      setPublicKey(publicId);
      setEventKey(eventId);
      setSeatInfo(parseData.pricingData);
      setTooltipData(parseData.tooltipData);
    }
  }, [eventDetails]);

  const getOperatingTime = (time) => {
    if (time !== 0) {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      let min;
      if (minutes < 10) {
        min = `0${minutes}`;
      } else if (minutes >= 10) {
        min = minutes;
      }
      if (hours > 12) {
        const hr = hours - 12;
        return `${hr}:${min} PM`;
      }
      if (hours === 0) {
        return `${12}:${min} AM`;
      }
      return `${hours}:${min} AM`;
    }
    return '12:00 AM';
  };

  const onObjectSelected = (item) => {
    const seatsList = [...seatsData];
    const selectedGid = item.selectedTicketType;
    const price = item.category.pricing && item.category.pricing.ticketTypes.filter((data) => data.ticketType === selectedGid);
    // eslint-disable-next-line no-param-reassign
    item.seatPrice = price && price[0].price;
    seatsList.push(item);
    setSelectedSeats(seatsList);
    seatsData = seatsList;
    localStorage.setItem('seatsData', JSON.stringify(seatsData));
  };

  const onObjectDeselected = (item, price) => {
    const seatPriceGid = price && price.ticketType;
    const seatsList = [...seatsData];
    const seatId = item.id;
    if (!item.selected) {
      const seatsList2 = seatsList.filter((data) => data.id !== seatId);
      setSelectedSeats(seatsList2);
      seatsData = seatsList2;
      localStorage.setItem('seatsData', JSON.stringify(seatsData));
    } else if (item.selected && seatPriceGid) {
      const seatList1 = seatsList.filter((data) => data.id === seatId && data.selectedTicketType === seatPriceGid);
      const seatsList2 = seatsList.filter((data) => data.id === seatId && data.selectedTicketType !== seatPriceGid);
      const seatsList3 = seatsList.filter((data) => data.id !== seatId);
      // eslint-disable-next-line no-unused-vars
      const seatFilterd = seatList1.slice(-1);
      setSelectedSeats([...seatList1, ...seatsList2, ...seatsList3]);
      seatsData = [...seatList1, ...seatsList2, ...seatsList3];
      localStorage.setItem('seatsData', JSON.stringify(seatsData));
    }
  };

  const getSubToatal = (list) => {
    totalAmount = list.reduce((n, { seatPrice }) => n + seatPrice, 0);
    // eslint-disable-next-line max-len
    return (currencyFormatter((list.reduce((n, { seatPrice }) => n + seatPrice, 0)), eventDetails && eventDetails.seatPageMetaTickets[0].currency.name));
  };

  const tooltipFormat = (obj) => {
    const keyId = obj.category.key;
    const tooltipLabel = tooltipData && tooltipData.filter((data) => data.category.id === keyId);
    if (tooltipLabel && tooltipLabel.length !== 0) {
      return `${tooltipLabel[0].category.tooltip}`;
    }
    return '';
  };
  useEffect(() => {
    if (paymentPagesDetails && paymentPagesDetails.seatPageMetaTickets && paymentPagesDetails.seatPageMetaTickets.length !== 0) {
      const itemList = [...paymentPagesDetails.seatPageMetaTickets];
      const categoryType = itemList.filter((item) => item.type !== 'TICKET' && item.type !== 'PROMOTIONAL_CODES');
      setCategory(categoryType);
    }
  }, [paymentPagesDetails]);

  useEffect(() => {
    if (current === 2 && !submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  simpleValidator.current.purgeFields();

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  const onPhoneNumberChange1 = (e, i) => {
    const list = [...category];
    const updatedPhone1 = e.currentTarget.value;
    list[i].value = updatedPhone1.replace(/[^0-9+]/g, '');
    list[i].phoneFormate = updatedPhone1.replace(/[^0-9+]/g, '');
    setCategory(list);
  };

  const onCountryCodeChange = (value) => {
    setCountryCode(value);
    const data = formatNumber(`${value}${phoneNumber}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  };

  const getByLabelText = (type) => {
    if (type === 'INPUT_ALPHA_NUMERIC') {
      return 'Alpha Numeric';
    } if (type === 'INPUT_NUMBER') {
      return 'Number';
    } if (type === 'INPUT_SINGLE_LINE_TEXT') {
      return 'Single Line Text';
    } if (type === 'PRICE_AMOUNT_STOCK_BASED') {
      return 'Stock';
    } if (type === 'INPUT_PHONE') {
      return 'Phone';
    } if (type === 'INPUT_EMAIL') {
      return 'Email';
    }
    return null;
  };

  const next = (event) => {
    if (current === 0) {
      event.preventDefault();
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setCurrent(current + 1);
      simpleValidator.current.hideMessages();
      forceUpdate();
    } else if (current === 1) {
      event.preventDefault();
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      if (nolayout) {
        const selectedDate = moment.tz(new Date(), selectedAccount.timezone);
        const dateStart = selectedDate.utc().format(MOMENT_FORMAT);
        const seatsMetaData = [];
        const list1 = [...category];
        const getTotalAmount = eventAmount.replace(/[^\d]/g, '');
        for (let i = 0; i < list1.length; i += 1) {
          const tempObj = {};
          tempObj.seatPageMetaTicketGid = list1[i].gid;
          tempObj.type = list1[i].type;
          tempObj.value = list1[i].value;
          tempObj.label = list1[i].category;
          tempObj.category = list1[i].category;
          seatsMetaData.push(tempObj);
        }
        // eslint-disable-next-line max-len
        const filterMetaData = seatsMetaData.filter(entry => !('value' in entry && (entry.value === null || entry.value === undefined || entry.value === '')));
        const finalEntries = [];
        for (const entry of filterMetaData) {
          if (entry.type === 'PRICE_AMOUNT_STOCK_BASED') {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < entry.value; i++) {
              finalEntries.push({
                ...entry,
                value: 1,
              });
            }
          } else {
            finalEntries.push(entry);
          }
        }
        const payload = {
          seatPageMetaGid: eventDetails.gid,
          customerDetails: {
            name,
            email,
            phoneNumber: `${countryCode}${phoneNumber}`,
          },
          offlinePaymentType: paymentType,
          amount: Number(getTotalAmount),
          transactionDate: dateStart,
          offlinePaymentNote: paymentNote,
          seatPageTicketEntries: finalEntries,
        };
        submit(payload);
      } else {
        setCurrent(current + 1);
      }
    } else if (current === 2) {
      const selectedDate = moment.tz(new Date(), selectedAccount.timezone);
      const dateStart = selectedDate.utc().format(MOMENT_FORMAT);
      const seatsMetaData = [];
      const categ = category.filter((item) => item.value);
      const list = [...categ];
      const seatS = [...selectedSeats];
      for (let i = 0; i < seatS.length; i += 1) {
        const tempObj = {};
        tempObj.seatPageMetaTicketGid = seatS[i].selectedTicketType;
        tempObj.seat = seatS[i].id;
        tempObj.value = seatS[i].seatPrice * 100;
        tempObj.label = seatS[i].category.label;
        tempObj.category = seatS[i].seatId;
        tempObj.type = 'TICKET';
        seatsMetaData.push(tempObj);
      }
      for (let i = 0; i < list.length; i += 1) {
        const tempObj = {};
        tempObj.seatPageMetaTicketGid = list[i].gid;
        tempObj.type = list[i].type;
        tempObj.value = list[i].value;
        tempObj.label = getByLabelText(list[i].type);
        tempObj.category = getByLabelText(list[i].type);
        seatsMetaData.push(tempObj);
      }
      const payload = {
        seatPageMetaGid: eventDetails.gid,
        customerDetails: {
          name,
          email,
          phoneNumber: `${countryCode}${phoneNumber}`,
        },
        offlinePaymentType: paymentType,
        amount: totalAmount * 100,
        transactionDate: dateStart,
        offlinePaymentNote: paymentNote,
        seatPageTicketEntries: seatsMetaData,
      };
      setDisableButton(true);
      submit(payload);
    }
  };

  const prev = (event) => {
    event.preventDefault();
    setDisableButton(false);
    setCurrent(current - 1);
    setSelectedSeats([]);
  };

  const updateQuantityBased = (e, i) => {
    const list = [...category];
    const qnty = Number(e);
    list[i].quantity = qnty === 0 ? null : qnty;
    list[i].value = qnty;
    if (qnty !== 0) {
      const amount = qnty * (list[i].price / 100);
      list[i].amount = amount;
    } else if (qnty === 0) {
      const amount = 0;
      list[i].amount = amount;
    }
    setCategory(list);
  };

  const onPaymentTypeChange = (val) => {
    setPaymentType(val);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      width="540px"
      footer={current === 0
        ? [
          <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
          <SpButton key="2" onClick={next}>Next</SpButton>,
        ]
        : current === 1
          ? [
            <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
            <SpButton key="3" onClick={prev} type="secondary">Previous</SpButton>,
            <SpButton key="2" onClick={next}>{nolayout ? 'Add Offline Payment' : 'Next'}</SpButton>,
          ]
          : [
            <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
            <SpButton key="3" onClick={prev} type="secondary">Previous</SpButton>,
            <SpButton key="2" onClick={next} disabled={disableButton || (selectedSeats && selectedSeats.length === 0)}>Add Offline Payment</SpButton>,
          ]}
      title="Offline Payment"
    >
      <>
        <div style={{ marginTop: '-8px' }}>
          <Steps current={current} className="subscriptions">
            <Step key="1" title="Cusomer" />
            <Step key="2" title="Category" />
            <Step key="3" title="Event" />
          </Steps>
        </div>
        <div className="px-5 mt-2">
          <div className={submitting ? 'OVERLAY' : ''} />
          {current === 0 && (
            <>
              <Row className="mt-4">
                <Col>
                  <div className="mb-3">
                    <label htmlFor=""><SpH5>Name</SpH5></label>
                    <Input
                      placeholder="Customer Name"
                      value={name}
                      onChange={(e) => setName(e.currentTarget.value)}
                    />
                    <SpError>
                      {simpleValidator.current.message('name', name, 'required|stringLength')}
                    </SpError>
                  </div>
                  <div className="mb-3">
                    <label htmlFor=""><SpH5>Email</SpH5></label>
                    <Input
                      placeholder="Customer email"
                      value={email}
                      onChange={(e) => setEmail(e.currentTarget.value)}
                    />
                    <SpError>
                      {simpleValidator.current.message('email', email, 'required|email|checkLowerCase')}
                    </SpError>
                  </div>

                  <div className="mb-3">
                    <label htmlFor=""><SpH5>Phone Number</SpH5></label>
                    <div className="d-flex">
                      <div
                        style={{ width: '20%' }}
                        onKeyPress={e => countryCodeValidation(e)}
                      >
                        <Select
                          className="w-100"
                          showSearch
                          defaultValue={countryCode}
                          onChange={onCountryCodeChange}
                        >
                          {COUNTRY_PHONE_CODE.map((item) => (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      <div style={{ width: '80%' }}>
                        <Input
                          className="InputnumFormate"
                          placeholder="Phone Number"
                          value={phoneFormate}
                          onChange={onPhoneNumberChange}
                          onBlur={e => {
                            if (e.currentTarget.value) {
                              setPhone(e.currentTarget.value);
                              const value = formatNumber(`${countryCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                              const formater = value.substr(value.indexOf(' ') + 1);
                              setPhoneFormate(formater);
                            }
                          }}
                        />
                        <SpError>
                          {simpleValidator.current.message('phone number', phoneNumber, `required|phoneNumValidation:${countryCode}${phoneNumber}`)}
                        </SpError>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor=""><SpH5>Amount</SpH5></label>
                    <Input
                      placeholder="0.00"
                      value={eventAmount}
                      onChange={(e) => setAmount(e.currentTarget.value)}
                      onKeyPress={e => {
                        const keyCode = e.charCode || e.which;
                        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                          e.preventDefault();
                        }
                      }}
                      onBlur={e => {
                        if (e.currentTarget.value) {
                          const value = formatAmount(e.currentTarget.value, currencyType);
                          setAmount(value);
                        }
                      }}
                    />
                    <SpError>
                      {simpleValidator.current.message('amount', eventAmount, 'required')}
                    </SpError>
                  </div>
                  <div className="mb-3">
                    <label htmlFor=""><SpH5>Payment Type</SpH5></label>
                    <Select
                      className="w-100"
                      showSearch
                      defaultValue={paymentType || 'Select Payment Type'}
                      onChange={onPaymentTypeChange}
                    >
                      {OfflinePaymentType.map((item) => (
                        <Option key={item.key} value={item.value}>
                          {item.key}
                        </Option>
                      ))}
                    </Select>
                    <SpError>
                      {simpleValidator.current.message('Payment type', paymentType, 'required')}
                    </SpError>
                  </div>
                  {
                    paymentType && paymentType === 'CUSTOM' && (
                      <div className="mb-3">
                        <label htmlFor=""><SpH5>Payment Note</SpH5></label>
                        <Input
                          placeholder="Offline Payment Note"
                          value={paymentNote}
                          onChange={(e) => setPaymentNote(e.currentTarget.value)}
                        />
                        <SpError>
                          {simpleValidator.current.message('notes', paymentNote, 'required')}
                        </SpError>
                      </div>
                    )
                  }
                </Col>
              </Row>
            </>
          )}
          {current === 1 && (
            <>
              <Row className="mt-4">
                <Col>
                  {
                    category && category.length !== 0 && (
                      <>
                        {
                          category.map((item, i) => (
                            <div className="mb-3">
                              <label htmlFor=""><SpH5>{item.category ? item.category : getByLabelText(item.type)}</SpH5></label>
                              {
                                item.type === 'PRICE_AMOUNT_STOCK_BASED' ? (
                                  <>
                                    <>
                                      <div className="d-flex">
                                        <div className="mr-2">
                                          <Input
                                            placeholder={item.stockQuantity || 'Add max'}
                                            defaultValue={item.quantity}
                                            onChange={(e) => updateQuantityBased(e.currentTarget.value, i)}
                                            onKeyPress={e => {
                                              const keyCode = e.charCode || e.which;
                                              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                                e.preventDefault();
                                              }
                                            }}
                                            onKeyDown={event => {
                                              if (event.key === '.' || event.key === '-') {
                                                event.preventDefault();
                                              }
                                            }}
                                          />
                                          {
                                            item.required ? (
                                              <SpError>
                                                {simpleValidator.current.message('Stock', item.quantity,
                                                  // eslint-disable-next-line max-len
                                                  `required|onlyInteger|minQuantity:${category[i].stockQuantity}`)}
                                              </SpError>
                                            ) : (
                                              <SpError>
                                                {simpleValidator.current.message('Stock', item.quantity,
                                                  // eslint-disable-next-line max-len
                                                  `onlyInteger|minQuantity:${category[i].stockQuantity}`)}
                                              </SpError>
                                            )
                                          }
                                        </div>
                                        {
                                          item.quantity && (
                                            <div style={{ width: '80%' }} className="pt-1">
                                              <SpText fontSize="16px" fontWeight="600">
                                                {item && item.currency && item.currency.prefix} {item.amount}
                                              </SpText>
                                            </div>
                                          )
                                        }
                                      </div>
                                    </>
                                  </>
                                ) : item.type === 'INPUT_PHONE' ? (
                                  <>
                                    <div className="mb-3">
                                      <div className="d-flex">
                                        <div
                                          style={{ width: '20%' }}
                                          onKeyPress={e => countryCodeValidation(e)}
                                        >
                                          <Select
                                            className="w-100"
                                            showSearch
                                            defaultValue={countryCode}
                                            onChange={onCountryCodeChange}
                                          >
                                            {COUNTRY_PHONE_CODE.map((items) => (
                                              <Option key={items} value={items}>
                                                {items}
                                              </Option>
                                            ))}
                                          </Select>
                                        </div>
                                        <div style={{ width: '80%' }}>
                                          <Input
                                            className="InputnumFormate"
                                            placeholder="Phone Number"
                                            value={item.phoneFormate || ''}
                                            onChange={(e) => onPhoneNumberChange1(e, i)}
                                            onBlur={e => {
                                              if (e.currentTarget.value) {
                                                const list = [...category];
                                                list[i].value = e.currentTarget.value;
                                                const value = formatNumber(`${countryCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                                                list[i].phoneFormate = value.substr(value.indexOf(' ') + 1);
                                                setCategory(list);
                                              }
                                            }}
                                          />
                                          <SpError>
                                            {
                                              simpleValidator.current.message(getByLabelText(item.type),
                                                item.value,
                                                `${item.required ? 'required' : ''}|phoneNumValidation:${countryCode}${item.value}`)
                                            }

                                          </SpError>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <Input
                                      placeholder={getByLabelText(item.type)}
                                      value={item.value ? item.value : ''}
                                      onChange={(e) => {
                                        const list = [...category];
                                        list[i].value = e.currentTarget.value;
                                        setCategory(list);
                                      }}
                                    />
                                    <>
                                      {
                                        item.type === 'INPUT_EMAIL' ? (
                                          item.required ? (
                                            <SpError>
                                              {simpleValidator.current.message(getByLabelText(item.type),
                                                category[i].value, 'required|emailId|checkLowerCase')}
                                            </SpError>
                                          ) : (
                                            <SpError>
                                              {simpleValidator.current.message(getByLabelText(item.type),
                                                category[i].value, 'emailId|checkLowerCase')}
                                            </SpError>
                                          )
                                        ) : (
                                          item.required ? (
                                            <SpError>
                                              {simpleValidator.current.message(getByLabelText(item.type),
                                                category[i].value, 'required')}
                                            </SpError>
                                          ) : null
                                        )
                                      }
                                    </>
                                  </>
                                )
                              }

                            </div>
                          ))
                        }
                      </>
                    )
                  }
                </Col>
              </Row>
            </>
          )}
          {current === 2 && (
            <>
              <div className="ml-4 mr-4">

                <div
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >

                  <div
                    className="mt-4 mb-4"
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <p style={{ fontSize: '24px', fontWeight: '700' }}>
                      {eventDetails && eventDetails.title} @ {eventDetails && eventDetails.venueAddress}
                    </p>
                  </div>
                  <div
                    className="mt-4 mb-4"
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <p style={{ fontSize: '18px' }}>
                      {
                        eventDetails && moment.utc(eventDetails.eventDate).format('MMM DD, YYYY')
                      },
                      {getOperatingTime(eventDetails && eventDetails.eventStartTime)} - {getOperatingTime(eventDetails && eventDetails.eventEndTime)}
                    </p>
                  </div>
                  <SeatsioSeatingChart
                    workspaceKey={publicKey}
                    pricing={seatInfo}
                    priceFormatter={(price) => formatPrice(price)}
                    openDraftDrawing="true"
                    event={eventKey}
                    region="na"
                    onObjectSelected={onObjectSelected}
                    onObjectDeselected={(item, price) => onObjectDeselected(item, price)}
                    holdOnSelect={false}
                    tooltipInfo={(object) => tooltipFormat(object)}
                  />
                </div>
                <div
                  style={{
                    justifyContent: 'start',
                    textAlign: 'center',
                  }}
                  className="pt-4"
                >
                  <div>
                    <div
                      className="pt-4"
                      style={{
                        justifyContent: 'start',
                      }}
                    >
                      <p style={{ fontSize: '20px', fontWeight: '600' }}>Order Summary</p>
                    </div>
                    {
                      selectedSeats && selectedSeats.length !== 0 && (
                        <>
                          {
                            selectedSeats.map((item) => (
                              <div
                                className="mt-4"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'start',
                                }}
                              >
                                <div
                                  style={{
                                    width: '50%',
                                    justifyContent: 'start',
                                  }}
                                >
                                  {
                                    item.numSelected
                                      ? <p style={{ fontSize: '18px', fontWeight: '600' }}>{item.seatId} - {item.numSelected}</p>
                                      : <p style={{ fontSize: '18px', fontWeight: '600' }}>{item.seatId}</p>
                                  }
                                </div>
                                <div
                                  style={{
                                    width: '50%',
                                    justifyContent: 'start',
                                  }}
                                >
                                  <p style={{ fontSize: '16px' }}>
                                    {currencyFormatter((item.seatPrice), eventDetails && eventDetails.seatPageMetaTickets[0].currency.name)}
                                  </p>
                                </div>
                              </div>
                            ))
                          }
                        </>
                      )
                    }


                    <hr />

                    <div
                      className="mt-4"
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                      }}
                    >
                      <div
                        style={{
                          width: '50%',
                          justifyContent: 'start',
                        }}
                      >
                        <p style={{ fontSize: '18px', fontWeight: '600' }}>Subtotal</p>
                      </div>
                      <div
                        style={{
                          width: '50%',
                          justifyContent: 'start',
                        }}
                      >
                        <p style={{ fontSize: '16px' }}>{selectedSeats.length !== 0 ? getSubToatal(selectedSeats) : '$ 0:00'}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  test: state.account.test,
  submitting: state.loading.submitting,
  selectedAccount: state.account.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(OfflinePaymentPage);
