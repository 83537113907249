// @flow
import React, {
  useRef,
  useState,
  createRef,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';
import {
  Modal,
  Steps,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  subscriptionv2Actions,
  notificationActions,
  invoiceDetailsAction,
  inventoryItemsActions,
  inventoryPrinterLabelActions,
} from 'store/actions';
import {
  axiosAuthInstance,
  axiosAuthInstance2,
  API_END_POINTS,
} from 'api';
import { formatNumber, findPhoneNumbersInText } from 'libphonenumber-js';
import { NOTIFICATION } from 'store/actionTypes';
import formatAmount from 'utils/formatAmount';
import moment from 'moment-timezone';
import { SpButton } from 'components/DesignKit';
import getCountryAlpha2 from 'utils/getCountryAlpha2';
import AddItem from '../../../../InventoryOrders/components/ItemsList/components/AddNewItem';

import PlanDetails from './PlanDetails';
import LinkDetails from './LinkDetails';
import ReviewSubscription from './ReviewSubscription';
import AddNewProduct from './AddNewProduct';
import SelectCoupon from './SelectCoupon';
import AddCoupon from './AddNewCoupon';
import SelectTaxrate from './SelectTaxrate';
import AddTaxrate from './AddNewTax';

type Props = {
  close: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
  subscriptionDetails: Object,
  plans: Array<Object>,
  products: Array<Object>,
  planResult: Array<Object>,
  customerResult: Array<Object>,
  productResult: Array<Object>,
  fetchPlanV2Search: Function,
  getCustomersList: Function,
  setNotification: Function,
  phoneCode: string,
  submit: Function,
  applyCoupon: Function,
  role: Object,
  applyTaxRate: Function,
  supportedCurrency: Array,
  getProductsList: Function,
  productsList: Array<Object>,
  addNewProcut: Function,
  validator: boolean,
  addedNewProduct: Object,
  subScriptionModal: Function,
  newCoupon: Object,
  addNewCoupon: Function,
  getTaxes: Function,
  taxList: Array<Object>,
  addNewTaxRate: Function,
  newTaxrate: Object,
  statesInCountry: Array<Object>,
  zipTag: String,
  cntryId: String,
  fetchInventoryItems: Function,
  inventoryItems: Array<Object>,
  internalAccount: Object,
  fetchItemsList: Function,
  fetchPrinterLabelList: Function,
  fetchModifierItemsList: Function,
  itemsList: Array<Object>,
  modifierItemsList: Array<Object>,
  labelList: Array,
  addInventoryItems: Function,
  hanldleAddInventoryRedirect: Function,
  hanldleItemModalRedirect: Function,
  addedNewItem:Object,

};

const { Step } = Steps;

const AddSubscription = (props: Props) => {
  const {
    visible,
    close,
    plans,
    planResult,
    customerResult,
    productResult,
    products,
    subscriptionDetails,
    submitting,
    selectedAccount,
    phoneCode,
    role,
    submit,
    fetchPlanV2Search,
    getCustomersList,
    setNotification,
    applyCoupon,
    applyTaxRate,
    supportedCurrency,
    getProductsList,
    productsList,
    addNewProcut,
    validator,
    addedNewProduct,
    subScriptionModal,
    newCoupon,
    addNewCoupon,
    getTaxes,
    taxList,
    addNewTaxRate,
    newTaxrate,
    statesInCountry,
    zipTag,
    cntryId,
    fetchInventoryItems,
    inventoryItems,
    internalAccount,
    addInventoryItems,
    fetchItemsList,
    fetchModifierItemsList,
    fetchPrinterLabelList,
    itemsList,
    modifierItemsList,
    labelList,
    hanldleAddInventoryRedirect,
    hanldleItemModalRedirect,
    addedNewItem,
  } = props;

  const [, forceUpdate] = useState();
  const location = useLocation();
  const planDetailsRef = createRef();
  const linkDetailsRef = createRef();
  const [refArray, setRefArray] = useState([planDetailsRef, linkDetailsRef]);
  const [current, setCurrent] = useState(0);
  const currencyType = selectedAccount && selectedAccount.currency;
  const simpleValidator = useRef(new SimpleReactValidator());
  // eslint-disable-next-line max-len
  const [planAamount, setPlanAmount] = useState((subscriptionDetails && subscriptionDetails.planAmount) ? formatAmount(subscriptionDetails.planAmount / 100, currencyType) : '');
  // eslint-disable-next-line max-len
  const [billAmount, setBillAmount] = useState((subscriptionDetails && subscriptionDetails.planAmount) ? formatAmount((subscriptionDetails.planAmount / 100) * (subscriptionDetails.planQuantity), currencyType) : '');
  const [planName, setPlanName] = useState(subscriptionDetails ? subscriptionDetails.plan.name : '');
  const [prefix, setPrefix] = useState(subscriptionDetails
    ? (subscriptionDetails.currency && subscriptionDetails.currency.prefix)
    : (selectedAccount.currency && selectedAccount.currency.prefix));
  const [currencyCode, setCurrencyCode] = useState(subscriptionDetails
    ? (subscriptionDetails.currency && subscriptionDetails.currency.name)
    : (selectedAccount.currency && selectedAccount.currency.name));
  const [period, setPeriod] = useState(subscriptionDetails ? subscriptionDetails.planBillingPeriod : 1);
  const [descriptions, setDescriptions] = useState(subscriptionDetails ? subscriptionDetails.plan.description : '');
  const [counts, setCounts] = useState(subscriptionDetails ? subscriptionDetails.planTotalPayments : '');
  const [qtys, setQtys] = useState(subscriptionDetails ? subscriptionDetails.planQuantity : 1);
  const [planDate, setPlanDate] = useState('');
  const [pfrequency, setPfrequency] = useState(subscriptionDetails ? subscriptionDetails.planBillingFrequency : 'MONTH');
  const [startDates, setStartDates] = useState(false);
  const [plansGid, setPlansGid] = useState(subscriptionDetails ? subscriptionDetails.plan.gid : '');
  const [planList, setPlanList] = useState(plans);
  const [apiPlanDate, setApiPlanDate] = useState('');
  const [customerName, setCustomerName] = useState(subscriptionDetails ? subscriptionDetails.customer.name : '');
  // eslint-disable-next-line max-len
  const [applicationFee, setApplicationFee] = useState((subscriptionDetails && subscriptionDetails.applicationFeePercentage) ? formatAmount(subscriptionDetails.applicationFeePercentage / 100, currencyType) : '0.00');
  const [productEmail, setProductEmail] = useState(subscriptionDetails ? subscriptionDetails.customer.email : '');
  const [productphNumber, setProductPhone] = useState('');
  const [phoneFormate, setPhoneFormate] = useState();
  // eslint-disable-next-line max-len
  const [notes, setNotes] = useState((subscriptionDetails && subscriptionDetails.subscriptionLineItems) ? subscriptionDetails.subscriptionLineItems[0].note : '');
  const [productData, setProductData] = useState(products);
  const [customerList, setCustomerList] = useState(customerResult);
  const [customersGid, setCustomersGid] = useState(subscriptionDetails ? subscriptionDetails.customer.gid : '');
  const [countryCodes, setCountryCodes] = useState('');
  // eslint-disable-next-line max-len
  const [notify, setNotify] = useState((subscriptionDetails && subscriptionDetails.notificationType) ? subscriptionDetails.notificationType : 'Email');
  const [disableSubscriptionPlan, setDisableSubscriptionPlan] = useState(false);
  const [disableSubscriptionCustomer, setDisableSubscriptionCustomer] = useState(false);
  const [lineItemDtoList, setLineItemDtosList] = useState([{
    amount: 0,
    rate: 0,
    productGid: '',
    currencyCode: '',
    description: '',
    name: '',
    note: '',
    quantity: '1',
    recurring: false,
    itemGid: '',
  }]);
  const [disableButton, setDisableButton] = useState(false);
  const [addProductModal, setAddProductModal] = useState(false);
  const [addItemModal, setAddItemModal] = useState(false);
  const [addedProduct, setAddedProduct] = useState(false);
  const [addedItem, setAddedItem] = useState(false);
  const dateFormat = 'MMM DD, YYYY';
  const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
  const [subscriptionAmount, setSubScriptionAmount] = useState();
  const [updatedCoupon, setUpdatedCoupon] = useState({});
  const [couponRedirect, setCouponRedirect] = useState(false);
  const [addCouponModal, setAddCouponModal] = useState(false);
  const [selectCouponModal, setSelectCouponModal] = useState(false);
  const [selectTaxrateModal, setSelectTaxrateModal] = useState(false);
  const [addTaxModal, setAddTaxModal] = useState(false);
  const [allTaxes, setAllTaxes] = useState(taxList);
  const [taxRedirect, setTaxRedirect] = useState(false);
  const [allSelectedTaxrates, setAllSelectedTaxrates] = useState([]);
  const [streetName, setStreetName] = useState('');
  const [cityName, setCityName] = useState('');
  const [stateName, setStateName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [streetName1, setStreetName1] = useState('');
  const [cityName1, setCityName1] = useState('');
  const [stateName1, setStateName1] = useState('');
  const [zipCode1, setZipCode1] = useState('');
  const [disableAddressDetails, setDisableAddressDetails] = useState(false);
  const [addon, setAddon] = useState(false);
  const countryCode = (getCountryAlpha2(cntryId));
  const countryName = selectedAccount && selectedAccount.country && selectedAccount.country.name;
  const [cupoAaddon, setCupoAaddon] = useState({});
  const [allRatesAaddon, setAllRatesAaddon] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [shippingAmount, setShippingAmount] = useState(subscriptionDetails ? subscriptionDetails.shippingAmount : null);
  const legacySubscriber = selectedAccount && selectedAccount.legacySubscription;

  const planData = {
    planAamount,
    billAmount,
    planName,
    shippingAmount,
    period,
    descriptions,
    counts,
    qtys,
    planDate,
    apiPlanDate,
    pfrequency,
    startDates,
    plansGid,
    planList,
    applicationFee,
    disableSubscriptionPlan,
    subscriptionAmount,
    notify,
    prefix,
    currencyCode,
    lineItemDtoList,
    cupoAaddon,
    allRatesAaddon,
    totalAmount,
  };

  const linkData = {
    productEmail,
    productphNumber,
    notes,
    customersGid,
    countryCodes,
    customerList,
    customerName,
    productData,
    phoneFormate,
    disableSubscriptionCustomer,
    streetName,
    cityName,
    stateName,
    zipCode,
    streetName1,
    cityName1,
    stateName1,
    zipCode1,
    countryCode,
    countryName,
    disableAddressDetails,
    addon,
  };

  const fetchPlanDetails = (search) => {
    fetchPlanV2Search(search);
  };

  const fetchCustomerDetails = (search) => {
    getCustomersList(search);
  };
  const debouncedFetchInventoryItems = debounce(fetchInventoryItems, 2000, { leading: true });

  const getDataItems = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'name';
    const direction = parsed.direction || 'ASC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    // const sortParam = '&sortBy=sortOrder&direction=ASC';
    // const filterParam = parsed.filterParams || '';
    // const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    // const nameSearch = searchText ? (searchText === '' ? null : `&name.LIKE=${searchText}`) : null;
    debouncedFetchInventoryItems({
      currentPage, sortParams,
    });
  };


  useEffect(() => {
    getDataItems(location.search);
  }, [selectedAccount]);

  useEffect(() => {
    getProductsList();
  }, [selectedAccount]);

  const getListItems = () => {
    const sortParams = '&sortBy=name&direction=ASC';
    const currentPage = 1;
    const filterParams = '';
    debouncedFetchInventoryItems({
      currentPage, sortParams, filterParams,
    });
  };

  useEffect(() => {
    setPlanList(planResult || planList);
  }, [planResult]);

  useEffect(() => {
    setCustomerList(customerResult);
  }, [customerResult]);

  useEffect(() => {
    setProductData(productResult || products);
  }, [productResult]);

  useEffect(() => {
    if (!validator) {
      setAddProductModal(false);
      setAddCouponModal(false);
      setAddTaxModal(false);
      setAddItemModal(false);
    }
  }, [validator]);

  useEffect(() => {
    if (newCoupon.gid && couponRedirect) {
      setUpdatedCoupon(newCoupon);
    }
  }, [newCoupon]);

  const fetchCustomer = (searchText) => {
    getCustomersList(searchText);
  };

  const addNewProductforPlan = () => {
    setAddProductModal(true);
  };

  const addNewItemforPlan = () => {
    setAddItemModal(true);
    fetchItemsList('&sortBy=name&direction=DESC');
    fetchModifierItemsList('&sortBy=name&direction=DESC');
    fetchPrinterLabelList('&sortBy=createdAt&direction=DESC');
    hanldleItemModalRedirect(true);
  };

  const addNewCuponforPlan = (value) => {
    setSelectCouponModal(value);
  };

  const addNewTaxforPlan = (value) => {
    setSelectTaxrateModal(value);
  };

  const updateCoupon = (Coupon: Object) => {
    setUpdatedCoupon(Coupon);
    setSelectCouponModal(false);
  };
  const updateShippingFee = (amount: Number) => {
    setShippingAmount(amount);
  };

  const removeCoupon = () => {
    setUpdatedCoupon({});
    // setCouponMsg('');
    simpleValidator.current.purgeFields();
  };

  const addCoupon = (coupon: Object) => {
    const {
      name,
      percentageOff,
      amountOff,
      validity,
      expiresAt,
      maximumRedemption,
      code,
      minimumAmount,
      promotionalRedeemed,
      promoExpiresAt,
      promotional,
      customerGid,
      validiyInMonths,
    } = coupon;
    const params = {
      active: true,
      name,
      percentageOff: (percentageOff ? parseFloat(percentageOff) : null),
      amountOff: (amountOff ? parseInt((amountOff).replace(/[_\W]+/g, ''), 10) : null),
      validity,
      expiresAt,
      maximumRedemption: (maximumRedemption ? parseInt(maximumRedemption, 10) : null),
      validiyInMonths: (validiyInMonths ? parseInt(validiyInMonths, 10) : null),
      currencyCode,
    };
    const payload = {
      active: true,
      code,
      enabledForCheckout: true,
      expiresAt: promoExpiresAt,
      maximumRedemption: promotionalRedeemed ? parseInt(promotionalRedeemed, 10) : null,
      minimumAmount: minimumAmount ? parseInt((minimumAmount).replace(/[_\W]+/g, ''), 10) : null,
      minimumAmountCurrency: currencyCode,
      restrictedToCustomers: [
        customerGid,
      ],
    };
    subScriptionModal(true);
    if (promotional) {
      addNewCoupon({
        params,
        payload,
      });
    } else if (!promotional) {
      addNewCoupon({
        params,
      });
    }
    setCouponRedirect(true);
  };

  const newCouponAdd = () => {
    setSelectCouponModal(false);
    setAddCouponModal(true);
  };

  const addNewProduct = (newProduct: Object) => {
    const {
      productName,
      currency,
      productDescription,
      amount,
      productNotes,
      upfront,
    } = newProduct;

    const productAmount = amount.replace(/[. ,:-]+/g, '');

    const params = {
      name: productName,
      currencyCode: currency,
      description: productDescription,
      amount: Number(productAmount),
      note: productNotes,
      paymentMethodType: [
        'CARD',
      ],
      statementDescription: '',
      upfront,
    };

    addNewProcut({
      params,
    });
    subScriptionModal(true);
    setAddedProduct(true);
  };

  useEffect(() => {
    if (newTaxrate.gid && taxRedirect) {
      const listOfTaxes = [...allSelectedTaxrates];
      const taxRates = {};
      Object.assign(taxRates, newTaxrate);
      const taxRate = {
        taxRates,
      };
      listOfTaxes.push(taxRate);
      setAllSelectedTaxrates(listOfTaxes);
    }
  }, [newTaxrate]);

  useEffect(() => {
    setAllTaxes(taxList);
  }, [taxList]);

  const newTaxAdd = () => {
    setSelectTaxrateModal(false);
    setAddTaxModal(true);
  };

  const fetchTaxDetails = (searchParams) => {
    getTaxes(searchParams);
  };

  const onItemSearch = (itemSearch) => {

    const sortParams = '&sortBy=name&direction=ASC';
    const currentPage = 1;
    const filterParams = '';
    debouncedFetchInventoryItems({
      currentPage, sortParams, filterParams, nameSearch: itemSearch ? (itemSearch === '' ? null : `&name.LIKE=${itemSearch}`) : null,
    });
  };

  const onProductSearch = (productSearch) => {
    getProductsList(productSearch);
  };

  const updateTaxrate = (Taxrate: Object) => {
    const listOfTaxes = [...allSelectedTaxrates];
    listOfTaxes.push(Taxrate);
    setAllSelectedTaxrates(listOfTaxes);
    setSelectTaxrateModal(false);
  };

  const removeTax = (i) => {
    const listOfTaxes = [...allSelectedTaxrates];
    listOfTaxes.splice(i, 1);
    setAllSelectedTaxrates(listOfTaxes);
  };

  const addTaxrate = (taxRate: Object) => {
    const {
      displayName,
      jurisdiction,
      percentage,
      inclusive,
      taxDescription,
    } = taxRate;
    const params = {
      displayName,
      jurisdiction,
      percentage,
      inclusive,
      description: taxDescription,
    };
    addNewTaxRate({
      params,
    });
    subScriptionModal(true);
    setTaxRedirect(true);
  };

  useEffect(() => {
    setCustomerList(customerResult);
    if (subscriptionDetails && subscriptionDetails.gid) {
      const displayFormat = moment(subscriptionDetails.planStartDate).format(dateFormat);
      setPlanDate(displayFormat);
      setApiPlanDate(subscriptionDetails.planStartDate);
      setDisableSubscriptionPlan(true);
      setDisableSubscriptionCustomer(true);
      if (subscriptionDetails.customer && subscriptionDetails.customer.phoneNumber) {
        const phone = subscriptionDetails.customer.phoneNumber || null;
        const phoneResult = phone ? findPhoneNumbersInText(phone) : null;
        if ((phoneResult && phoneResult.length > 0) && phoneResult[0].number) {
          setCountryCodes(`+${phoneResult[0].number.countryCallingCode}`);
          setProductPhone(phoneResult[0].number.nationalNumber);
          const data = formatNumber(phoneResult[0].number.number, 'INTERNATIONAL');
          const formater = data.substr(data.indexOf(' ') + 1);
          setPhoneFormate(formater);
        }
      }
    } else {
      const displayFormat = moment().utc().tz(selectedAccount.timezone).startOf('day')
        .format(dateFormat);
      // eslint-disable-next-line
      const selectedDate = moment.utc().tz(selectedAccount.timezone).startOf('day').utc().format(MOMENT_FORMAT);
      if (selectedAccount && selectedAccount.timezone) {
        setPlanDate(displayFormat);
        setApiPlanDate(selectedDate);
      }
    }
  }, []);

  useEffect(() => {
    if (current === 2 && !submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  useEffect(() => {
    setRefArray(existingElements => (
      [...existingElements]
    ));
  }, []);

  const next = async () => {
    const validationArray = [];
    const valuesArray = [];
    if (current === 0) {
      validationArray.push(refArray[0].current.validate());
      valuesArray.push(refArray[0].current.getValues());
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setPlanAmount(valuesArray[0].amount);
      setBillAmount(valuesArray[0].billAmount);
      setPlanName(valuesArray[0].planName);
      setShippingAmount(valuesArray[0].shippingAmount)
      setPeriod(valuesArray[0].period);
      setDescriptions(valuesArray[0].description);
      setCounts(valuesArray[0].count);
      setQtys(valuesArray[0].qty);
      setPlanDate(valuesArray[0].dop);
      setStartDates(valuesArray[0].startDate);
      setPfrequency(valuesArray[0].frequency);
      setPlansGid(valuesArray[0].planGid);
      setApiPlanDate(valuesArray[0].planApiDate);
      setApplicationFee(valuesArray[0].applicationFee ? valuesArray[0].applicationFee : '0.00');
      setDisableSubscriptionPlan(valuesArray[0].disablePlanDetails);
      setSubScriptionAmount(valuesArray[0].subscriptionAmount);
      setPrefix(valuesArray[0].prefix);
      setCurrencyCode(valuesArray[0].currencyCode);
      setNotify(valuesArray[0].notify);
      setLineItemDtosList(valuesArray[0].lineItemDtoList);
      setCupoAaddon(valuesArray[0].cupoAaddon);
      setAllRatesAaddon(valuesArray[0].allRatesAaddon);
      setTotalAmount(valuesArray[0].totalAmount);
      setCurrent(current + 1);
    } else if (current === 1) {
      validationArray.push(refArray[1].current.validate());
      const linkDetails = refArray[1].current.getValues();
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setAddedProduct(false);
      setAddedItem(false);
      setCustomerName(linkDetails.customersName);
      setProductEmail(linkDetails.email);
      setProductPhone(linkDetails.phoneNumber);
      setPhoneFormate(linkDetails.phoneFormate);
      setDisableSubscriptionCustomer(linkDetails.disableCustomerDetails);
      setNotes(linkDetails.note);
      setCustomersGid(linkDetails.customerGid);
      setCountryCodes(linkDetails.countryCode);
      setStreetName(linkDetails.streetName);
      setCityName(linkDetails.cityName);
      setStateName(linkDetails.stateName);
      setZipCode(linkDetails.zipCode);
      setStreetName1(linkDetails.streetName1);
      setCityName1(linkDetails.cityName1);
      setStateName1(linkDetails.stateName1);
      setZipCode1(linkDetails.zipCode1);
      setDisableAddressDetails(linkDetails.disableAddressDetails);
      setAddon(linkDetails.addon);
      setCurrent(current + 1);
    } else if (current === 2) {
      setDisableButton(true);
      let planAddGid;
      let customerAddGid;
      if (!planData.plansGid) {
        const planObject = {
          name: planData.planName,
          description: planData.descriptions,
          currencyCode: planData.currencyCode,
          billingFrequency: planData.pfrequency,
          billingPeriod: planData.period,
        };
        try {
          const { data: { entity: { gid } } } = await axiosAuthInstance2.post(API_END_POINTS.PLAN, planObject);
          planAddGid = gid;
          setPlansGid(gid);
        } catch (error) {
          setNotification({
            type: NOTIFICATION.ERROR,
            payload: error.response.data && error.response.data.message,
          });
        }
      }
      let planDataGid;
      if (planAddGid) {
        planDataGid = {
          planAddGid,
        };
      }
      let shippingAddress = null;
      if (linkData.streetName1 && linkData.cityName1 && linkData.stateName1 && linkData.zipCode1) {
        shippingAddress = {
          street: linkData.streetName1,
          city: linkData.cityName1,
          state: linkData.stateName1,
          postalCode: linkData.zipCode1,
          countryCode: linkData.countryCode,
        };
      }
      if (!linkData.customersGid) {
        const customerObject = {
          email: linkData.productEmail,
          name: linkData.customerName,
          phoneNumber: linkData.productphNumber ? `${linkData.countryCodes}${linkData.productphNumber}` : null,
          billingAddress: {
            street: linkData.streetName,
            city: linkData.cityName,
            state: linkData.stateName,
            postalCode: linkData.zipCode,
            countryCode: linkData.countryCode,
          },
          shippingAddress: shippingAddress || null,
        };
        try {
          const { data: { entity: { gid } } } = await axiosAuthInstance.post(API_END_POINTS.ADD_CUSTOMERS, customerObject);
          customerAddGid = gid;
        } catch (error) {
          setNotification({
            type: NOTIFICATION.ERROR,
            payload: error.response.data && error.response.data.message,
          });
        }
      }
      if (linkData.customersGid && !disableAddressDetails) {
        const customerObject = {
          email: linkData.productEmail,
          name: linkData.customerName,
          phoneNumber: linkData.productphNumber ? `${linkData.countryCodes}${linkData.productphNumber}` : null,
          billingAddress: {
            street: linkData.streetName,
            city: linkData.cityName,
            state: linkData.stateName,
            postalCode: linkData.zipCode,
            countryCode: linkData.countryCode,
          },
          shippingAddress: shippingAddress || null,
        };
        try {
          // eslint-disable-next-line max-len
          const { data: { entity: { gid } } } = await axiosAuthInstance.patch(`${API_END_POINTS.ADD_CUSTOMERS}/${linkData.customersGid}`, customerObject);
          customerAddGid = gid;
        } catch (error) {
          setNotification({
            type: NOTIFICATION.ERROR,
            payload: error.response.data && error.response.data.message,
          });
        }
      }
      let linkDataGid;
      if (customerAddGid) {
        linkDataGid = {
          customerAddGid,
        };
      }
      subScriptionModal(false);
      const payload = Object.assign(planData, linkData, planDataGid, linkDataGid, lineItemDtoList, allSelectedTaxrates, shippingAmount);
      submit(
        payload,
      );
    }
  };

  const getListProducts = () => {
    getProductsList();
  };

  const addSelectedItem = (customer: Object) => {
    const {
      name,
      price,
      alternateName,
      onlineName,
      onlineEnabled,
      sku,
      isPos,
      priceType,
      primaryColor,
      categories,
      order,
      modifierGroups,
      isItemAvailable,
      cost,
      logoUrl,
      // eslint-disable-next-line no-shadow
      description,
      tagDetails,
      enableStock,
      enableDynamicAvailability,
      stockQuantity,
      onlineAvailability,
      posAvailability,
      shopDetails,
      isRecurring,

    } = customer;
    const totalPrice = price ? price.replace(/[^\d]/g, '') : 0;
    const totalCost = cost ? cost.replace(/[^\d]/g, '') : 0;

    const params = {
      name,
      alternateName,
      onlineName,
      onlineEnabled,
      sku,
      price: Number(totalPrice),
      hidden: !isPos,
      priceType,
      sortOrder: Number(order),
      colorCode: primaryColor,
      categoryGids: categories || null,
      shopGids: shopDetails || [],
      modifierGroupGids: modifierGroups || null,
      available: isItemAvailable,
      cost: Number(totalCost),
      imageUrl: logoUrl,
      description,
      tagGids: tagDetails || [],
      enableStock,
      enableDynamicAvailability,
      stockQuantity: Number(stockQuantity),
      onlineAvailability: Number(onlineAvailability * 100),
      posAvailability: Number(posAvailability * 100),
      recurring: isRecurring,
    };
    addInventoryItems({
      params,
    });
    setAddedItem(true);
    hanldleAddInventoryRedirect(true);
  };
  const prev = () => {
    setDisableButton(false);
    if (current === 0) {
      close();
    } else if (current === 1) {
      setCurrent(current - 1);
      setAddedProduct(false);
      setAddedItem(false);
    } else if (current === 2) {
      setCurrent(current - 1);
    } else if (current === 3) {
      setCurrent(current - 1);
    }
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      width="640px"
      footer={current === 0
        ? [
          <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
          <SpButton key="2" onClick={next}>Next</SpButton>,
        ]
        : current === 1
          ? [
            <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
            <SpButton key="3" onClick={prev} type="secondary">Previous</SpButton>,
            <SpButton key="2" onClick={next}>Next</SpButton>,
          ]
          : [
            <SpButton key="1" onClick={() => close()} type="secondary">Cancel</SpButton>,
            <SpButton key="3" onClick={prev} type="secondary">Previous</SpButton>,
            <SpButton key="2" onClick={next} disabled={disableButton}>Create Subscription</SpButton>,
          ]}
      title="Add Subscription"
    >
      <>
        <div style={{ marginTop: '-8px' }}>
          <Steps current={current} className="subscriptions">
            <Step key="1" title="Plan Details" />
            <Step key="2" title="Link Details" />
            <Step key="3" title="Review" />
          </Steps>
        </div>
        <div className="px-5 mt-2">
          <div className={submitting ? 'OVERLAY' : ''} />
          {current === 0 && (
            <PlanDetails
              ref={refArray[0]}
              selectedAccount={selectedAccount}
              planData={planData}
              fetchPlanDetails={fetchPlanDetails}
              applyCoupon={applyCoupon}
              updatedCoupon={updatedCoupon}
              applyTaxRate={applyTaxRate}
              allSelectedTaxrates={allSelectedTaxrates}
              supportedCurrency={supportedCurrency}
              removeCoupon={removeCoupon}
              role={role}
              removeTax={removeTax}
              productsList={productsList}
              getListItems={getListItems}
              addNewProductforPlan={addNewProductforPlan}
              addNewItemforPlan={addNewItemforPlan}
              addNewCuponforPlan={addNewCuponforPlan}
              addNewTaxforPlan={addNewTaxforPlan}
              addedNewProduct={addedNewProduct}
              addedProduct={addedProduct}
              addedItem={addedItem}
              onItemSearch={onItemSearch}
              inventoryItems={inventoryItems}
              legacySubscriber={legacySubscriber}
              getListProducts={getListProducts}
              onProductSearch={onProductSearch}
              getDataItems={getDataItems}
              addedNewItem={addedNewItem}
              updateShippingAmount={updateShippingFee}

            />
          )}
          {/* ADD PRODUCT MODAL */}
          {addProductModal && (
            <AddNewProduct
              visible={addProductModal}
              submitting={submitting}
              supportedCurrency={supportedCurrency}
              close={() => setAddProductModal(false)}
              submit={addNewProduct}
              selectedAccount={selectedAccount}
            />
          )}
          {/* ADD ITEM MODAL */}

          {addItemModal && (
            <AddItem
              visible={addItemModal}
              submitting={submitting}
              itemsList={itemsList && itemsList}
              modifierItemsList={modifierItemsList && modifierItemsList}
              labelList={labelList && labelList}
              selectedAccount={selectedAccount}
              close={() => setAddItemModal(false)}
              submit={addSelectedItem}
            />
          )}
          {/* SELECT COUPON MODAL */}
          {selectCouponModal && (
            <SelectCoupon
              visible={selectCouponModal}
              submitting={submitting}
              close={() => setSelectCouponModal(false)}
              submit={updateCoupon}
              newCouponAdd={newCouponAdd}
            />
          )}

          {/* ADD COUPON MODAL */}
          {addCouponModal && (
            <AddCoupon
              visible={addCouponModal}
              submitting={submitting}
              close={() => setAddCouponModal(false)}
              submit={addCoupon}
              selectedAccount={selectedAccount}
              allCustomers={customerResult}
              fetchCustomer={fetchCustomer}
            />
          )}

          {/* SELECT TAXRATE MODAL */}
          {selectTaxrateModal && (
            <SelectTaxrate
              visible={selectTaxrateModal}
              submitting={submitting}
              close={() => setSelectTaxrateModal(false)}
              submit={updateTaxrate}
              newTaxAdd={newTaxAdd}
            />
          )}

          {/* ADD TAX MODAL */}
          {addTaxModal && (
            <AddTaxrate
              visible={addTaxModal}
              submitting={submitting}
              close={() => setAddTaxModal(false)}
              submit={addTaxrate}
              selectedAccount={selectedAccount}
              allTaxes={allTaxes}
              fetchTaxDetails={fetchTaxDetails}
            />
          )}
          {current === 1 && (
            <LinkDetails
              ref={refArray[1]}
              selectedAccount={selectedAccount}
              phoneCode={phoneCode}
              linkData={linkData}
              statesInCountry={statesInCountry}
              zipTag={zipTag}
              fetchCustomerDetails={fetchCustomerDetails}
              countryCode={countryCode}
              countryName={countryName}
              shippingAmount={shippingAmount}
            />
          )}
          {current === 2 && (
            <ReviewSubscription
              planData={planData}
              linkData={linkData}
              prefix={prefix}
              lineItemDtoList={lineItemDtoList}
              allSelectedTaxrates={allSelectedTaxrates}
              selectedAccount={selectedAccount}
              totalAmount={totalAmount}
              updatedCoupon={updatedCoupon}
              shippingAmount={shippingAmount}
            />
          )}
        </div>
      </>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  products: state.subscription.products.content,
  planResult: state.subscriptionv2.plans.content,
  customerResult: state.invoiceDetails.customersList,
  productsList: state.invoiceDetails.productsList,
  productResult: state.subscription.productsearch.content,
  addedNewProduct: state.invoiceDetails.newProduct,
  plans: state.planv2.content,
  test: state.account.test,
  submitting: state.loading.submitting,
  selectedAccount: state.account.selectedAccount,
  validator: state.loading.validator,
  newCoupon: state.invoiceDetails.newCoupon,
  taxList: state.invoiceDetails.taxList,
  newTaxrate: state.invoiceDetails.newTaxrate,
  inventoryItems: state.inventoryItems.content,
  internalAccount: state.account.internalAccount,
  modifierItemsList: state.inventoryItems.modifierItemsList.content,
  itemsList: state.inventoryItems.itemsList.content,
  labelList: state.inventoryPrinterLabel.labelList.content,
  addedNewItem: state.inventoryItems.addedItem.entity,


});

const mapDispatchToProps = (dispatch) => ({
  fetchInventoryItems: param => dispatch(inventoryItemsActions.fetchInventoryItems({
    payload: param,
  })),
  fetchPlanV2Search: param => dispatch(subscriptionv2Actions.fetchPlanV2Search({
    payload: param,
  })),
  getCustomersList: param => dispatch(invoiceDetailsAction.getCustomersList({
    payload: param,
  })),
  getProductsList: param => dispatch(invoiceDetailsAction.getProductsList({
    payload: param,
  })),
  addNewProcut: param => dispatch(invoiceDetailsAction.addNewProcut({
    payload: param,
  })),
  addNewCoupon: param => dispatch(invoiceDetailsAction.addNewCoupon({
    payload: param,
  })),
  getTaxes: param => dispatch(invoiceDetailsAction.getTaxes({
    payload: param,
  })),
  addNewTaxRate: param => dispatch(invoiceDetailsAction.addNewTaxRate({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchItemsList: param => dispatch(inventoryItemsActions.fetchItemsList({
    payload: param,
  })),
  fetchPrinterLabelList: (payload) => dispatch(inventoryPrinterLabelActions.fetchPrinterLabelList({
    payload,
  })),
  fetchModifierItemsList: param => dispatch(inventoryItemsActions.fetchModifierItemsList({
    payload: param,
  })),
  addInventoryItems: param => dispatch(inventoryItemsActions.addInventoryItems({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AddSubscription);
